import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { GogglesHelmetsType } from "../../components/layouts/header/navbar/categories-menu/category-item/menuStructure";
import { FaqItem, News, NewsMap } from "../../interfaces/cmsInterfaces";
import { ItemsMap, ItemsTutorialPillMap, Page } from "../../interfaces/gridInterfaces";
import { Lang, StoreConfiguration } from "../../interfaces/mainInterfaces";
import {
  BannerContent,
  Brand,
  BrandGroups,
  BrandsAndEssilor,
  FirstLevelMenu,
  MenuContentsId,
  SecondLevelMenu,
  StripContent,
  ThirdLevelMenuObj,
} from "../../interfaces/menuInterfaces";
import {
  Popup,
  PopupAccessAccounting,
  PopupLpVariants,
  PopupSapDecoupling,
  PopupServiceRequest,
  PopupVideoSetting,
} from "../../interfaces/popupInterfaces";
import { mapBrandGroupByBrandUtil } from "../../utils/menuUtils";
import { partitionArray } from "../../utils/utils";
import {
  LpVariants,
  TechnologyIconsObj,
  TechnologyIconsPayload,
  TutorialPill,
} from "../catalogue/catalogueInterface";
import { TermAssociation } from "../search/searchInterfaces";
import { RootState } from "../storeConfig";
import { FacetView } from "./../../interfaces/facetInterfaces";
import {
  GenericCustomObject,
  LXConfiguration,
  RequestStatus,
} from "./../../interfaces/mainInterfaces";
import {
  ApiError,
  ApiErrorStatus,
  BrandImagesService,
  BrandsImages,
  CMSContent,
  CMSTutorialPillsContent,
  CheckMailDuplication,
  ContactInfo,
  CountryOptions,
  FontFamilies,
  InstrumentsItem,
  LensesColumn,
  LensesColumnEssilor,
  StarDoorsPerBrand,
  StoreState,
} from "./storeInterfaces";

export const CMS_ROUTES = {
  HOMEPAGE: "homepage",
  LOGIN_PAGE: "login",
  NEED_HELP: "need-help",
  PRE_PLP: "preplp",
  PLP: "plp",
};

export const CLOSED_POPUP_ACCESS_ACCOUNTING = {
  open: false,
  type: null,
};

export const sliceName = "store";

const initialState: StoreState = {
  errorStatus: null,
  error: null,
  fallbackLang: "en-US",
  currentLang: null, // the current language (esempio "en-US") of the store / private or public
  supportedCurrencies: [],
  supportedLanguages: [],
  publicSupportedLanguages: [],
  redirectPublicLocale: null,
  redirectPrivateLocale: null,
  storeFeatures: [],
  lxConfigurations: [],
  company: "",
  storeId: "",
  loadingSingleNews: "IDLE",
  hpLoading: "IDLE",

  //cms content
  pagesLayout: {
    [CMS_ROUTES.HOMEPAGE]: { page: CMS_ROUTES.HOMEPAGE },
    [CMS_ROUTES.PRE_PLP]: { page: CMS_ROUTES.PRE_PLP },
    [CMS_ROUTES.PLP]: { page: CMS_ROUTES.PLP },
    [CMS_ROUTES.LOGIN_PAGE]: { page: CMS_ROUTES.LOGIN_PAGE },
    [CMS_ROUTES.NEED_HELP]: { page: CMS_ROUTES.NEED_HELP },
  },
  itemsMap: {},
  itemsTooltipMap: {},
  itemsPopupMap: {},
  landingPageStatus: "IDLE",
  navigationTabStatus: "IDLE",
  faqList: [],
  newsList: null,
  singleNews: null,
  techIconsContents: {},
  tutorialPills: null,
  canRenderPopupTutorialPills: true,

  //lp fonts
  requestedFonts: null,
  downloadedFonts: null,

  // popups
  popupPersonification: {
    open: false,
  },
  popupAccessAccounting: CLOSED_POPUP_ACCESS_ACCOUNTING,
  popupVideoSetting: {
    open: false,
    src: null,
    top: null,
  },
  popupServiceRequest: {
    open: false,
    requestType: null,
    addressOf: null,
    addressDetails: null,
    orderIdentifier: null,
    shippingCode: null,
    b2bOrderIdentifier: null,
  },

  popupLpVariants: {
    open: false,
    productCode: null,
  },
  popupUserNotMigrated: {
    open: false,
  },
  popupDuplicatedEmail: {
    open: false,
  },

  checkMailDuplication: null,

  // menu
  menu: [],
  menuContentsId: {
    advBannerId: undefined,
    stripId: undefined,
  },
  bannerContents: [],
  stripContents: [],
  thirdLevelMenu: {},
  brandGroups: {},
  brandGroupByBrandMap: {},
  lensesMenu: [],
  lensesEssilorMenu: [],
  getMenuStatus: "IDLE",
  getThirdMenuStatus: {},
  getLensesMenuStatus: "IDLE",
  advBannerStatus: "IDLE",
  instrumentsMenu: [],
  getInstrumentsMenuStatus: "IDLE",

  countries: [],
  brandsImages: {},

  // landing page
  lpVariantsList: {
    items: [],
    recordSetTotal: 0,
  },
  lpVariantsListStatus: "IDLE",
  stickyBarType: "ALL",

  // Footer menu,
  contactInfo: {},

  // Decoupling resource
  popupSapDecoupling: {
    open: false,
  },
  decouplingDetails: [],

  // search
  termAssociation: null,

  // URLS
  url360Members: null, // EL360
  urlRewards: null, // REWARDS
  urlLensSimulator: null, // LENS SIMULATOR

  // MULTIDOOR STARS
  starDoorsPerBrand: {},
  starDoorsPerBrandStatus: "IDLE",

  // FOR FOOTER CHINA
  chinaSpecificWebDomain: false,
};

export const storeSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<ApiError>) => {
      state.error = action.payload;
    },
    hideError: (state) => {
      state.error = null;
    },
    toggleVideoPopup: (state, action: PayloadAction<PopupVideoSetting>) => {
      state.popupVideoSetting = action.payload;
    },
    togglePopupPersonification: (state, action: PayloadAction<Popup>) => {
      state.popupPersonification = action.payload;
    },
    togglePopupAccessAccounting: (state, action: PayloadAction<PopupAccessAccounting>) => {
      state.popupAccessAccounting = action.payload;
    },
    togglePopupServiceRequest: (state, action: PayloadAction<PopupServiceRequest>) => {
      state.popupServiceRequest = action.payload;
    },
    togglePopupLpVariants: (state, action: PayloadAction<PopupLpVariants>) => {
      state.popupLpVariants = action.payload;
    },
    togglePopupUserNotMigrated: (state, action: PayloadAction<Popup>) => {
      state.popupUserNotMigrated = action.payload;
    },
    togglePopupDuplicatedEmail: (state, action: PayloadAction<Popup>) => {
      state.popupDuplicatedEmail = action.payload;
    },
    setCheckMailDuplication: (state, action: PayloadAction<null | CheckMailDuplication>) => {
      state.checkMailDuplication = action.payload;
    },
    savePageLayout: (state, action: PayloadAction<Page>) => {
      state.pagesLayout[action.payload.page] = action.payload;
    },
    saveLandingPageStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.landingPageStatus = action.payload;
    },
    saveNavigationTabStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.navigationTabStatus = action.payload;
    },
    saveErrorStatus: (state, action: PayloadAction<ApiErrorStatus>) => {
      state.errorStatus = action.payload.status;
    },
    setLoadingHp: (state: StoreState, action: PayloadAction<RequestStatus>) => {
      state.hpLoading = action.payload;
    },

    saveContent: (state, action: PayloadAction<CMSContent>) => {
      if (action.payload && action.payload.item) {
        const idItem: string = action.payload.id;
        const item: any = action.payload.item;

        state.itemsMap[idItem] = {
          id: idItem,
          type: item?.type,
          layoutVariant: item?.layoutVariant?.[0] ? item?.layoutVariant[0] : null,
          content: item,
          result: item?.result ? item?.result : [],
          brand: action.payload.brand,
          title: item?.title,
          html: item?.html,
          collectionTitle: item?.collectionTitle,
        };
      }
    },
    saveTooltipContent: (state, action: PayloadAction<CMSTutorialPillsContent>) => {
      if (action.payload && action.payload.item) {
        const idItem: string = action.payload.id;
        const item: any = action.payload.item;

        state.itemsTooltipMap[idItem] = {
          title: item?.title,
          description: item?.description,
          linkString: item?.linkString,
          linkLabel: item?.linkLabel,
          openInNewTab: item?.openInNewTab,
          externalLink: item?.externalLink,
          id: idItem,
        };
      }
    },
    savePopupContent: (state, action: PayloadAction<CMSTutorialPillsContent>) => {
      if (action.payload && action.payload.item) {
        const idItem: string = action.payload.id;
        const item: any = action.payload.item;

        state.itemsPopupMap[idItem] = {
          title: item?.title,
          description: item?.description,
          linkString: item?.linkString,
          linkLabel: item?.linkLabel,
          openInNewTab: item?.openInNewTab,
          externalLink: item?.externalLink,
          srcImg: item?.srcImg,
          id: idItem,
        };
      }
    },
    saveLensesMenu: (state, action: PayloadAction<LensesColumn[]>) => {
      state.lensesMenu = action.payload;
    },
    saveLensesEssilorMenu: (state, action: PayloadAction<LensesColumnEssilor[]>) => {
      state.lensesEssilorMenu = action.payload;
    },
    saveGetMenuStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getMenuStatus = action.payload;
    },
    saveGetThirdMenuStatus: (
      state,
      action: PayloadAction<{ identifier: string; status: RequestStatus }>
    ) => {
      state.getThirdMenuStatus[action.payload.identifier] = action.payload.status;
    },
    saveGetLensesMenuStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getLensesMenuStatus = action.payload;
    },
    saveAdvBannerStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.advBannerStatus = action.payload;
    },
    saveMenu: (state, action: PayloadAction<FirstLevelMenu[]>) => {
      state.menu = action.payload;
    },
    saveMenuContents: (state, action: PayloadAction<MenuContentsId>) => {
      state.menuContentsId = action.payload;
    },
    saveAdvBannerContents: (state, action: PayloadAction<BannerContent[]>) => {
      state.bannerContents = action.payload;
    },
    saveStripContents: (state, action: PayloadAction<StripContent[]>) => {
      state.stripContents = action.payload;
    },
    saveInstrumentsMenu: (state, action: PayloadAction<InstrumentsItem[]>) => {
      state.instrumentsMenu = action.payload;
    },
    saveGetInstrumentsMenuStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getInstrumentsMenuStatus = action.payload;
    },
    saveThirdLevelMenu: (
      state,
      action: PayloadAction<{
        customId: string;
        content: FacetView[];
        identifier: string;
        type?: GogglesHelmetsType;
      }>
    ) => {
      const getFacetAndRemaining = (facets: FacetView[], filter: (value: FacetView) => boolean) => {
        const [facet, remaining] = partitionArray(facets, filter);

        return { facet: facet[0], remaining };
      };

      const { identifier, customId, content, type } = action.payload;
      if (identifier.toUpperCase() === "GOGGLES_HELMETS") {
        let newContent = content.map((facet) => {
          if (type) return { ...facet, type: [type] };
          else return facet;
        });

        if (state.thirdLevelMenu[customId]) {
          const newMenu: FacetView[] = state.thirdLevelMenu[customId].map((column) => {
            //update brand column
            if (column.value === "manufacturer.raw") {
              const { facet: brand, remaining: filteredNewContent } = getFacetAndRemaining(
                newContent,
                (_) => _.value === "manufacturer.raw"
              );
              newContent = filteredNewContent;
              if (brand) {
                const type: GogglesHelmetsType[] = [];
                if (column.type) type.push(...column.type);
                if (brand.type) type.push(...brand.type);
                const newEntry = [...column.entry];
                brand.entry.forEach((brandEntry) => {
                  const findBrand = newEntry.find((_) => _.identifier === brandEntry.identifier);
                  if (!findBrand) newEntry.push(brandEntry);
                });
                return {
                  ...column,
                  entry: newEntry,
                  type: type,
                };
              }
            }

            //update common facet
            if (
              column.extendedData.propertyvalue === "NEW" ||
              column.extendedData.propertyvalue === "BESTSELLER"
            ) {
              const { facet: newType, remaining: filteredNewContent } = getFacetAndRemaining(
                newContent,
                (_) => _.extendedData.propertyvalue === column.extendedData.propertyvalue
              );
              newContent = filteredNewContent;

              const type: GogglesHelmetsType[] = [];
              if (newType?.type) type.push(...newType?.type);
              if (column.type) type.push(...column.type);

              return {
                ...column,
                type: type,
              };
            }

            return column;
          });

          state.thirdLevelMenu[customId] = [...newMenu, ...newContent];
        } else {
          state.thirdLevelMenu[customId] = newContent;
        }
      } else {
        state.thirdLevelMenu[customId] = content;
      }
    },
    resetThirdLevelMenu: (state) => {
      state.thirdLevelMenu = {};
    },

    saveContactInfo: (state, action: PayloadAction<ContactInfo>) => {
      state.contactInfo = action.payload;
    },
    saveBrandList: (state, action: PayloadAction<BrandGroups>) => {
      state.brandGroups = action.payload;
    },
    saveOrderedBrandList: (state, action: PayloadAction<BrandGroups>) => {
      const temp = mapBrandGroupByBrandUtil(action.payload);
      state.brandGroupByBrandMap = temp;
    },
    saveStoreConfiguration: (state, { payload }: PayloadAction<StoreConfiguration>) => {
      if (payload.supportedCurrencies) {
        state.supportedCurrencies = payload.supportedCurrencies;
      }
      //  non dovrebbe servire
      // if (payload.storeFeatures) {
      //   state.storeFeatures = payload.storeFeatures;
      // }
      if (payload.company) {
        state.company = payload.company;
      }
      if (payload.supportedLanguages) {
        state.supportedLanguages = payload.supportedLanguages;
      }

      if (payload.lxConfigurations) {
        state.lxConfigurations = payload.lxConfigurations;
      }

      if (payload.storeId) {
        state.storeId = payload.storeId;
      }

      if (payload.chinaSpecificWebDomain) {
        state.chinaSpecificWebDomain = payload.chinaSpecificWebDomain;
      }
    },
    savePublicStoreConfiguration: (state, action: PayloadAction<StoreConfiguration>) => {
      if (action.payload.supportedLanguages) {
        // console.log("[DEBUG][ACTION][savePublicStoreConfiguration]");
        state.publicSupportedLanguages = action.payload.supportedLanguages;
      }
    },
    saveRedirectPublicLocale: (state, action: PayloadAction<string | null>) => {
      // console.log("[DEBUG][ACTION][saveRedirectPublicLocale]:", action.payload);
      state.redirectPublicLocale = action.payload;
    },
    saveRedirectPrivateLocale: (state, action: PayloadAction<string | null>) => {
      // console.log("[DEBUG][ACTION][saveRedirectPrivateLocale]:", action.payload);
      state.redirectPrivateLocale = action.payload;
    },
    saveFaq: (state, action: PayloadAction<FaqItem[]>) => {
      state.faqList = action.payload;
    },
    saveNews: (state, action: PayloadAction<NewsMap>) => {
      state.newsList = action.payload;
    },
    saveTutorialPills: (state, action: PayloadAction<TutorialPill[] | null>) => {
      state.tutorialPills = action.payload;
    },
    saveCanRenderPopupTutorialPills: (state, action: PayloadAction<boolean>) => {
      state.canRenderPopupTutorialPills = action.payload;
    },
    resetNews: (state) => {
      state.singleNews = initialState.singleNews;
    },
    saveSingleNews: (state, action: PayloadAction<{ id: string; content: News }>) => {
      state.singleNews = action.payload.content;
    },
    saveCountries: (state, action: PayloadAction<CountryOptions[]>) => {
      state.countries = action.payload;
    },
    saveCurrentLang: (state, action: PayloadAction<string>) => {
      state.currentLang = action.payload;
    },
    resetPageLayout: (state, action: PayloadAction<string>) => {
      if (Object.values(CMS_ROUTES).includes(action.payload))
        state.pagesLayout = {
          ...state.pagesLayout,
          [action.payload]: { page: action.payload },
        };
    },
    saveBrandImages: (state, action: PayloadAction<BrandImagesService[]>) => {
      const images: BrandsImages = {};
      action.payload.map((element: BrandImagesService) => {
        const brandId = element.subjectTaxonomy.filter(
          (_) => _.externalReference !== "logo" && _.externalReference !== "lens-logo"
        )[0].name;
        images[brandId] = element.data.uri;
      });
      state.brandsImages = { ...state.brandsImages, ...images };
    },
    setLoadingSingleNews: (state, action: PayloadAction<RequestStatus>) => {
      state.loadingSingleNews = action.payload;
    },
    saveSearchTermAssociation: (state, action: PayloadAction<TermAssociation>) => {
      state.termAssociation = action.payload;
    },
    saveLpVariantsList: (state, action: PayloadAction<LpVariants | null>) => {
      state.lpVariantsList = action.payload;
    },
    setLpVariantsListStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.lpVariantsListStatus = action.payload;
    },
    resetLpVariantsList: (state) => {
      state.lpVariantsList = initialState.lpVariantsList;
    },
    setStickyBarType: (state, action: PayloadAction<string>) => {
      state.stickyBarType = action.payload;
    },
    // Decoupling resource
    toggleSapDecouplingPopup: (state, action: PayloadAction<PopupSapDecoupling>) => {
      state.popupSapDecoupling = action.payload;
    },

    saveUrlWithToken: (state, action: PayloadAction<string>) => {
      state.url360Members = action.payload;
    },

    saveRewardsUrlWithToken: (state, action: PayloadAction<string>) => {
      state.urlRewards = action.payload;
    },

    saveUrlLensSimulator: (state, action: PayloadAction<string>) => {
      state.urlLensSimulator = action.payload;
    },

    saveStarDoorsPerBrand: (state, action: PayloadAction<StarDoorsPerBrand>) => {
      state.starDoorsPerBrand = action.payload;
    },

    saveStarDoorsPerBrandStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.starDoorsPerBrandStatus = action.payload;
    },

    saveRequestedFonts: (state, action: PayloadAction<FontFamilies[] | null>) => {
      state.requestedFonts = action.payload;
    },

    saveDownloadedFonts: (state, action: PayloadAction<FontFamilies[] | null>) => {
      state.downloadedFonts = action.payload;
    },

    addTechIconContents: (state, action: PayloadAction<TechnologyIconsPayload>) => {
      state.techIconsContents = {
        ...state.techIconsContents,
        [action.payload.brand]: {
          ...state.techIconsContents[action.payload.brand],
          [action.payload.type]: action.payload.icons,
        },
      };
    },

    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  showError,
  hideError,
  saveContent,
  saveTooltipContent,
  savePopupContent,
  toggleVideoPopup,
  savePageLayout,
  saveLandingPageStatus,
  saveNavigationTabStatus,
  togglePopupPersonification,
  togglePopupAccessAccounting,
  togglePopupServiceRequest,
  togglePopupLpVariants,
  saveErrorStatus,
  setLoadingHp,
  saveMenu,
  saveMenuContents,
  saveAdvBannerContents,
  saveStripContents,
  saveLensesMenu,
  saveLensesEssilorMenu,
  saveInstrumentsMenu,
  saveThirdLevelMenu,
  resetThirdLevelMenu,
  saveContactInfo,
  saveBrandList,
  saveOrderedBrandList,
  saveStoreConfiguration,
  savePublicStoreConfiguration,
  saveRedirectPublicLocale,
  saveRedirectPrivateLocale,
  saveFaq,
  saveNews,
  saveSingleNews,
  saveCountries,
  saveCurrentLang,
  resetPageLayout,
  resetNews,
  saveBrandImages,
  setLoadingSingleNews,
  saveGetMenuStatus,
  saveGetThirdMenuStatus,
  saveGetLensesMenuStatus,
  saveAdvBannerStatus,
  saveGetInstrumentsMenuStatus,
  saveSearchTermAssociation,
  saveLpVariantsList,
  setLpVariantsListStatus,
  resetLpVariantsList,
  setStickyBarType,
  toggleSapDecouplingPopup,
  saveUrlWithToken,
  saveRewardsUrlWithToken,
  saveUrlLensSimulator,
  togglePopupUserNotMigrated,
  saveStarDoorsPerBrand,
  saveStarDoorsPerBrandStatus,
  togglePopupDuplicatedEmail,
  setCheckMailDuplication,
  saveRequestedFonts,
  saveDownloadedFonts,
  addTechIconContents,
  saveTutorialPills,
  saveCanRenderPopupTutorialPills,
} = storeSlice.actions;

export const selectPagesLayout = (state: RootState): { [page: string]: Page } =>
  state.store.pagesLayout;

export const selectHomePageLayout = (state: RootState): Page =>
  state.store.pagesLayout[CMS_ROUTES.HOMEPAGE];

export const selectFallbackLang = (state: RootState): string => {
  return state.store.fallbackLang;
};

export const selectItemsMap = (state: RootState): ItemsMap => state.store.itemsMap;
export const selectTooltipMap = (state: RootState): ItemsTutorialPillMap =>
  state.store.itemsTooltipMap;
export const selectPopupMap = (state: RootState): ItemsTutorialPillMap => state.store.itemsPopupMap;

export const selectLandingPageStatus = (state: RootState): RequestStatus =>
  state.store.landingPageStatus;

export const selectNavigationTabsStatus = (state: RootState): RequestStatus => {
  return state.store.navigationTabStatus;
};

export const selectPopupVideoSettings = (state: RootState): PopupVideoSetting => {
  return state.store.popupVideoSetting;
};

export const selectPopupPersonification = (state: RootState): Popup => {
  return state.store.popupPersonification;
};

export const selectPopupAccessAccounting = (state: RootState): PopupAccessAccounting => {
  return state.store.popupAccessAccounting;
};

export const selectPopupServiceRequest = (state: RootState): PopupServiceRequest => {
  return state.store.popupServiceRequest;
};

export const selectPopupLpVariants = (state: RootState): PopupLpVariants => {
  return state.store.popupLpVariants;
};

export const selectPopupUserNotMigrated = (state: RootState): Popup => {
  return state.store.popupUserNotMigrated;
};

export const selectCheckMailDuplication = (state: RootState): null | CheckMailDuplication => {
  return state.store.checkMailDuplication;
};

export const selectPopupDuplicatedEmail = (state: RootState): Popup => {
  return state.store.popupDuplicatedEmail;
};

export const selectSupportedLanguages = (state: RootState): Lang[] => {
  return state.store.supportedLanguages;
};

export const selectPublicSupportedLanguages = (state: RootState): Lang[] => {
  return state.store.publicSupportedLanguages;
};

export const selectRedirectPublicLocale = (state: RootState): string | null => {
  return state.store.redirectPublicLocale;
};

export const selectRedirectPrivateLocale = (state: RootState): string | null => {
  return state.store.redirectPrivateLocale;
};

export const selectMenu = (state: RootState): FirstLevelMenu[] => {
  return state.store.menu;
};

export const selectMenuContent = (state: RootState): MenuContentsId => {
  return state.store.menuContentsId;
};

export const selectAdvBanner = (state: RootState): BannerContent[] => {
  return state.store.bannerContents;
};

export const selectStripContents = (state: RootState): StripContent[] => {
  return state.store.stripContents;
};

export const selectThirdLevelMenu = (state: RootState): ThirdLevelMenuObj => {
  return state.store.thirdLevelMenu;
};
export const selectContactInfo = (state: RootState): ContactInfo => {
  return state.store.contactInfo;
};

export const selectBrandGroups = (state: RootState): BrandGroups => {
  return state.store.brandGroups;
};

export const selectBrandGroupByBrandMap = (state: RootState): GenericCustomObject => {
  return state.store.brandGroupByBrandMap;
};

export const selectLoadingHp = (state: RootState): RequestStatus => {
  return state.store.hpLoading;
};

export const selectBrandLabels = createSelector(
  selectMenu,
  selectBrandGroups,
  (menus: FirstLevelMenu[], brandgroups) => {
    const brandLabels: { [brand: string]: string } = {};
    const brandsMenu = menus?.find((menu) => menu.identifier === "BRANDS");
    const brandsEssilorMenu = menus?.find((menu) => menu.identifier === "ESSILOR");
    brandsMenu?.catalogGroupView?.forEach((brand) => {
      brandLabels[brand.identifier] = brand.name;
    });
    brandsEssilorMenu?.catalogGroupView?.forEach((brand) => {
      brand.brandCodes?.forEach((_) => (brandLabels[_ as string] = brand.name));
    });
    Object.keys(brandgroups).forEach((key) => {
      brandgroups[key].forEach((brand) => {
        if (!brandLabels[brand.brand] && brandLabels[key]) {
          brandLabels[brand.brand] = brandLabels[key];
        }
      });
    });

    return brandLabels;
  }
);

export const selectBrandAndEssilor = createSelector(
  selectMenu,
  (menus: FirstLevelMenu[]): BrandsAndEssilor => {
    const brands: Brand = {};
    const brandsEssilor: Brand = {};
    const brandsMenu = menus?.find((menu) => menu.identifier === "BRANDS");
    const brandsEssilorMenu = menus?.find((menu) => menu.identifier === "ESSILOR");
    brandsMenu?.catalogGroupView?.forEach((brand) => {
      brands[brand.identifier] = brand.name;
    });
    brandsEssilorMenu?.catalogGroupView?.forEach((brand) => {
      brand.brandCodes?.forEach((_) => (brandsEssilor[_ as string] = brand.name));
    });

    return { brands, brandsEssilor };
  }
);

// //TODO remove??
export const selectBrandGroupByBrand = createSelector(
  selectBrandGroups,
  (brandGroups: BrandGroups) => {
    const brandGroupByBrand: { [brand: string]: string } = {};

    Object.keys(brandGroups).forEach((brandgroup) => {
      brandGroups[brandgroup].forEach((brandObj) => {
        brandGroupByBrand[brandObj.brand as any] = brandgroup;
      });
      brandGroupByBrand;
    });

    return brandGroupByBrand;
  }
);

export const selectCatalogGroupView = (state: RootState): SecondLevelMenu[] => {
  return state.store.menu?.[0].catalogGroupView ? state.store.menu[0].catalogGroupView : [];
};

export const selectLensesMenu = (state: RootState): LensesColumn[] => {
  return state.store.lensesMenu;
};

export const selectLensesEssilorMenu = (state: RootState): LensesColumnEssilor[] => {
  return state.store.lensesEssilorMenu;
};

export const selectInstrumentsMenu = (state: RootState): InstrumentsItem[] => {
  return state.store.instrumentsMenu;
};

export const selectGetMenuStatus = (state: RootState): RequestStatus => {
  return state.store.getMenuStatus;
};

export const selectGetThirdMenuStatus = (
  state: RootState
): {
  [key: string]: RequestStatus;
} => {
  return state.store.getThirdMenuStatus;
};

export const selectGetLensesMenuStatus = (state: RootState): RequestStatus => {
  return state.store.getLensesMenuStatus;
};

export const selectAdvBannerStatus = (state: RootState): RequestStatus => {
  return state.store.advBannerStatus;
};

export const selectGetInstrumentsMenuStatus = (state: RootState): RequestStatus => {
  return state.store.getInstrumentsMenuStatus;
};

// export const selectBrandGroupByType = (
//   facet: FacetViewEntry[],
//   baseUrl: string,
//   baseUrlKids: string,
//   considerBrandsAsAdult = false
// ) => {
//   return createSelector(selectBrandGroups, selectBrandLabels, (brandgroup, labels) =>
//     groupBrandGroupByType(brandgroup, labels, facet, baseUrl, baseUrlKids, considerBrandsAsAdult)
//   );
// };

export const selectFaq = (state: RootState): FaqItem[] => {
  return state.store.faqList;
};

export const selectNews = (state: RootState): NewsMap | null => {
  return state.store.newsList;
};

export const selectTutorialPills = (state: RootState): TutorialPill[] | null => {
  return state.store.tutorialPills;
};

export const selectCanRenderPopupTutorialPills = (state: RootState): boolean => {
  return state.store.canRenderPopupTutorialPills;
};

export const selectSingleNews = (state: RootState): News | null => {
  return state.store.singleNews;
};

export const selectCountries = (state: RootState): CountryOptions[] => {
  return state.store.countries;
};

export const selectCurrentLang = (state: RootState): string | null => {
  return state.store.currentLang;
};

export const selectBrandImages = (state: RootState): BrandsImages => {
  return state.store.brandsImages;
};

export const selectLxConfigurations = (state: RootState): LXConfiguration[] => {
  return state.store.lxConfigurations;
};

export const selectCompany = (state: RootState): string => {
  return state.store.company;
};

export const selectStoreId = (state: RootState): string => {
  return state.store.storeId;
};

export const selectChinaSpecificWebDomain = (state: RootState): boolean => {
  return state.store.chinaSpecificWebDomain;
};

export const selectLoadingSingleNews = (state: RootState): RequestStatus => {
  return state.store.loadingSingleNews;
};

export const selectSearchTermAssociation = (state: RootState): TermAssociation | null => {
  return state.store.termAssociation;
};
export const selectLpVariantsList = (state: RootState): LpVariants | null => {
  return state.store.lpVariantsList;
};
export const selectLpVariantsListStatus = (state: RootState): RequestStatus => {
  return state.store.lpVariantsListStatus;
};

export const selectStickyBarType = (state: RootState): string => {
  return state.store.stickyBarType;
};

export const selectError = (state: RootState): ApiError | null => {
  return state.store.error;
};

export const selectErrorStatus = (state: RootState): number | null => {
  return state.store.errorStatus;
};

export const selectChatUrl = createSelector(
  selectLxConfigurations,

  (lxConfigurations: LXConfiguration[]) => {
    const chatUrl =
      lxConfigurations.length > 0 &&
      lxConfigurations.filter((element) => element.key === "it.luxottica.chat.endpoint")?.[0]
        ?.value;
    return chatUrl;
  }
);

export const selectCancellationDateOffset = createSelector(
  selectLxConfigurations,

  (lxConfigurations: LXConfiguration[]) => {
    const offset =
      lxConfigurations.length > 0
        ? lxConfigurations.filter(
            (element) => element.key === "com.luxottica.catalogentry.cancellationdate.offset"
          )?.[0]?.value
        : "0";
    return parseInt(offset);
  }
);

// Decoupling resource
export const selectPopupSapDecoupling = (state: RootState): PopupSapDecoupling => {
  return state.store.popupSapDecoupling;
};

export const selectUrl360Members = (state: RootState): string | null => {
  return state.store.url360Members;
};

export const selectUrlRewards = (state: RootState): string | null => {
  return state.store.urlRewards;
};

export const selectUrlLensSimulator = (state: RootState): string | null => {
  return state.store.urlLensSimulator;
};

export const selectStarDoorsPerBrand = (state: RootState): StarDoorsPerBrand => {
  return state.store.starDoorsPerBrand;
};

export const selectStarDoorsPerBrandStatus = (state: RootState): RequestStatus => {
  return state.store.starDoorsPerBrandStatus;
};

export const selectRequestedFonts = (state: RootState): FontFamilies[] | null => {
  return state.store.requestedFonts;
};

export const selectDownloadedFonts = (state: RootState): FontFamilies[] | null => {
  return state.store.downloadedFonts;
};

export const selectTechIconContents = (state: RootState): TechnologyIconsObj => {
  return state.store.techIconsContents;
};

export default storeSlice.reducer;
