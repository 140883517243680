import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../hooks/useTranslation";
import { sendAnalyticsData } from "../../store/analytics/analyticsService";
import { saveSelectedDoorList } from "../../store/multidoor/multidoorSagas";
import { selectIsMassiveOrderModeActive, selectIsMultidoor } from "../../store/user/userSlice";
import CustomText from "../styled-UI/CustomText";
import ToggleButtonSimplified from "../styled-UI/ToggleButtonSimplified";

const MassiveOrderModeToggleAccount = (): JSX.Element => {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const isMassiveOrderMode = useSelector(selectIsMassiveOrderModeActive);
  const isMultidoor = useSelector(selectIsMultidoor);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(saveSelectedDoorList({ massiveOrderMode: e.target.checked }));

    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MainNav_MyAccount_Overlay_MassiveOrder",
    });
  };

  return isMultidoor ? (
    <div className="d-flex justify-content-between align-items-center" style={{ gap: `1rem` }}>
      <CustomText as="p" fontSizePx={13} font="font-regular" lineHeightPx={18} fontWeightNum={700}>
        {translateLabel("MASSIVE_ORDER")}
      </CustomText>
      <ToggleButtonSimplified defaultChecked={isMassiveOrderMode} onChange={handleChange} />
    </div>
  ) : (
    <></>
  );
};

export default MassiveOrderModeToggleAccount;
