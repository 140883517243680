import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { saveCanRenderPopupTutorialPills } from "../store/store/storeSlice";

const useSetTutorialPillsPopupVisibility = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveCanRenderPopupTutorialPills(false));

    return () => {
      dispatch(saveCanRenderPopupTutorialPills(true));
    };
  }, [dispatch]);
};

export default useSetTutorialPillsPopupVisibility;
