import { SagaIterator } from "@redux-saga/types";
import { PayloadAction, createAction } from "@reduxjs/toolkit";
import { call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { handleError, showErrorPopup } from "../store/storeSagas";
import {
  CMSPictureContentPayload,
  CatalogueLayout,
  CatalogueLayoutPayload,
  ChangePasswordPayload,
  ChangePasswordPrivatePayload,
  CustomerAttributes,
  DisplayPricesOptions,
  DisplayPricesPayload,
  Door,
  DoorAndStoreIdPayload,
  GetDoorsAddressPayload,
  GetPrivilegesPayload,
  LogoutPayload,
  OrganizationDetails,
  UpdateSelectedDoorPayload,
  UserAttributes,
  UserCredentials,
  UserHavingPrivilege,
  getPersonificationCustomersPayload,
  getPersonificationDummyPayload,
  postPersonificationPayload,
} from "./userInterfaces";
import { userService } from "./userServices";
import {
  loginSuccess,
  resetState,
  saveEssilorBadgePicture,
  saveGetEssilorBadgePictureStatus,
  saveGetPersonificationDummyStatus,
  saveGetPersonificationStoresStatus,
  saveIsBackOfficeUser,
  saveMultiDoors,
  savePersonificationDummy,
  savePersonificationStores,
  savePrivileges,
  saveTotalDoors,
  selectActiveDoor,
  selectEssilorBadgeCode,
  selectIsSubuser,
  selectLoginKeys,
  selectMultiDoors,
  selectStoreIdentifier,
  setAdvancedSearch,
  setAdvancedSearchLoading,
  setCataloguePageLayout,
  setCataloguePageLayoutLoading,
  setCataloguePdpLayoutChanged,
  setChangePasswordError,
  setChangePasswordLoading,
  setContextTutorialPillsPopup,
  setDisplayPrices,
  setDisplayPricesLoading,
  setEssilorBadgeCode,
  setForgotPasswordError,
  setForgotPasswordLoading,
  setGetPrivilegesStatus,
  setIsLegalTermsAccepted,
  setJustLogged,
  setKeysLoading,
  setLegalTermsOfUseDictionary,
  setLoginError,
  setLoginKeys,
  setLoginLoading,
  setMostUsed,
  setMostUsedLoading,
  setOnePortalConfiguration,
  setOrganizationDetails,
  setPersonificationCustomers,
  setPersonificationCustomersStatus,
  setPostPersonificationStatus,
  setSelectedDoor,
  setSrpOptInChoice,
  setSrpOptInChoiceOp,
  setSrpOptInLoading,
  setSrpOptInLoadingOp,
  setStoreIdentifier,
  sliceName,
  userContextSuccess,
} from "./userSlice";

import { MostUsed, MostUsedPayload } from "../../interfaces/mostUsedInterfaces";
import { mapMostUsedCms } from "../../utils/mostUsedUtils";
import { broadcastMessage } from "../../utils/storageUtils";
import { isMultidoor, isSoldToAddress, mapContextTutorialPills } from "../../utils/userUtils";
import multidoorService from "../multidoor/multidoorService";
import { stopMultidoorLoading } from "../multidoor/multidoorSlice";
import profileService from "../profile/profileServices";
import { savePublicUserPolicy } from "../profile/profileSlice";
import { CheckMailDuplication } from "../store/storeInterfaces";
import storeService from "../store/storeService";
import {
  saveErrorStatus,
  setCheckMailDuplication,
  togglePopupDuplicatedEmail,
} from "../store/storeSlice";

/* ACTIONS */
export const loginKeys = createAction(`${sliceName}/loginKeys`);
export const login = createAction<UserCredentials>(`${sliceName}/login`);
//-------- ATTENZIONE NON MODIFICARE IL NOME DI QUESTA AZIONE: --------
//-------- SE SI VUOLE MODIFICARE BISOGNA MODIFICARE ANCHE I REDUCER CHE ASCOLTANO QUESTA AZIONE ------
export const logout = createAction<LogoutPayload>(`${sliceName}/logout`);
export const resetPassword = createAction<string>(`${sliceName}/resetPassword`);
export const changePassword = createAction<ChangePasswordPayload>(`${sliceName}/changePassword`);
export const changePasswordPrivate = createAction<ChangePasswordPrivatePayload>(
  `${sliceName}/changePasswordPrivate`
);

//Personification
export const getPersonificationStores = createAction(`${sliceName}/getPersonificationStores`);
export const getPersonificationDummy = createAction<getPersonificationDummyPayload>(
  `${sliceName}/getPersonificationDummy`
);
export const getPersonificationCustomers = createAction<getPersonificationCustomersPayload>(
  `${sliceName}/getPersonificationCustomers`
);
export const postPersonification = createAction<postPersonificationPayload>(
  `${sliceName}/postPersonification`
);
export const getUserContext = createAction(`${sliceName}/getUserContext`);
export const updateSelectedDoor = createAction<UpdateSelectedDoorPayload>(
  `${sliceName}/updateSelectedDoor`
);
export const getAdvancedSearch = createAction(`${sliceName}/getAdvancedSearch`);

export const getOrganizationDetails = createAction<DoorAndStoreIdPayload>(
  `${sliceName}/getOrganizationDetails`
);
export const getPrivileges = createAction<GetPrivilegesPayload>(`${sliceName}/getPrivileges`);
export const getPrivilegesSuccess = createAction<boolean>(`${sliceName}/getPrivilegesSuccess`);

export const getTotalDoors = createAction(`${sliceName}/getTotalDoors`);
export const getDoorsAddress = createAction<GetDoorsAddressPayload>(`${sliceName}/getDoorsAddress`);
export const getUserMailCheckDuplication = createAction(`${sliceName}/getUserMailCheckDuplication`);

export const getMostUsed = createAction(`${sliceName}/getMostUsed`);
export const updateMostUsed = createAction<MostUsedPayload>(`${sliceName}/updateMostUsed`);
// Display Prices
export const updateSelectedDisplayPrices = createAction<DisplayPricesPayload>(
  `${sliceName}/updateSelectedDisplayPrices`
);
export const updateCataloguePageLayout = createAction<CatalogueLayoutPayload>(
  sliceName + "/updateCataloguePageLayout"
);
export const getCMSPictureContent = createAction<CMSPictureContentPayload>(
  sliceName + "/getCMSPictureContent"
);
export const getPictureAccountMenu = createAction(`${sliceName}/getPictureAccountMenu`);

export const updateSrpOptInChoice = createAction<string>(sliceName + "updateSrpOptInChoice");
export const updateSrpOptInChoiceOp = createAction<string>(sliceName + "updateSrpOptInChoiceOp");
export const updateTutorialPills = createAction<string>(sliceName + "updateTutorialPills");
export const updateTermsOfService = createAction<string>(sliceName + "updateTermsOfService");

/* SAGAS */

/**
 * Obtain login keys
 *
 * @return {*}  {SagaIterator}
 */
function* loginKeysSaga(): SagaIterator {
  try {
    yield put(setKeysLoading("LOADING"));

    const response = yield call(userService.getLoginKeys);
    yield put(setLoginKeys(response.data.data));

    yield put(setKeysLoading("SUCCESS"));
  } catch (error) {
    yield put(setKeysLoading("ERROR"));
    yield put(handleError(error));
  }
}

/**
 * Login user
 *
 * @return {*}  {SagaIterator}
 */
function* loginSaga(action: PayloadAction<UserCredentials>): SagaIterator {
  try {
    yield put(setLoginLoading("LOADING"));
    let loginKeys = yield select(selectLoginKeys);
    loginKeys = { ...loginKeys };
    loginKeys.userCredentials = action.payload;
    const JWT = yield call(userService.getJWT, loginKeys);
    const validation = yield call(userService.getValidation, JWT.data.access_token);

    const csrfToken = validation.headers["x-csrf-token"];

    if (csrfToken) {
      try {
        sessionStorage.setItem("csrfToken", csrfToken);
      } catch (e) {
        console.warn(e);
      }
    }

    yield put(
      loginSuccess({
        storeIdentifier: validation.data.data.storeIdentifier,
        session: validation.data.data.session,
      })
    );
  } catch (error) {
    yield put(setLoginLoading("IDLE"));
    yield put(setChangePasswordLoading("IDLE"));

    let type = "";

    switch (error?.response?.status) {
      case 302:
        type = "LOGIN_CHANGE_PASSWORD_ERROR";
        const redirect = error?.response?.data?.data?.redirectUrl;
        yield put(setLoginError({ type, redirect, status: true }));
        return;

      case 400:
        if (error?.response?.data?.error_description?.includes("username or password")) {
          type = "LOGIN_WRONG_CREDENTIALS_ERROR";
          yield put(setLoginError({ type, status: true }));
          return;
        }

        if (error?.response?.data?.errors?.[0]?.code === "_ERR_USER_NOT_FOUND_OR_NOT_ACTIVE") {
          type = "LOGIN_ERR_USER_NOT_FOUND_OR_NOT_ACTIVE";
          yield put(setLoginError({ type, status: true }));
          return;
        }

        break;
    }

    type = "LOGIN_GENERIC_ERROR";
    yield put(setLoginError({ type, status: true }));
  }
}

/**
 * Logout user
 *
 * @return {*}  {SagaIterator}
 */
function* logoutSaga({ payload }: PayloadAction<LogoutPayload>): SagaIterator {
  try {
    yield put(resetState());
    //call localstorage clear (else there is an error in saga)
    yield call([localStorage, localStorage.clear]);

    yield call([sessionStorage, sessionStorage.clear]);

    //to check if user has clicked of logout button
    if (payload.buttonClicked) sessionStorage.setItem("MYL_clicked_logout", "true");

    yield call(userService.logout);

    broadcastMessage("logout");
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Reset User Password
 *
 * @return {*}  {SagaIterator}
 */
function* resetPasswordSaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(setForgotPasswordLoading("LOADING"));
    yield call(userService.resetPassword, payload);
    yield put(setForgotPasswordLoading("SUCCESS"));
  } catch (error) {
    let type = "";
    yield put(setForgotPasswordLoading("IDLE"));

    if (error?.response?.status === 404) {
      type = "FORGOT_PASSWORD_USER_NOT_FOUND";
      yield put(setForgotPasswordError({ type, status: true }));

      return;
    }
    yield put(handleError(error));
  }
}

/**
 * Change User Password
 *
 * @return {*}  {SagaIterator}
 */
function* changePasswordSaga({ payload }: PayloadAction<ChangePasswordPayload>): SagaIterator {
  try {
    yield put(setChangePasswordLoading("LOADING"));

    /*----- IF IS FIRST TIME, SEND POLICY PREFERENCES -----*/
    if (!payload.isRegistred) {
      const policyPayload = {
        username: payload.username as string,
        uuid: payload.uuid as string,
        marketingCommunications: payload.acceptMarketing === "yes",
        privacyPersonalData: true,
      };

      const { data } = yield call(profileService.submitPublicUserPolicy, policyPayload); // if it fails everything fails
      yield put(savePublicUserPolicy(data?.data?.contextAttribute ? data.data : null));
    }

    /*----- CHANGE PASSWORD SERVICE -----*/
    yield call(userService.changePassword, payload);
    yield put(setChangePasswordLoading("SUCCESS"));

    /*----- IF CHANGE PASSWORD IS SUCCESSFUL, LOGIN USER -----*/
    yield put(loginKeys());
    const loginKeysAction = yield take(setLoginKeys.type);

    if (loginKeysAction)
      yield put(login({ username: payload.username, password: payload.newPassword }));
  } catch (error) {
    // show specific error message if 400 & it's a `password complexity requirements` error, otherwise generic
    let type = "";

    switch (error?.response?.status) {
      case 400 &&
        error?.response?.data?.errors?.[0]?.message?.includes("password complexity requirements"):
        type = "CHANGE_PASSWORD_COMPLEXITY_REQUIREMENTS";
        break;

      case 403:
        yield put(
          showErrorPopup({
            status: error?.response?.status,
            message: "EXPIRED_LINK_ERROR",
          })
        );
        yield put(saveErrorStatus({ status: error?.response?.status }));
        type = "EXPIRED_LINK_ERROR";
        break;

      case 401:
        // if (error?.response?.data?.errors?.[0]?.code === "ERR_401 UNAUTHORIZED")
        type = "CHANGE_PASSWORD_ERR_USER_NOT_FOUND_OR_NOT_ACTIVE";
        break;

      default:
        type = "CHANGE_PASSWORD_GENERIC_ERROR";
    }
    yield put(setChangePasswordLoading("ERROR"));
    yield put(setChangePasswordError({ type, status: true }));
    yield put(handleError(error));
  }
}

/**
 * Change User Password
 *
 * @return {*}  {SagaIterator}
 */
function* changePasswordPrivateSaga({
  payload,
}: PayloadAction<ChangePasswordPrivatePayload>): SagaIterator {
  try {
    yield put(setChangePasswordLoading("LOADING"));

    /*----- CHANGE PASSWORD SERVICE -----*/
    yield call(userService.changePasswordPrivate, payload);
    yield put(setChangePasswordLoading("SUCCESS"));
  } catch (error) {
    let type = "";

    if (error?.response?.status === 400) {
      const errorMsg = error.response.data.errors[0].message;

      if (errorMsg.includes("password complexity requirements")) {
        type = "CHANGE_PASSWORD_COMPLEXITY_REQUIREMENTS";
      } else {
        type = "CHANGE_PASSWORD_GENERIC_ERROR";
      }
    }
    if (typeof error === "string") type = error;
    yield put(setChangePasswordLoading("ERROR"));
    yield put(setChangePasswordError({ type, status: true }));
    yield put(handleError(error));
  }
}

/**
 * Check for dupricated emails
 *
 */
function* getUserMailCheckDuplicationSaga(): SagaIterator {
  try {
    const storeIdentifier = yield select(selectStoreIdentifier);
    const { data } = yield call(userService.getUserMailCheckDuplication, storeIdentifier);
    const res: CheckMailDuplication = data.data;

    yield put(setCheckMailDuplication(data.data));

    if (res.selfMailDuplication || res.subusersMailDuplicated?.length !== 0) {
      yield put(togglePopupDuplicatedEmail({ open: true }));
      yield put(setJustLogged(false));
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * Get Personificatiion stores
 *
 */
function* getPersonificationStoresSaga(): SagaIterator {
  try {
    yield put(saveGetPersonificationStoresStatus("LOADING"));
    const response = yield call(userService.getPersonificationStores, {});

    const stores = response.data.data?.body;
    if (stores) yield put(savePersonificationStores(stores));

    yield put(saveGetPersonificationStoresStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePersonificationStores(null));
    yield put(saveGetPersonificationStoresStatus("ERROR"));
  }
}

/**
 * Get Personificatiion dummy
 *
 */
function* getPersonificationDummySaga({
  payload,
}: PayloadAction<getPersonificationDummyPayload>): SagaIterator {
  try {
    yield put(saveGetPersonificationDummyStatus("LOADING"));
    const response = yield call(userService.getPersonificationDummy, payload);

    const { dummyOrgentityAddress, dummyOrgentity } = response.data?.data;
    const dummy = { ...dummyOrgentityAddress, orgentityId: dummyOrgentity.orgentityId };

    if (dummy) yield put(savePersonificationDummy(dummy));

    yield put(saveGetPersonificationDummyStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(savePersonificationDummy(null));
    yield put(saveGetPersonificationDummyStatus("ERROR"));
  }
}

/**
 * Get Personification Popup customers
 *
 * @return {*}  {SagaIterator}
 */
function* getPersonificationCustomersSaga({
  payload,
}: PayloadAction<getPersonificationCustomersPayload>): SagaIterator {
  try {
    yield put(setPersonificationCustomersStatus("LOADING"));
    const { data } = yield call(userService.getPersonificationCustomers, payload);
    yield put(setPersonificationCustomers(data.data));
    yield put(setPersonificationCustomersStatus("SUCCESS"));
  } catch (error) {
    yield put(setPersonificationCustomersStatus("ERROR"));
    yield put(handleError(error));
  }
}

/**
 * Send User to Personificate
 *
 * @return {*}  {SagaIterator}
 */
function* postPersonificationSaga({
  payload,
}: PayloadAction<postPersonificationPayload>): SagaIterator {
  try {
    yield put(setPostPersonificationStatus("LOADING"));
    const { data } = yield call(userService.postPersonification, payload);

    const storeIdentifier = payload.storeSelected.storeIdentifier;
    const currentStoreIdentifier = yield select(selectStoreIdentifier);

    // if (storeIdentifier === currentStoreIdentifier) yield put(getUserContext());
    // else yield put(setStoreIdentifier(storeIdentifier));
    if (storeIdentifier !== currentStoreIdentifier) yield put(setStoreIdentifier(storeIdentifier));

    yield put(setPostPersonificationStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setPostPersonificationStatus("ERROR"));
  }
}

/**
 * Get User Context
 *
 * @return {*}  {SagaIterator}
 */
function* userContextSaga(): SagaIterator {
  try {
    yield put(setGetPrivilegesStatus("LOADING"));
    const storeIdentifier: string = yield select(selectStoreIdentifier);

    /* GET USER CONTEXT */
    const userContextResponse = yield call(userService.getUserContext, storeIdentifier);
    const usercontent = userContextResponse?.data?.data;
    yield put(userContextSuccess(usercontent));
    yield put(stopMultidoorLoading("doorList"));
    yield put(saveIsBackOfficeUser(usercontent?.backOfficeUser ?? false));
    yield put(setChangePasswordLoading("IDLE"));

    /* GET USER PRIVILEGES */
    const activeDoor: Door = usercontent.activeDoor;
    yield put(getPrivileges({ storeIdentifier, activeDoor }));

    /* GET INFO FOR DOORS */
    if (isMultidoor(usercontent.multiDoors, activeDoor)) {
      yield put(getDoorsAddress({ doorId: usercontent.multiDoors, storeIdentifier }));
    } else {
      yield put(saveMultiDoors(usercontent.multiDoors));
    }

    /* GET ORGANIZATION DETAILS to print in account menu */
    yield put(getOrganizationDetails({ doorId: activeDoor, storeIdentifier }));

    /* GET ATTRIBUTES */
    const userAttributes: UserAttributes[] = usercontent?.userAttributes;

    // DISPLAY PRICES PREFERENCE
    const pricePreferenceAttrs: UserAttributes[] =
      userAttributes?.filter((attribute: UserAttributes) => attribute.key === "PRICE_PREFERENCE") ??
      [];
    if (pricePreferenceAttrs.length !== 0)
      yield put(setDisplayPrices(pricePreferenceAttrs[0]?.value as DisplayPricesOptions));

    yield put(setOnePortalConfiguration(usercontent.onePortalConfiguration));
    //SRP-OPT-IN PREFERENCE
    const srpOptInChoice: string =
      userAttributes?.find((attribute: UserAttributes) => attribute.key === "SRP_OPT_IN_CHOICE")
        ?.value || "";
    yield put(setSrpOptInChoice(srpOptInChoice));

    const srpOptInChoiceOp: string =
      userAttributes?.find((attribute: UserAttributes) => attribute.key === "SRP_OPT_IN_CHOICE_OP")
        ?.value || "";
    yield put(setSrpOptInChoiceOp(srpOptInChoiceOp));

    // PLP LAYOUT PREFERENCE
    const plpLayoutPreferenceAttrs: UserAttributes[] =
      userAttributes?.filter(
        (attribute: UserAttributes) => attribute.key === "PLP_LAYOUT_PREFERENCE"
      ) ?? [];
    if (plpLayoutPreferenceAttrs.length !== 0)
      yield put(
        setCataloguePageLayout({
          layout: plpLayoutPreferenceAttrs[0]?.value as CatalogueLayout,
          pageType: "plp",
        })
      );

    // PDP LAYOUT PREFERENCE
    const pdpLayoutPreferenceAttrs: UserAttributes[] =
      userAttributes?.filter(
        (attribute: UserAttributes) => attribute.key === "PDP_LAYOUT_PREFERENCE"
      ) ?? [];
    if (pdpLayoutPreferenceAttrs.length !== 0) {
      yield put(
        setCataloguePageLayout({
          layout: pdpLayoutPreferenceAttrs[0]?.value as CatalogueLayout,
          pageType: "pdp",
        })
      );

      yield put(setCataloguePdpLayoutChanged(true));
    }

    if (userAttributes?.length) {
      const contextTutorialPills = mapContextTutorialPills(userAttributes);
      if (contextTutorialPills !== undefined) {
        yield put(setContextTutorialPillsPopup(contextTutorialPills));
      }
    }

    // LEGAL TERMS OF USE
    // Saving the dictionary stored in the user attribute. This dictionary "[language] = timestamp"
    // contains all the timestamps of the documents that have been already accepted in the differents
    // stores.

    const legalTermsDictionary =
      userAttributes?.find((attribute: UserAttributes) => attribute.key === "TERMS_OF_SERVICE")
        ?.value ?? null;
    yield put(setIsLegalTermsAccepted(false));
    yield put(
      setLegalTermsOfUseDictionary(
        legalTermsDictionary ? JSON.parse(atob(legalTermsDictionary)) : null
      )
    );

    const customerAttributes: CustomerAttributes[] = usercontent?.customerAttributes;
    //BADGE ESSILOR CODE - MY ACCOUNT MENU
    const essilorBadgeCode =
      customerAttributes.find((_) => _.key === "essilor_partnership")?.value || "";
    yield put(setEssilorBadgeCode(essilorBadgeCode));
  } catch (error) {
    yield put(setChangePasswordLoading("IDLE"));
    yield put(handleError(error));
  }
}

/**
 * Save picture account menu when account menu is opened
 */
function* getPictureAccountMenuSaga(): SagaIterator {
  try {
    const essilorBadgeCode = yield select(selectEssilorBadgeCode);
    //SHOW BADGE ESSILOR IN MYACCOUNT MENU
    if (essilorBadgeCode !== "" && essilorBadgeCode !== "ES0") {
      const essilorBadgeUrl = `/contents-subsidiary/essilorexperts-${essilorBadgeCode.toLocaleLowerCase()}`;
      yield put(
        getCMSPictureContent({
          path: essilorBadgeUrl,
          additionalParams: { qryProfile: "myl-thumbnail" },
        })
      );
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Update selected door w/ side effect (ie. running a callback() passed as payload)
 *
 * @param {PayloadAction<UpdateSelectedDoorPayload>} action
 * @return {*}  {SagaIterator}
 */
function* updateSelectedDoorSaga(action: PayloadAction<UpdateSelectedDoorPayload>): SagaIterator {
  try {
    yield put(setSelectedDoor(action.payload.door));
    action.payload?.callback?.(action.payload.door);
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get Advanced Search
 *
 */
function* getAdvancedSearchSaga(): SagaIterator {
  try {
    yield put(setAdvancedSearchLoading(true));
    const { data } = yield call(userService.getAdvancedSearch);
    yield put(setAdvancedSearch(data.data));
  } catch (error) {
    yield put(handleError(error));
  } finally {
    yield put(setAdvancedSearchLoading(false));
  }
}

/**
 * Get Door details
 *
 */
function* getOrganizationDetailsSaga(action: PayloadAction<DoorAndStoreIdPayload>): SagaIterator {
  try {
    const response = yield call(userService.getOrganizationDetails, action.payload);
    const data = response.data?.data;
    if (data) {
      const details: OrganizationDetails = {
        displayName: data.displayName,
        firstName: data.contactInfo?.firstName,
        address1: data.contactInfo?.address1,
        address2: data.contactInfo?.address2,
        city: data.contactInfo?.city,
        country: data.contactInfo?.country,
        state: data.contactInfo?.state,
        orgentityName: data.organizationName,
        orgentityId: data.organizationId,
        email1: data?.contactInfo?.email1,
      };
      yield put(setOrganizationDetails(details));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

/**
 * Get User privileges
 *
 */
function* getPrivilegesSaga(action: PayloadAction<GetPrivilegesPayload>): SagaIterator {
  try {
    yield put(setGetPrivilegesStatus("LOADING"));
    const { data } = yield call(userService.getPrivileges, action.payload.storeIdentifier);

    const multidoors = yield select(selectMultiDoors);
    const activeDoor: Door = yield select(selectActiveDoor);
    const isSubuser: boolean = yield select(selectIsSubuser);
    if (
      isSubuser &&
      multidoors &&
      activeDoor &&
      !multidoors.find((door: any) => door.orgentityName === activeDoor.orgentityName)
    ) {
      const newUserHavingPrivilege: UserHavingPrivilege[] = [];
      const subuser = { username: activeDoor.username, orgentityName: activeDoor.orgentityName };
      data.data.userHavingPrivilege.forEach((privilege: UserHavingPrivilege) => {
        const subuserHasPrivilege = data.data.mainDoorPrivilege.privileges.find(
          (priv: string) => priv === privilege.privilege
        );
        if (subuserHasPrivilege) {
          newUserHavingPrivilege.push({ ...privilege, users: [...privilege.users, subuser] });
        } else {
          newUserHavingPrivilege.push({ ...privilege });
        }
      });
      const dataCopy = { ...data.data, userHavingPrivilege: newUserHavingPrivilege };
      yield put(savePrivileges(dataCopy));
    } else {
      yield put(savePrivileges(data.data));
    }

    yield put(getPrivilegesSuccess(true));
    yield put(setGetPrivilegesStatus("SUCCESS"));
  } catch (error) {
    yield put(handleError(error));
    yield put(setGetPrivilegesStatus("ERROR"));
  }
}

/**
 * Get total doors number
 *
 */
function* getTotalDoorsSaga(): SagaIterator {
  try {
    const qparams = {
      page: 1,
      size: 1,
    };
    const response = yield call(multidoorService.getDoorList, qparams);
    const doorsList = response.data?.data;
    const totalDoors = doorsList?.totalDoors ?? 0;
    yield put(saveTotalDoors(totalDoors));
  } catch (error) {
    yield put(saveTotalDoors(0));
    yield put(handleError(error));
  }
}

/**
 * Get doors info for multidoor
 *
 */
function* getDoorsAddressSaga(action: PayloadAction<GetDoorsAddressPayload>): SagaIterator {
  try {
    // yield put(startProfileLoading("addresses"));
    const { storeIdentifier } = action.payload;

    const { data } = yield call(profileService.getProfileAddresses, { storeIdentifier });

    const addressList = data.data?.addressList;
    const soldToAddresses = isSoldToAddress(addressList);
    const enrichedDoors = action?.payload?.doorId.map((door) => {
      const singleAddress = soldToAddresses.find((_) => _.orgentityName === door.orgentityName);
      const address =
        singleAddress?.address1 +
        ", " +
        singleAddress?.city +
        " " +
        singleAddress?.state +
        " - " +
        singleAddress?.country;
      return {
        ...door,
        address: address,
        customerName: singleAddress?.customerName ?? "",
      };
    });

    yield put(saveMultiDoors(enrichedDoors));
    // yield put(saveProfileAddresses(data.data.addressList));
    // yield put(stopProfileLoading("addresses"));
  } catch (error) {
    yield put(saveMultiDoors([]));
    // yield put(stopProfileLoading("addresses"));
    yield put(handleError(error));
  }
}

/*
 * Update selected display prices
 */
function* updateSelectedDisplayPricesSaga(
  action: PayloadAction<DisplayPricesPayload>
): SagaIterator {
  try {
    const { selectedDisplayPrices } = action.payload;
    yield put(setDisplayPricesLoading("LOADING"));
    yield call(userService.updateSelectedDisplayPrices, { selectedDisplayPrices });
    yield put(setDisplayPrices(selectedDisplayPrices));
    yield put(setDisplayPricesLoading("SUCCESS"));
  } catch (error) {
    yield put(setDisplayPricesLoading("ERROR"));
    yield put(handleError(error));
  }
}

function* updateCataloguePageLayoutSaga(
  action: PayloadAction<CatalogueLayoutPayload>
): SagaIterator {
  const { pageType } = action.payload;
  try {
    yield put(setCataloguePageLayoutLoading({ status: "LOADING", pageType }));

    yield put(setCataloguePageLayout(action.payload));
    yield call(userService.updateCataloguePageLayout, action.payload);
    yield put(setCataloguePageLayoutLoading({ status: "SUCCESS", pageType }));
  } catch (err) {
    yield put(setCataloguePageLayoutLoading({ status: "ERROR", pageType }));
  }
}

function* updateSrpOptInChoiceSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setSrpOptInLoading("LOADING"));
    yield call(userService.updateSrpOptInChoice, action.payload);
    yield put(setSrpOptInChoice(action.payload));
    yield put(setSrpOptInLoading("SUCCESS"));
    document.location.reload();
  } catch (err) {
    yield put(setSrpOptInLoading("ERROR"));
  }
}

function* updateSrpOptInChoiceOpSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setSrpOptInLoadingOp("LOADING"));
    yield call(userService.updateSrpOptInChoiceOp, action.payload);
    yield put(setSrpOptInChoiceOp(action.payload));
    yield put(setSrpOptInLoadingOp("SUCCESS"));
    document.location.reload();
  } catch (err) {
    yield put(setSrpOptInLoadingOp("ERROR"));
  }
}

function* updateTutorialPillsSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(userService.updateTutorialPills, action.payload);
  } catch (err) {
    yield put(handleError(err));
  }
}

function* updateTermsOfServiceSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(userService.updateTermsOfServiceAcceptance, action.payload);
  } catch (err) {
    yield put(handleError(err));
  }
}

function* getCMSPictureContentSaga(action: PayloadAction<CMSPictureContentPayload>): SagaIterator {
  yield put(saveGetEssilorBadgePictureStatus("LOADING"));
  try {
    const params = {
      page: "*",
      pagePath: action.payload.path,
      ...action.payload.additionalParams,
    };
    const response = yield call(storeService.getPageLayout, params);

    if (response && response.data.data) {
      const picture = response.data.data.pictures[0];
      const pictureURI = picture.data.uri ?? "";
      yield put(saveEssilorBadgePicture(pictureURI));
    }
    yield put(saveGetEssilorBadgePictureStatus("SUCCESS"));
  } catch (error) {
    yield put(saveGetEssilorBadgePictureStatus("ERROR"));
    yield put(handleError(error));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////MOST USED//////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Obtain user's most used content
 *
 * @return {*}  {SagaIterator}
 */
function* getMostUsedSaga(): SagaIterator {
  try {
    yield put(setMostUsedLoading({ type: "get", value: "LOADING" }));

    const response = yield call(userService.getMostUsed);
    const chips = response?.data?.data?.chips?.result?.[0]?.items;
    const preferences = response?.data?.data?.preferences;
    const mostUsedCms = mapMostUsedCms(chips);
    const mostUsed: MostUsed = {
      mostUsedPreferences: preferences,
      mostUsedChipsCms: mostUsedCms,
    };
    yield put(setMostUsed(mostUsed));

    yield put(setMostUsedLoading({ type: "get", value: "SUCCESS" }));
  } catch (error) {
    yield put(setMostUsedLoading({ type: "get", value: "ERROR" }));
  }
}

/**
 * Updates user's most used chips
 *
 * @return {*} {SagaIterator}
 */
function* updateMostUsedSaga(action: PayloadAction<MostUsedPayload>): SagaIterator {
  try {
    yield put(setMostUsedLoading({ type: "update", value: "LOADING" }));

    yield call(userService.updateMostUsed, action.payload);
  } catch (error) {
    yield put(setMostUsedLoading({ type: "update", value: "ERROR" }));
    yield put(handleError(error));
  } finally {
    yield put(getMostUsed());
    yield put(setMostUsedLoading({ type: "update", value: "SUCCESS" }));
  }
}

export function* userSaga(): SagaIterator {
  yield takeEvery(loginKeys.type, loginKeysSaga);
  yield takeEvery(login.type, loginSaga);
  yield takeLatest(logout.type, logoutSaga);
  yield takeLatest(getMostUsed.type, getMostUsedSaga);
  yield takeLatest(updateMostUsed.type, updateMostUsedSaga);
  yield takeLatest(resetPassword.type, resetPasswordSaga);
  yield takeLatest(changePassword.type, changePasswordSaga);
  yield takeLatest(changePasswordPrivate.type, changePasswordPrivateSaga);
  yield takeLatest(getPersonificationCustomers.type, getPersonificationCustomersSaga);
  yield takeLatest(postPersonification.type, postPersonificationSaga);
  yield takeLatest(updateSelectedDisplayPrices.type, updateSelectedDisplayPricesSaga);
  yield takeLatest(updateCataloguePageLayout.type, updateCataloguePageLayoutSaga);
  yield takeLatest(updateSrpOptInChoice.type, updateSrpOptInChoiceSaga);
  yield takeLatest(updateSrpOptInChoiceOp.type, updateSrpOptInChoiceOpSaga);
  yield takeLatest(updateTutorialPills.type, updateTutorialPillsSaga);
  yield takeLatest(updateTermsOfService.type, updateTermsOfServiceSaga);
  yield takeEvery(getUserContext.type, userContextSaga);
  yield takeEvery(updateSelectedDoor.type, updateSelectedDoorSaga);
  yield takeEvery(getAdvancedSearch.type, getAdvancedSearchSaga);
  yield takeEvery(getOrganizationDetails.type, getOrganizationDetailsSaga);
  yield takeEvery(getPrivileges.type, getPrivilegesSaga);
  yield takeEvery(getPersonificationStores.type, getPersonificationStoresSaga);
  yield takeEvery(getPersonificationDummy.type, getPersonificationDummySaga);
  yield takeEvery(getTotalDoors.type, getTotalDoorsSaga);
  yield takeEvery(getDoorsAddress.type, getDoorsAddressSaga);
  yield takeEvery(getUserMailCheckDuplication.type, getUserMailCheckDuplicationSaga);
  yield takeEvery(getCMSPictureContent.type, getCMSPictureContentSaga);
  yield takeEvery(getPictureAccountMenu.type, getPictureAccountMenuSaga);
}
