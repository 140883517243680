import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorObj, RequestStatus } from "../../interfaces/mainInterfaces";
import { emptyError } from "../../utils/consts";
import { RootState } from "../storeConfig";

import { OrderHistoryDetailsEssilor } from "../checkout/orderConfirmationEssilorInterfaces";
import {
  GetDeliveryNoteResponse,
  OrderDetailsResult,
  OrderDetailsSummary,
  OrderHistoryFiltersStored,
  OrderHistoryResult,
  OrderHistoryState,
} from "./orderHistoryInterface";

export const sliceName = "orderHistory";

const orderHistoryResultFiltersDefault: OrderHistoryFiltersStored = {
  from: "",
  to: "",
  orderMode: null,
  orderType: null,
  status: null,
  reference: "",
  orderNumber: "",
  orderPlacedBy: "",
  customId: "",
  brand: null,
  toggleFilters: "",
  pageSize: { value: 15, label: "15" },
  pageNumber: 1,
  door: null,
};

const initialState: OrderHistoryState = {
  orderHistoryResult: null,
  orderHistoryResultFilters: orderHistoryResultFiltersDefault,
  orderDetailsResult: null,
  orderDetailsResultSummary: null,
  orderDetailsEssilor: null,
  essilorOrderJSON: "",
  deliveryNoteResult: null,
  errorInputOrderHistory: { ...emptyError },
  getOrderHistoryStatus: "IDLE",
  getOrderDetailsStatus: "IDLE",
  isRepeatable: false,
};

export const orderHistorySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    saveOrderHistoryResult: (state, action: PayloadAction<OrderHistoryResult | null>) => {
      state.orderHistoryResult = action.payload;
    },
    saveOrderHistoryFilters: (state, action: PayloadAction<OrderHistoryFiltersStored | null>) => {
      state.orderHistoryResultFilters = action.payload;
    },
    saveOrderDetailsResult: (state, action: PayloadAction<OrderDetailsResult | null>) => {
      state.orderDetailsResult = action.payload;
    },
    saveOrderDetailsResultSummary: (state, action: PayloadAction<OrderDetailsSummary[] | null>) => {
      state.orderDetailsResultSummary = action.payload;
    },
    saveOrderDetailsEssilor: (state, action: PayloadAction<OrderHistoryDetailsEssilor | null>) => {
      state.orderDetailsEssilor = action.payload;
    },
    saveOrderDetailsJSON: (state, action: PayloadAction<string>) => {
      state.essilorOrderJSON = action.payload;
    },
    saveDeliveryNoteResponse: (state, action: PayloadAction<GetDeliveryNoteResponse | null>) => {
      state.deliveryNoteResult = action.payload;
    },
    setErrorInputOrderHistory: (state, action: PayloadAction<ErrorObj>) => {
      state.errorInputOrderHistory = action.payload;
    },
    setOrderHistoryStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getOrderHistoryStatus = action.payload;
    },
    setOrderDetailsStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.getOrderDetailsStatus = action.payload;
    },
    setIsRepeatable: (state, { payload }) => {
      state.isRepeatable = payload;
    },
    resetOrderDetails: (state) => {
      state.orderDetailsResult = null;
      state.deliveryNoteResult = null;
    },
    resetState: () => initialState,
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  saveOrderHistoryResult,
  saveOrderHistoryFilters,
  saveOrderDetailsResult,
  saveOrderDetailsResultSummary,
  saveOrderDetailsEssilor,
  saveOrderDetailsJSON,
  saveDeliveryNoteResponse,
  setErrorInputOrderHistory,
  setOrderHistoryStatus,
  setOrderDetailsStatus,
  setIsRepeatable,
  resetOrderDetails,
  resetState,
} = orderHistorySlice.actions;

export const selectOrderHistoryResult = (state: RootState): OrderHistoryResult | null => {
  return state.orderHistory.orderHistoryResult;
};
export const selectOrderHistoryFilters = (state: RootState): OrderHistoryFiltersStored | null => {
  return state.orderHistory.orderHistoryResultFilters;
};

export const selectOrderDetailsResult = (state: RootState): OrderDetailsResult | null => {
  return state.orderHistory.orderDetailsResult;
};

export const selectOrderDetailsResultSummary = (state: RootState): OrderDetailsSummary[] | null => {
  return state.orderHistory.orderDetailsResultSummary;
};

export const selectErrorOrderHistoryInput = (state: RootState): ErrorObj => {
  return state.orderHistory.errorInputOrderHistory;
};

export const selectOrderHistoryStatus = (state: RootState): RequestStatus => {
  return state.orderHistory.getOrderHistoryStatus;
};

export const selectOrderDetailsStatus = (state: RootState): RequestStatus => {
  return state.orderHistory.getOrderDetailsStatus;
};

export const selectOrderDetailJSON = (state: RootState): string => {
  return state.orderHistory.essilorOrderJSON;
};

export const selectIsRepeatable = (state: RootState): boolean | undefined => {
  return state.orderHistory.isRepeatable;
};

export const selectDeliveryNoteResponse = (state: RootState): GetDeliveryNoteResponse | null => {
  return state.orderHistory.deliveryNoteResult;
};

export const selectOrderDetailsEssilor = (state: RootState): OrderHistoryDetailsEssilor | null => {
  return state.orderHistory.orderDetailsEssilor;
};

export default orderHistorySlice.reducer;
