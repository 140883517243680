import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x-icon.svg";
import { paletteLight } from "../../../../assets/theme/palette";
import useTranslation from "../../../../hooks/useTranslation";
import { getWarrantyInboxCount } from "../../../../store/aftersales/aftersalesSaga";
import {
  selectForceUpdateCounterSR,
  setForceUpdateCounterSR,
} from "../../../../store/serviceRequests/serviceRequestSlice";
import { togglePopupPersonification } from "../../../../store/store/storeSlice";
import { logout } from "../../../../store/user/userSagas";
import {
  selectEssilorBadgeCode,
  selectEssilorBadgePicture,
  selectIsBackOfficeUser,
  selectIsMultidoor,
  selectOrganizationDetails,
  selectSelectedDoor,
  selectUserName,
} from "../../../../store/user/userSlice";
import { getPlaceholderImage } from "../../../../utils/catalogueUtils";
import { navbarSections } from "../../../pages/my-account/account-navbar/navbarSections";
import Button from "../../../styled-UI/Button";
import CustomText from "../../../styled-UI/CustomText";
import MassiveOrderModeToggleAccount from "../../../widgets/MassiveOrderModeToggleAccount";
import WrapperLayoutMenu from "../../WrapperLayoutMenu";
import AccountMenuAccordion from "./AccontMenuAccordion";
import useSetTutorialPillsPopupVisibility from "../../../../hooks/useSetTutorialPillsPopupVisibility";

interface Props {
  showAccountMenu: (showMenu: boolean) => void;
  isStripVisible: boolean;
}

const AccountMenu = ({ showAccountMenu, isStripVisible }: Props): JSX.Element => {
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null);
  const dispatch = useDispatch();
  const orgentityDetails = useSelector(selectOrganizationDetails);
  const { translateLabel } = useTranslation();
  const username = useSelector(selectUserName);
  const backOfficeUser = useSelector(selectIsBackOfficeUser);
  const selectedDoor = useSelector(selectSelectedDoor);
  const forceUpdateCounterWarrantySr = useSelector(selectForceUpdateCounterSR);
  const togglePersonificationPopup = () => {
    dispatch(togglePopupPersonification({ open: true }));
  };
  const isMultidoor = useSelector(selectIsMultidoor);
  useSetTutorialPillsPopupVisibility();
  const accountMenuRef = useRef<HTMLDivElement | null>(null);

  //ESSILOR BADGE
  const essilorBadgeCode = useSelector(selectEssilorBadgeCode);
  const showBadge =
    essilorBadgeCode === "ES1" || essilorBadgeCode === "ES2" || essilorBadgeCode === "ES3";
  const essilorBadgePicture = useSelector(selectEssilorBadgePicture);
  const placeholderBadge = getPlaceholderImage("portrait", "gray");

  //GET COUNTER FOR WARRANTY INBOX AND SERVICE REQUEST PAGE
  useEffect(() => {
    if (selectedDoor && forceUpdateCounterWarrantySr) {
      dispatch(getWarrantyInboxCount(selectedDoor.orgentityId));
      dispatch(setForceUpdateCounterSR(false));
    }
  }, [selectedDoor, forceUpdateCounterWarrantySr]);

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (accountMenuRef.current) {
        const target = (event.target as Node) || null;
        accountMenuRef.current.contains(target) ? showAccountMenu(true) : showAccountMenu(false);
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const closeButton = (
    <WrapperButton>
      <CloseButton data-element-id="AccountMenu_Close" onClick={() => showAccountMenu(false)}>
        <CloseIcon />
      </CloseButton>
    </WrapperButton>
  );

  return (
    <MenuWrapper
      className={clsx("account-menu")}
      data-element-id="MainNav_MyAccount_Overlay"
      isOpen={!!openedAccordion}
      isStripVisible={isStripVisible}
    >
      <WrapperLayoutMenu>
        <MenuContainer ref={accountMenuRef}>
          {/* ////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////// */}
          {/* //////////////////////////////////FIRST COLUMN//////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////// */}
          <Column>
            <Header>
              <CustomText
                as="p"
                font="font-medium"
                fontSizePx={18}
                lineHeightPx={18}
                fontWeightNum={700}
              >
                {translateLabel("ACCOUNT_MENU_TITLE")}
              </CustomText>
              <MassiveOrderModeToggleAccount />
            </Header>
            <MenuSection style={{ borderTop: `2px solid ${paletteLight.gray.borders}` }}>
              {/* ////////////////////////////////////////////////////////////////////////// */}
              {/* //////////////////////////////////USER INFO/////////////////////////////// */}
              {/* ////////////////////////////////////////////////////////////////////////// */}
              <div>
                <CustomText
                  as="p"
                  fontSizePx={14}
                  lineHeightPx={16.8}
                  fontWeightNum={700}
                  font="font-medium"
                  uppercase
                >
                  {username}
                </CustomText>
                <div>
                  <CustomText
                    as="p"
                    font="font-regular"
                    fontSizePx={14}
                    lineHeightPx={24}
                    marginTopPx={15}
                    fontWeightNum={400}
                    uppercase
                  >
                    {orgentityDetails &&
                      orgentityDetails?.displayName + " - " + orgentityDetails?.firstName}
                  </CustomText>
                  <CustomText
                    as="p"
                    font="font-regular"
                    fontSizePx={14}
                    lineHeightPx={24}
                    fontWeightNum={400}
                    uppercase
                  >
                    {orgentityDetails &&
                      orgentityDetails?.address1 +
                        " " +
                        orgentityDetails?.address2 +
                        ", " +
                        orgentityDetails?.city +
                        " " +
                        orgentityDetails?.state +
                        " - " +
                        orgentityDetails?.country}
                  </CustomText>
                </div>
              </div>
              <ButtonWrapper>
                {(process.env.NODE_ENV === "development" || backOfficeUser) && (
                  <Button
                    type="secondary"
                    onClick={(e) => {
                      togglePersonificationPopup();
                      e.stopPropagation();
                    }}
                    data-element-id="MainNav_MyAccount_Overlay_Change"
                  >
                    {translateLabel("ACCOUNT_MENU_CHANGE")}
                  </Button>
                )}
                <Button
                  type="secondary"
                  onClick={(e) => {
                    dispatch(logout({ buttonClicked: true }));
                    e.stopPropagation();
                  }}
                  data-element-id="MainNav_MyAccount_Overlay_Logout"
                >
                  {translateLabel("LOGOUT")}
                </Button>
              </ButtonWrapper>
              {showBadge && essilorBadgePicture !== "" && (
                <RightSection>
                  <CustomText
                    as="p"
                    font="font-regular"
                    fontSizePx={18}
                    lineHeightPx={18}
                    fontWeightNum={700}
                    paddingBottomPx={16}
                  >
                    {translateLabel("ACCOUNT_MENU_BADGES")}
                  </CustomText>
                  <Badges>
                    <BadgeButton data-element-id="AccountMenu_Partnerships">
                      {/* data-description={`_`}> */}
                      <img src={essilorBadgePicture} />
                    </BadgeButton>
                  </Badges>
                </RightSection>
              )}
            </MenuSection>
            {/* ////////////////////////////////////////////////////////////////////////// */}
            {/* ////////////////////////////////// CTA//////////////////////////////////// */}
            {/* ////////////////////////////////////////////////////////////////////////// */}
          </Column>
          <Column className={clsx("background-half")} isMultidoor={isMultidoor}>
            {navbarSections.map((section, index) => (
              <AccountMenuAccordion
                closeButton={closeButton}
                title={section.sectionTitle}
                contents={section.links}
                key={section.sectionTitle}
                isOpen={openedAccordion === section.sectionTitle}
                isFirst={index === 0}
                onToggle={() =>
                  setOpenedAccordion((prev) =>
                    prev === section.sectionTitle ? null : section.sectionTitle
                  )
                }
                showAccountMenu={showAccountMenu}
              />
            ))}
          </Column>
        </MenuContainer>
      </WrapperLayoutMenu>
    </MenuWrapper>
  );
};

const BadgeButton = styled.button`
  cursor: default;
  display: flex;
  img {
    max-width: fit-content;
    max-height: 4rem;
  }
`;

const Header = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuWrapper = styled.div<{
  isOpen?: boolean;
  isStripVisible?: boolean;
}>`
  ${(props) => props.isOpen && `background-color: ${props.theme.palette.white}`};

  position: absolute;
  height: calc(
    100vh -
      ${(props) =>
        props.isStripVisible
          ? props.theme.headerHeightRem + "rem"
          : props.theme.headerHeightRem - props.theme.stripHeightRem + "rem"}
  );
  max-height: 45.85rem;

  top: ${(props) =>
    (props.isStripVisible
      ? props.theme.headerHeightRem
      : props.theme.headerHeightRem - props.theme.stripHeightRem) + "rem"};
  left: 0;
  cursor: auto;
  width: 100%;

  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100vw;
      background-color: ${(props) => props.theme.palette.white};
      z-index: 1;
      left: -100vw;
    }
  }
`;

const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Column = styled.div<{ isMultidoor?: boolean }>`
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
  &:nth-child(1) {
    padding: 2rem 2rem 2rem 2.5rem;
    border-right: 1px solid ${(props) => props.theme.palette.gray.borders}; /* Bordo destro solo per la prima colonna */

    @media ${(props) => props.theme.queries.md} {
      padding: 2rem 2rem 2rem 1rem;
    }
  }

  &:nth-child(2) {
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: ${(props) => (props.isMultidoor ? `3.5rem 2rem` : `2.7rem 2rem`)};
  }
  background: ${(props) => props.theme.palette.white};

  &.background-half {
    background: linear-gradient(to right, white 51%, transparent 50%);
    padding: ${(props) =>
      props.isMultidoor ? `3.5rem 2rem 2rem 2.5rem` : `2.7rem 2rem 2rem 2.5rem`};
  }
`;

const MenuSection = styled.div`
  padding: 1.5rem 0 1rem;
  display: grid;
  gap: 1rem;
`;

const Badges = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.gray.borders};
  display: flex;
  justify-content: start;
  gap: 1rem;
  padding: 1rem 0 0;
`;

const RightSection = styled.div`
  background-color: ${(props) => props.theme.palette.gray.light};
  padding: 1.5rem 1.5rem 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const WrapperButton = styled.div`
  cursor: pointer;
  text-align: right;
  padding: 0 4rem 0 0;
  width: 50%;
  position: absolute;
  top: 2rem;
`;

const CloseButton = styled.button`
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MemoizedAccountMenu = React.memo(AccountMenu);
