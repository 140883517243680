import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as ArrowRightIcon } from "../../../../../../assets/icons/arrowhead-right-icon.svg";
import useGetThirdLevelMenu from "../../../../../../hooks/useGetThirdLevelMenu";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import useTranslation from "../../../../../../hooks/useTranslation";
import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";
import { getPagePath } from "../../../../../../routing/routesUtils";
import { selectThirdLevelMenu } from "../../../../../../store/store/storeSlice";
import { selectIsMultidoor } from "../../../../../../store/user/userSlice";
import { getCompleteId } from "../../../../../../utils/menuUtils";
import { capitalizeFirstLetter } from "../../../../../../utils/utils";
import CustomText from "../../../../../styled-UI/CustomText";
import LinkButton from "../../../../../styled-UI/LinkButton";
import LoaderMenu from "../../../../../styled-UI/loader/menu/LoaderMenu";
import InstrumentsMenu from "./InstrumentsMenu";
import LensesMenu from "./LensesMenu";
import MenuColumns from "./MenuColumns";

interface Props {
  menu: SecondLevelMenu;
  setCurrent: (category: string) => void;
  current: string;
  setToggleMenu: (identifier: string) => void;
  marginTop: number;
  openProp?: boolean; //DEV ONLY: for styling purposes
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

const CategoryItemComponent = ({
  openProp = false,
  current,
  setCurrent,
  menu,
  setToggleMenu,
  marginTop = 0,
  onScrollFadeStateChange,
}: Props): JSX.Element => {
  const { name, uriPicture, identifier, uniqueID } = menu;
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);
  const isMultidoor = useSelector(selectIsMultidoor);
  const getThirdLevelMenu = useGetThirdLevelMenu();

  const { translateLabel } = useTranslation();
  const history = useHistory();
  const completeId = getCompleteId(uniqueID, identifier);
  const [menuShown, setMenuShown] = useState<boolean>(openProp);
  const currentMenu = thirdLevelMenu[completeId];
  const faded = completeId === current ? false : true;

  //BRANDS
  const [infoBrands, setInfoBrands] = useState<{
    hasStars: boolean;
    hasBrands: boolean;
  } | null>(null);

  useEffect(() => {
    if (currentMenu) {
      let hasStars = false;
      let hasBrands = false;

      currentMenu?.forEach((_) => {
        if (_.value === "manufacturer.raw") {
          _.entry?.forEach((e) => {
            if (e.isStars) {
              hasStars = true;
            } else {
              hasBrands = true;
            }
          });
        }
      });

      setInfoBrands({
        hasStars,
        hasBrands,
      });
    }
  }, [thirdLevelMenu[completeId]]);

  /* When hover on menu, call api to get menu info only the first time */
  const toggleMenu = () => {
    const open = current === completeId ? false : true;
    if (!openProp) {
      setMenuShown(open);
    }
    if (open) {
      setCurrent(completeId);
    } else {
      setCurrent("");
    }

    //Get information for third level menu
    getThirdLevelMenu(open, menu);
  };

  const showViewAll =
    menu.completeUrl &&
    !(
      identifier.includes("GOGGLES_HELMETS") ||
      identifier.includes("AFA") ||
      identifier.includes("LENSES")
    ) &&
    currentMenu &&
    infoBrands !== null &&
    !infoBrands.hasStars;

  return (
    <Container>
      {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////CATEGORY MENU///////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
      <CategoryButton
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu();
        }}
        className={clsx(current !== "" && faded && "item-faded")}
        data-element-id={`MainNav_Products_${menu.identifier.replace(/ /g, "_")}`}
      >
        <CustomText
          as="div"
          fontSizePx={14}
          fontWeightNum={400}
          font="font-regular"
          justifyContent="start"
          isEllipsis
        >
          <CategoryContainer opened={current === completeId}>
            <a>
              {menu.completeUrl &&
              identifier.toLowerCase() !== "lenses" &&
              identifier.toLowerCase() !== "instruments"
                ? capitalizeFirstLetter(name)
                : translateLabel(name)}
            </a>
          </CategoryContainer>
        </CustomText>
        <ArrowRightIcon />
      </CategoryButton>
      {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////SUBMENU/////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
      {menuShown && current === completeId && (
        <Submenu
          marginTop={marginTop}
          className={clsx(
            isMultidoor && "submenu-multidoor",
            infoBrands?.hasStars && infoBrands?.hasBrands && "submenu-gradient",
            infoBrands?.hasStars && !infoBrands?.hasBrands && "submenu-stars"
          )}
        >
          <SubmenuMenu className="submenuMenu">
            {identifier === "Lenses" || identifier === "LENSES" ? (
              <LensesMenu menu={menu} setToggleMenu={setToggleMenu} />
            ) : identifier === "Instruments" || identifier === "INSTRUMENTS" ? (
              <InstrumentsMenu menu={menu} setToggleMenu={setToggleMenu} />
            ) : currentMenu ? (
              <MenuColumns
                menu={menu}
                toggleMenu={toggleMenu}
                setToggleMenu={setToggleMenu}
                onScrollFadeStateChange={onScrollFadeStateChange}
              />
            ) : (
              <LoaderMenu />
            )}
            {showViewAll && (
              <ViewAllContainer>
                <LinkButton
                  uppercase
                  underline={false}
                  onClick={() => {
                    toggleMenu();
                    setToggleMenu("");
                    resetScrollPosition();
                    history.push(getPagePath(menu.completeUrl));
                  }}
                  data-element-id={`MainNav_Menu_ViewAll`}
                >
                  {translateLabel("VIEW_ALL")}
                </LinkButton>
              </ViewAllContainer>
            )}
          </SubmenuMenu>
        </Submenu>
      )}
    </Container>
  );
};

const Container = styled.div``;

export const CategoryContainer = styled.div<{ opened: boolean }>`
  border-bottom: ${(props) =>
    props.opened ? "1px solid " + props.theme.palette.primary : "1px solid transparent"};

  a {
    ${(props) => props.opened && "font-weight: 700"};
  }

  &:hover {
    border-bottom: ${(props) => "1px solid " + props.theme.palette.primary};
    a {
      font-weight: 700;
    }
  }
`;

export const CategoryButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0;

  svg {
    object-fit: contain;
    width: 1rem;
  }
`;
const Submenu = styled.div<{ marginTop?: number }>`
  box-shadow: ${(props) => props.theme.palette.menuBoxShadow};
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  height: 100%;

  top: 0;
  padding: 2.5rem 2rem 2rem 2rem;
  left: 33%;
  width: 33%;
`;
const SubmenuMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 19.875rem;
  overflow-y: auto;
  max-height: 100%;
`;

const ViewAllContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;

  button {
    border-bottom: 1px solid ${(props) => props.theme.palette.primary};
  }
`;

export default CategoryItemComponent;
