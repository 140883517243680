import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontFamilies } from "../../store/store/storeInterfaces";
import {
  saveDownloadedFonts,
  selectDownloadedFonts,
  selectRequestedFonts,
} from "../../store/store/storeSlice";

const removeCommonElements = (
  searchArray: FontFamilies[],
  differenceArray: FontFamilies[]
): FontFamilies[] => {
  return searchArray.filter((item) => {
    return differenceArray.indexOf(item) < 0;
  });
};

export const CustomFonts = (): JSX.Element => {
  // Use this to test const requestedFonts: FontFamilies[] = ["Caboto"];
  const requestedFonts = useSelector(selectRequestedFonts);
  const downloadedFonts = useSelector(selectDownloadedFonts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestedFonts) {
      const missingFonts = downloadedFonts
        ? removeCommonElements(requestedFonts, downloadedFonts)
        : requestedFonts;
      missingFonts.forEach((fontFamily: FontFamilies) => {
        switch (fontFamily) {
          case "72 Condensed":
            require("../css/font-imports/72-condensed.css");
            break;
          case "Aktiv Grotesk":
            require("../css/font-imports/aktiv-grotesk.css");
            break;
          case "Bembo MT Pro":
            require("../css/font-imports/bembo-mt-pro.css");
            break;
          case "Bulgari Capitalis":
            require("../css/font-imports/bulgari-capitalis.css");
            break;
          case "Burberry":
            require("../css/font-imports/burberry.css");
            break;
          case "Burberry House Regular":
            require("../css/font-imports/burberry-house.css");
            break;
          case "Caboto":
            require("../css/font-imports/caboto.css");
            break;
          case "Calibri":
            require("../css/font-imports/calibri.css");
            break;
          case "Chanel Corpo":
            require("../css/font-imports/chanel-corpo.css");
            break;
          case "Din-Pro":
            require("../css/font-imports/din-pro.css");
            break;
          case "Din-Pro Condensed":
            require("../css/font-imports/din-pro-condensed.css");
            break;
          case "Euclid Ignited":
            require("../css/font-imports/euclid-ignited.css");
            break;
          case "Fenice STD":
            require("../css/font-imports/fenice-std.css");
            break;
          case "Franklin Gothic Demi Regular":
            require("../css/font-imports/franklin-gothic-demi.css");
            break;
          case "Futura LT":
            require("../css/font-imports/futura-lt.css");
            break;
          case "Futura STD":
            require("../css/font-imports/futura-std.css");
            break;
          case "Garamond Narrow Plain":
            require("../css/font-imports/garamond-narrow-plain.css");
            break;
          case "Helvetica Neue":
            require("../css/font-imports/helvetica-neue.css");
            break;
          case "Hurme Geometric":
            require("../css/font-imports/hurme-geometric.css");
            break;
          case "ITC Novarese for Swarovski":
            require("../css/font-imports/itc-novarese-for-swarovski.css");
            break;
          case "Kors Sans":
            require("../css/font-imports/kors-sans.css");
            break;
          case "RadiantURWBol":
            require("../css/font-imports/radiant-urw-bol.css");
            break;
          case "Ray-Ban Sans":
            require("../css/font-imports/ray-ban-sans.css");
            break;
          case "Santral":
            require("../css/font-imports/santral.css");
            break;
          case "Selawik":
            require("../css/font-imports/selawik.css");
            break;
          case "Sweet Sans Pro":
            require("../css/font-imports/sweet-sans-pro.css");
            break;
          case "Versace":
            require("../css/font-imports/versace.css");
            break;
          default:
            break;
        }
      });
      if (missingFonts.length && downloadedFonts) {
        dispatch(saveDownloadedFonts([...missingFonts, ...downloadedFonts]));
      } else if (missingFonts.length) {
        dispatch(saveDownloadedFonts([...missingFonts]));
      }
    }
  }, [requestedFonts]);

  return <></>;
};
