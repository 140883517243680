import clsx from "clsx";
import React, { useState } from "react";
import styled from "styled-components/macro";

interface ToggleProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
}

const ToggleButtonSimplified = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ disabled = false, onChange, onClick, defaultChecked }, forwardedRef): JSX.Element => {
    return (
      <StyledToggleButton onClick={onClick} disabled={disabled}>
        <label className="switch">
          <input type="checkbox" onChange={onChange} ref={forwardedRef} checked={defaultChecked} />
          <div className="slider" />
          <span className={clsx(defaultChecked ? "switch-on" : "switch-off")}>
            {defaultChecked ? `On` : `Off`}
          </span>
        </label>
      </StyledToggleButton>
    );
  }
);

ToggleButtonSimplified.displayName = "ToggleButton";

const StyledToggleButton = styled.div<{ disabled: boolean; fontSize?: number }>`
  .switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 2rem;
    background-color: ${(props) => props.theme.palette.gray.medium};
    -webkit-transition: 0.4s;
    transition: transform 0.4s;
    border-radius: 2.125rem;
    margin: 0 1rem 0 0;
    width: 4.25rem;
    box-sizing: border-box;
  }

  .slider:before {
    content: "";
    position: absolute;
    left: 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${(props) => props.theme.palette.primary};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked ~ .slider {
    background-color: ${(props) => props.theme.palette.white};
    border: solid 1px ${(props) => props.theme.palette.primary};
  }

  input:checked ~ .slider:before {
    -webkit-transform: translateX(2.188rem);
    -ms-transform: translateX(2.188rem);
    transform: translateX(2.188rem);
  }

  .switch-off {
    color: ${(props) => props.theme.palette.gray.dark};
    font-size: 0.813rem;
  }

  .switch-on {
    color: ${(props) => props.theme.palette.primary};
    font-size: 0.813rem;
  }

  input:checked ~ .switch-off {
    color: ${(props) => props.theme.palette.gray.dark};
  }

  input:checked ~ .switch-on {
    color: ${(props) => props.theme.palette.primary};
  }

  ${(props) =>
    props.disabled &&
    `
    background-color: transparent;

    .slider {
      background-color: ${props.theme.palette.gray.medium}!important;
      border: solid 1px ${props.theme.palette.gray.dark}!important;
    }

    .slider:before {
      background-color: ${props.theme.palette.gray.dark}!important;
    }

    .switch-off {
      color:${props.theme.palette.gray.medium}!important;
    }

    .switch-on {
      color: ${props.theme.palette.gray.dark}!important;
    }

    .switch {
      transition: none;
      pointer-events: none;   
    }

  `}
`;

export default ToggleButtonSimplified;
