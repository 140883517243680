import React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import {
  selectGetInstrumentsMenuStatus,
  selectInstrumentsMenu,
} from "../../../../../../store/store/storeSlice";

import { ReactComponent as LinkIcon } from "../../../../../../assets/icons/ext-link.svg";
import useTranslation from "../../../../../../hooks/useTranslation";
import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";
import CustomText from "../../../../../styled-UI/CustomText";
import LoaderMenu from "../../../../../styled-UI/loader/menu/LoaderMenu";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";

interface Props {
  menu: SecondLevelMenu;
  setToggleMenu: (identifier: string) => void;
}

const InstrumentsMenu = ({ menu, setToggleMenu }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { identifier } = menu;
  const getInstrumentsMenuStatus = useSelector(selectGetInstrumentsMenuStatus);
  const instrumentsMenu = useSelector(selectInstrumentsMenu);

  return getInstrumentsMenuStatus !== "LOADING" ? (
    <Container>
      <UrlList>
        <HeaderListContainer>
          <CustomText fontSizePx={18} as="span" marginBottomPx={20}>
            {translateLabel("INSTRUMENTS")}
          </CustomText>
          <NewTag position={externalReferenceNewTag[menu.name]}></NewTag>
        </HeaderListContainer>

        <ListItemsContainer>
          {instrumentsMenu.map((_) => {
            return (
              <React.Fragment key={identifier + _.name}>
                <LinkElement>
                  <CustomText
                    fontSizePx={14}
                    lineHeightPx={18}
                    as="button"
                    color="primary"
                    font="font-bold"
                    uppercase
                    onClick={() => window.open(_.url, "_blank")}
                  >
                    {translateLabel(_.name)}
                  </CustomText>
                  <LinkIcon />
                </LinkElement>
              </React.Fragment>
            );
          })}
        </ListItemsContainer>
      </UrlList>
    </Container>
  ) : (
    <LoaderMenu />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const UrlList = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    max-width: 14rem;
  }
`;

const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div span {
    max-width: 14rem;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.palette.primary};
      font-weight: 700;
    }
  }
`;

const LinkElement = styled.div`
  display: flex;
  & > svg {
    width: 20px;
    height: 20px;
    align-self: center;
  }
`;

export default InstrumentsMenu;
