import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPagePath } from "../../../../routing/routesUtils";
import { PinValidated } from "../../../../store/accounting/accountingInterfaces";
import { selectPinValidated } from "../../../../store/accounting/accountingSlice";
import { togglePopupAccessAccounting } from "../../../../store/store/storeSlice";

export interface HandleAccountPinClick {
  handleAccountPinClick: (linkTitle: string, linkUrl: string) => boolean;
}

const PIN_LINKS = [
  "CREDIT_TITLE",
  "INVOICES_TITLE",
  "LENS_CARNET_TITLE",
  "PAYMENT_SECTION_TITLE",
  "PAYMENT_MANAGEMENT_TITLE",
  "PAYMENT_MANAGEMENT_TYP_TITLE",
  "PAYMENT_METHOD_TITLE",
  "PAYMENT_HISTORY_LIST_TITLE",
  "PAYMENT_DOCUMENT_HISTORY_TITLE",
  "PAYMENT_STATEMENT_TITLE",
  "PRIVACY_POLICY",
] as const;

type PinLinks = typeof PIN_LINKS[number];

type PinConfig = {
  [key in PinLinks]: {
    type: PinValidated;
    allowedUrl: string;
  };
};

export const PIN_CONFIG: PinConfig = {
  CREDIT_TITLE: {
    type: "credit",
    allowedUrl: "/account/credit",
  },
  INVOICES_TITLE: {
    type: "invoices",
    allowedUrl: "/account/invoices",
  },
  LENS_CARNET_TITLE: {
    type: "lensCarnet",
    allowedUrl: "/account/lens-carnet",
  },
  PAYMENT_SECTION_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-management",
  },
  PAYMENT_MANAGEMENT_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-management",
  },
  PAYMENT_MANAGEMENT_TYP_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-typ",
  },
  PAYMENT_METHOD_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-method",
  },
  PAYMENT_HISTORY_LIST_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-history",
  },
  PAYMENT_DOCUMENT_HISTORY_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-document-history",
  },
  PAYMENT_STATEMENT_TITLE: {
    type: "payment",
    allowedUrl: "/account/payment-statement",
  },
  PRIVACY_POLICY: {
    type: "payment",
    allowedUrl: "/account/payment-policy",
  },
};

export const getPinPaths = (): string[] =>
  Object.entries(PIN_CONFIG)?.map(([, config]) => config.allowedUrl);

const useAccountPinRedirect = (): HandleAccountPinClick => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pinValidated = useSelector(selectPinValidated);

  const handleAccountPinClick = (linkTitle: string, linkUrl: string): boolean => {
    if (linkTitle in PIN_CONFIG) {
      const config = PIN_CONFIG[linkTitle as typeof PIN_LINKS[number]];

      if (pinValidated === config.type) return false;
      // does not require PIN b/c it's already validated, redirect is handled where this function is called
      else
        dispatch(
          togglePopupAccessAccounting({
            open: true,
            type: config.type,
            callback: (success?: boolean) => success && history.push(getPagePath(linkUrl)),
          })
        );
      return true; // requires pin, popup will be shown, no action is taken where this function is called
    } else return false; // this url does not require PIN, so redirect is handled where this function is called
  };

  return { handleAccountPinClick };
};

export default useAccountPinRedirect;
