export function getColumns(
  breakpoint: boolean,
  showBrands: boolean,
  showBrandsEssilor: boolean,
  isStarsAvailable: boolean,
  isBrandBannerAvailable: boolean
): { brands: number; essilor: number; stars: number } {
  const columns: { brands: number; essilor: number; stars: number } = {
    brands: 0,
    essilor: 0,
    stars: 0,
  };

  if (breakpoint) {
    if (showBrands && showBrandsEssilor && !isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 3;
      columns.essilor = 1;
    } else if (showBrands && !showBrandsEssilor && !isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 5;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (showBrands && showBrandsEssilor && isStarsAvailable) {
      columns.brands = 1;
      columns.essilor = 1;
      columns.stars = 2;
    } else if (showBrands && !showBrandsEssilor && isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 2;
      columns.essilor = 0;
      columns.stars = 2;
    } else if (!showBrands && showBrandsEssilor && isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 1;
      columns.stars = 3;
    } else if (!showBrands && !showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (showBrands && !showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 3;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (!showBrands && !showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 0;
      columns.stars = 3;
    } else if (!showBrands && showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 3;
      columns.stars = 0;
    } else if (showBrands && !showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 1;
      columns.essilor = 0;
      columns.stars = 2;
    } else if (!showBrands && showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 1;
      columns.stars = 2;
    } else if (showBrands && showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 2;
      columns.essilor = 1;
      columns.stars = 0;
    }
  } else {
    if (showBrands && showBrandsEssilor && !isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 4;
      columns.essilor = 1;
    } else if (showBrands && !showBrandsEssilor && !isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 5;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (showBrands && showBrandsEssilor && isStarsAvailable) {
      columns.brands = 2;
      columns.essilor = 1;
      columns.stars = 2;
    } else if (showBrands && !showBrandsEssilor && isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 2;
      columns.essilor = 0;
      columns.stars = 3;
    } else if (!showBrands && showBrandsEssilor && isStarsAvailable && !isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 1;
      columns.stars = 4;
    } else if (!showBrands && !showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (showBrands && !showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 4;
      columns.essilor = 0;
      columns.stars = 0;
    } else if (!showBrands && !showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 0;
      columns.stars = 4;
    } else if (!showBrands && showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 4;
      columns.stars = 0;
    } else if (showBrands && !showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 2;
      columns.essilor = 0;
      columns.stars = 2;
    } else if (!showBrands && showBrandsEssilor && isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 0;
      columns.essilor = 1;
      columns.stars = 2;
    } else if (showBrands && showBrandsEssilor && !isStarsAvailable && isBrandBannerAvailable) {
      columns.brands = 2;
      columns.essilor = 1;
      columns.stars = 0;
    }
  }

  return columns;
}
