import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import useCanRender from "../../../../hooks/useCanRender";

import usePreviewMode from "../../../../hooks/usePreviewMode";
import useTranslation from "../../../../hooks/useTranslation";
import { getPagePath } from "../../../../routing/routesUtils";
import { selectCountQuantity } from "../../../../store/cart/cartSlice";
import CustomText from "../../../styled-UI/CustomText";
import ConditionalRender from "../../../widgets/ConditionalRender";
import { MemoizedAccountMenu } from "../account-menu/AccountMenu";

import { ReactComponent as AccountIcon } from "../../../../assets/icons/account-icon.svg";
import { ReactComponent as BagIcon } from "../../../../assets/icons/bag-2-icon.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-icon.svg";
import { getSectionsAlerts } from "../../../../store/accounting/accountingSagas";
import {
  selectNotificationAlerts,
  selectNotificationsAlertsStatus,
} from "../../../../store/accounting/accountingSlice";
import { getSearchTermAssociation } from "../../../../store/store/storeSagas";
import { selectSearchTermAssociation } from "../../../../store/store/storeSlice";
import { getPictureAccountMenu } from "../../../../store/user/userSagas";
import {
  selectCanBuyModelsGlobal,
  selectCanBuySparepartsGlobal,
  selectEssilorBadgePicture,
} from "../../../../store/user/userSlice";
import { allowedServicesIds } from "../../../../utils/accountingUtils";
import Loader from "../../../styled-UI/Loader";
import SearchContainer from "./SearchContainer";

interface BagProps {
  count?: number | null;
}

const BagButtonIcon = ({ count }: BagProps): JSX.Element => {
  const canRender = useCanRender();
  return (
    <ButtonIconContainer className="button-dark" count={count}>
      <BagIcon />
      {canRender("CART") && !!count && <span>{count}</span>}
    </ButtonIconContainer>
  );
};

const MainNavbar = ({ isStripVisible }: { isStripVisible: boolean }): JSX.Element => {
  const { translateLabel } = useTranslation();
  const { isPreview } = usePreviewMode();
  const cartQuantity = useSelector(selectCountQuantity);
  const totalNumberNotifications = useSelector(selectNotificationAlerts);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const canBuySparesparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const [canBuyModelsAndSpareparts, setCanBuyModelsAndSpareparts] = useState<boolean>(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const essilorBadgePicture = useSelector(selectEssilorBadgePicture);
  const dispatch = useDispatch();
  const searchTermAssociation = useSelector(selectSearchTermAssociation);
  const notificationsStatus = useSelector(selectNotificationsAlertsStatus);
  ///////////////// handle expanded/collapsed search
  const [expanded, setExpanded] = useState<boolean>(false);
  const [menuUpdatedHeight, setMenuUpdatedHeight] = useState<number>(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const menuHeight = document.getElementById("search-container")?.offsetHeight ?? 0;
    const headerHeight = document.getElementById("header")?.offsetHeight ?? 0;
    const viewportHeight = document.body.offsetHeight - headerHeight;
    if (menuHeight > viewportHeight) {
      setMenuUpdatedHeight(viewportHeight);
    } else {
      setMenuUpdatedHeight(menuHeight);
    }
  }, [expanded]);

  useEffect(() => {
    if (canBuyModelsGlobal || canBuySparesparepartsGlobal) {
      setCanBuyModelsAndSpareparts(canBuyModelsGlobal || canBuySparesparepartsGlobal);
    }
  }, [canBuyModelsGlobal, canBuySparesparepartsGlobal]);

  useEffect(() => {
    if (showUserMenu) {
      dispatch(getSectionsAlerts(allowedServicesIds)); // get sections counts
      !essilorBadgePicture && dispatch(getPictureAccountMenu());
    }
  }, [showUserMenu]);

  // Used to animate the search when is clicked
  useEffect(() => {
    if (expanded) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 600);
      return () => clearTimeout(timer);
    }
  }, [expanded]);

  return (
    <RightNavbar>
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ///////////////////////////////SEARCH///////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      <SearchAnimation isExpanded={expanded}>
        {isVisible && (
          <SearchContainer
            closeExpandMenu={() => setExpanded(false)}
            data-element-id="MainNav_Search"
            height={menuUpdatedHeight}
            isStripVisible={isStripVisible}
          />
        )}
      </SearchAnimation>
      <ItemSearchContainer
        isExpanded={expanded}
        data-element-id="MainNav_Search"
        onClick={() => {
          setExpanded(!expanded);
          if (!searchTermAssociation) dispatch(getSearchTermAssociation());
        }}
      >
        <SearchBox isExpanded={expanded}>
          <CustomText
            as="span"
            fontSizePx={14}
            fontWeightNum={600}
            color="primary"
            font="font-regular"
          >
            {translateLabel("SEARCH")}
          </CustomText>
          <div>
            <IconContainer data-element-id="MainNav_Search_SearchBox">
              <SearchIcon />
            </IconContainer>
          </div>
        </SearchBox>
      </ItemSearchContainer>
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ///////////////////////////////ORDER////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      <ConditionalRender privilege="ORDER_HISTORY">
        <TextButtonContainer
          to={getPagePath("/account/order-history")}
          data-element-id="MainNav_Orders"
          isExpanded={location.pathname.includes("/account/order-history")}
        >
          <CustomText
            as="span"
            fontSizePx={14}
            fontWeightNum={600}
            color="primary"
            font="font-regular"
          >
            {translateLabel("ORDERS_SECTION")}
          </CustomText>
        </TextButtonContainer>
      </ConditionalRender>
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ///////////////////////////////ACCOUNT//////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      <ItemNavbarContainer isExpanded={showUserMenu}>
        <div style={{ padding: "1rem 0" }} onClick={() => setShowUserMenu(!showUserMenu)}>
          <ButtonIconContainer>
            <AccountIcon />
            {notificationsStatus === "LOADING" ? (
              <LoaderContainer>
                <Loader sizePx={12} />
              </LoaderContainer>
            ) : (
              totalNumberNotifications > 0 && <span />
            )}
          </ButtonIconContainer>
        </div>
      </ItemNavbarContainer>
      {showUserMenu && <Overlay isStripVisible={isStripVisible} />}
      {showUserMenu && (
        <MemoizedAccountMenu showAccountMenu={setShowUserMenu} isStripVisible={isStripVisible} />
      )}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ///////////////////////////////CART/////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {/* ////////////////////////////////////////////////////////////////////////// */}
      {canBuyModelsAndSpareparts && (
        <ConditionalRender privilege="CART">
          {isPreview ? (
            <CartContainer data-element-id="MainNav_Bag">
              <BagButtonIcon count={cartQuantity?.count} />
            </CartContainer>
          ) : (
            <CartLink
              to={getPagePath("/cart")}
              data-element-id="MainNav_Bag"
              isExpanded={location.pathname.includes("/cart")}
            >
              <BagButtonIcon count={cartQuantity?.count} />
            </CartLink>
          )}
        </ConditionalRender>
      )}
      {/* end CART BUTTON */}
    </RightNavbar>
  );
};

const SearchAnimation = styled.div<{ isExpanded: boolean }>`
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;

const RightNavbar = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const commonStyles = (props: any) => css`
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props.isExpanded && `border-bottom: solid 2px ${props.theme.palette.primary}`};

  &:hover {
    cursor: pointer;
    position: relative;

    :after {
      position: absolute;
      bottom: 0;
      ${!props.isExpanded && `border-bottom: solid 2px ${props.theme.palette.primary}`};
      content: "";
      width: 100%;
    }
  }
`;

const ItemNavbarContainer = styled.div<{ isExpanded: boolean }>`
  ${commonStyles}
  &:hover > div:first-of-type
    border: 1px solid ${(props) => props.theme.palette.chip.blue};
  }
`;

const ItemSearchContainer = styled.div<{ isExpanded: boolean }>`
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.isExpanded && `border-bottom: solid 2px ${props.theme.palette.primary}`};
`;

const TextButtonContainer = styled(Link)<{ isExpanded: boolean }>`
  ${commonStyles}
  padding: 0 1rem;
`;

const CartLink = styled(Link)<{ isExpanded: boolean }>`
  ${commonStyles}

  div svg {
    fill: ${(props) => props.theme.palette.primary};
  }
  background-color: ${(props) => props.theme.palette.white};
`;

const CartContainer = styled.div`
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.primary};
  cursor: pointer;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: -0.4rem;
  right: -0.125rem;
  background-color: ${(props) => props.theme.palette.white};
`;

const ButtonIconContainer = styled.div<{ count?: number | null }>`
  position: relative;
  flex-shrink: 0;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.25rem;
    width: 1.25rem;
  }

  span {
    position: absolute;
    background-color: ${(props) => props.theme.palette.textBlue};
    color: ${(props) => props.theme.palette.white};
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    top: -0.33rem;
    right: -0.19rem;
  }

  &.button-dark {
    span {
      color: ${(props) => props.theme.palette.white};
      border: solid 0.125rem ${(props) => props.theme.palette.white};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.438rem;
      line-height: 1.438rem;
      width: 1.438rem;
      top: -0.65rem;
      right: -0.75rem;
      font-family: ${(props) => props.theme.fonts.fontMedium};
      font-size: 0.625rem;
      font-weight: ${(props) => (props.count && props.count > 9 ? `600` : `400`)};
    }
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;

  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const SearchBox = styled.div<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 2.25rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.isExpanded ? props.theme.palette.chip.blue : props.theme.palette.gray.borders};

  &:hover {
    cursor: pointer;
    position: relative;

    ${(props) => !props.isExpanded && `border: 1px solid` + props.theme.palette.chip.blue};
  }
`;

const Overlay = styled.div<{ isStripVisible?: boolean }>`
  position: fixed;
  left: 0;
  top: ${(props) =>
    (props.isStripVisible
      ? props.theme.headerHeightRem
      : props.theme.headerHeightRem - props.theme.stripHeightRem) + "rem"};
  width: 100%;
  height: 100vh;
  background-color: rgba(1, 9, 23, 0.8);
`;

export const MemoizedMainNavbar = React.memo(MainNavbar);
