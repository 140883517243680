import React, { useState, useEffect } from "react";
import useTranslation from "../../../hooks/useTranslation";
import Popup from "../../styled-UI/Popup";
import styled from "styled-components";
import CustomText from "../../styled-UI/CustomText";
import Button from "../../styled-UI/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLegalTermsAccepted,
  selectLegalTermsOfUseDictionary,
  setIsLegalTermsAccepted,
} from "../../../store/user/userSlice";
import Checkbox from "../../styled-UI/Checkbox";
import { updateTermsOfService } from "../../../store/user/userSagas";
import { selectCurrentLang } from "../../../store/store/storeSlice";
import { Document } from "../../../store/documents/documentsInterfaces";
import { LegalTermsDocument } from "../../../store/catalogue/catalogueInterface";
import { replaceStringWithElement } from "../../../utils/utils";
import LinkButton from "../../styled-UI/LinkButton";
import { selectTermsConditionDocument } from "../../../store/documents/documentsSlice";

interface Props {
  isOpen: boolean;
  currentTermsDoc: Document | null;
}

const LegalRequirementsPopup = ({ isOpen, currentTermsDoc }: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectCurrentLang);
  const legalTermsDictionary = useSelector(selectLegalTermsOfUseDictionary);
  const termsConditionDoc = useSelector(selectTermsConditionDocument);
  const title = translateLabel("LEGAL_REQUIREMENTS_POPUP_TITLE");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isError, setIsError] = useState<boolean>(false); //THE USER HAS TO MAKE A CHOICE
  const termsDocumentsDict = useSelector(selectLegalTermsOfUseDictionary); // the dictionary of doc already accepted for different countries
  const [showPopupLegalReq, setShowPopupLegalReq] = useState<boolean>(false);

  const handleSubmit = () => {
    if (acceptTerms && !isError) {
      if (currentLanguage && currentTermsDoc?.modificationDate) {
        let newDict: LegalTermsDocument = {};
        if (termsDocumentsDict) {
          newDict = { ...termsDocumentsDict };
          newDict[currentLanguage] = currentTermsDoc.modificationDate;
          dispatch(updateTermsOfService(btoa(JSON.stringify(newDict))));
        } else {
          newDict[currentLanguage] = currentTermsDoc.modificationDate;
          dispatch(updateTermsOfService(btoa(JSON.stringify(newDict))));
        }
      }
      dispatch(setIsLegalTermsAccepted(true));
      // close();
    } else {
      setIsError(true);
    }
  };

  const onLinkClick = () => {
    window.open(currentTermsDoc?.data?.uri, "_blank");
  };

  /// This useEffect is needed in order to show or not the legal terms popup.
  /// If there is no document to accept, the popup isn't shown. If in the dictionary of legal terms there is already
  /// a documents accepted for the current language we make a check of the timestamp in order to see if the document
  /// to accept retrieved from CMS is more recent then that one already accepted. If the last condition is true the
  /// popup is shown.

  useEffect(() => {
    // First of all if there is no documents of legal terms of use, the popup is not shown
    if (termsConditionDoc === null) setShowPopupLegalReq(false);
    // If there is a document to accept and there are no documents already accepted the popup is shown
    else if (legalTermsDictionary === null || !termsConditionDoc?.modificationDate)
      setShowPopupLegalReq(true);
    else {
      // Checking if there is already a document accepted for the current language
      const legalDocCurrentLanguage = Object.keys(legalTermsDictionary).find(
        (_) => _ === currentLanguage
      );
      if (!termsConditionDoc?.modificationDate) setShowPopupLegalReq(true);
      // If there is, we check if the modificationDate of the already accepted document is older then the document
      // retrieved from CMS, in this case we show the popup for the new document to be accepted. If there isn't a document already
      // accepted for the current language we show the popup.
      else if (legalDocCurrentLanguage) {
        const docAcceptedData = new Date(
          legalTermsDictionary[legalDocCurrentLanguage].split("[")[0]
        );
        const newDocDate = new Date(termsConditionDoc?.modificationDate.split("[")[0]);
        if (docAcceptedData >= newDocDate) {
          setShowPopupLegalReq(false);
          dispatch(setIsLegalTermsAccepted(true));
        } else setShowPopupLegalReq(true);
      } else setShowPopupLegalReq(true);
    }
  }, [legalTermsDictionary, termsConditionDoc]);

  return showPopupLegalReq ? (
    <Popup
      isOpen={isOpen}
      close={close}
      title={title}
      enableOverflowAuto
      disableCloseButton={true}
      hideCloseButton={true}
      zindex={3000}
    >
      <Container>
        <Description>
          <CustomText as="p" font="font-bold" fontSizePx={14} color="primary" lineHeightPx={24}>
            {translateLabel("LEGAL_REQUIREMENTS_POPUP_DESCRIPTION")}
          </CustomText>
        </Description>
        <ConfirmationSection>
          <Checkbox
            label={translateLabel("LEGAL_REQUIREMENTS_ACCEPTANCE")}
            hideLabel
            name={"ACCEPT_TERMS"}
            checked={acceptTerms}
            onChange={() => {
              setAcceptTerms(!acceptTerms);
              setIsError(false);
            }}
          ></Checkbox>
          <WrapLinkLabel>
            {replaceStringWithElement(
              translateLabel("LEGAL_REQUIREMENTS_ACCEPTANCE"),
              "LEGAL_REQUIREMENTS_DOCUMENT_NAME",
              <LinkButton color="primary" onClick={onLinkClick} bold>
                {translateLabel("LEGAL_REQUIREMENTS_DOCUMENT_NAME")}
              </LinkButton>
            )}
          </WrapLinkLabel>
        </ConfirmationSection>
        {isError && (
          <ErrorContainer>
            <CustomText as="span" font="font-regular" fontSizePx={14} color="red" lineHeightPx={24}>
              {translateLabel("LEGAL_REQUIREMENTS_MANDATORY_FIELDS_ERROR")}
            </CustomText>
          </ErrorContainer>
        )}
        <ButtonsContainer>
          <Button type="primary" onClick={handleSubmit}>
            {translateLabel("LEGAL_REQUIREMENTS_CONFIRMATION_BUTTON")}
          </Button>
        </ButtonsContainer>
      </Container>
    </Popup>
  ) : (
    <></>
  );
};

const Container = styled.div`
  width: 65.25rem;
  padding: 2rem;
`;

const Description = styled.div`
  padding-bottom: 1.75rem;
`;

const ConfirmationSection = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

const ErrorContainer = styled.div`
  display: flex;
  padding-bottom: 3.125rem;
`;

const WrapLinkLabel = styled.span`
  margin: 0 0.1875rem;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.fontBold};
  color: ${(props) => props.theme.palette.primary};
  button {
    margin: 0 0.1875rem;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default LegalRequirementsPopup;
