import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import {
  selectBrandGroups,
  selectGetLensesMenuStatus,
  selectLensesEssilorMenu,
  selectLensesMenu,
  selectLxConfigurations,
} from "../../../../../../store/store/storeSlice";

import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";
import LensesColumn from "./LensesColumn";
import OrderNowColumn from "./OrderNowColumn";
import LoaderMenu from "../../../../../styled-UI/loader/menu/LoaderMenu";
import {
  LensesColumn as LensesColumnInterface,
  LensesColumnEssilor as LensesColumnEssilorInterface,
  LensItemEssilor,
} from "../../../../../../store/store/storeInterfaces";
import LensesColumnEssilor from "./LensesColumnEssilor";
import useCanRender from "../../../../../../hooks/useCanRender";

interface Props {
  menu: SecondLevelMenu;
  setToggleMenu: (identifier: string) => void;
}

const LensesMenu = ({ menu, setToggleMenu }: Props): JSX.Element => {
  const canRender = useCanRender();
  const { identifier } = menu;
  const getLensesMenuStatus = useSelector(selectGetLensesMenuStatus);

  const LXConfiguration = useSelector(selectLxConfigurations);
  const isEssilorMode =
    LXConfiguration?.filter(
      (element) => element.key === "com.luxottica.oneportal.lenses.menu.enable"
    )?.[0]?.value === "Y";

  let hasEssilorBrands = false;
  let lensesEssilorMenu: LensesColumnEssilorInterface[] = [];
  let lensesMenu: LensesColumnInterface[] = [];
  const brandGroups = useSelector(selectBrandGroups);
  const [canShowAuthentics, setCanShowAuthentics] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>("");

  useEffect(() => {
    if (brandGroups && lensesEssilorMenu.length) {
      const authenticsCol = lensesEssilorMenu.find(
        (column) => column.identifier === "AUTHENTICS_BRANDS"
      );
      if (authenticsCol) {
        const colBrands = authenticsCol.items.map((item) => item.id);
        const brandIds: string[] = [];
        colBrands.forEach((id) => brandGroups[id].forEach((brand) => brandIds.push(brand.brand)));
        const tempCanRender = brandIds.some(
          (brandId) =>
            canRender("RX_COMPLETE_JOB_" + brandId) ||
            canRender("RX_FRAME_TO_COME_" + brandId) ||
            canRender("RX_LENS_ONLY_" + brandId)
        );
        tempCanRender && setCanShowAuthentics(tempCanRender);
      }
    }
  }, [brandGroups, lensesEssilorMenu]);

  if (isEssilorMode) {
    lensesEssilorMenu = useSelector(selectLensesEssilorMenu)
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder);
    hasEssilorBrands = lensesEssilorMenu.some((category) => category.identifier === "BRAND");
  } else {
    lensesMenu = useSelector(selectLensesMenu);
  }

  const canShowColumn = (columnId: string) => {
    if (columnId === "CATEGORIES" || columnId === "BRAND") {
      return canRender("LENS_DIGITAL_CATALOGUE");
    } else if (columnId === "AUTHENTICS_BRANDS") {
      return (
        canRender("RX_LENS_ONLY") || canRender("RX_FRAME_TO_COME") || canRender("RX_COMPLETE_JOB")
      );
    }
    return (
      canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") ||
      canRender("LENS_DIGITAL_CATALOGUE") ||
      canRender("RX_LENS_ONLY") ||
      canRender("RX_FRAME_TO_COME") ||
      canRender("RX_COMPLETE_JOB")
    );
  };

  const orderNowData: LensesColumnEssilorInterface = {
    name: "ORDER_NOW",
    identifier: "ORDER_NOW",
    displayOrder: 4,
    items: [
      {
        id: "LENS_CONFIGURATOR",
        name: "LENS_CONFIGURATOR",
        type: "LENS_CONFIGURATOR",
        linkString: canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")
          ? "/rx-prescription?rxFlow=Essilor"
          : canRender("RX")
          ? "/rx-prescription?rxFlow=Authentics"
          : "",
        displayOrder: 0,
      },
      {
        id: "DIGITAL_CATALOGUE",
        name: "DIGITAL_CATALOGUE",
        type: "DIGITAL_CATALOGUE",
        linkString: "/digital-catalog",
        displayOrder: 1,
      },
      {
        id: "SAVED_ORDERS",
        name: "SAVED_ORDERS",
        type: "SAVED_ORDERS",
        linkString: "/essilor-draft-orders",
        displayOrder: 2,
      },
    ],
  };

  return getLensesMenuStatus !== "LOADING" ? (
    <Container isEssilorMode={isEssilorMode}>
      {isEssilorMode &&
        lensesEssilorMenu.map((lensesCol: LensesColumnEssilorInterface) => (
          <>
            {(lensesCol.identifier === "AUTHENTICS_BRANDS" ? canShowAuthentics : true) &&
              canShowColumn(lensesCol.identifier) && (
                <LensColumnContainerEssilor
                  key={lensesCol.name}
                  isAuthentics={hasEssilorBrands && lensesCol.identifier === "AUTHENTICS_BRANDS"}
                >
                  <LensesColumnEssilor
                    identifier={identifier}
                    lensesMenu={lensesCol}
                    tileName={identifier}
                    hasEssilorBrands={hasEssilorBrands}
                    setToggleMenu={setToggleMenu}
                    current={current}
                    setCurrent={setCurrent}
                  />
                </LensColumnContainerEssilor>
              )}
          </>
        ))}
      {isEssilorMode && canShowColumn("ORDER_NOW") && (
        <LensColumnContainerEssilor>
          <LensesColumnEssilor
            identifier={"ORDER_NOW"}
            lensesMenu={orderNowData}
            tileName={"ORDER_NOW"}
            setToggleMenu={setToggleMenu}
            current={current}
            setCurrent={setCurrent}
          />
        </LensColumnContainerEssilor>
      )}
      {!isEssilorMode && (
        <>
          {lensesMenu.map((lensesCol) => (
            <LensColumnContainer key={lensesCol.collectionTitle}>
              <LensesColumn
                identifier={identifier}
                lensesMenu={lensesCol}
                tileName={identifier}
                setToggleMenu={setToggleMenu}
              />
            </LensColumnContainer>
          ))}
          <LensColumnContainer>
            <OrderNowColumn tileName={identifier} setToggleMenu={setToggleMenu} />
          </LensColumnContainer>
        </>
      )}
    </Container>
  ) : (
    <LoaderMenu />
  );
};

const Container = styled.div<{ isEssilorMode?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

const LensColumnContainer = styled.div<{ hasMoreColumns?: boolean }>`
  width: 100%;
`;

const LensColumnContainerEssilor = styled.div<{ isAuthentics?: boolean }>`
  ${(props) => !props.isAuthentics && `width: 100%;`};
`;

export default LensesMenu;
