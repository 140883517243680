import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { useDragScroll } from "../../../../hooks/useDragScroll";
import useGetAllThirdLevelMenu from "../../../../hooks/useGetAllThirdLevelMenu";
import { MostUsedChipLocal } from "../../../../interfaces/mostUsedInterfaces";
import { sendAnalyticsData } from "../../../../store/analytics/analyticsService";
import { selectGetMenuStatus, selectMenu } from "../../../../store/store/storeSlice";
import { getMostUsed } from "../../../../store/user/userSagas";
import { selectMostUsed, selectMostUsedLoading } from "../../../../store/user/userSlice";
import { normalizeChipLabel } from "../../../../utils/mostUsedUtils";
import WrapperLayout from "../../../layouts/WrapperLayout";
import useNavbarSectionLinkHandler from "../../../layouts/header/navbar/useNavbarSectionLinkHandler";
import Chip from "../../../styled-UI/Chip";
import CustomText from "../../../styled-UI/CustomText";
import LoaderHomepageMostUsed from "../../../styled-UI/loader/most-used/LoaderHomepageMostUsed";
import CustomizeHomeModal from "./CustomizeHomeModal";
import DefaultMostUsed from "./DefaultMostUsed";
import useSelectedMostUsed, { ChipsHandler } from "./useSelectedMostUsed";

const PersonalizedChips = ({ chipsHandler }: { chipsHandler: ChipsHandler }): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();
  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();

  const handleRedirect = (chip: MostUsedChipLocal) => {
    if (chip.type === "Services&MyAccount")
      handleNavbarSectionLinkClick({
        identifier: chip.name,
        title: chip.label,
        url: chip.url,
        routes: [],
      });
    else history.push(chip.url);

    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  if (chipsHandler.selectedChips.length === 0) return <></>;
  return (
    <>
      {chipsHandler.selectedChips.map((chip) => (
        <Chip
          key={chip.name + chip.label}
          text={
            chip.type === "Brands" || chip.type === "Services&MyAccount" || chip.type === "Essilor"
              ? chip.label
              : translateLabel(normalizeChipLabel(chip.label))
          }
          onClick={() => handleRedirect(chip)}
          hideIcon={true}
          noMaxWidth
          showTitle
          style="bg-gray"
          borderRadiusPx={8}
          widthPx={172}
          heightPx={44}
          paddingLeftPx={16}
          paddingRightPx={16}
          gapPx={16}
          justifyContent="center"
          fontSize={12}
          lineHeightPx={18}
          letterSpacingPx={0.2}
          fontWeight={700}
          inheritWidth
          customColor={chip.titleColour}
          customBackgroundColor={chip.backgroundColour}
          noBorders
        />
      ))}
    </>
  );
};

const MostUsed = (): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const getThirdLevelMenu = useGetAllThirdLevelMenu();
  const menuData = useSelector(selectMenu);

  const [customizeOpen, setCustomizeOpen] = useState<boolean>(false);

  const { ref, isLeftGradientVisible, isRightGradientVisible } = useDragScroll();
  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// PERSONAL MOST USED //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const chipsHandler = useSelectedMostUsed();

  const mostUsedData = useSelector(selectMostUsed)?.mostUsedPreferences;
  const mostUsedLoading = useSelector(selectMostUsedLoading);
  const getMenuStatus = useSelector(selectGetMenuStatus);

  const isLoading =
    mostUsedLoading.get === "LOADING" ||
    mostUsedLoading.update === "LOADING" ||
    getMenuStatus === "LOADING" ||
    chipsHandler.isLoading;

  useEffect(() => {
    mostUsedData?.bookmarks?.length == 0 && dispatch(getMostUsed());
  }, []);

  //fetch api to get third level menu if not yet available
  useEffect(() => {
    getThirdLevelMenu(
      customizeOpen,
      menuData
        .filter(({ identifier }) => identifier === "PRODUCTS")
        .flatMap(({ catalogGroupView }) => catalogGroupView || [])
    );
  }, [customizeOpen, menuData]);

  //////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////// RENDER ////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////

  const showPersonalMostUsed = !mostUsedData?.unused;

  return (
    <LayoutBackground>
      <WrapperLayout width100Sm>
        <Section data-element-id="MostUsed">
          <TextContainer>
            <CustomText
              fontSizePx={24}
              as="span"
              color="white"
              lineHeightPx={24}
              wordBreak="normal"
              isEllipsis
              width={211}
            >
              {translateLabel("MOST_USED")}
            </CustomText>
          </TextContainer>
          <Chips
            ref={ref}
            isLeftGradientVisible={isLeftGradientVisible}
            isRightGradientVisible={isRightGradientVisible}
            data-element-id="MostUsed_Tag"
          >
            {isLoading ? (
              <LoaderHomepageMostUsed />
            ) : showPersonalMostUsed ? (
              <PersonalizedChips chipsHandler={chipsHandler} />
            ) : (
              <DefaultMostUsed accountServiceChips={chipsHandler.availableChips} />
            )}

            <Chip
              disabled={!mostUsedData?.canEdit}
              letterCase="capitalize"
              key="test"
              text={translateLabel("MOST_USED_EDIT_SETTINGS_LABEL")}
              onClick={() => setCustomizeOpen(true)}
              hideIcon={true}
              showTitle
              style="bg-gray"
              borderRadiusPx={8}
              maxWidth="10.75rem"
              heightPx={44}
              paddingLeftPx={16}
              paddingRightPx={16}
              gapPx={16}
              justifyContent="center"
              fontSize={12}
              lineHeightPx={18}
              letterSpacingPx={0.2}
              fontWeight={700}
              typeIcon="plus"
              showFileIcon
              iconWithoutMargin
              customSpacedContent={8}
              spacedContent
              inheritWidth={true}
            />
          </Chips>
        </Section>
        {customizeOpen && (
          <CustomizeHomeModal isOpen={customizeOpen} close={() => setCustomizeOpen(false)} />
        )}
      </WrapperLayout>
    </LayoutBackground>
  );
};

const Section = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 4.125rem;
  gap: 3rem;
  align-items: center;
`;

interface ChipsProps {
  isLeftGradientVisible: boolean;
  isRightGradientVisible: boolean;
}
const Chips = styled.div<ChipsProps>`
  display: flex;
  justify-content: flex-start;
  height: inherit;
  align-items: center;
  margin-right: 1.5rem;
  gap: 0.5rem;
  height: inherit;
  flex: 1;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer, legacy Edge */
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2.188rem; /* Width of the gradient */
    pointer-events: none; /* Ensure gradients don't block interaction */
  }

  /* Left gradient */
  &::before {
    background: ${({ isLeftGradientVisible }) =>
      isLeftGradientVisible
        ? "linear-gradient(90deg, #091E56 0%, rgba(255, 255, 255, 0) 100%)"
        : "none"};
  }

  /* Right gradient */
  &::after {
    right: 1.5rem;
    background: ${({ isRightGradientVisible }) =>
      isRightGradientVisible
        ? "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #091E56 100%)"
        : "none"};
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  max-width: 13.188rem;
`;

export const LayoutBackground = styled.div<{ backgroundColor?: string; height?: string }>`
  padding: 0.625rem 0;
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : `linear-gradient(270deg, #011f5d 0%, #011b57 50.11%, #031434 100%)`};
  ${(props) => props.height && `height: ${props.height};`}
`;

export default MostUsed;

export const MemoizedMostUsed = React.memo(MostUsed);
