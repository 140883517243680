import React from "react";
import styled from "styled-components/macro";

import { SecondLevelMenu } from "../../interfaces/menuInterfaces";

import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectBrandLabels } from "../../store/store/storeSlice";
import CustomText from "./CustomText";

interface Props {
  brandGroup: SecondLevelMenu;
  onClick: () => void;
  fallbackLabel?: string;
  "data-element-id"?: string | null;
  enableDataDescription?: boolean;
  style?: "default" | "stars";
}

const BrandButton = ({
  brandGroup,
  onClick,
  fallbackLabel,
  "data-element-id": dataElementId,
  enableDataDescription = false,
  style = "default",
}: Props): JSX.Element => {
  const labels = useSelector(selectBrandLabels);

  return (
    <>
      {brandGroup && (
        <BrandButtonEl
          className={clsx(style === "stars" && "brand-button-stars")}
          onClick={onClick}
          {...(dataElementId ? { "data-element-id": dataElementId } : {})}
          {...(enableDataDescription ? { "data-description": brandGroup.identifier } : {})}
        >
          <CustomText
            as="span"
            fontSizePx={14}
            fontWeightNum={400}
            font="font-regular"
            justifyContent="start"
            isEllipsis
          >
            {fallbackLabel ?? labels[brandGroup.identifier ?? brandGroup.brandCodes]}
          </CustomText>
        </BrandButtonEl>
      )}
    </>
  );
};

const BrandButtonEl = styled.button`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;

  &.brand-button-stars {
    p {
      color: ${(props) => props.theme.palette.white};
    }
  }
`;

export default BrandButton;
