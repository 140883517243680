import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";
import { ProductTags, ProductTagsUsage } from "../../../interfaces/productInterface";
import Tag from "../../styled-UI/Tag";

interface Props {
  tags?: ProductTags[];
  isHorizontalTile?: boolean;
  usage?: ProductTagsUsage;
  brand: string;
  noMargins?: boolean;
  isEllipsis?: boolean;
  isAFAOrGogglesHelmetVertical?: boolean;
}

const TileTags = ({
  tags,
  isHorizontalTile = false,
  usage = "active",
  brand,
  noMargins = false,
  isEllipsis = false,
  isAFAOrGogglesHelmetVertical,
}: Props): JSX.Element => {
  const canRender = useCanRender();

  const hasPrivilegeStars = canRender("UX_STARS_EXPERIENCE") && canRender("STARS_SECTION");

  const checkStars = (tag: ProductTags) =>
    usage === "active" && tag.usage?.includes("stars") && hasPrivilegeStars;

  const checkSmartShopper = (tag: string) => {
    if (tag.toLowerCase() !== "customizable") return true;
    if (!brand) return false;
    return canRender("M4C") && canRender("SMART_SHOPPER_CUSTOM_" + brand);
  };

  return (
    <Container
      {...(isAFAOrGogglesHelmetVertical && {
        className: clsx(isAFAOrGogglesHelmetVertical),
      })}
    >
      {tags?.map((tag: ProductTags) => {
        if (
          checkStars(tag) ||
          (tag.usage?.includes(usage) && checkSmartShopper(tag?.identifier ?? ""))
        )
          return (
            <SingleTag
              key={tag.value}
              className={clsx(isHorizontalTile && "horizontal-tile")}
              noMargins={noMargins}
            >
              <Tag isEllipsis={isEllipsis} tag={tag.value} img={tag?.img} />
            </SingleTag>
          );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  &.afa-goggles-helmets {
    gap: 8px;
  }
`;

const SingleTag = styled.div<{ noMargins: boolean }>`
  display: inline-block;
  margin: ${(props) => (props.noMargins ? "0" : "0 0.5rem 0.5rem 0")};

  &.horizontal-tile {
    margin: 0 0.5rem 0 0;
  }
`;

export default TileTags;
