import React from "react";
import clsx from "clsx";
import styled from "styled-components/macro";
import { NavigationType } from "./Carousel";

interface Props {
  dot: number;
  index: number;
  currentPage: number;
  isAnimated?: boolean;
  navigationType: NavigationType;
  changeSlide: (arg: number) => void;
  "data-element-id"?: string;
  "data-description"?: string;
  backgroundDark?: boolean;
  dotSelectedColor?: string;
  dotCustomColor?: string;
}

const CarouselDot = ({
  dot,
  index,
  isAnimated = false,
  changeSlide,
  navigationType,
  currentPage,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  backgroundDark = false,
  dotSelectedColor,
  dotCustomColor,
}: Props): JSX.Element => {
  const handleClick = (dot: number) => {
    changeSlide(dot);
  };

  const ContainerClasses = clsx(
    index === currentPage && navigationType === "one-tile" && "selected",
    navigationType === "one-tile" && "one-title-container-dot"
  );

  const classNames = clsx(
    "dot",
    navigationType === "one-tile" && "one-title-dot",
    index === currentPage && navigationType !== "one-tile" && "selected",
    (navigationType === "highlights" ||
      navigationType === "one-tile" ||
      navigationType === "lp-banner" ||
      backgroundDark) &&
      "color"
  );

  return (
    <Container className={ContainerClasses} customColor={dotCustomColor}>
      <div className="circle__box">
        <div className="circle__wrapper circle__wrapper--right">
          <div className="circle__whole circle__right"></div>
        </div>
        <div className="circle__wrapper circle__wrapper--left">
          <div className="circle__whole circle__left"></div>
        </div>
      </div>
      {/* </LoadingAnimation> */}
      <StyledDot
        {...(dataElementId ? { "data-element-id": dataElementId } : {})}
        {...(dataDescription ? { "data-description": dataDescription } : {})}
        selectedColor={dotSelectedColor}
        customColor={dotCustomColor}
        navigationType={navigationType}
        className={classNames}
        onClick={() => handleClick(dot)}
      />
    </Container>
  );
};

const Container = styled.div<{ customColor?: string }>`
  position: relative;
  &.one-title-container-dot {
    width: 1.125rem;
    height: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  &.selected {
    border: 1.6px solid
      ${(props) => (props.customColor ? props.customColor : props.theme.palette.white)};
    border-radius: 50%;
  }

  &:last-of-type {
    .dot {
      margin-right: 0;
    }
  }
`;

interface DotProps {
  navigationType: string;
  selectedColor?: string;
  customColor?: string;
}

const StyledDot = styled.button<DotProps>`
  cursor: pointer;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary};
  opacity: 20%;
  margin-right: ${(props) => (props.navigationType === "highlights" ? "1.25rem" : "0.5rem")};

  &.one-title-dot {
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0;
  }

  &.color {
    opacity: unset;
    background-color: ${(props) =>
      props?.selectedColor ? props.selectedColor : props.theme.palette.white};
  }

  &.selected {
    opacity: unset;
    background-color: ${(props) =>
      props?.selectedColor ? props.selectedColor : props.theme.palette.gray.dark};
  }
`;

export default CarouselDot;
