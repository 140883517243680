import React, { useEffect, useState } from "react";
import useTranslation from "../../../../../hooks/useTranslation";
import { MostUsedItem } from "../../../../../interfaces/cmsInterfaces";
import { MostUsedChipLocal } from "../../../../../interfaces/mostUsedInterfaces";
import { sendAnalyticsData } from "../../../../../store/analytics/analyticsService";
import { useHandleCmsLink } from "../../../../../utils/cmsUtils";
import Chip from "../../../../styled-UI/Chip";

interface Props {
  mostUsed: MostUsedItem;
  availableBrandsChips: MostUsedChipLocal[];
  myAccountServiceChips: MostUsedChipLocal[];
}

const CMSMostUsedAccountServiceChip = ({
  mostUsed,
  availableBrandsChips,
  myAccountServiceChips,
}: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();
  const { translateLabel } = useTranslation();
  const { linkString, title, titleColour, externalLink, openInNewTab, backgroundColour } = mostUsed;
  const [showChip, setShowChip] = useState<boolean>(false);

  useEffect(() => {
    if (availableBrandsChips.length) {
      setShowChip(availableBrandsChips.some((brand) => brand.url === mostUsed.linkString));
    }
  }, [availableBrandsChips]);

  useEffect(() => {
    if (myAccountServiceChips.length) {
      setShowChip(myAccountServiceChips.some((account) => account.url === mostUsed.linkString));
    }
  }, [myAccountServiceChips]);

  const handleRedirect = (link?: string, externalLink?: boolean, openInNewTab?: boolean): void => {
    handleCmsLink(link, externalLink, openInNewTab);
    sendAnalyticsData({
      id: "Click",
      Tracking_Type: "link",
      data_element_id: "MostUsed_Tag",
    });
  };

  return (
    <>
      {showChip && (
        <Chip
          letterCase="capitalize"
          customColor={titleColour}
          text={translateLabel(title)}
          hideIcon={true}
          onClick={() => {
            handleRedirect(linkString, externalLink, openInNewTab);
          }}
          style="bg-gray"
          borderRadiusPx={8}
          widthPx={172}
          heightPx={44}
          paddingLeftPx={16}
          paddingRightPx={16}
          gapPx={16}
          justifyContent="center"
          fontSize={12}
          lineHeightPx={18}
          letterSpacingPx={0.2}
          fontWeight={700}
          inheritWidth={true}
          customBackgroundColor={backgroundColour}
          noBorders
        />
      )}
    </>
  );
};

export default CMSMostUsedAccountServiceChip;
