import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import useMenuColumnsInfo, { MenuDivInfo } from "../../../../../../hooks/useMenuColumnsInfo";
import { SecondLevelMenu } from "../../../../../../interfaces/menuInterfaces";
import BrandsColumn from "./BrandsColumn";
import MenuColumn from "./MenuColumn";

interface Props {
  menu: SecondLevelMenu;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  nameCategory?: string;
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

interface MenuDiv {
  key: string;
  content: JSX.Element;
}

const MenuColumns = ({
  menu,
  toggleMenu,
  setToggleMenu,
  nameCategory,
  onScrollFadeStateChange,
}: Props): JSX.Element => {
  const [current, setCurrent] = useState<string>("");

  const menuContentInfo: MenuDivInfo[] = useMenuColumnsInfo({
    menu,
    nameCategory,
    considerBrandsAsAdult: false,
  });

  const menuContent = useMemo(() => {
    const columns: MenuDiv[] = [];
    const brandsColumns: MenuDiv[] = [];
    const starsBrandsColumns: MenuDiv[] = [];

    menuContentInfo.forEach((info: MenuDivInfo) => {
      info.isBrandColumn
        ? info.isStars
          ? starsBrandsColumns.push({
              key: info.key,
              content: (
                <BrandsColumn
                  isStars={info.isStars}
                  key={info.keyColumn}
                  isKids={info.isKids}
                  identifier={info.identifier}
                  toggleMenu={toggleMenu}
                  setToggleMenu={setToggleMenu}
                  title={info.title || ""}
                  showStarIcon={info.showStarIcon}
                  brandColumns={info.brandColumns}
                  current={current}
                  setCurrent={setCurrent}
                  onScrollFadeStateChange={onScrollFadeStateChange}
                />
              ),
            })
          : brandsColumns.push({
              key: info.key,
              content: (
                <BrandsColumn
                  isStars={info.isStars}
                  key={info.keyColumn}
                  isKids={info.isKids}
                  identifier={info.identifier}
                  toggleMenu={toggleMenu}
                  setToggleMenu={setToggleMenu}
                  title={info.title || ""}
                  showStarIcon={info.showStarIcon}
                  brandColumns={info.brandColumns}
                  current={current}
                  setCurrent={setCurrent}
                  onScrollFadeStateChange={onScrollFadeStateChange}
                />
              ),
            })
        : columns.push({
            key: info.key,
            content: (
              <MenuColumn
                key={info.keyColumn}
                baseUrl={info.baseUrl}
                columnStructure={info.columnStructure}
                identifier={info.identifier}
                toggleMenu={toggleMenu}
                setToggleMenu={setToggleMenu}
                title={info.title}
                nameCategory={info.nameCategory}
                categoryColumns={info.categoryColumns}
                onScrollFadeStateChange={onScrollFadeStateChange}
              />
            ),
          });
    });

    if (brandsColumns.length && starsBrandsColumns.length)
      starsBrandsColumns.push({
        key: "span",
        content: <Divider />,
      });

    if ((brandsColumns.length || starsBrandsColumns.length) && columns.length)
      columns.push({
        key: "span",
        content: <Divider />,
      });

    return [...columns, ...starsBrandsColumns, ...brandsColumns];
  }, [menuContentInfo, current]);

  return menuContent.length > 0 ? (
    <Container>
      {menuContent?.map((_) => {
        return <div key={_.key}>{_.content}</div>;
      })}
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  overflow-y: auto;
  display: grid;
  row-gap: 1.5rem;
  height: 100%;
`;

const Divider = styled.hr``;

export default MenuColumns;
