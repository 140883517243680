/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import SiteSetter from "./components/widgets/SiteSetter";
import PrivateRoute from "./components/widgets/PrivateRoute";
import ErrorModal from "./components/widgets/ErrorModal";
import theme from "./assets/theme/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/bootstrap-grid.min.css";
import "./assets/css/global-styles.css";
import NotFound from "./components/pages/not-found/NotFound";
import { publicRoutes } from "./routing/publicRoutes";
import { authRoutes } from "./routing/authRoutes";
import GuardRoute from "./components/widgets/GuardRoute";
import GenericConditionalRender from "./components/widgets/GenericConditionalRender";
import { getPageNumberFromUrl } from "./utils/filterUtils";
import LoadingBackground from "./components/styled-UI/LoadingBackground";
import { BreakpointProvider } from "./assets/theme/breakpoint";
import { CustomFonts } from "./assets/theme/customFonts";
import useGenesysChat from "./hooks/useGenesysChat";

// Scroll to top of the page
const ScrollToTop = () => {
  const location = useLocation();
  const currentPage = getPageNumberFromUrl();

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("root")?.scrollTo(0, 0);
    });
  }, [location.pathname, currentPage]);
  return <></>;
};

export default function App(): JSX.Element {
  useGenesysChat();
  return (
    <ThemeProvider theme={theme}>
      <CustomFonts />
      <BreakpointProvider queries={theme.queries}>
        <Router>
          <ScrollToTop />
          <SiteSetter />
          {/* {process.env.NODE_ENV !== "development" && <QualtricsSurvey />} */}
          {/* MOVED IN APP LAYOUT */}
          <Suspense fallback={<LoadingBackground />}>
            <Switch>
              {publicRoutes.map(({ path, component: C }) => (
                <GuardRoute
                  key={`${path}-route`}
                  path={path}
                  exact
                  render={(props) => <C {...props} />}
                ></GuardRoute>
              ))}
              {authRoutes.map(({ path, component }) => (
                <PrivateRoute
                  key={`${component}-route`}
                  path={path}
                  exact
                  render={(props) => {
                    return (
                      <GenericConditionalRender props={props} path={path} component={component} />
                    );
                  }}
                ></PrivateRoute>
              ))}
              <Route path="*" render={(props: any) => <NotFound {...props} />}></Route>
            </Switch>
          </Suspense>
          <ErrorModal />
        </Router>
      </BreakpointProvider>
    </ThemeProvider>
  );
}
