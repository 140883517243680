import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";

import { getWarrantyInboxCount } from "../../../../store/aftersales/aftersalesSaga";
import {
  selectActiveDoor,
  selectAtLeastOneTruePrivileges,
  selectCanBuySparepartsGlobal,
  selectIsMultidoor,
  selectIsSubuser,
  selectMultiDoors,
  selectSelectedDoor,
} from "../../../../store/user/userSlice";
import { NavbarSectionLink } from "../../../pages/my-account/account-navbar/navbarSections";
import CustomText from "../../../styled-UI/CustomText";
import ConditionalRender from "../../../widgets/ConditionalRender";
import useNavbarSectionLinkHandler from "../navbar/useNavbarSectionLinkHandler";

import clsx from "clsx";
import { ReactComponent as ArrowRightIcon } from "../../../../assets/icons/arrowhead-right-icon.svg";
import { paletteLight } from "../../../../assets/theme/palette";
import {
  selectSectionAlerts,
  selectSectionsAlertsStatus,
} from "../../../../store/accounting/accountingSlice";
import { externalReferenceNewTag } from "../../../../utils/cmsUtils";
import BoxCounter from "../../../styled-UI/BoxCounter";
import Loader from "../../../styled-UI/Loader";
import NewTag from "../../../widgets/tutorial-pills/new-tag/NewTag";

interface Props {
  title: string;
  closeButton: JSX.Element;
  contents: NavbarSectionLink[];
  isOpen: boolean;
  isFirst: boolean;
  onToggle: () => void;
  showAccountMenu: (showMenu: boolean) => void;
}

const AccountMenuAccordion = ({
  title,
  closeButton,
  contents,
  isOpen,
  isFirst,
  onToggle,
  showAccountMenu,
}: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const canRender = useCanRender();
  const dispatch = useDispatch();
  const isMultidoor = useSelector(selectIsMultidoor);

  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();

  const userPrivileges = useSelector(selectAtLeastOneTruePrivileges);
  const selectedDoor = useSelector(selectSelectedDoor);
  const accountMenuRef = useRef<HTMLDivElement | null>(null);

  //const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrollingElement, setScrollingElement] = useState<string>("");
  const [showColumn, setShowColumn] = useState<boolean>(false);
  const canBuySparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  const multidoors = useSelector(selectMultiDoors);
  const sectionAlerts = useSelector(selectSectionAlerts);
  const sectionsAlertsStatus = useSelector(selectSectionsAlertsStatus);

  const privileges = canRender("AFTER_SALES_REQUEST") || canRender("SERVICE_REQUEST_PAGE");

  //check if there are subitems with count to show in order to print white dot in blue accordion :)
  const isIdsInCounts = useCallback((): boolean => {
    return (
      !!sectionAlerts &&
      contents.some(
        (item) => !item?.notShowForSubuser && item?.serviceId?.some((id) => sectionAlerts[id] > 0)
      )
    );
  }, [sectionAlerts, contents]);

  useEffect(() => {
    if (selectedDoor)
      if (isOpen && title === "AFTERSALES_SECTION" && privileges) {
        dispatch(getWarrantyInboxCount(selectedDoor?.orgentityId));
      }
  }, [isOpen]);

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (accountMenuRef.current && !isOpen) {
        const target = (event.target as Node) || null;
        accountMenuRef.current.contains(target) ? showAccountMenu(false) : showAccountMenu(true);
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [isOpen]);

  useEffect(() => {
    if (userPrivileges) {
      let status = false;

      contents.forEach((item) => {
        if (item.privilege) {
          if (isSubuser && item.exclusivePrivilege) {
            if (mainDoor && multidoors.includes(mainDoor) && canRender(item.privilege, mainDoor)) {
              status = true;
            } else if (canRender(item.privilege)) status = true;
          } else if (canRender(item.privilege)) {
            status = true;
          }
        } else if (!item.privilege) status = true;
      });

      if (status !== showColumn) setShowColumn(status);
    }
  }, [userPrivileges]);

  const handleClick = (link: NavbarSectionLink) => handleNavbarSectionLinkClick(link);

  const isAccordionItemVisibile = (item: NavbarSectionLink): boolean => {
    if (isSubuser && item.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        !canRender(item.privilege, mainDoor)
      )
        return false;
    }
    if (!canBuySparepartsGlobal && item.title === "WARRANTY_TITLE") {
      return false;
    }
    if (isSubuser && item.notShowForSubuser) return false;
    return true;
  };

  useEffect(() => {
    if (isOpen && scrollingElement) {
      const menuCategory = document.getElementById(scrollingElement);
      if (menuCategory) {
        menuCategory.scrollIntoView({ behavior: "smooth" });
        menuCategory.scrollTop -= 10;
      }
    }
  }, [isOpen, scrollingElement]);

  if (showColumn) {
    return (
      <Accordion>
        <AccordionHeader
          onClick={(e) => {
            onToggle();
            setScrollingElement(`MainNav_MyAccount_Overlay_Links_${title}`);
            e.stopPropagation();
          }}
          data-element-id={`MainNav_MyAccount_Overlay_Links_${title}`}
        >
          <HeaderListContainer>
            <HeaderText isOpen={isOpen}>
              <CustomText
                as="span"
                fontSizePx={14}
                font={"font-regular"}
                fontWeightNum={isOpen ? 700 : 400}
              >
                {translateLabel(title)}
              </CustomText>
              {isIdsInCounts() ? (
                <HeaderNotification />
              ) : (
                sectionsAlertsStatus === "LOADING" &&
                !sectionAlerts && (
                  <LoaderContainer>
                    <Loader sizePx={10} />
                  </LoaderContainer>
                )
              )}
            </HeaderText>
            <NewTag position={externalReferenceNewTag[`Account.${title}`]}></NewTag>
          </HeaderListContainer>

          <ArrowRightIcon />
        </AccordionHeader>
        <WrapperThirdLevel ref={accountMenuRef}>
          {isOpen && (
            <AccountMenuAccordionItems isMultidoor={isMultidoor}>
              {closeButton}
              {contents.map((item) => {
                return (
                  <ConditionalRender
                    key={item.url}
                    privilege={item.privilege}
                    onlySubuser={item.onlySubuser}
                    isNotBOUser={item.isNotBOUser}
                  >
                    {isAccordionItemVisibile(item) && (
                      <AccordionItem>
                        <button
                          onClick={(e) => {
                            showAccountMenu(false);
                            handleClick(item);
                            e.stopPropagation();
                          }}
                          data-element-id={`MainNav_MyAccount_Overlay_Links_${title}_${item.dataElementId}`}
                          style={{ width: "100%" }}
                        >
                          <WrapCount>
                            <CustomText
                              as="span"
                              fontSizePx={14}
                              font="font-regular"
                              lineHeightPx={18}
                              fontWeightNum={400}
                            >
                              {translateLabel(item.title)}
                            </CustomText>
                            {item.serviceId && (
                              <BoxCounter
                                backgroundColor={paletteLight.blue.whale}
                                ids={item.serviceId}
                              />
                            )}
                          </WrapCount>
                        </button>
                      </AccordionItem>
                    )}
                  </ConditionalRender>
                );
              })}
            </AccountMenuAccordionItems>
          )}
        </WrapperThirdLevel>
      </Accordion>
    );
  }
  return null;
};

const WrapperThirdLevel = styled.div``;

const Accordion = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const AccountMenuAccordionItems = styled.div<{ isMultidoor?: boolean }>`
  padding: 2.5rem 0 2rem 2rem;
  padding: ${(props) => (props.isMultidoor ? `3.5rem 0 2rem 2rem` : `2.7rem  0 2rem 2rem`)};
  width: 50%;
  position: absolute;
  left: 50%;
  box-shadow: ${(props) => props.theme.palette.menuBoxShadow};
  height: 100%;
  top: 0;
  background-color: ${(props) => props.theme.palette.white};

  > div:first-child {
    width: 100%;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 0;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.white};

  span {
    color: ${(props) => props.theme.palette.primary};
  }

  div {
    margin: unset;
    padding: unset;
  }

  svg {
    height: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }

  &.double-padding {
    padding: 1rem 2rem 1rem 0;
  }

  &.double-bottom-padding {
    padding: 0.5rem 2rem 1rem 0;
  }
`;

const HeaderListContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  div {
    margin: unset;
    padding: unset;
  }
`;

const HeaderText = styled.div<{ isOpen?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 0.25rem;

  & > span {
    border-bottom: 1px solid ${(props) =>
      props.isOpen ? props.theme.palette.primary : `transparent`};
    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.palette.primary};
      font-weight: 700;
    }
`;

const LoaderContainer = styled.div`
  height: 0.5rem;
  width: 0.5rem;
`;

const HeaderNotification = styled.div`
  background-color: ${(props) => props.theme.palette.blue.whale};
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
`;

const AccordionItem = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;

  &:nth-child(2) {
    padding-top: 0.5rem;
  }

  &:last-child {
    padding-bottom: 2rem;
  }
`;

export const WrapCount = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: row;
  align-items: center;

  & > span:first-of-type {
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.palette.primary};
      font-weight: 700;
    }
  }
`;

export const BoxCount = styled.div`
  border-radius: 1.125rem;
  background-color: ${(props) => props.theme.palette.primary};
  width: 1.875rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
`;

export default AccountMenuAccordion;
