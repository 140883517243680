import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useBreakpoint } from "../../../../../assets/theme/breakpoint";
import useCanRender from "../../../../../hooks/useCanRender";
import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import CategoryItem from "./category-item/CategoryItem";
import { useDispatch } from "react-redux";
import { saveCanRenderPopupTutorialPills } from "../../../../../store/store/storeSlice";
import useSetTutorialPillsPopupVisibility from "../../../../../hooks/useSetTutorialPillsPopupVisibility";

interface Props {
  catMenuData?: SecondLevelMenu[];
  setToggleMenu: (identifier: string) => void;
  isBannerOpen: (open: boolean) => void;
  marginTop: number;
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

const CategoriesMenu = ({
  catMenuData,
  setToggleMenu,
  isBannerOpen,
  marginTop = 0,
  onScrollFadeStateChange,
}: Props): JSX.Element => {
  useSetTutorialPillsPopupVisibility();
  const canRender = useCanRender();
  const [current, setCurrent] = useState<string>("");
  const breakpoints = useBreakpoint();
  const heightRem = breakpoints["md"] ? 15 : 16.75;
  const canShowCategory = (identifier: string) => {
    if (identifier === "Lenses" || identifier === "LENSES") {
      return canRender("LENS_DIGITAL_CATALOGUE") || canRender("RX");
    } else if (identifier === "Instruments" || identifier === "INSTRUMENTS")
      return canRender("INSTRUMENTS");
    else return true;
  };

  let categoryCanRender = 0;
  catMenuData?.forEach((catmenuitem: SecondLevelMenu) => {
    if (canShowCategory(catmenuitem.identifier)) categoryCanRender++;
  });
  const respHeight = categoryCanRender > 6 ? -0.85 : 0.85;

  useEffect(() => {
    isBannerOpen(current ? false : true);
  }, [current, isBannerOpen]);

  return (
    <MenuContainer>
      <Menu>
        {catMenuData &&
          catMenuData.map(
            (catmenuitem: SecondLevelMenu, index: number) =>
              canShowCategory(catmenuitem.identifier) && (
                <CategoryItem
                  menu={catmenuitem}
                  key={
                    catmenuitem.name
                      ? catmenuitem.identifier + catmenuitem.name
                      : `cat-item-${index}`
                  }
                  setCurrent={setCurrent}
                  current={current}
                  setToggleMenu={setToggleMenu}
                  marginTop={marginTop + heightRem + respHeight}
                  onScrollFadeStateChange={onScrollFadeStateChange}
                />
              )
          )}
      </Menu>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  padding: 2rem 2rem 2rem 2.5rem;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  width: 33%;
  background-color: ${(props) => props.theme.palette.white};

  @media ${(props) => props.theme.queries.minLaptop} {
    width: 33vw;
  }

  @media ${(props) => props.theme.queries.md} {
    padding: 2rem 2rem 2rem 1rem;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.white};
  gap: 16px;
  height: 100%;
}
`;

export default CategoriesMenu;
