import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useGetThirdLevelMenu from "../../../../hooks/useGetThirdLevelMenu";
import { RequestStatus } from "../../../../interfaces/mainInterfaces";
import { FirstLevelMenu, ThirdLevelMenuObj } from "../../../../interfaces/menuInterfaces";

import {
  instanceOfMostUsedChipCatalogue,
  MostUsedChip,
  MostUsedChipCatalogue,
} from "../../../../interfaces/mostUsedInterfaces";
import { LensesColumnEssilor } from "../../../../store/store/storeInterfaces";
import {
  selectLensesEssilorMenu,
  selectMenu,
  selectThirdLevelMenu,
} from "../../../../store/store/storeSlice";
import { selectMostUsed } from "../../../../store/user/userSlice";
import { getCompleteId } from "../../../../utils/menuUtils";
import { getCategoryFromCatalogueBookmark } from "../../../../utils/mostUsedUtils";
import { MenuStructureKeysWithLenses } from "../../../layouts/header/navbar/categories-menu/category-item/menuStructure";

export interface CategoriesDataHandler {
  categoriesInfo: CategoriesInfo[];
  isCategoriesLoading: boolean;
}

export interface CategoriesInfo {
  category: MenuStructureKeysWithLenses;
  status: RequestStatus;
  id: string;
  label: string;
}

/**
 * Hook to handle the retrieval of the data for each product category,
 * required to generate the selected Chips from the API's data.
 *
 * For only those categories that appear in at least one selected bookmark,
 * the third-level menu or lenses menu is retrieved from the API.
 * An array is used to keep track, for each category:
 *    - of the status of retrieval:
 *        - SUCCESS when the third-level menu is loaded successfully
 *        - ERROR if is not found among the menu category, which means that the user's assortment has changed,
 *          and that category is no longer available (therefore the corresponding MostUsed won't, as well).
 *    - the id and label
 *    - the identifier present in the menu configuration object
 *
 * @return {*}  {CategoriesDataHandler}
 */
const useCategoriesData = (): CategoriesDataHandler => {
  const getThirdLevelMenu = useGetThirdLevelMenu();
  const lensesMenu = useSelector(selectLensesEssilorMenu);

  const mostUsedChips = useSelector(selectMostUsed)?.mostUsedPreferences?.bookmarks;
  const menuData = useSelector(selectMenu)?.find((_) => _.identifier === "PRODUCTS");
  const thirdLevelMenu = useSelector(selectThirdLevelMenu);

  const [categoriesInfo, setCategoriesInfo] = useState<CategoriesInfo[]>([]);

  /////////////////// get third-level menu info for each category for which a bookmark exists
  useEffect(() => {
    if (menuData && mostUsedChips && mostUsedChips.length > 0) {
      let relevantCategories = getCategoriesPresentInBookmark(mostUsedChips, menuData, "LOADING");

      relevantCategories = relevantCategories.map((category) => {
        const menu = menuData?.catalogGroupView?.find(
          (_) => _.identifier?.toUpperCase() === category.category
        );

        const status = menu
          ? getLoadingStatusOfCategory(category, thirdLevelMenu, lensesMenu)
          : "ERROR"; // set to ERROR if no second-level menu is found for the current category

        if (status === "LOADING") getThirdLevelMenu(true, menu);
        return { ...category, status };
      });

      setCategoriesInfo(relevantCategories);
    }
  }, [menuData, mostUsedChips]);

  /////////////////// update loading statuses each time new menu data arrives
  useEffect(() => {
    setCategoriesInfo((oldState) => {
      let hasChanges = false; // we only update state if any change is found

      const newState = oldState.map((category) => {
        const status = getLoadingStatusOfCategory(category, thirdLevelMenu, lensesMenu);

        if (status !== category.status) hasChanges = true;
        return { ...category, status };
      });

      if (hasChanges) return newState;
      else return oldState;
    });
  }, [thirdLevelMenu, lensesMenu]);

  return useMemo(() => {
    return {
      categoriesInfo,
      isCategoriesLoading: categoriesInfo.some((_) => _.status === "LOADING"),
    };
  }, [categoriesInfo]);
};

const getLoadingStatusOfCategory = (
  category: CategoriesInfo,
  thirdLevelMenu: ThirdLevelMenuObj,
  lensesMenu: LensesColumnEssilor[]
): RequestStatus => {
  if (category.status === "ERROR") return "ERROR";

  const isMenuLoaded =
    category.category === "LENSES" ? lensesMenu.length !== 0 : thirdLevelMenu[category.id];

  return isMenuLoaded ? "SUCCESS" : "LOADING";
};

const getCategoriesPresentInBookmark = (
  mostUsedChips: MostUsedChip[],
  menuData: FirstLevelMenu | undefined,
  defaultStatus: RequestStatus
): CategoriesInfo[] => {
  // get all bookmarks of type "Catalogue"
  const catalogueBookmarks: MostUsedChipCatalogue[] = mostUsedChips.filter(
    instanceOfMostUsedChipCatalogue
  );

  // get all UNIQUE categories that appear in the above bookmarks
  // return a object that tracks, for each of them, the loading status of the corresponding third-level menu
  const relevantCategories = uniqBy(
    catalogueBookmarks.map((_) => getCategoriesInfo(_, menuData, defaultStatus)),
    (_) => _.category
  );

  return relevantCategories;
};

export const getCategoriesInfo = (
  chip: MostUsedChipCatalogue,
  menuData: FirstLevelMenu | undefined,
  defaultStatus: RequestStatus
): CategoriesInfo => {
  const category = getCategoryFromCatalogueBookmark(chip);

  const menu = menuData?.catalogGroupView?.find((_) => _.identifier?.toUpperCase() === category);

  const id = getCompleteId(menu?.uniqueID || "", menu?.identifier || "");

  return {
    category,
    status: defaultStatus,
    id,
    label: menu?.name ?? "",
  };
};

export default useCategoriesData;
