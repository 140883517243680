import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLxConfigurations } from "../store/store/storeSlice";
import {
  selectActiveDoor,
  selectIsBackOfficeUser,
  selectLocale,
  selectOrganizationDetails,
} from "../store/user/userSlice";
import useCanRender from "./useCanRender";

export default function useGenesysChat(): void {
  const LXConfiguration = useSelector(selectLxConfigurations);
  const currentLocale = useSelector(selectLocale);
  const activeDoor = useSelector(selectActiveDoor);
  const orgentityDetails = useSelector(selectOrganizationDetails);
  const isBackofficeUser = useSelector(selectIsBackOfficeUser);
  const canRender = useCanRender();

  const genesysScript = LXConfiguration?.filter(
    (element) => element.key === "com.luxottica.chat.genesys.script"
  )?.[0]?.value;

  useEffect(() => {
    const inlineScript = document.createElement("script");

    if (canRender("CHAT_NEW_GENESYS") && !isBackofficeUser) {
      if (genesysScript && orgentityDetails) {
        const confScript = configureScript(
          genesysScript,
          orgentityDetails?.country?.toLocaleLowerCase() || "",
          currentLocale ? currentLocale.toLocaleLowerCase() : "",
          activeDoor?.orgentityName || ""
        );
        inlineScript.type = "text/javascript";
        inlineScript.innerHTML = confScript;
        inlineScript.nonce = "DAzDfjXu096LaFS6n_asyQ";
        document.body.append(inlineScript);
      }
    }

    return () => {
      inlineScript.remove();
    };
  }, [
    genesysScript,
    currentLocale,
    orgentityDetails,
    isBackofficeUser,
    canRender("CHAT_NEW_GENESYS"),
  ]);

  const configureScript = (
    script: string,
    country: string,
    locale: string,
    user: string
  ): string => {
    //Supported languages and countries on Genesys
    const supportedLanguages = ["en-us", "es-us", "es-mx", "fr-ca", "pt-br"];
    const supportedCountries = { br: "BRA", us: "USA", mx: "MEX", ca: "CAN" };
    type countriesKeys = keyof typeof supportedCountries;

    let tempScript = script;
    if (country in supportedCountries) {
      tempScript = tempScript.replace(
        "COUNTRY_PLACEHOLDER_FE",
        supportedCountries[country as countriesKeys]
      );
    } else {
      //Fallback on USA
      tempScript = tempScript.replace("COUNTRY_PLACEHOLDER_FE", "USA");
    }

    if (supportedLanguages.includes(locale)) {
      tempScript = tempScript.replace("LANG_PLACEHOLDER_FE", currentLocale.toLocaleLowerCase());
    } else {
      //Fallback on en-us(
      if (currentLocale.includes("fr")) {
        tempScript = tempScript.replace("LANG_PLACEHOLDER_FE", "fr-ca");
      }
      if (currentLocale.includes("es")) {
        tempScript = tempScript.replace("LANG_PLACEHOLDER_FE", "es-us");
      }
      tempScript = tempScript.replace("LANG_PLACEHOLDER_FE", "en-us");
    }
    tempScript = tempScript.replace("ACCNUM_PLACEHOLDER_FE", user || "");
    tempScript = tempScript.replace(
      "PAGEURL_PLACEHOLDER_FE",
      "https://www.my.essilorluxottica.com"
    );
    return tempScript;
  };
}
