import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useCanRender from "../../../../../hooks/useCanRender";
import useTranslation from "../../../../../hooks/useTranslation";

import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/arrowhead-right-icon.svg";
import { ReactComponent as LinkIcon } from "../../../../../assets/icons/ext-link.svg";
import { resetRxState } from "../../../../../store/rx/rxSlice";
import { getVideoPlaylist } from "../../../../../store/services/servicesSaga";
import { selectVideoPlaylist } from "../../../../../store/services/servicesSlice";
import {
  selectActiveDoor,
  selectCanBuyModelsGlobal,
  selectCanBuySparepartsGlobal,
  selectIsSubuser,
  selectMultiDoors,
  selectSelectedDoor,
} from "../../../../../store/user/userSlice";
import { externalReferenceNewTag } from "../../../../../utils/cmsUtils";
import {
  NavbarSection,
  NavbarSectionLink,
} from "../../../../pages/my-account/account-navbar/navbarSections";
import CustomText from "../../../../styled-UI/CustomText";
import ConditionalRender from "../../../../widgets/ConditionalRender";
import NewTag from "../../../../widgets/tutorial-pills/new-tag/NewTag";
import { HeaderListContainer } from "../categories-menu/category-item/MenuColumn";
import useNavbarSectionLinkHandler from "../useNavbarSectionLinkHandler";
import MemoizedCloseButton from "../../../../widgets/CloseButton";

interface Props {
  service: NavbarSection;
  hideServices: boolean;
  current: string;
  setCurrent: (value: string) => void;
  setToggle: (identifier: string) => void;
}

const ServiceSectionColumn = ({
  service,
  hideServices = false,
  current,
  setCurrent,
  setToggle,
}: Props): JSX.Element | null => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();

  const { handleNavbarSectionLinkClick } = useNavbarSectionLinkHandler();
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const canBuySparepartsGlobal = useSelector(selectCanBuySparepartsGlobal);
  const isSubuser = useSelector(selectIsSubuser);
  const mainDoor = useSelector(selectActiveDoor);
  const selectedDoor = useSelector(selectSelectedDoor);
  const videoContents = useSelector(selectVideoPlaylist);
  const multidoors = useSelector(selectMultiDoors);
  const isOpen = service.sectionTitle === current;
  useEffect(() => {
    if (selectedDoor && !videoContents && service.sectionTitle === "DIGITAL_APPLICATION_SECTION") {
      dispatch(getVideoPlaylist(selectedDoor.orgentityId));
    }
  }, [selectedDoor, videoContents]);

  const [showColumn, setShowColumn] = useState<boolean>(false);

  const checkPrivilege = (link?: NavbarSectionLink) => {
    if (isSubuser && link?.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        canRender(link?.privilege, mainDoor)
      )
        setShowColumn(true);
    } else {
      if (!link?.privilege || canRender(link?.privilege)) setShowColumn(true);
    }
  };

  useEffect(() => {
    service.links?.forEach((value: NavbarSectionLink) => {
      if (!showColumn) checkPrivilege(value);
    });
  }, []);

  const goToService = (link: NavbarSectionLink) => {
    handleNavbarSectionLinkClick(link);

    if (link.title.toUpperCase().includes("RX")) dispatch(resetRxState());
  };

  const isServiceHidden = (link: NavbarSectionLink): boolean => {
    if (isSubuser && link.exclusivePrivilege) {
      if (
        mainDoor &&
        multidoors.some((door) => mainDoor.orgentityName === door.orgentityName) &&
        !canRender(link.privilege, mainDoor)
      )
        return true;
    }
    if (
      link.title === "RX_PRESCRIPTION_TITLE" ||
      link.title === "POS_SOURCE_FILE_TITLE" ||
      link.title === "PRICE_LIST_TITLE" ||
      link.title === "ITEMS_MASTER_DATA_TITLE"
    ) {
      if (link.title === "PRICE_LIST_TITLE" && !canRender("SUGGESTED_RETAIL_PRICE")) {
        return true;
      }
      if (!canBuyModelsGlobal || hideServices) {
        return true;
      }
    }

    if (link.title === "SPAREPARTS_TITLE" || link.title === "WARRANTY_TITLE") {
      if (!canBuySparepartsGlobal || hideServices) {
        return true;
      }
    }

    if (link.identifier === "DIGITAL_SCREEN") {
      if (videoContents && videoContents?.length === 0) {
        return true;
      }
    }

    return false;
  };

  if (showColumn) {
    return (
      <Container opened={isOpen}>
        <HeaderListContainer
          onClick={(e) => {
            e.stopPropagation();
            setCurrent(isOpen ? "" : service.sectionTitle);
          }}
          key={service.sectionTitle}
        >
          <CustomText
            fontSizePx={14}
            lineHeightPx={17}
            as="span"
            font="font-regular"
            data-element-id={`MainNav_Services_${service.sectionTitle}`}
          >
            {translateLabel(service.sectionTitle)}
          </CustomText>
          <NewTag position={externalReferenceNewTag[service.sectionTitle]}></NewTag>
          <ArrowRightIcon style={{ marginLeft: "auto" }} />
        </HeaderListContainer>

        {isOpen && (
          <UrlContainer>
            {service.links?.map((value: NavbarSectionLink) => {
              const content = (
                <LinkElement>
                  <CustomText
                    key={value.title}
                    fontSizePx={14}
                    as="button"
                    font="font-regular"
                    lineHeightPx={18}
                    onClick={() =>
                      value.identifier === "LENSES_TRADE_MARKETING"
                        ? window.open("https://myessilor.it/login/", "_blank")
                        : goToService(value)
                    }
                    data-element-id={`MainNav_Services_${service.sectionTitle}_${value.dataElementId}`}
                  >
                    {translateLabel(value.title)}
                  </CustomText>
                  {value.identifier === "LENSES_TRADE_MARKETING" ||
                    (value.identifier === "DIGITAL_DISCOUNT_VOUCHER" && <LinkIcon />)}
                </LinkElement>
              );

              return value.privilege ? (
                <ConditionalRender
                  privilege={value.privilege}
                  key={value.title}
                  onlySubuser={value.onlySubuser}
                >
                  {!isServiceHidden(value) && content}
                </ConditionalRender>
              ) : (
                content
              );
            })}
            <MemoizedCloseButton
              lastItem
              action={() => setToggle("")}
              dataElementId="AccountMenu_Close"
            />
          </UrlContainer>
        )}
      </Container>
    );
  }
  return null;
};

const Container = styled.div<{ opened?: boolean }>`
  max-width: 100%;
  span {
    ${(props) => props.opened && "border-bottom: 1px solid" + props.theme.palette.primary};
    ${(props) => props.opened && "font-weight: 700"};
    &:hover {
      border-bottom: ${(props) => "1px solid" + props.theme.palette.primary};
      font-weight: 700;
    }
  }
`;

const UrlContainer = styled.div`
  background-color: ${(props) => props.theme.palette.white};
  box-shadow: ${(props) => props.theme.palette.menuBoxShadow};
  position: absolute;
  top: 0;
  left: 33%;
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: auto;
  padding: 2.5rem 2rem 2rem 2rem;
  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

const LinkElement = styled.li`
  display: flex;
  & > button {
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid ${(props) => props.theme.palette.primary};
      font-weight: 700;
    }
  }
  & > svg {
    width: 20px;
    height: 20px;
    align-self: center;
  }
`;

export default ServiceSectionColumn;
