import { useMemo } from "react";

import { MostUsedItem } from "../interfaces/cmsInterfaces";
import useCanRender from "./useCanRender";
import { MOST_USED_LENSES_KEYS, mostUsedLenses } from "../utils/mostUsedUtils";
import { useSelector } from "react-redux";
import { selectMostUsed } from "../store/user/userSlice";

const useMostUsedLensesCMS = (): MostUsedItem[] => {
  const canRender = useCanRender();
  const cmsConfiguration = useSelector(selectMostUsed).mostUsedChipsCms?.default;

  const canRenderItems = (): MostUsedItem[] => {
    const items: MostUsedItem[] = [];
    if (canRender("LENS_DIGITAL_CATALOGUE")) {
      const item = cmsConfiguration?.find(
        (obj) => obj.externalRef === mostUsedLenses.get(MOST_USED_LENSES_KEYS.DIGITAL_CATALOGUE)
      );
      items.push({
        id: MOST_USED_LENSES_KEYS.DIGITAL_CATALOGUE,
        name: MOST_USED_LENSES_KEYS.DIGITAL_CATALOGUE,
        type: "LENSES",
        title: "MOST_USED_DIGITAL_CATALOGUE",
        modificationDate: "",
        linkString: "/digital-catalog",
        creationDate: "",
        analyticsTag: [
          {
            id: "",
            name: "MostUsedDigitalCatalog",
            externalReference: "MostUsedDigitalCatalog",
          },
        ],
        titleColour: item?.titleColour,
        backgroundColour: item?.backgroundColour,
      });
    }
    if (canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY") || canRender("RX")) {
      const item = cmsConfiguration?.find(
        (obj) => obj.externalRef === mostUsedLenses.get(MOST_USED_LENSES_KEYS.LENS_CONFIGURATOR)
      );
      items.push({
        id: MOST_USED_LENSES_KEYS.LENS_CONFIGURATOR,
        name: MOST_USED_LENSES_KEYS.LENS_CONFIGURATOR,
        type: "LENSES",
        title: "MOST_USED_LENS_CONFIGURATOR",
        modificationDate: "",
        linkString: canRender("LENS_DIGITAL_CATALOGUE_ORDER_ENTRY")
          ? "/rx-prescription?rxFlow=Essilor"
          : canRender("RX")
          ? "/rx-prescription?rxFlow=Authentics"
          : "",
        creationDate: "",
        analyticsTag: [
          {
            id: "",
            name: "MostUsedLensConfigurator",
            externalReference: "MostUsedLensConfigurator",
          },
        ],
        titleColour: item?.titleColour,
        backgroundColour: item?.backgroundColour,
      });
    }

    return items;
  };

  return useMemo(() => {
    return canRenderItems();
  }, [cmsConfiguration]);
};

export default useMostUsedLensesCMS;
