import React from "react";
import styled from "styled-components/macro";
import { BannerContent } from "../../../interfaces/menuInterfaces";
import { getAnalyticsTagId, useHandleCmsLink } from "../../../utils/cmsUtils";
import Button from "../../styled-UI/Button";
import CustomText from "../../styled-UI/CustomText";
import SetInnerHTMLWithSanitize from "../SetInnerHTMLWithSanitize";

interface Props {
  slide: BannerContent;
}

const AdvBannerSlide = ({ slide }: Props): JSX.Element => {
  const { handleCmsLink } = useHandleCmsLink();

  const { title, descriptionText, descriptionTitle, imgSrc, link, linkLabel } = slide;
  const analyticsTag = getAnalyticsTagId(slide, slide.id);
  const dataDescription1 =
    typeof analyticsTag === "string" ? analyticsTag.replace(/[.,]/g, "_") : "";
  const dataDescription2 = typeof slide?.name === "string" ? slide.name.replace(/[.,]/g, "_") : "";

  const handleRedirect = (): void => {
    handleCmsLink(link);
  };

  return (
    <Container>
      <img src={imgSrc} alt="" />
      <LowerSection>
        <CustomText
          as="span"
          fontSizePx={14}
          fontWeightNum={600}
          font="font-regular"
          marginBottomPx={8}
          uppercase
        >
          <SetInnerHTMLWithSanitize text={title} />
        </CustomText>
        <Divider />
        <CustomText
          as="span"
          fontSizePx={20}
          fontWeightNum={600}
          lineHeightPx={30}
          font="font-regular"
          marginTopPx={8}
        >
          <SetInnerHTMLWithSanitize text={descriptionTitle} />
        </CustomText>
        <CustomText
          as="span"
          fontSizePx={16}
          fontWeightNum={300}
          lineHeightPx={30}
          font="font-regular"
          marginTopPx={8}
        >
          <SetInnerHTMLWithSanitize text={descriptionText} />
        </CustomText>
        <Button
          noPadding
          type="transparent"
          underline
          data-description={`${dataDescription1}_${dataDescription2}`}
          data-element-id={`MainNav_AdvCarousel`}
          onClick={handleRedirect}
        >
          <CustomText
            as="span"
            fontSizePx={18}
            fontWeightNum={700}
            lineHeightPx={22}
            font="font-regular"
            marginTopPx={16}
            uppercase
          >
            <SetInnerHTMLWithSanitize text={linkLabel} />
          </CustomText>
        </Button>
      </LowerSection>
    </Container>
  );
};

const Container = styled.div`
  img {
    width: 100%;
    height: 23rem;
    object-fit: cover;
    object-position: center;
  }
`;

const LowerSection = styled.div`
  padding: 2.5rem;
  @media ${(props) => props.theme.queries.md} {
    padding: 1.5rem 1.5rem 0.5rem;
  }
`;

const Divider = styled.div`
  margin-bottom: 1rem;
  opacity: 0.3;
  border: solid 1px ${(props) => props.theme.palette.textBlue};
  @media ${(props) => props.theme.queries.md} {
    margin-bottom: 0.5rem;
  }
`;

export default AdvBannerSlide;
