import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { ReactComponent as LinkIconWhite } from "../../../../assets/icons/ext-link-white.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/ext-link.svg";
import { paletteLight } from "../../../../assets/theme/palette";
import useTranslation from "../../../../hooks/useTranslation";
import {
  getCmsContentStrip,
  getEyemed,
  redirectToEssilorPro,
} from "../../../../store/store/storeSagas";
import {
  selectGetMenuStatus,
  selectMenuContent,
  selectStripContents,
} from "../../../../store/store/storeSlice";
import {
  selectIsMultidoor,
  selectIsMassiveOrderModeActive,
} from "../../../../store/user/userSlice";
import { externalReferenceNewTag } from "../../../../utils/cmsUtils";
import { LayoutBackground } from "../../../pages/homepage/most-used/MostUsed";
import CustomText from "../../../styled-UI/CustomText";
import ConditionalRender from "../../../widgets/ConditionalRender";
import { MemoizedMultiDoorBanner } from "../../../widgets/MultiDoorBanner";
import SetInnerHTMLWithSanitize from "../../../widgets/SetInnerHTMLWithSanitize";
import NewTag from "../../../widgets/tutorial-pills/new-tag/NewTag";
import WrapperLayout from "../../WrapperLayout";
import NavbarMenuLinkButton from "./NavbarMenuLinkButton";
import theme from "../../../../assets/theme/theme";

export type MenuLinkButtonType = "EssilorPro" | "Eyemed";

const StripMenu = ({ isVisible }: { isVisible: boolean }): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const getMenuStatus = useSelector(selectGetMenuStatus);
  const stripId = useSelector(selectMenuContent)?.stripId;
  const stripMessage = useSelector(selectStripContents)?.[0]?.description;
  const isMultiDoor = useSelector(selectIsMultidoor);
  const isMassiveOrder = useSelector(selectIsMassiveOrderModeActive);
  const textColor = isMultiDoor ? paletteLight.chip.blue : paletteLight.white;
  const icon = isMultiDoor ? <LinkIcon /> : <LinkIconWhite />;

  useEffect(() => {
    stripId && dispatch(getCmsContentStrip({ id: stripId }));
  }, [stripId]);

  const handleOnClick = (type: MenuLinkButtonType, callback: () => void) => {
    const callbackParams = {
      callback: (success?: boolean, data?: string) => {
        if (success && data) window.open(data);
        else callback?.();
      },
    };

    dispatch(
      type === "EssilorPro" ? redirectToEssilorPro(callbackParams) : getEyemed(callbackParams)
    );
  };

  return (
    <StripWrapper isVisible={isVisible} id="strip-menu">
      <LayoutBackground
        {...(isMultiDoor && { backgroundColor: paletteLight.gray.medium })}
        {...(isMassiveOrder && { backgroundColor: paletteLight.yellow })}
        height={theme.stripHeightRem + "rem"}
      >
        <WrapperLayout width100Sm isNavbar>
          <WrapperMenu ref={menuRef}>
            {isMultiDoor ? (
              <MemoizedMultiDoorBanner />
            ) : (
              <StripMessage>
                {stripMessage && (
                  <CustomText
                    className="buttonRight"
                    as="div"
                    fontSizePx={14}
                    color="white"
                    font="font-regular"
                  >
                    <SetInnerHTMLWithSanitize text={stripMessage} />
                  </CustomText>
                )}
              </StripMessage>
            )}
            <MenuRight>
              {getMenuStatus === "SUCCESS" && (
                <ConditionalRender privilege="EYEMED" isNotBOUser>
                  <NavElement>
                    <RightButton>
                      <NavbarMenuLinkButton
                        onDispatch={handleOnClick}
                        text="INSURANCE"
                        popupTitle="ERROR_POPUP_TITLE"
                        popupContent="HOMEPAGE_PAIRING_ERROR_GENERIC_INSURANCE"
                        type="Eyemed"
                        data-element-id="MainNav_Insurance"
                        textColor={textColor}
                      />
                      {icon}
                    </RightButton>
                    <MenuRightNewTag>
                      <NewTag position={externalReferenceNewTag["INSURANCE"]}></NewTag>
                    </MenuRightNewTag>
                  </NavElement>
                </ConditionalRender>
              )}

              {getMenuStatus === "SUCCESS" && (
                <ConditionalRender privilege="ESSILOR_PRO" isNotBOUser>
                  <NavElement>
                    <RightButton>
                      <NavbarMenuLinkButton
                        onDispatch={handleOnClick}
                        text="ESSILOR_PRO"
                        popupTitle="ERROR_POPUP_TITLE"
                        popupContent="HOMEPAGE_PAIRING_ERROR_GENERIC"
                        type="EssilorPro"
                        data-element-id="MainNav_Essilorpro"
                        textColor={textColor}
                      />
                      {icon}
                    </RightButton>
                    <MenuRightNewTag>
                      <NewTag position={externalReferenceNewTag["ESSILOR_PRO"]}></NewTag>
                    </MenuRightNewTag>
                  </NavElement>
                </ConditionalRender>
              )}

              {getMenuStatus === "SUCCESS" && (
                <ConditionalRender privilege="LEONARDO_SECTION" isNotBOUser>
                  <NavElement>
                    <RightButton>
                      <CustomText
                        data-element-id="MainNav_Leonardo"
                        className="buttonRight"
                        as="button"
                        fontSizePx={14}
                        customColor={textColor}
                        font="font-regular"
                        onClick={() =>
                          window.open(
                            globalEnvVariables.leonardoUrl + "/newuni/bff/myel/sso",
                            "_blank"
                          )
                        }
                      >
                        {translateLabel("LEONARDO")}
                      </CustomText>
                      {icon}
                    </RightButton>
                    <MenuRightNewTag className="leonardoTag">
                      <NewTag position={externalReferenceNewTag["LEONARDO_SECTION"]}></NewTag>
                    </MenuRightNewTag>
                  </NavElement>
                </ConditionalRender>
              )}
            </MenuRight>
          </WrapperMenu>
        </WrapperLayout>
      </LayoutBackground>
    </StripWrapper>
  );
};

const WrapperMenu = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StripWrapper = styled.nav<{ isVisible: boolean }>`
  position: fixed;
  top: ${(props) => (props.isVisible ? "0" : "-3rem")};
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 999;
`;

const MenuRightNewTag = styled.div`
  display: flex;
  padding-left: 0.625rem;
  div {
    margin: unset;
  }
  &:empty {
    padding: 0;
  }
`;

const MenuRight = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const RightButton = styled.div`
  display: flex;
  border-bottom: 2px solid transparent;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const NavElement = styled.span<{ onlyOneMenuRight?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > button {
    word-break: unset;
  }
`;

const StripMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StripMenu;

export const MemoizedStripMenu = React.memo(StripMenu);
