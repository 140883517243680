import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import useCanRender from "../../../hooks/useCanRender";

import { getPrecartCount } from "../../../store/cart/cartSagas";
import { getBrandGroups, getMenu, getStarDoorsForBrand } from "../../../store/store/storeSagas";
import {
  selectBrandGroups,
  selectMenu,
  selectStarDoorsPerBrand,
} from "../../../store/store/storeSlice";
import Logo from "../Logo";
import { MemoizedMainNavbar } from "./navbar/MainNavbar";

import {
  getNotificationsAlerts,
  getSectionsAlerts,
} from "../../../store/accounting/accountingSagas";
import { allowedServicesIds } from "../../../utils/accountingUtils";
import WrapperLayout from "../WrapperLayout";
import { MemoizedNavbarMenu } from "./navbar/NavbarMenu";
import { MemoizedStripMenu } from "./navbar/StripMenu";

const HeaderComponent = (): JSX.Element => {
  const location = useLocation();
  const canRender = useCanRender();
  const dispatch = useDispatch();

  const brandGroups = useSelector(selectBrandGroups);
  const menu = useSelector(selectMenu);
  const starDoorsPerBrand = useSelector(selectStarDoorsPerBrand);

  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef(0);

  ///////////////// download menu for the first time
  useEffect(() => {
    if (Object.entries(starDoorsPerBrand)?.length === 0) dispatch(getStarDoorsForBrand());

    if (menu.length === 0) {
      dispatch(
        getMenu({
          starsRequired: canRender("STARS"),
        })
      );
    }

    if (brandGroups && !Object.keys(brandGroups).length) dispatch(getBrandGroups()); // get brand groups

    dispatch(getNotificationsAlerts()); // get notifications counts
    location?.pathname?.includes("/account/") && dispatch(getSectionsAlerts(allowedServicesIds)); // get sections counts

    if (canRender("CART")) dispatch(getPrecartCount(false)); // get number of items in cart
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("root");
      if (!element) return;

      const currentScrollY = element.scrollTop;

      if (currentScrollY > lastScrollY.current && currentScrollY > 50) {
        setIsVisible(false); // Hide when scrolling down
      } else {
        setIsVisible(true); // Show when scrolling up
      }

      lastScrollY.current = currentScrollY;
    };

    let rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.addEventListener("scroll", handleScroll);
    }

    // Fallback: If rootElement isn't found yet, observe the DOM for changes
    const observer = new MutationObserver(() => {
      rootElement = document.getElementById("root");
      if (rootElement) {
        rootElement.addEventListener("scroll", handleScroll);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (rootElement) rootElement.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Header id="header" isStripVisible={isVisible}>
        <MemoizedStripMenu isVisible={isVisible} />
        <WrapperLayout width100Sm isNavbar height="4.375rem">
          <Wrapper>
            {/* LEFT NAVBAR */}
            <MemoizedNavbarMenu isStripVisible={isVisible} />
            {/* LEFT NAVBAR end*/}

            {/* LOGO */}
            <LogoContainer className="no-hover">
              <Logo heightPx={23} isLink />
            </LogoContainer>
            {/* LOGO end */}

            <LoggedSection>
              <MemoizedMainNavbar isStripVisible={isVisible} />
            </LoggedSection>
          </Wrapper>
        </WrapperLayout>
      </Header>
    </>
  );
};

const Header = styled.header<{ isStripVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  background-color: ${(props) => props.theme.palette.white};
  position: fixed;
  height: ${(props) =>
    (props.isStripVisible
      ? props.theme.headerHeightRem
      : props.theme.headerHeightRem - props.theme.stripHeight) + "rem"};
  transition: height 0.3s ease-in-out;
  top: 0;
  z-index: 500;
  transition: top 0.3s;
  border-bottom: 1px solid ${(props) => props.theme.palette.textBlue};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width: ${(props) => props.theme.maxWrapperWidth}; */
  margin: 0 auto;
  height: 100%;
  width: 100%;
`;

const LogoContainer = styled.nav`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 3px;

  @media ${(props) => props.theme.queries.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 1.5rem;
    left: unset;
    transform: unset;
  }
`;

const LoggedSection = styled.div`
  display: flex;
  z-index: 101;
  height: 100%;
  flex-shrink: 0;
`;

export default HeaderComponent;

export const MemoizedHeaderComponent = React.memo(HeaderComponent);
