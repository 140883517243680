import clsx from "clsx";
import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import CustomScrollbar from "../../components/styled-UI/CustomScrollbar";
import { getPagePath } from "../../routing/routesUtils";
import {
  saveIsFromSuggestion,
  saveSuggestions,
  selectSuggestions,
} from "../../store/search/searchSlice";
import { getSuggestionString } from "../../utils/utils";

import useCanRender from "../../hooks/useCanRender";
import { SearchCategories, Suggestion } from "../../store/search/searchInterfaces";
import { appendFiltersToURLSearchParams } from "../../utils/filterUtils";
import { getCategoryQueryParam, getSuggestionResults } from "../../utils/searchUtils";
import { ReactComponent as XIcon } from "../../assets/icons/x-icon-rounded.svg";

interface SearchCustomProps {
  placeholder?: string;
  category: SearchCategories;
  value?: string;
  closeExpandMenu?: () => void;
  setIsSelectOpen?: (open: boolean) => void;
  "data-element-id"?: string;
}

type SearchProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & SearchCustomProps;

const Search = ({
  placeholder,
  category,
  value,
  closeExpandMenu,
  setIsSelectOpen,
  "data-element-id": dataElementId,
}: SearchProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const canRender = useCanRender();

  const [currentText, setCurrentText] = useState<string>("");
  const [timeoutSuggestion, setTimeoutSuggestion] = useState<NodeJS.Timeout>();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSearchOnFocus, setIsSearchOnFocus] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

  const suggestions = useSelector(selectSuggestions);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (elementRef.current) {
        const target = (event.target as Node) || null;
        if (!elementRef.current.contains(target)) {
          setShowSuggestions(false);
          setIsSelectOpen?.(false);
        }
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  //set value from props
  useEffect(() => {
    if (value) setCurrentText(value);
  }, [value]);

  const suggestionsLength = suggestions.reduce(
    (previousValue, currentValue, currentIndex, suggestions) => {
      return (
        previousValue +
        (getSuggestionString(suggestions, currentText.toLowerCase(), currentIndex) ? 1 : 0)
      );
    },
    0
  );

  const SearchClassNames = clsx(currentText.length >= 3 && "input-bigger-then-three");
  const DropdownClassName = clsx(
    suggestionsLength === 0 && "dropdown-zero-line",
    suggestionsLength === 1 && "dropdown-one-line",
    suggestionsLength === 2 && "dropdown-two-line",
    suggestionsLength === 3 && "dropdown-three-line",
    suggestionsLength === 4 && "dropdown-four-line",
    suggestionsLength === 5 && "dropdown-five-line"
  );

  const handleSuggestions = (term: string) => {
    const filters = getCategoryQueryParam(category);
    getSuggestionResults(term, dispatch, filters);
    setShowSuggestions(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);

    if (e.target.value.length >= 3) {
      if (timeoutSuggestion) {
        clearTimeout(timeoutSuggestion);
      }
      const newTimeoutSuggestion = setTimeout(() => {
        handleSuggestions(e.target.value.toLowerCase().trim());
      }, 500);
      setTimeoutSuggestion(newTimeoutSuggestion);
    } else {
      dispatch(saveSuggestions([]));
    }
  };

  const cleanInput = () => {
    setCurrentText("");
    dispatch(saveSuggestions([]));
    closeExpandMenu && closeExpandMenu();
  };

  const goToSearchPage = (text: string, isFromSuggestion = false) => {
    const filters = getCategoryQueryParam(category);
    const params = appendFiltersToURLSearchParams(filters);

    if (text.length > 0 && text.trim().length > 0) {
      const url = `/search-results/${encodeURIComponent(text.trim())}?${params.toString()}`;
      history.push(getPagePath(url));
      dispatch(saveIsFromSuggestion(isFromSuggestion));
      cleanInput();
    }
  };

  const handleKeypress = (e: React.KeyboardEvent<Element>) => {
    if (e.key === "Enter") {
      const search =
        selectedSuggestionIndex > 0 && suggestions[selectedSuggestionIndex]
          ? suggestions[selectedSuggestionIndex]?.term
          : currentText;

      goToSearchPage(search);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
    if (suggestions?.length > 0 && showSuggestions) {
      let index = selectedSuggestionIndex;
      if (e.key === "ArrowUp") {
        if (selectedSuggestionIndex === 0) {
          index = suggestions.length - 1;
        } else {
          index -= 1;
        }
      } else if (e.key === "ArrowDown") {
        if (selectedSuggestionIndex === suggestions.length - 1) {
          index = 0;
        } else {
          index += 1;
        }
      }

      setSelectedSuggestionIndex(index);
    }
  };
  const handleOnClickSuggestion = (suggestion: string) => goToSearchPage(suggestion, true);

  const renderSuggestion = () => {
    if (suggestions?.length > 0 && canRender("CATALOGUE")) {
      return suggestions.map((suggestion: Suggestion, index: number) => {
        const autocomplete = getSuggestionString(suggestions, currentText.toLowerCase(), index);
        const selected = selectedSuggestionIndex === index;
        setIsSelectOpen?.(true);

        if (autocomplete)
          return (
            <div
              className={clsx("result-line", { selected })}
              key={index}
              onClick={() => handleOnClickSuggestion(suggestion.term)}
              onMouseEnter={() => !selected && setSelectedSuggestionIndex(index)}
            >
              <span>{currentText}</span>
              <SuggestValue>{autocomplete}</SuggestValue>
            </div>
          );
      });
    }
  };

  return (
    <Container>
      <InputWrapper>
        <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
          {isSearchOnFocus && <PlaceholderText>{placeholder}</PlaceholderText>}
          <StyledInput
            value={currentText}
            currentText={currentText}
            className={SearchClassNames}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyPress={handleKeypress}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsSearchOnFocus(true)}
            onBlur={() => !currentText && setIsSearchOnFocus(false)}
            {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            autoFocus
          />
        </div>
        {showSuggestions && (
          <Autocomplete>
            <SpaceSpan>{currentText}</SpaceSpan>
            {currentText.length > 3 &&
              suggestions?.length > 0 &&
              suggestions[0] &&
              getSuggestionString(suggestions, currentText, 0)}
          </Autocomplete>
        )}
        <XIcon
          onClick={() => {
            if (currentText) {
              setCurrentText("");
              dispatch(saveSuggestions([]));
              setIsSearchOnFocus(false);
            }
          }}
          style={{ marginLeft: "auto", height: "1.25rem", width: "auto", zIndex: 9999 }}
        />
      </InputWrapper>
      {suggestions?.length > 0 && showSuggestions && (
        <>
          <StyledScrollable className={DropdownClassName} ref={elementRef}>
            <CustomScrollbar>
              <StyledDropdown>{renderSuggestion()}</StyledDropdown>
            </CustomScrollbar>
          </StyledScrollable>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  position: relative;
`;

const InputWrapper = styled.div`
  padding: 0.938rem 1rem;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 0.25rem;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  &:focus-within {
    border: 1px solid ${(props) => props.theme.palette.primary};
  }
`;

const SpaceSpan = styled.span`
  user-select: none;
  color: transparent;
  display: inline-block;
  content: "";
`;

const Autocomplete = styled.div`
  color: ${(props) => props.theme.palette.gray.dark};
  font-family: ${(props) => props.theme.fonts.fontRegular};
  font-size: 1.25rem;
  position: relative;
  z-index: 1;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledInput = styled.input<any>`
  width: 100%;
  border: 0;
  box-sizing: border-box;
  font-size: 0.813rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.2px;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  z-index: 2;
  padding: 0px;
  background-color: transparent;
  font-family: ${(props) => props.theme.fonts.fontRegular};
  appearance: none;
  outline: none;
  border-color: transparent;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.palette.primary};

  &:hover {
    cursor: pointer;
  }

  &.input-bigger-then-three {
    width: 100%;
  }

  &&::-webkit-input-placeholder {
    color: ${(props) => props.theme.palette.primary};
  }
  &&::-moz-placeholder {
    color: ${(props) => props.theme.palette.primary};
  }
  &&:-ms-input-placeholder {
    color: ${(props) => props.theme.palette.primary};
  }
  &&:-moz-placeholder {
    color: ${(props) => props.theme.palette.primary};
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

const PlaceholderText = styled.span<any>`
  color: ${(props) => props.theme.palette.gray.neutral};
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.2px;
  transition: 0.2s ease all;
`;

const lineHeightLg = 3.5;

const StyledScrollable = styled.div`
  position: relative;
  height: calc(${lineHeightLg * 7 + "rem"} + 1px);
  margin-top: 0.25rem;
  border: 1px solid ${(props) => props.theme.palette.primary};
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  /* padding-bottom: 2.25rem; TO NOT HAVE SCROLLBAR WHEN NOT NEEDED */
  z-index: 101;
  overflow-x: hidden;
  max-height: 14rem;
  .ScrollbarsCustom-Track {
    right: 3px !important;
  }

  &.dropdown-zero-line {
    height: 1px;
  }
  &.dropdown-one-line {
    height: calc(${lineHeightLg + "rem"} + 4px);
  }
  &.dropdown-two-line {
    height: calc(${lineHeightLg * 2 + "rem"} + 4px);
  }
  &.dropdown-three-line {
    height: calc(${lineHeightLg * 3 + "rem"} + 4px);
  }
  &.dropdown-four-line {
    height: calc(${lineHeightLg * 4 + "rem"} + 4px);
  }
  &.dropdown-five-line {
    height: calc(${lineHeightLg * 5 + "rem"} + 4px);
  }
`;

const StyledDropdown = styled.div`
  box-sizing: border-box;
  text-align: left;
  text-transform: capitalize;

  .result-line {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: ${lineHeightLg + "rem"};
    font-size: 0.813rem;
    font-weight: 600;
    padding: 0.688rem 1rem;
    font-family: ${(props) => props.theme.fonts.fontRegular};
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.borders};
    .result-value {
      padding-right: 0.125rem;
      color: ${(props) => props.theme.palette.primary};
    }

    &.selected {
      background-color: #ccd8ff4f;
    }
  }
`;

const SuggestValue = styled.span`
  margin-left: 1px;
  color: ${(props) => props.theme.palette.gray.dark};
  font-family: ${(props) => props.theme.fonts.fontRegular};
`;

export default Search;
