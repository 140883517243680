import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

interface Props {
  children: any;
  clearBackground?: boolean;
}

const WrapperLayoutMenu = ({ children, clearBackground }: Props): JSX.Element => {
  return (
    <Wrapper clearBackground={clearBackground} className={clsx("print-ready")}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<Props>`
  min-width: ${(props) => props.theme.minWrapperWidth};
  max-width: ${(props) => props.theme.maxWrapperWidth};
  margin: 0 auto;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    background-color: ${(props) => props.theme.palette.white};
    z-index: -1;
    left: -100vw;
  }

  @media ${(props) => props.theme.queries.md} {
    max-width: ${(props) => props.theme.maxWrapperWithMD};
    margin: 0 auto;
  }

  @media ${(props) => props.theme.queries.sm} {
    background-color: ${(props) => !props.clearBackground && props.theme.palette.white};
  }
`;

export default WrapperLayoutMenu;
