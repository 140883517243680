import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "./LoaderElement";

export const LoaderBanner = (): JSX.Element => {
  return (
    <CarouselWrapper>
      <Img>
        <LoaderElement height={368} width={475} />
      </Img>
      <Content>
        <LoaderElement height={18} width={400} />
        <LoaderElement height={1} width={400} />
        <LoaderElement height={120} width={400} />
        <LoaderElement height={22} width={400} css={{ paddingTop: "2rem" }} />
      </Content>
    </CarouselWrapper>
  );
};

const Img = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export default LoaderBanner;
