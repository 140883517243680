import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import { fromDashToCamelCase } from "../../utils/utils";
import { FontFamilies } from "../../store/store/storeInterfaces";

export type Fonts =
  | "font-light"
  | "font-regular"
  | "font-medium"
  | "font-heavy"
  | "font-bold"
  | "custom-light"
  | "custom-regular"
  | "custom-medium"
  | "custom-heavy"
  | "custom-bold";

export type Tags = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "span" | "button" | "div";
export type Color =
  | "primary"
  | "white"
  | "gray-dark"
  | "gray-medium"
  | "gray-neutral"
  | "text-blue"
  | "red"
  | "green"
  | "secondary";

interface Props {
  font?: Fonts;
  fontFamily?: FontFamilies | null;
  fontWeight?: string;
  children: any;
  uppercase?: boolean;
  underline?: boolean;
  whiteSpace?: string;
  fontSizePx?: number;
  lineHeightPx?: number;
  letterSpacing?: number;
  as: Tags;
  color?: Color;
  customColor?: string;
  onClick?: any;
  marginTopPx?: number | string;
  marginBottomPx?: number | string;
  marginRightPx?: number | string;
  marginLeftPx?: number | string;
  paddingTopPx?: number | string;
  paddingBottomPx?: number | string;
  paddingRightPx?: number | string;
  paddingLeftPx?: number | string;
  "data-element-id"?: string;
  "data-description"?: string;
  isFlex?: boolean;
  isInline?: boolean;
  isEllipsis?: boolean;
  isClickable?: boolean;
  textAlign?: "left" | "center" | "right";
  wordBreak?: string;
  className?: string;
  title?: string;
  fontWeightNum?: number;
  width?: number;
  height?: number;
  justifyContent?: string;
  padding?: number;
  gap?: number;
  isRounded?: boolean;
  widthText?: string;
  customBackground?: string;
  borderBottom?: string;
}

const CustomText = ({
  title,
  font = "font-medium",
  fontFamily,
  fontWeight = "normal",
  uppercase = false,
  underline = false,
  whiteSpace = "normal",
  as = "p",
  color = "primary",
  customColor,
  children,
  fontSizePx = 16,
  lineHeightPx = 18,
  letterSpacing = 0.2,
  onClick,
  marginTopPx = 0,
  marginBottomPx = 0,
  marginRightPx = 0,
  marginLeftPx = 0,
  paddingTopPx = 0,
  paddingRightPx = 0,
  paddingLeftPx = 0,
  paddingBottomPx = 0,
  "data-element-id": dataElementId,
  "data-description": dataDescription,
  isFlex = true,
  isInline = false,
  isEllipsis,
  isClickable = false,
  textAlign = "left",
  wordBreak = "break-word",
  className,
  fontWeightNum,
  width,
  height,
  justifyContent,
  padding,
  gap,
  isRounded,
  widthText,
  customBackground,
  borderBottom,
  ...rest
}: Props): JSX.Element => {
  const classNames = clsx(
    uppercase && "text-uppercase",
    underline && "text-underline",
    as === "button" && "button-cursor",
    isEllipsis && "text-ellipsis",
    isRounded && "rounded",
    widthText && "custom-width",
    className
  );

  return (
    <Text
      className={classNames}
      font={font}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      as={as}
      color={customColor && customColor != "No Colour" ? customColor : color}
      whiteSpace={whiteSpace}
      marginTopPx={marginTopPx}
      marginBottomPx={marginBottomPx}
      marginRightPx={marginRightPx}
      marginLeftPx={marginLeftPx}
      paddingTopPx={paddingTopPx}
      paddingBottomPx={paddingBottomPx}
      paddingRightPx={paddingRightPx}
      paddingLeftPx={paddingLeftPx}
      fontSizePx={fontSizePx}
      lineHeightPx={lineHeightPx}
      letterSpacing={letterSpacing}
      isFlex={isFlex}
      isInline={isInline}
      isClickable={isClickable}
      textAlign={textAlign}
      wordBreak={wordBreak}
      fontWeightNum={fontWeightNum}
      width={width}
      widthText={widthText}
      height={height}
      justifyContent={justifyContent}
      padding={padding}
      gap={gap}
      customBackground={customBackground}
      borderBottom={borderBottom}
      {...(title && { title: title })}
      {...(onClick ? { onClick: () => onClick() } : {})}
      {...(dataElementId ? { "data-element-id": dataElementId } : {})}
      {...(dataDescription ? { "data-description": dataDescription } : {})}
      {...rest}
    >
      {children}
    </Text>
  );
};

const printFont = (props: any) => {
  let fontFam = "";
  if (props.fontFamily) {
    fontFam = props.fontFamily + " ";
  }
  if (props.font && props.theme.fonts[fromDashToCamelCase(props.font)]) {
    return fontFam + props.theme.fonts[fromDashToCamelCase(props.font)];
  } else {
    return fontFam + props.theme.fonts.fontMedium;
  }
};
interface TextProps {
  fontSizePx: number;
  color: string;
  whiteSpace: string;
  font: Fonts | null;
  fontFamily?: FontFamilies | null;
  fontWeight?: string;
  marginTopPx: number | string;
  marginBottomPx: number | string;
  marginRightPx: number | string;
  marginLeftPx: number | string;
  paddingTopPx: number | string;
  paddingBottomPx: number | string;
  paddingRightPx: number | string;
  paddingLeftPx: number | string;
  lineHeightPx: number;
  letterSpacing: number;
  isFlex: boolean;
  isInline: boolean;
  isClickable: boolean;
  textAlign?: "left" | "center" | "right";
  wordBreak: string;
  fontWeightNum?: number;
  width?: number;
  widthText?: string;
  height?: number;
  justifyContent?: string;
  padding?: number;
  gap?: number;
  customBackground?: string;
  borderBottom?: string;
}

const Text = styled.p<TextProps>`
  display: ${(props) => (props.isInline ? "inline" : props.isFlex ? "flex" : "block")};
  text-align: ${(props) => props.textAlign};
  align-items: center;
  font-family: ${(props) => printFont(props)};
  font-size: ${(props) => props.fontSizePx / props.theme.baseFontSize}rem;
  line-height: ${(props) => props.lineHeightPx / props.theme.baseFontSize}rem;
  letter-spacing: ${(props) => props.letterSpacing / props.theme.baseFontSize}rem;
  word-break: ${(props) => props.wordBreak};
  white-space: ${(props) => props.whiteSpace};
  font-weight: ${(props) => props.fontWeight};
  ${(props) => props.isClickable === true && `cursor: pointer;`}
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : `font-weight: normal;`)}
  ${(props) => props.fontWeightNum && `font-weight: ${props.fontWeightNum};`}
  ${(props) => props.width && `width: ${props.width / props.theme.baseFontSize}rem;`}
  ${(props) => props.height && `height: ${props.height / props.theme.baseFontSize}rem;`}
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.padding && `padding: ${props.padding / props.theme.baseFontSize}rem;`}
  ${(props) => props.customBackground && `background: ${props.customBackground};`}
  ${(props) => props.gap && `gap: ${props.gap / props.theme.baseFontSize}rem;`}
  ${(props) => props.borderBottom && `border-bottom: ${props.borderBottom};`}
  
  //MARGINS
  margin-top: ${(props) =>
    typeof props.marginTopPx === "number"
      ? `${props.marginTopPx / props.theme.baseFontSize}rem`
      : `${props.marginTopPx}`};
  margin-left: ${(props) =>
    typeof props.marginLeftPx === "number"
      ? `${props.marginLeftPx / props.theme.baseFontSize}rem`
      : `${props.marginLeftPx}`};
  margin-bottom: ${(props) =>
    typeof props.marginBottomPx === "number"
      ? `${props.marginBottomPx / props.theme.baseFontSize}rem`
      : `${props.marginBottomPx}`};
  margin-right: ${(props) =>
    typeof props.marginRightPx === "number"
      ? `${props.marginRightPx / props.theme.baseFontSize}rem`
      : `${props.marginRightPx}`};

  //PADDINGS
  padding-top: ${(props) =>
    props.paddingTopPx && typeof props.paddingTopPx === "number"
      ? `${props.paddingTopPx / props.theme.baseFontSize}rem`
      : `${props.paddingTopPx}`};
  padding-left: ${(props) =>
    props.paddingLeftPx && typeof props.paddingLeftPx === "number"
      ? `${props.paddingLeftPx / props.theme.baseFontSize}rem`
      : `${props.paddingLeftPx}`};
  padding-bottom: ${(props) =>
    props.paddingBottomPx && typeof props.paddingBottomPx === "number"
      ? `${props.paddingBottomPx / props.theme.baseFontSize}rem`
      : `${props.paddingBottomPx}`};
  padding-right: ${(props) =>
    props.paddingRightPx && typeof props.paddingRightPx === "number"
      ? `${props.paddingRightPx / props.theme.baseFontSize}rem`
      : `${props.paddingRightPx}`};

  //COLOR
  color: ${(props) => {
    switch (props.color) {
      case "primary":
        return props.theme.palette.primary;
      case "secondary":
        return props.theme.palette.secondary;
      case "white":
        return props.theme.palette.white;
      case "gray-dark":
        return props.theme.palette.gray.dark;
      case "gray-medium":
        return props.theme.palette.gray.medium;
      case "gray-neutral":
        return props.theme.palette.gray.neutral;
      case "text-blue":
        return props.theme.palette.textBlue;
      case "red":
        return props.theme.palette.feedback.error;
      case "green":
        return props.theme.palette.feedback.success;
      default:
        return props.color;
    }
  }};

  svg {
    fill: currentColor;
    height: 0.75rem;
    margin: 0 0.375rem;
  }

  &.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: ${(props) =>
      props.width ? `${props.width / props.theme.baseFontSize}rem;` : `fit-content;`};
  }

  &.text-uppercase {
    text-transform: uppercase;
  }

  &.text-underline {
    text-decoration: underline;
  }

  &.button-cursor {
    cursor: pointer;
  }

  &.rounded {
    border-radius: 6.25rem;
    border: 1px solid ${(props) => props.theme.palette.primary};
    background: ${(props) => props.theme.palette.white};
  }

  &.custom-width {
    ${(props) => props.widthText && `width: ${props.widthText};`}
  }
`;

export default CustomText;
