import React, { memo } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-icon.svg";
import clsx from "clsx";

interface Props {
  action?: () => void;
  dataElementId?: string;
  type?: "default" | "white";
  lastItem?: boolean;
}

const CloseButton = ({ action, dataElementId, type = "default", lastItem }: Props): JSX.Element => {
  return (
    <CloseWrapper
      data-element-id={dataElementId}
      onClick={() => action?.()}
      className={clsx(type === "white" && "white", lastItem && "last-item")}
    >
      <CloseIcon />
    </CloseWrapper>
  );
};

const CloseWrapper = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: auto;
  align-items: center;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.white {
    border-radius: 6.25rem;
    padding: 0.5rem;
    background-color: rgba(3, 20, 52, 0.3);

    svg {
      fill: ${(props) => props.theme.palette.white};
    }
  }

  &.last-item {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1;
  }
`;

const MemoizedCloseButton = memo(CloseButton);
export default MemoizedCloseButton;
