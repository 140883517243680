import React, { memo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import { paletteLight } from "../../assets/theme/palette";
import useTranslation from "../../hooks/useTranslation";
import { getPagePath } from "../../routing/routesUtils";
import CustomText from "./CustomText";
import LinkButton from "./LinkButton";

type BannerProps = {
  text: string;
  selectedDoors: number;
  totalDoors: number;
  id?: string;
};

const Banner = ({ text, selectedDoors, totalDoors, id }: BannerProps): JSX.Element => {
  const history = useHistory();
  const { translateLabel } = useTranslation();

  return (
    <StyledBanner id={id}>
      <CustomText
        as="p"
        font="font-regular"
        fontSizePx={14}
        lineHeightPx={18}
        fontWeightNum={400}
        customColor={paletteLight.chip.blue}
      >
        {text}
      </CustomText>
      <CustomText
        as="p"
        font="font-regular"
        fontSizePx={14}
        lineHeightPx={18}
        fontWeightNum={400}
        customColor={paletteLight.chip.blue}
      >
        |
      </CustomText>
      <CustomText
        as="p"
        font="font-bold"
        fontSizePx={13}
        lineHeightPx={18}
        fontWeightNum={700}
        customColor={paletteLight.chip.blue}
      >
        {translateLabel("BANNER_SELECTED_DOORS")} {selectedDoors}/{totalDoors}
      </CustomText>
      <CustomText
        as="p"
        font="font-bold"
        fontSizePx={14}
        lineHeightPx={22}
        fontWeightNum={600}
        customColor={paletteLight.chip.blue}
      >
        |
      </CustomText>
      <LinkButton
        uppercase
        customColor={paletteLight.chip.blue}
        onClick={() => {
          history.push(getPagePath("/account/multidoor"));
        }}
      >
        {translateLabel("BANNER_EDIT_DOORS")}
      </LinkButton>
    </StyledBanner>
  );
};

const StyledBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media print {
    position: relative;
  }
`;

export default Banner;

export const MemoizedBanner = memo(Banner);
