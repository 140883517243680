import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import styled from "styled-components/macro";
import { paletteLight } from "../../../assets/theme/palette";
import { selectAdvBanner, selectAdvBannerStatus } from "../../../store/store/storeSlice";
import CarouselDotBar from "../../styled-UI/CarouselDotBar";
import LoaderBanner from "../../styled-UI/loader/LoaderBanner";
import AdvBannerSlide from "./AdvBannerSlide";

interface Props {
  type:
    | "menu.standard.brands"
    | "menu.stars.brands"
    | "menu.standard.categories"
    | "menu.stars.categories";
  isBannerAvailable?: (isBanner: boolean) => void;
}

const AdvBanner = ({ type, isBannerAvailable }: Props): JSX.Element => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const banners = useSelector(selectAdvBanner);
  const status = useSelector(selectAdvBannerStatus);

  const filteredSlides = useMemo(() => {
    const slidesToShow = banners.filter((item) => new Set(item.externalReference).has(type));
    return slidesToShow;
  }, [banners, type]);

  useEffect(() => {
    isBannerAvailable?.(!!filteredSlides?.length);
  }, [filteredSlides, isBannerAvailable]);

  const defaultSettings = {
    dots: false,
    arrows: false,
    draggable: true,
    speed: 500,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    onSwipe: () => {
      console.log("swipe");
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (_: any, next: any) => setCurrentSlide(next),
  };

  const changeSlide = (val: number) => {
    sliderRef.slickGoTo(val);
  };

  return status === "SUCCESS" ? (
    filteredSlides?.length > 0 ? (
      <div style={{ width: "inherit" }}>
        <Slider ref={(val) => setSliderRef(val)} {...defaultSettings}>
          {filteredSlides &&
            filteredSlides.map((slide, index: number) => {
              return <AdvBannerSlide key={index} slide={slide} />;
            })}
        </Slider>
        <DotBar>
          <CarouselDotBar
            lastPage={filteredSlides && filteredSlides.length - 1}
            currentPage={currentSlide}
            changeSlide={changeSlide}
            navigationType="one-tile"
            isAnimated
            data-element-id="News_Navigation"
            data-description="dots"
            dotSelectedColor={paletteLight.primary}
            dotCustomColor={paletteLight.primary}
          />
        </DotBar>
      </div>
    ) : (
      <div style={{ width: "inherit" }}></div>
    )
  ) : status === "LOADING" ? (
    <LoaderBanner />
  ) : (
    <></>
  );
};

const DotBar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -1.5rem;
`;
export default AdvBanner;

export const MemoizedAdvBanner = React.memo(AdvBanner);
