import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../hooks/useTranslation";
import { getTotalDoors } from "../../store/user/userSagas";
import {
  selectActiveDoor,
  selectIsMassiveOrderModeActive,
  selectIsMultidoor,
  selectMultiDoors,
  selectTotalDoors,
} from "../../store/user/userSlice";
import { MemoizedBanner } from "../styled-UI/Banner";

function MultiDoorBanner(): JSX.Element | null {
  const dispatch = useDispatch();
  const { translateLabel } = useTranslation();
  const activeDoor = useSelector(selectActiveDoor);
  const multiDoors = useSelector(selectMultiDoors);
  const isMultidoor = useSelector(selectIsMultidoor);
  const totalDoors = useSelector(selectTotalDoors);
  const massiveOrderMode = useSelector(selectIsMassiveOrderModeActive);

  useEffect(() => {
    if (isMultidoor) dispatch(getTotalDoors());
  }, [isMultidoor, activeDoor?.orgentityName]);

  const label = massiveOrderMode
    ? "MULTIDOOR_BANNER_YOU_ARE_NOW_IN_MASSIVE_ORDER_MODE"
    : "MULTIDOOR_BANNER_YOU_ARE_NOW_IN_MULTIDOOR";

  return isMultidoor ? (
    <MemoizedBanner
      text={translateLabel(label)}
      selectedDoors={multiDoors.length}
      totalDoors={totalDoors || 0}
      id="banner"
    />
  ) : null;
}

export default MultiDoorBanner;

export const MemoizedMultiDoorBanner = React.memo(MultiDoorBanner);
