import React, { useState } from "react";
import styled from "styled-components/macro";

import useSetTutorialPillsPopupVisibility from "../../../../../hooks/useSetTutorialPillsPopupVisibility";
import ServiceSectionColumn from "./ServiceSectionColumn";
import { serviceMenu } from "./serviceSections";

interface Props {
  hideServices: boolean;
  setToggle: (identifier: string) => void;
}

const ServicesMenu = ({ hideServices = false, setToggle }: Props): JSX.Element => {
  const [current, setCurrent] = useState<string>("");
  useSetTutorialPillsPopupVisibility();

  return (
    <MenuContainer>
      <Menu>
        {serviceMenu.map((service) => (
          <ServiceSectionColumn
            service={service}
            key={service.sectionTitle}
            hideServices={hideServices}
            current={current}
            setCurrent={setCurrent}
            setToggle={setToggle}
          />
        ))}
      </Menu>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: grid;
  overflow-y: auto;
  width: 33%;
  background-color: ${(props) => props.theme.palette.white};
  padding: 0.5rem 2rem 2rem 2.5rem;

  @media ${(props) => props.theme.queries.md} {
    padding: 0.5rem 2rem 2rem 1rem;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0 0;
`;

export default ServicesMenu;
