import clsx from "clsx";
import React, { useRef, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useTranslation from "../../../../../../hooks/useTranslation";

import { getPagePath } from "../../../../../../routing/routesUtils";
import { ColBrandGroup } from "../../../../../../utils/menuUtils";
import CustomText from "../../../../../styled-UI/CustomText";
import { useMenuRedirectMultidoorStars } from "../../useMenuRedirectMultidoorStars";

import { ReactComponent as ArrowRightIcon } from "../../../../../../assets/icons/arrowhead-right-icon.svg";
import { ReactComponent as StarIcon } from "../../../../../../assets/icons/star-icon.svg";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { HeaderListContainer } from "./MenuColumn";
import MemoizedCloseButton from "../../../../../widgets/CloseButton";

interface Props {
  isKids?: boolean;
  identifier: string;
  isStars?: boolean;
  setCurrent: (current: string) => void;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  title: string;
  showStarIcon?: boolean;
  brandColumns?: ColBrandGroup[];
  current: string;
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

const BrandsColumn = ({
  isKids = false,
  identifier,
  toggleMenu,
  setToggleMenu,
  isStars = false,
  title,
  showStarIcon = false,
  brandColumns = [],
  current,
  setCurrent,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const history = useHistory();
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [scrollBottom, setScrollBottom] = useState<boolean>(false);
  const type = isKids ? "kids" : "adults";
  const dataElementIdIsStars = isStars ? "_Stars_" : "_Brand_";
  const currentBrand = `${identifier}.${title}`;
  const isOpen = current == currentBrand;
  const redirectToPLP = (brand: ColBrandGroup) => {
    resetScrollPosition();
    history.push(getPagePath(brand.link));

    toggleMenu(false);
    setToggleMenu("");
  };
  const [itemsPerColumn, setItemsPerColumn] = useState<number>(5);

  useEffect(() => {
    const updateItemsPerColumn = () => {
      if (scrollableRef.current) {
        const containerHeight = scrollableRef.current.clientHeight;
        const itemHeight = 39;
        const maxItems = Math.floor(containerHeight / itemHeight);

        setItemsPerColumn(maxItems);
      }
    };

    window.addEventListener("resize", updateItemsPerColumn);
    updateItemsPerColumn();

    return () => {
      window.removeEventListener("resize", updateItemsPerColumn);
    };
  }, [brandColumns.length, isOpen]);

  const [column1, column2] = useMemo(() => {
    return brandColumns.reduce<ColBrandGroup[][]>(
      (acc, item, index) => {
        const targetColumn = index % (itemsPerColumn * 2) < itemsPerColumn ? 0 : 1;
        acc[targetColumn].push(item);
        return acc;
      },
      [[], []]
    );
  }, [brandColumns, itemsPerColumn]);

  const handleScroll = () => {
    if (!scrollableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
    const hasTopFade = scrollTop > 0;
    const hasBottomFade = scrollTop + clientHeight < scrollHeight;
    // Notify the parent about whether we need top/bottom fades
    setScrollTop(hasTopFade);
    setScrollBottom(hasBottomFade && !hasTopFade);
  };

  // useMenuRedirectMultidoorStars isolates the logic required to redirect to the prePLP / PLP corresponding to a specific brand
  // refer to the hook's file for more info regarding this logic
  const { handleClick: handleBrandClick } = useMenuRedirectMultidoorStars({
    redirect: redirectToPLP,
  });

  const renderColumn = (col: ColBrandGroup[]) => {
    return (
      <SingleColumn>
        {col.map((_: ColBrandGroup) => {
          return (
            <React.Fragment key={_.brandgroup + type + identifier}>
              <li
                style={{ display: "flex", maxHeight: `1.188rem` }}
                key={_.brandgroup + type + identifier}
              >
                <a
                  data-element-id={`MainNav_Products_${identifier}${dataElementIdIsStars}${_.label}`
                    ?.replace(/-/g, "_")
                    ?.replace(/ /g, "_")}
                >
                  <CustomText
                    fontSizePx={14}
                    as="span"
                    font="font-regular"
                    lineHeightPx={18}
                    color={isStars ? "white" : "primary"}
                    isClickable
                    onClick={() => handleClick(_)}
                    isEllipsis
                    height={21}
                  >
                    {_.label || _.brandgroup}
                  </CustomText>
                </a>
              </li>
            </React.Fragment>
          );
        })}
      </SingleColumn>
    );
  };

  const handleClick = (brand: ColBrandGroup) =>
    handleBrandClick(brand, brand.brands?.[0], brand.label || "", brand.isStars);

  useEffect(() => {
    if (isOpen) {
      const el = scrollableRef.current;
      if (!el) return;
      el.addEventListener("scroll", handleScroll, { passive: true });
      // Check immediately
      handleScroll();

      return () => {
        el.removeEventListener("scroll", handleScroll);
      };
    }
  }, [brandColumns, isOpen, scrollableRef.current?.clientHeight]);

  return (
    <>
      {brandColumns?.length > 0 && (
        <UrlList>
          <HeaderListContainer
            onClick={(e) => {
              e.stopPropagation();
              setCurrent(isOpen ? "" : currentBrand);
            }}
            key={type + identifier}
            className={clsx(isOpen && "is-selected")}
          >
            <CustomText fontSizePx={14} lineHeightPx={17} font="font-regular" as="span">
              {translateLabel(title)}
            </CustomText>
            <NewTag position={externalReferenceNewTag[currentBrand]}></NewTag>
            <ArrowRightIcon style={{ marginLeft: "auto" }} />
          </HeaderListContainer>

          {isOpen && (
            <ListItemsContainer className={clsx(isStars && "stars")}>
              <ListFlex ref={scrollableRef}>
                {scrollTop && <FadeTop />}
                {showStarIcon && (
                  <div style={{ paddingBottom: "0.74", display: "flex", gap: "0.5rem" }}>
                    <IconContainer>
                      <StarIcon />
                    </IconContainer>
                    <CustomText
                      fontSizePx={14}
                      color="white"
                      uppercase
                      lineHeightPx={17}
                      as="span"
                      fontWeightNum={700}
                    >
                      {translateLabel(title)}
                    </CustomText>
                  </div>
                )}
                <Columns>
                  {renderColumn(column1)}
                  {renderColumn(column2)}
                </Columns>
                <MemoizedCloseButton
                  action={() => setToggleMenu("")}
                  dataElementId="MainNav_Menu_Close"
                  lastItem
                  type={isStars ? "white" : "default"}
                />
                {scrollBottom && <FadeBottom />}
              </ListFlex>
            </ListItemsContainer>
          )}
        </UrlList>
      )}
    </>
  );
};

const UrlList = styled.ul``;
const ListFlex = styled.div`
  display: flex;
  overflow-y: auto;
  max-height: 45.85rem;
  gap: 1.25rem;
  flex-direction: column;
  height: 100%;
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 1.688rem; /* Sposta la scrollbar più in basso */
  }

  &::after {
    content: "";
    display: block;
    height: 1.688rem; /* Aggiunge spazio extra alla fine, simulando la posizione abbassata */
  }
`;

const SingleColumn = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  width: 12.25rem;
}
`;
const ListItemsContainer = styled.div`
  box-shadow: ${(props) => props.theme.palette.menuBoxShadow};
  background-color: ${(props) => props.theme.palette.white};
  position: absolute;
  top: 0;
  left: 29.688rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 2.5rem 2.5rem 2rem 2rem;
  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary};
      font-weight: 700;
    }
  }

  &.stars {
    span {
      &:hover {
        border-bottom: 1px solid ${({ theme }) => theme.palette.white};
      }
    }
    background-color: ${(props) => props.theme.palette.chip.blue};
  }

  @media ${(props) => props.theme.queries.md} {
    left: calc(29.688rem - 3.5rem);
  }

  @media ${(props) => props.theme.queries.minLaptop} {
    left: 33vw;
    width: 33vw;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100vw;
    background-color: ${(props) => props.theme.palette.white};
  }
`;

const IconContainer = styled.div`
  svg {
    height: 1rem;
    fill: ${(props) => props.theme.palette.white};
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
`;

const FadeTop = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5.5rem;
  pointer-events: none;
  z-index: 10; /* above the menu items */
  background: linear-gradient(0deg, rgba(164, 164, 164, 0) 0%, rgba(164, 164, 164, 0.2) 100%);
`;

const FadeBottom = styled.div`
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.5rem;
  pointer-events: none;
  z-index: 10;
  background: linear-gradient(180deg, rgba(164, 164, 164, 0) 0%, rgba(164, 164, 164, 0.2) 100%);
`;

export default BrandsColumn;
