import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import useCanRender from "../../hooks/useCanRender";
import { parseUrlInfo } from "../../routing/routesUtils";
import { getPrecartCount } from "../../store/cart/cartSagas";
import {
  getBrandImages,
  getMenu,
  getStoreConfiguration,
  getTutorialPills,
  getUrlLensSimulator,
  getUrlWithTokenEl360,
  getUrlWithTokenRewards,
} from "../../store/store/storeSagas";
import {
  resetThirdLevelMenu,
  selectBrandImages,
  selectMenu,
  togglePopupUserNotMigrated,
} from "../../store/store/storeSlice";
import { getUserContext, getUserMailCheckDuplication, logout } from "../../store/user/userSagas";
import {
  selectAtLeastOneTruePrivileges,
  selectIsLogged,
  selectIsNotMigrated,
  selectJustLogged,
  selectLanguageId,
  selectLocale,
  selectMultiDoors,
  selectStoreIdentifier,
  setJustLogged,
} from "../../store/user/userSlice";
import { getAcceptCookie } from "../../utils/cookieUtils";
import { getTermsConditionDocument } from "../../store/documents/documentsSagas";

const setHTMLLangAttrFromLocale = (currentLocale?: string) => {
  if (!currentLocale) return;

  // const lang = currentLocale.split("-")?.[0];
  document.documentElement.setAttribute("lang", currentLocale);
};

const SiteSetter = (): null => {
  const [acceptAnalytics, setAcceptAnalytics] = useState<boolean>(false);

  const isLogged = useSelector(selectIsLogged);
  const storeIdentifier = useSelector(selectStoreIdentifier);
  const dispatch = useDispatch();
  const location = useLocation();
  const acceptCookie = getAcceptCookie();
  const currentLocale = useSelector(selectLocale);
  const multidoors = useSelector(selectMultiDoors);
  const menu = useSelector(selectMenu);
  const canRender = useCanRender();
  const brandImages = useSelector(selectBrandImages);
  const privileges = useSelector(selectAtLeastOneTruePrivileges);
  const isNotMigrated = useSelector(selectIsNotMigrated);
  const justLogged = useSelector(selectJustLogged);
  const languageId = useSelector(selectLanguageId);
  const urlInfo = parseUrlInfo();

  const cookieType = {
    ALL: "All",
    TECHNICAL: "Technical",
  };

  const eventListenerFunction = (e: any) => {
    if (e) {
      if (e?.key != "message") return; // ignore other keys
      const message = e?.newValue;
      if (!message) return; // ignore empty msg or msg reset

      if (message === "logout") {
        dispatch(logout({}));
      }
    }
  };

  //MEASURE LOADING TIME
  const durationLength = (): number => {
    if (window.performance && window.performance.getEntriesByType) {
      const entries = window.performance.getEntriesByType("navigation");
      if (entries.length > 0) {
        const navTiming = entries[0];
        const pageLoadTime = navTiming.duration;
        return pageLoadTime / 1000;
      }
    }
    return 0;
  };

  useEffect(() => {
    window.addEventListener("storage", eventListenerFunction);
    window.onbeforeunload = function () {
      dispatch(setJustLogged(false));
    };

    () => window.removeEventListener("storage", eventListenerFunction);
  }, []);

  ///// OLD HANDLING OF POSTLOGIN
  // useEffect(() => {
  //   if (queryParams.toString()) {
  //     if (
  //       !isLogged &&
  //       queryParams.has("postLogin") &&
  //       queryParams.get("postLogin") === "true" &&
  //       queryParams.has("storeIdentifier")
  //     ) {
  //       const storeIdentifier = queryParams.get("storeIdentifier");
  //       dispatch(
  //         loginSuccess({
  //           //TODO GET USERNAME E SESSION
  //           storeIdentifier: storeIdentifier as string,
  //           session: "",
  //         })
  //       );
  //     }
  //   }
  // }, []);

  useEffect(() => {
    //ELSE, GET THE INITIAL CALLS (necessary for using the website)
    if (isLogged && storeIdentifier) {
      dispatch(getStoreConfiguration());
      dispatch(getUserContext());
    }
  }, [isLogged, storeIdentifier]);

  // DO TUTORIAL PILLS CALL AND TERMSCONDITION DOCUMENT CALL
  // The second one is used in order to decide if show or not the terms and condition acceptance popup
  useEffect(() => {
    if (isLogged && urlInfo?.locale && urlInfo?.storeIdentifier) {
      dispatch(getTutorialPills());
      dispatch(getTermsConditionDocument("terms_condition"));
    }
  }, [isLogged, urlInfo?.locale, urlInfo?.storeIdentifier]);

  //ENABLE GOOGLE ANALYTICS IF COOKIE IS PRESENT
  useEffect(() => {
    if (acceptCookie && acceptCookie == cookieType.ALL && globalEnvVariables.analyticsCode) {
      setAcceptAnalytics(true);
      ReactGA.initialize(globalEnvVariables.analyticsCode, {
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      });
    }
  }, [acceptCookie]);

  //SEND GOOGLE ANALYTICS INFO AT PAGE CHANGE
  useEffect(() => {
    if (acceptAnalytics && location.pathname) {
      const urlInfo = parseUrlInfo();
      const dimension1 = urlInfo.storeIdentifier;
      const dimension2 = location.pathname
        .split("/")
        .splice(dimension1 ? 3 : 2)
        .join("/");
      const pathname = location.pathname;
      if (dimension1) {
        ReactGA.set({ dimension1: dimension1 });
      }
      ReactGA.set({ dimension2: dimension2 });
      ReactGA.set({ location: pathname });

      // ReactGA.pageview(dimension2);
      //with react-ga4, it seems dimensions have to be passed as parameters, ReactGA.set has no effect
      const pageViewParams: { [key: string]: string } = {
        hitType: "pageview",
        page: dimension2,
        dimension2: dimension2,
        location: pathname,
        pageLoadTime: durationLength().toString(),
      };
      if (dimension1) {
        pageViewParams.dimension1 = dimension1;
      }
      ReactGA.send(pageViewParams);
    }
  }, [acceptAnalytics, location.pathname]);

  //MENU

  const handleGetBrandImages = () => {
    if (Object.keys(brandImages).length === 0) {
      const brandsIdentifier: string[] = [];
      const brandsEssilorIdentifier: string[] = [];

      const brandMenu = menu?.filter((_) => _?.identifier === "BRANDS")[0];
      const brandMenuEssilor = menu?.filter((_) => _?.identifier === "ESSILOR")[0];

      brandMenuEssilor?.catalogGroupView?.map((brand) => {
        brand.brandCodes?.forEach((_) => brandsEssilorIdentifier.push(_));
        brandsEssilorIdentifier.push(brand.identifier);
      });

      brandMenu?.catalogGroupView?.map((brand) => {
        brandsIdentifier.push(brand.identifier);
      });

      if (brandsIdentifier.length > 0)
        dispatch(getBrandImages({ brands: brandsIdentifier, isEssilor: false }));
      if (brandsEssilorIdentifier.length > 0)
        dispatch(getBrandImages({ brands: brandsEssilorIdentifier, isEssilor: true }));
    }
  };

  //download brand images
  useEffect(() => {
    menu && handleGetBrandImages();
  }, [menu]);

  //update menu after locale change
  useEffect(() => {
    canRender("CART") && dispatch(getPrecartCount(true));

    menu &&
      Object.keys(menu).length &&
      dispatch(
        getMenu({
          starsRequired: canRender("STARS"),
        })
      );
    dispatch(resetThirdLevelMenu());

    //add lang attribute to HTML tag to fix issues with not displaying all characters correctly in Turkish language
    setHTMLLangAttrFromLocale(currentLocale);
  }, [currentLocale, multidoors]);

  useEffect(() => {
    if (privileges.length) {
      canRender("EL_360") && dispatch(getUrlWithTokenEl360(null));
      canRender("REWARDS") && dispatch(getUrlWithTokenRewards(null));
      canRender("LENS_SIMULATOR") && dispatch(getUrlLensSimulator(null));
      canRender("ENABLE_POPUP_DUPLICATED_EMAIL") &&
        justLogged &&
        dispatch(getUserMailCheckDuplication());
    }
  }, [privileges]);

  useEffect(() => {
    if (isNotMigrated) {
      dispatch(togglePopupUserNotMigrated({ open: true }));
    }
  }, [isNotMigrated]);

  return null;
};

export default SiteSetter;
