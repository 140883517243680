import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components/macro";
import useTranslation from "../../../../../hooks/useTranslation";

import { SecondLevelMenu } from "../../../../../interfaces/menuInterfaces";
import { getPagePath } from "../../../../../routing/routesUtils";
import BrandButton from "../../../../styled-UI/BrandButton";
import CustomText from "../../../../styled-UI/CustomText";
import { useMenuRedirectMultidoorStars } from "../useMenuRedirectMultidoorStars";

import clsx from "clsx";
import { ReactComponent as StarIcon } from "../../../../../assets/icons/star-icon.svg";
import useSetTutorialPillsPopupVisibility from "../../../../../hooks/useSetTutorialPillsPopupVisibility";

interface Props {
  brands?: SecondLevelMenu[];
  setToggleMenu: (identifier: string) => void;
  enableDataDescription?: boolean;
  isEssilor?: boolean;
  isStars?: boolean;
  hasStars?: boolean;
  columns: number;
  borderLeft?: boolean;
  isStripVisible?: boolean;
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

const ITEM_HEIGHT = 19 + 20; // 19px brand row height + 20px row gap

const BrandMenu = ({
  brands,
  setToggleMenu,
  enableDataDescription = false,
  isEssilor = false,
  isStars = false,
  hasStars = false,
  borderLeft = false,
  columns = 0,
  isStripVisible,
  onScrollFadeStateChange,
}: Props): JSX.Element => {
  const history = useHistory();
  useSetTutorialPillsPopupVisibility();
  const { translateLabel } = useTranslation();
  const boxRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [finalColumns, setFinalColumns] = useState<number>(0);
  const [brandChunks, setBrandChunks] = useState<SecondLevelMenu[][]>([]);

  useEffect(() => {
    if (!containerRef.current) return;

    // Get the height of the component
    const updateHeight = () => {
      setContainerHeight(
        (containerRef.current?.clientHeight || 0) - (hasStars || isStars ? 89 : 0)
      );
    };

    updateHeight(); // Set initial height
    window.addEventListener("resize", updateHeight); // Update on window resize

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleScroll = () => {
    if (!boxRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = boxRef.current;
    const hasTopFade = scrollTop > 0;
    const hasBottomFade = scrollTop + clientHeight + 1 < scrollHeight;

    // Notify the parent about whether we need top/bottom fades
    onScrollFadeStateChange?.(hasTopFade, hasBottomFade);
  };

  useEffect(() => {
    const el = boxRef.current;
    if (!el) return;
    el.addEventListener("scroll", handleScroll, { passive: true });
    // Check immediately
    handleScroll();

    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, [brands]);

  useEffect(() => {
    if (!brands || !brands.length) {
      setFinalColumns(0);
      setBrandChunks([]);
      return;
    }

    // If the container ref doesn’t exist yet, we can’t measure:
    // fallback or wait until we have a real height:
    if (!boxRef.current || containerHeight === 0) {
      // e.g. fallback to 1 column for now
      setFinalColumns(1);
      setBrandChunks([brands]);
      return;
    }

    const totalItems = brands.length;

    // 1) Figure out how many items fit in one column if we tried to avoid scrolling
    const itemsPerColumnNoScroll = Math.floor(containerHeight / ITEM_HEIGHT);

    // 2) If we can't fit even one item in the visible container:
    if (itemsPerColumnNoScroll <= 0) {
      // fallback to 1 column containing everything
      setFinalColumns(1);
      setBrandChunks([brands]);
      return;
    }

    // 3) The columns needed to avoid scrolling at all:
    const columnsNeededNoScroll = Math.ceil(totalItems / itemsPerColumnNoScroll);

    let actualColumns: number;
    let chunkSize: number;

    if (columnsNeededNoScroll <= columns) {
      // This means we can fit all items in fewer columns than "columns"
      // => no extra scrolling is needed (unless itemsPerColumnNoScroll is an approximation)
      actualColumns = columnsNeededNoScroll;
      chunkSize = itemsPerColumnNoScroll;
    } else {
      // We exceed the user-specified max column count, so we must allow scrolling
      actualColumns = columns;

      // Now we want to distribute all items across `columns` columns:
      chunkSize = Math.ceil(totalItems / actualColumns);
    }

    // 4) Actually chunk the array top-to-bottom, then left-to-right
    //    i.e., the first column gets the first `chunkSize` items, the second gets the next `chunkSize`, etc.
    const newChunks: SecondLevelMenu[][] = [];
    let startIndex = 0;

    // We only want to create exactly `actualColumns` columns, so do a simple for-loop
    for (let c = 0; c < actualColumns; c++) {
      const endIndex = startIndex + chunkSize;
      newChunks.push(brands.slice(startIndex, endIndex));
      startIndex = endIndex;
    }

    // If somehow we still have leftover items after filling columns,
    // add them to the last column to avoid “missing” items:
    if (startIndex < totalItems && newChunks.length > 0) {
      newChunks[newChunks.length - 1] = [
        ...newChunks[newChunks.length - 1],
        ...brands.slice(startIndex),
      ];
    }

    // 5) Store the results
    setFinalColumns(actualColumns);
    setBrandChunks(newChunks);
  }, [brands, columns, containerHeight]);

  const redirectToPrePLP = (brand: SecondLevelMenu) => {
    //history.push brings to lens-configurator microFE when brands are essilor, to preplp otherwise
    if (isEssilor) {
      history.push(getPagePath(`/digital-catalog?brands=${brand.brandCodes?.join(",")}`));
    } else {
      history.push(getPagePath(`/preplp${brand.seo?.href ?? `/${brand.identifier}`}`));
    }
    setToggleMenu("");
  };

  // useMenuRedirectMultidoorStars isolates the logic required to redirect to the prePLP / PLP corresponding to a specific brand
  // refer to the hook's file for more info regarding this logic
  const { handleClick: handleBrandClick } = useMenuRedirectMultidoorStars({
    redirect: redirectToPrePLP,
  });

  const handleClick = (brand: SecondLevelMenu) =>
    handleBrandClick(brand, brand.identifier, brand.name, !!brand.isStars);

  return (
    <div style={{ width: "inherit" }}>
      {!!brands?.length && (
        <BrandsContainer
          isStripVisible={isStripVisible}
          className={clsx(isStars && "is-stars", !isEssilor && !hasStars && !isStars && "brands")}
          height={containerHeight}
          ref={containerRef}
        >
          {isStars && (
            <CustomText uppercase as="p" color="white">
              <IconContainer>
                <StarIcon />
              </IconContainer>
              {translateLabel("MENU_BRAND_STARS")}
            </CustomText>
          )}
          {hasStars && !isEssilor && (
            <CustomText uppercase as="p" height={25}>
              {translateLabel("MENU_BRAND_OTHERS")}
            </CustomText>
          )}
          <ScrollableContainer ref={boxRef}>
            <ColumnsWrapper
              finalColumns={finalColumns}
              className={clsx(isEssilor && "essilor", isEssilor && hasStars && "margin-essilor")}
              borderLeft={borderLeft}
            >
              {brandChunks.map((chunk, colIndex) => (
                <SingleColumn key={colIndex} isStars={isStars} isEssilor={isEssilor && hasStars}>
                  {chunk.map((brand) => {
                    return (
                      <BrandButton
                        key={brand.identifier}
                        brandGroup={brand}
                        onClick={() => handleClick(brand)}
                        data-element-id={isStars ? "MainNav_Brands_Stars" : "MainNav_Brands_Brand"}
                        enableDataDescription={enableDataDescription}
                        {...(!isStars && { fallbackLabel: brand.name })}
                        {...(isStars && { style: "stars" })}
                      />
                    );
                  })}
                </SingleColumn>
              ))}
            </ColumnsWrapper>
          </ScrollableContainer>
        </BrandsContainer>
      )}
    </div>
  );
};

const BrandsContainer = styled.div<{ isStripVisible?: boolean; height: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;

  &.brands {
    padding: 2rem 0 1rem 0;
  }

  max-height: min(
    calc(
      100vh -
        ${(props) =>
          props.isStripVisible
            ? props.theme.headerHeightRem + "rem"
            : props.theme.headerHeightRem - props.theme.stripHeightRem + "rem"}
    ),
    45.85rem
  );

  padding: ${(props) => (props.height < 730 ? "2rem 2rem 1rem 2rem" : "2rem")};

  &.is-stars {
    background-color: ${(props) => props.theme.palette.chip.blue};
    margin-left: -1rem;
  }
`;

const IconContainer = styled.div`
  svg {
    height: 1.375rem;
    fill: ${(props) => props.theme.palette.white};
  }
`;

const ScrollableContainer = styled.div`
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const ColumnsWrapper = styled.div<{ finalColumns: number; borderLeft?: boolean }>`
  display: flex;
  gap: 20px;
  flex: 1;

  &.essilor {
    height: 95%;

    padding-left: 2rem;
    ${(props) => props.borderLeft && `border-left: 0.5px solid ${props.theme.palette.textBlue}`};
  }

  &.margin-essilor {
    margin-top: 2.5rem;
  }
`;

const SingleColumn = styled.div<{ isStars?: boolean; isEssilor?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 12.25rem;
  gap: 1.25rem;

  margin-top: ${(props) => (props.isEssilor ? "1rem" : "0")};

  & > * {
    height: 19px;
  }

  span {
    border-bottom: 1px solid transparent;
    color: ${(props) => (props.isStars ? props.theme.palette.white : props.theme.palette.primary)};
    &:hover {
      font-family: "Gilmer";
      font-weight: 700;
      border-bottom: 1px solid
        ${(props) => (props.isStars ? props.theme.palette.white : props.theme.palette.primary)};
    }
  }
`;

//UNDERLINE
// text-decoration: underline;
// text-decoration-thickness: 0.0625rem;
// text-underline-offset: 0.125rem;
// text-decoration-color: ${(props) =>
//   props.isStars ? props.theme.palette.white : props.theme.palette.primary};

export default BrandMenu;
