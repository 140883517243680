import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { VTOTransitionLens } from "../../components/widgets/VMMV/VMMVInterfaces";
import {
  AdvTile,
  InstagramTooltip,
  Moodboard,
  MultiMediaType,
  OpticianTipsColumn,
} from "../../interfaces/cmsInterfaces";
import { FacetView, FacetViewEntry, FacetViewMacroFamily } from "../../interfaces/facetInterfaces";
import { Col } from "../../interfaces/gridInterfaces";
import {
  MultipleRequestStatus,
  RequestStatus,
  SetMultipleRequestStatus,
} from "../../interfaces/mainInterfaces";
import { PopupMultidoorAFASetting, PopupMultidoorSetting } from "../../interfaces/popupInterfaces";
import { Product, Sku, Variant } from "../../interfaces/productInterface";
import { checkIsAFAOrHelmet, mapProductsAFA } from "../../utils/AFAutils";
import { addPriceToProductorVariant, sortMacroFamilies } from "../../utils/catalogueUtils";
import { isBrandFilter, splitMacroFamilyAndStarsNewFilters } from "../../utils/filterUtils";
import { pricePrivileges } from "../../utils/privilegesUtils";
import { getAttribute, instanceOfProduct } from "../../utils/productUtils";
import { QueryParams } from "../search/searchInterfaces";
import { RootState } from "../storeConfig";
import {
  selectActiveDoor,
  selectAtLeastOneTruePrivileges,
  selectIsMultidoor,
  selectUsersPrivileges,
} from "../user/userSlice";
import {
  Attachment,
  AvailabileQuantityAFAInfoObj,
  AvailabilityStatus,
  AvailabilityStatusByDoor,
  BestsellersCatalogue,
  CatalogueState,
  CurrentCategoryPlp,
  CurrentProductPdp,
  GetPricesResult,
  KeylookObj,
  LoadingPdp,
  LoadingPlp,
  LoadingPrePlp,
  PDPVariantInfo,
  PDPVariantInfoAvailabilityPayload,
  PDPVariantInfoLoading,
  PDPVariantsInfo,
  PdpProductCarousel,
  PdpProductCarouselPayload,
  PlpCatalogue,
  PlpStarsCatalogue,
  PlpStarsCatalogueFacets,
  PlpVariants,
  PlpVariantsCatalogue,
  PreCartMassiveOrderProduct,
  PreCartProduct,
  PrePLPCarousels,
  PrePLPStarsCarousels,
  PrePLPStarsStatsPerCat,
  PrePlpCatalogue,
  PrePlpStarsCatalogue,
  PrePlpStarsCatalogueVisibility,
  PrevProductInfo,
  ProductInstagramBadge,
  ProductRecommendationCatalogue,
  RTRResponse,
  SavePlpStarsRestOfCataloguePayload,
  SetBestsellersPayload,
  SetKeylookPayload,
  SetProductRecommendationPayload,
  UpdateAvailabilityStatusByDoor,
  VideoAvailability,
  ZoomPopup,
} from "./catalogueInterface";

const DEFAULT_LOADING_PDP: MultipleRequestStatus<LoadingPdp> = {
  slug: "IDLE",
  variantDetails: "IDLE",
  productWithVariants: "IDLE",
  CMRCMoodBoard: "IDLE",
  CMRCExplosionVideo: "IDLE",
  CMRCInstagramLink: "IDLE",
  CMRCOpticalTips: "IDLE",
  CMRCSeethrough: "IDLE",
  CMRCAdvContent: "IDLE",
  CMVideo: "IDLE",
  CMRCSeethroughWithout: "IDLE",
  CMChannelTechIcons: "IDLE",
  CMChannelKeylook: "IDLE",
  CMChannelSport: "IDLE",
  CMVideoAvailability: "IDLE",
};

const DEFAULT_LOADING_PLP: MultipleRequestStatus<LoadingPlp> = {
  slug: "IDLE",
  layout: "IDLE",
  catalogue: "IDLE",
  facets: "IDLE",
};

const DEFAULT_LOADING_PREPLP: MultipleRequestStatus<LoadingPrePlp> = {
  starsStatistics: "IDLE",
  starsCarousels: "IDLE",
  catalogue: "IDLE",
  prePLPContent: "IDLE",
};

export const sliceName = "catalogue";
const initialState: CatalogueState = {
  /////////////////// COMMON
  preCart: [],
  preCartMassiveOrder: [],
  productPerPage: 24,
  variantsPerPage: 4,
  productAvailability: [],
  selectedTile: null,

  /////////////////// PDP
  productWithVariants: null,
  variantDetails: null,
  videoAvailability: null,
  currentProductPdp: {
    productId: null,
    variantId: null,
    type: null,
  },
  explosionVideo: "",
  seethroughImage: "",
  seethroughWithoutImage: "",
  advContents: [],
  videoContents: [],
  opticianTips: [],
  imagesCarouselPdp: [],
  imagesTransitionsPdp: [],
  view360Pdp: [],
  zoomPopup: { isOpen: false, uniqueId: undefined, prevUniqueId: undefined },
  loadingPDP: DEFAULT_LOADING_PDP,
  imagesCarouselPdpStatus: "IDLE",
  popupMultidoorSetting: { open: false, variant: null },
  popupMultidoorAFASetting: { open: false, AFAVariant: null },
  loadingAvailability: false,
  PDPVariantFacets: [],
  PDPVariantFacetsSelected: {},
  customerReferenceAFA: null,
  isUpdatingQuantityAFA: false,
  availabileQuantityAFAInfo: {},
  prevProductInfo: {
    brand: null,
    productCode: null,
    variantCodeNoUnderscore: null,
    environment: null,
    uniqueID: null,
  },

  //////////////////// PDP LIST
  pdpVariantsList: null,
  maxTilesInPDPList: 4,
  pdpVariantsListPrices: [],
  PDPVariantsInfo: {},
  PDPVariantsInfoLoading: {},

  /////////////////// PLP
  plpVariants: {
    items: [],
    recordSetCount: 0,
    recordSetTotal: 0,
  },
  currentCategoryPlp: {
    category: null,
    categoryId: null,
    tokenExternalValue: null,
  },
  expandedTileRequestStatus: "IDLE",
  plpCatalogue: null,
  plpFacetView: null,
  plpLoading: DEFAULT_LOADING_PLP,

  plpStarsCatalogue: null,
  plpStarsFacetView: null,
  plpStarsCatalogueStatus: "IDLE",
  plpStarsStatistics: [],
  plpStarsStatisticsStatus: "IDLE",
  plpStarsRestOfCatalogue: null,
  plpStarsRestOfCatalogueCMSInfo: null,
  plpStarsRestOfCatalogueStatus: "IDLE",
  plpStarsRestOfCatalogueMaxSlides: 12,

  plpVariantsCatalogue: null,
  plpVariantsFacetView: null,

  /////////////////// PRE-PLP
  prePLPLoading: DEFAULT_LOADING_PREPLP,
  prePLPCarousels: {},
  prePLPStarsCarousels: {},
  prePLPStarsCarouselsStats: {},
  prePLPBrandGroup: null,
  isPrePLPBrandGroupStars: false,
  isPrePLPBrandGroupStarsStatus: "IDLE",
  productsPerCarousel: "4",
  prePLPStarsCarouselsVisibility: null,

  ///// BESTSELLERS`
  bestSellers: null,
  bestSellersRequestStatus: "IDLE",
  bestSellersMaxSlides: 12,

  QRCode: "",
  VTOTransitionLens: null,
  instagramLink: null,

  ///// ALTERNATIVE PRODUCT
  alternativeProductId: null,

  //// SIMILAR PRODUCT
  similarProductId: null,
  PDPSimilarProducts: {
    products: [],
    totalProducts: 0,
    requestStatus: "IDLE",
  },
  PDPSimilarProductsMaxSlides: 16,

  //// PRODUCT COMPATIBLE WITH ACCESSORIES
  productsCompatibleProductId: null,
  PDPProductsCompatible: {
    products: [],
    totalProducts: 0,
    requestStatus: "IDLE",
  },
  PDPProductsCompatibleMaxSlides: 16,

  //// PRODUCT RECOMMENDATION
  productRecommendation: null,
  productRecommendationStatus: "IDLE",
  productRecommendationMaxSlides: 12,

  //// INSTAGRAM BADGE
  instagramBadges: null,

  //// MOODBOARD SPORT
  sportContents: {},

  //// KEYLOOK
  keylookContents: {},
  PDPKeylookProducts: {
    products: [],
    totalProducts: 0,
    requestStatus: "IDLE",
  },
  PDPKeylookProductsMaxSlides: 16,

  //// VMMV
  vmmvStatus: "LOADING",

  //// RTR
  variantsForRTR: {
    productPartNumber: "",
    RTRVariants: [],
  },
  RTRStatus: "LOADING",

  //// DIGITAL CATALOGUE
  urlDigitalCatalogue: "",
  urlsDigitalCatalogueCTA: {},
};

export const productSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    /////////////////// COMMON
    savePreCart: (state: CatalogueState, action: PayloadAction<PreCartProduct>) => {
      const preCartProduct = action.payload;

      if (!!state.preCart.length) {
        /* find if element is in precart */
        const index = state.preCart.findIndex(
          (element) =>
            element.partNumber === preCartProduct.partNumber &&
            element.multidoorId === preCartProduct.multidoorId &&
            element.availabilityId === preCartProduct.availabilityId
        );
        if (index !== -1) {
          const itemPreCart = state.preCart[index];
          /* if the quantity is 0, remove element from precart */
          if (preCartProduct.quantity === 0) {
            state.preCart.splice(index, 1);
          } else {
            if (preCartProduct.quantity) {
              itemPreCart.quantity = preCartProduct.quantity;
            }

            if (preCartProduct.customerReference)
              itemPreCart.customerReference = preCartProduct.customerReference;
          }
        } else {
          state.preCart.push(preCartProduct);
        }
      } else {
        state.preCart.push(preCartProduct);
      }
    },
    savePreCartMassiveOrder: (state, action: PayloadAction<PreCartMassiveOrderProduct>) => {
      const preCartProduct = action.payload;
      if (!!state.preCartMassiveOrder.length) {
        /* find if element is in precart */
        const index = state.preCartMassiveOrder.findIndex(
          (element) => element.partNumber === preCartProduct.partNumber
        );
        if (index !== -1) {
          const itemPreCart = state.preCartMassiveOrder[index];
          /* if the quantity is 0, remove element from precart */
          if (preCartProduct.quantity === 0) {
            state.preCartMassiveOrder.splice(index, 1);
          } else {
            if (preCartProduct.quantity) {
              itemPreCart.quantity = preCartProduct.quantity;
            }
          }
        } else {
          state.preCartMassiveOrder.push(preCartProduct);
        }
      } else {
        state.preCartMassiveOrder.push(preCartProduct);
      }
    },
    removeItemsFromPrecart: (state, action: PayloadAction<PreCartProduct[]>) => {
      const preCartProducts = action.payload;
      if (!!state.preCart.length) {
        preCartProducts.forEach((preCartProduct) => {
          /* find if element is in precart */
          const index = state.preCart.findIndex(
            (element) =>
              element.partNumber === preCartProduct.partNumber &&
              element.multidoorId === preCartProduct.multidoorId &&
              element.availabilityId === preCartProduct.availabilityId
          );
          if (index !== -1) {
            state.preCart.splice(index, 1);
          }
        });
      }
    },
    clearPreCart: (state) => {
      state.preCart = [];
      state.preCartMassiveOrder = [];
    },
    saveProductAvailability: (
      state: CatalogueState,
      action: PayloadAction<AvailabilityStatusByDoor[]>
    ) => {
      action.payload.forEach((door) => {
        const index = state.productAvailability.findIndex((_) => _.doorId === door.doorId);

        if (index !== -1) {
          const currentAvailability = state.productAvailability[index];

          const availabilityStatus: AvailabilityStatus[] = [];
          currentAvailability.availabilityStatus.forEach((_) => {
            if (
              door.availabilityStatus.findIndex(
                (status) => Object.keys(status)?.[0] === Object.keys(_)?.[0]
              ) === -1
            )
              availabilityStatus.push(_);
          });

          state.productAvailability[index] = {
            ...currentAvailability,
            availabilityStatus: [...door.availabilityStatus, ...availabilityStatus],

            availabilityInfo: [
              ...(currentAvailability.availabilityInfo ?? []),
              ...(door.availabilityInfo ?? []),
            ],
          };
        } else {
          state.productAvailability.push(door);
        }
      });
    },
    unfollowItemsAddedToCart: (state, action: PayloadAction<string[]>) => {
      const availability = state.productAvailability;
      const cartIdList = action.payload;
      state.productAvailability = availability.map((door) => ({
        ...door,
        availabilityStatus: door.availabilityStatus.map((item) => {
          const isInCart = cartIdList.includes(Object.keys(item)[0]);
          const isFollowed = Object.values(item)[0] === "BACKORDER_FOLLOWING";

          if (isInCart && isFollowed) {
            return { [Object.keys(item)[0]]: "BACKORDER_NOT_FOLLOWING" };
          }
          return item;
        }),
      }));
    },
    updateProductAvailabilityStatus: (
      state,
      action: PayloadAction<UpdateAvailabilityStatusByDoor>
    ) => {
      const newAvailability = action.payload;
      const index = state.productAvailability.findIndex((_) => _.doorId === newAvailability.doorId);

      if (index !== -1) {
        const currentAvailability = state.productAvailability[index];
        const indexStatus = currentAvailability.availabilityStatus.findIndex(
          (status) => Object.keys(newAvailability.availabilityStatus)[0] === Object.keys(status)[0]
        );

        state.productAvailability[index].availabilityStatus[indexStatus] =
          newAvailability.availabilityStatus;
      }
    },
    saveBackorderProductAvailability: (state, { payload }: PayloadAction<AvailabilityStatus>) => {
      state.productAvailability = state.productAvailability.map(
        (door: AvailabilityStatusByDoor) => ({
          ...door,
          availabilityStatus: door.availabilityStatus.map((status) => {
            return Object.keys(payload)[0] === Object.keys(status)[0] &&
              Object.values(status)[0] !== "NOT_AVAILABLE"
              ? payload
              : status;
          }),
        })
      );
    },
    setSelectedTile: (state, action: PayloadAction<Product | Variant | null>) => {
      state.selectedTile = action.payload;
    },

    resetState: () => initialState,

    /////////////////// PDP
    saveProductWithVariants: (state: CatalogueState, action: PayloadAction<Product | null>) => {
      state.productWithVariants = action.payload;
    },
    saveVariantDetails: (state: CatalogueState, action: PayloadAction<Variant | null>) => {
      state.variantDetails = action.payload;
    },
    saveVideoAvailability: (
      state: CatalogueState,
      action: PayloadAction<VideoAvailability[] | null>
    ) => {
      state.videoAvailability = action.payload;
    },
    setCurrentProductPdp: (state: CatalogueState, action: PayloadAction<CurrentProductPdp>) => {
      state.currentProductPdp = action.payload;
    },
    saveVariantsForRTR: (state: CatalogueState, action: PayloadAction<RTRResponse>) => {
      state.variantsForRTR = action.payload;
    },
    saveExplosionVideo: (state: CatalogueState, action: PayloadAction<string>) => {
      state.explosionVideo = action.payload;
    },
    saveMoodboard: (state: CatalogueState, action: PayloadAction<Moodboard>) => {
      state.moodboard = action.payload;
    },
    saveSeethroughImage: (state: CatalogueState, action: PayloadAction<string>) => {
      state.seethroughImage = action.payload;
    },
    saveSeethroughWithoutImage: (state: CatalogueState, action: PayloadAction<string>) => {
      state.seethroughWithoutImage = action.payload;
    },
    saveImagesCarouselPdpStatus: (state: CatalogueState, action: PayloadAction<RequestStatus>) => {
      state.imagesCarouselPdpStatus = action.payload;
    },
    saveIsZoomPopupOpen: (state: CatalogueState, action: PayloadAction<ZoomPopup>) => {
      state.zoomPopup = action.payload;
    },
    saveAdvContents: (state: CatalogueState, action: PayloadAction<MultiMediaType[]>) => {
      state.advContents = action.payload;
    },
    saveVideoContents: (state: CatalogueState, action: PayloadAction<MultiMediaType[]>) => {
      state.videoContents = action.payload;
    },

    // SPORT
    saveSportContents: (state: CatalogueState, action: PayloadAction<SetKeylookPayload>) => {
      state.sportContents = {
        ...state.sportContents,
        [action.payload.brand]: {
          ...state.sportContents[action.payload.brand],
          [action.payload.sport]: {
            sport: action.payload.keylook?.["moodboard-sport"],
            keylook: {
              keylook: action.payload.keylook?.keylook,
              "moodboard-keylook": action.payload.keylook?.["moodboard-keylook"],
            }, //action.payload.keylook,
          },
        },
      };
    },

    // KEYLOOK

    saveKeylookContents: (state: CatalogueState, action: PayloadAction<SetKeylookPayload>) => {
      state.keylookContents = {
        ...state.keylookContents,
        [action.payload.brand]: {
          ...state.keylookContents[action.payload.brand],
          [action.payload.sport]: {
            sport: action.payload.keylook?.["moodboard-sport"],
            keylook: {
              keylook: action.payload.keylook?.keylook,
              "moodboard-keylook": action.payload.keylook?.["moodboard-keylook"],
            }, //action.payload.keylook,
          },
        },
      };
    },

    savePDPKeylookProducts: (state, action: PayloadAction<PdpProductCarouselPayload>) => {
      const { type, catalogue, prices } = action.payload;

      if (action.payload.totalProducts)
        state.PDPKeylookProducts.totalProducts = action.payload.totalProducts;
      if (action.payload.requestStatus)
        state.PDPKeylookProducts.requestStatus = action.payload.requestStatus;

      switch (type) {
        case "reset":
          state.PDPKeylookProducts = {
            products: [],
            totalProducts: 0,
            requestStatus: "IDLE",
          };

          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing

            const newCatalogue = [...state.PDPKeylookProducts.products, ...catalogue];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => product2.uniqueID === product1.uniqueID) === i
            );

            state.PDPKeylookProducts.products = removeDoubles.slice(
              0,
              state.PDPKeylookProductsMaxSlides
            );
          }
          break;

        case "prices":
          if (state.PDPKeylookProducts.products && prices)
            state.PDPKeylookProducts.products = state.PDPKeylookProducts.products.map(
              (product: Product) => {
                return addPriceToProductorVariant(product, prices) as Product;
              }
            );

          break;

        default:
          break;
      }
    },

    saveOpticianTips: (state: CatalogueState, action: PayloadAction<OpticianTipsColumn[]>) => {
      state.opticianTips = action.payload;
    },
    saveImagesCarouselPdp: (state: CatalogueState, action: PayloadAction<Attachment[]>) => {
      state.imagesCarouselPdp = action.payload;
    },
    saveView360Pdp: (state: CatalogueState, action: PayloadAction<Attachment[]>) => {
      state.view360Pdp = action.payload;
    },
    saveImagesTransitionsPdp: (state: CatalogueState, action: PayloadAction<Attachment[]>) => {
      state.imagesTransitionsPdp = action.payload;
    },
    setLoadingPdp: (
      state: CatalogueState,
      action: PayloadAction<SetMultipleRequestStatus<LoadingPdp>>
    ) => {
      state.loadingPDP = { ...state.loadingPDP, [action.payload.type]: action.payload.value };
    },
    saveSeeOnInstagramLink: (state: CatalogueState, action: PayloadAction<InstagramTooltip>) => {
      state.instagramLink = action.payload;
    },
    saveQRCode: (state: CatalogueState, action: PayloadAction<string>) => {
      state.QRCode = action.payload;
    },
    saveVTOTransitionLens: (state, action: PayloadAction<VTOTransitionLens | null>) => {
      state.VTOTransitionLens = action.payload;
    },
    savePopupMultidoorSetting: (
      state: CatalogueState,
      action: PayloadAction<PopupMultidoorSetting>
    ) => {
      state.popupMultidoorSetting = action.payload;
    },
    savePopupMultidoorAFASetting: (state, action: PayloadAction<PopupMultidoorAFASetting>) => {
      state.popupMultidoorAFASetting = action.payload;
    },
    savePDPVariantFacets: (state, action: PayloadAction<FacetView[]>) => {
      state.PDPVariantFacets = action.payload;
    },
    savePDPVariantFacetsSelected: (state: CatalogueState, action: PayloadAction<QueryParams>) => {
      state.PDPVariantFacetsSelected = action.payload;
    },
    saveCustomerReferenceAFA: (state, action: PayloadAction<string | null>) => {
      state.customerReferenceAFA = action.payload;
    },
    saveIsUpdatingQuantityAFA: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingQuantityAFA = action.payload;
    },
    setPrevProductInfo: (state: CatalogueState, action: PayloadAction<PrevProductInfo>) => {
      state.prevProductInfo = action.payload;
    },

    //////////////////// PDP LIST
    savePdpVariantsList: (state: CatalogueState, action: PayloadAction<Variant[]>) => {
      state.pdpVariantsList = action.payload;
    },

    savePDPVariantsListPrices: (
      state: CatalogueState,
      action: PayloadAction<GetPricesResult[]>
    ) => {
      state.pdpVariantsListPrices = action.payload;
    },

    savePDPVariantsInfo: (state: CatalogueState, action: PayloadAction<PDPVariantInfo[]>) => {
      action.payload.forEach((variantInfo) => {
        const existingVariantInfo = state.PDPVariantsInfo[variantInfo.uniqueID];

        if (!existingVariantInfo) state.PDPVariantsInfo[variantInfo.uniqueID] = variantInfo;
        else {
          const newDoorInfo = existingVariantInfo.doorInfo;

          variantInfo.doorInfo.forEach((doorInfo) => {
            const existingDoorInfo = existingVariantInfo.doorInfo.find(
              (_) => _.doorId === doorInfo.doorId
            );

            if (!existingDoorInfo) newDoorInfo.push(doorInfo);
            else {
              existingDoorInfo.availabilities =
                existingDoorInfo.availabilities ?? doorInfo.availabilities;
              existingDoorInfo.price = existingDoorInfo.price ?? doorInfo.price;
              existingDoorInfo.starsSkus = existingDoorInfo.starsSkus ?? doorInfo.starsSkus;
            }
          });

          state.PDPVariantsInfo[variantInfo.uniqueID] = {
            selectedDoor: variantInfo.selectedDoor,
            uniqueID: variantInfo.uniqueID,
            doorInfo: newDoorInfo,
          };
        }
      });
    },

    savePDPVariantsInfoAvailability: (
      state,
      action: PayloadAction<PDPVariantInfoAvailabilityPayload>
    ) => {
      const { variantId, doorId, ...rest } = action.payload;

      //SAVE NEW REQUESTED DATE FOR AVAIABILITY
      const currentVariantInfo = state.PDPVariantsInfo[variantId];
      const index = currentVariantInfo?.doorInfo.findIndex((_) => _.doorId === doorId);

      const currentDoorInfo = currentVariantInfo?.doorInfo[index];
      if (index !== -1) {
        const availabilities = currentDoorInfo?.availabilities;

        if (currentDoorInfo.availabilities !== undefined && availabilities) {
          const indexAvaiability = currentDoorInfo?.availabilities.findIndex(
            (_) => _.availabilityId === rest.availabilityId
          );

          if (indexAvaiability !== -1) {
            currentDoorInfo.availabilities[indexAvaiability] = rest;
          } else {
            currentDoorInfo.availabilities?.push(rest);
          }
        } else {
          currentDoorInfo.availabilities = [rest];
        }
      }

      //UPDATE REQUESTED DATE IN ITEMS IN PRECART

      state.preCart.forEach((element) => {
        if (
          element.multidoorId === doorId &&
          element.sku.parentCatalogEntryID === variantId &&
          element.availabilityId === rest.availabilityId
        )
          element.requestedShipDate = rest.requestedShipDate;
      });
    },

    savePDPVariantsInfoLoading: (state, action: PayloadAction<PDPVariantInfoLoading[]>) => {
      action.payload.forEach(
        (variant) => (state.PDPVariantsInfoLoading[variant.uniqueID] = variant.status)
      );
    },

    saveAvailabileQuantityAFAInfo: (state, action: PayloadAction<PreCartProduct[]>) => {
      action.payload.forEach((item) => {
        if (checkIsAFAOrHelmet(item.sku.productCategory)) {
          const id = item.orgentityName + item.sku.uniqueID;
          const quantityToAdd = item.quantity ?? 0;
          if (state.availabileQuantityAFAInfo[id]) {
            const quantityNumber = Number(
              state.availabileQuantityAFAInfo[id].availableQuantityAdded
            );
            state.availabileQuantityAFAInfo[id].availableQuantityAdded = (
              quantityNumber + quantityToAdd
            )?.toString();
          } else {
            state.availabileQuantityAFAInfo[id] = {
              doorId: item.orgentityName,
              skuId: item.sku.uniqueID,
              availableQuantityAdded: quantityToAdd?.toString(),
            };
          }
        }
      });
    },

    /////////////////// PLP
    savePlpVariants: (state: CatalogueState, action: PayloadAction<PlpVariants>) => {
      state.plpVariants = action.payload;
      state.expandedTileRequestStatus = "SUCCESS";
    },
    appendPlpVariants: (state: CatalogueState, action: PayloadAction<PlpVariants>) => {
      const newItems = [...state.plpVariants.items, ...action.payload.items];
      state.plpVariants = {
        items: newItems,
        recordSetCount: newItems.length,
        recordSetTotal: action.payload.recordSetTotal,
      };
      state.expandedTileRequestStatus = "SUCCESS";
    },
    resetPlpVariants: (state: CatalogueState) => {
      state.plpVariants = {
        items: [],
        recordSetCount: 0,
        recordSetTotal: 0,
      };
    },
    setCurrentCategoryPlp: (state: CatalogueState, action: PayloadAction<CurrentCategoryPlp>) => {
      state.currentCategoryPlp = action.payload;
    },
    savePlpCatalogue: (state: CatalogueState, action: PayloadAction<PlpCatalogue | null>) => {
      state.plpCatalogue = action.payload;
    },
    savePlpFacetView: (state, action: PayloadAction<FacetView[] | null>) => {
      state.plpFacetView = action.payload;
    },
    setLoadingPlp: (
      state: CatalogueState,
      action: PayloadAction<SetMultipleRequestStatus<LoadingPlp>>
    ) => {
      state.plpLoading = { ...state.plpLoading, [action.payload.type]: action.payload.value };
    },
    resetLoadingPlp: (state: CatalogueState) => {
      state.plpLoading = DEFAULT_LOADING_PLP;
    },
    setExpandedTileRequestStatus: (state: CatalogueState, action: PayloadAction<RequestStatus>) => {
      state.expandedTileRequestStatus = action.payload;
    },
    setLoadingAvailability: (state: CatalogueState, action: PayloadAction<boolean>) => {
      state.loadingAvailability = action.payload;
    },
    savePlpStarsCatalogue: (
      state: CatalogueState,
      action: PayloadAction<PlpStarsCatalogue | null>
    ) => {
      state.plpStarsCatalogue = action.payload;
    },
    savePlpStarsFacetView: (state, action: PayloadAction<FacetView[] | null>) => {
      state.plpStarsFacetView = action.payload;
    },
    savePlpStarsCatalogueStatus: (state: CatalogueState, action: PayloadAction<RequestStatus>) => {
      state.plpStarsCatalogueStatus = action.payload;
    },
    savePlpStarsStatistics: (state: CatalogueState, action: PayloadAction<FacetView[]>) => {
      state.plpStarsStatistics = action.payload;
    },
    savePlpStarsStatisticsStatus: (state: CatalogueState, action: PayloadAction<RequestStatus>) => {
      state.plpStarsStatisticsStatus = action.payload;
    },
    savePlpStarsRestOfCatalogueCMSInfo: (state, action: PayloadAction<Col>) => {
      state.plpStarsRestOfCatalogueCMSInfo = action.payload;
    },
    savePlpStarsRestOfCatalogue: (
      state,
      action: PayloadAction<SavePlpStarsRestOfCataloguePayload>
    ) => {
      const { type, catalogue, prices } = action.payload;

      switch (type) {
        case "reset":
          state.plpStarsRestOfCatalogue = null;
          state.plpStarsRestOfCatalogueStatus = "IDLE";
          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing

            const newCatalogue = [
              ...(state.plpStarsRestOfCatalogue?.resultList ?? []),
              ...catalogue.resultList,
            ];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => {
                  if (instanceOfProduct(product2) && instanceOfProduct(product1))
                    return product2.uniqueID === product1.uniqueID;
                  else return false;
                }) === i
            );

            const maxedProducts = removeDoubles.slice(0, state.plpStarsRestOfCatalogueMaxSlides);

            state.plpStarsRestOfCatalogue = {
              ...catalogue,
              resultList: [...maxedProducts],
            };
          }

          break;

        case "prices":
          if (state.plpStarsRestOfCatalogue?.resultList && prices)
            state.plpStarsRestOfCatalogue.resultList = state.plpStarsRestOfCatalogue.resultList.map(
              (product: Product | AdvTile) => {
                if (instanceOfProduct(product))
                  return addPriceToProductorVariant(product, prices) as Product;
                else return product;
              }
            );

          break;

        default:
          break;
      }
    },
    savePlpStarsRestOfCatalogueStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.plpStarsRestOfCatalogueStatus = action.payload;
    },

    resetPlpStars: (state) => {
      // state.plpStarsCatalogue = null;
      // state.plpStarsCatalogueStatus = "IDLE";
      state.plpStarsStatistics = [];
      state.plpStarsStatisticsStatus = "IDLE";
      state.plpCatalogue = null;
      // state.plpLoading = DEFAULT_LOADING_PLP;
    },
    savePlpVariantsCatalogue: (
      state: CatalogueState,
      action: PayloadAction<PlpVariantsCatalogue | null>
    ) => {
      state.plpVariantsCatalogue = action.payload;
    },
    savePlpVariantsFacetView: (
      state: CatalogueState,
      action: PayloadAction<FacetView[] | null>
    ) => {
      state.plpVariantsFacetView = action.payload;
    },

    /////////////////// PRE-PLP
    setLoadingPrePlp: (
      state: CatalogueState,
      action: PayloadAction<SetMultipleRequestStatus<LoadingPrePlp>>
    ) => {
      state.prePLPLoading = { ...state.prePLPLoading, [action.payload.type]: action.payload.value };
    },
    savePrePLPCarousels: (
      state: CatalogueState,
      action: PayloadAction<{ id: string; content: PrePlpCatalogue }>
    ) => {
      const id = action.payload.id;
      state.prePLPCarousels[id] = action.payload.content;
      state.prePLPLoading["catalogue"] = "SUCCESS";
    },
    savePrePLPStarsCarousels: (
      state: CatalogueState,
      { payload }: PayloadAction<{ id: string; content: PrePlpStarsCatalogue }>
    ) => {
      const id = payload.id;
      state.prePLPStarsCarousels[id] = payload.content;
      state.prePLPLoading = { ...state.prePLPLoading, ["starsCarousels"]: "SUCCESS" };
    },
    savePrePLPStarsCarouselsStats: (
      state,
      { payload }: PayloadAction<{ id: string; content: FacetViewMacroFamily[] }>
    ) => {
      const id = payload.id;
      state.prePLPStarsCarouselsStats[id] = payload.content;
    },
    savePrePLPBrandGroup: (state, action: PayloadAction<string | null>) => {
      state.prePLPBrandGroup = action.payload;
    },
    setIsPrePLPBrandGroupStars: (state: CatalogueState, action: PayloadAction<boolean>) => {
      state.isPrePLPBrandGroupStars = action.payload;
    },
    setIsPrePLPBrandGroupStarsStatus: (
      state: CatalogueState,
      action: PayloadAction<RequestStatus>
    ) => {
      state.isPrePLPBrandGroupStarsStatus = action.payload;
    },
    savePrePLPStarsCarouselsVisibility: (
      state: CatalogueState,
      action: PayloadAction<PrePlpStarsCatalogueVisibility | null>
    ) => {
      state.prePLPStarsCarouselsVisibility = action.payload;
    },

    /////////////////// BESTSELLERS
    setBestSeller: (state, action: PayloadAction<SetBestsellersPayload>) => {
      const { type, catalogue, prices } = action.payload;

      switch (type) {
        case "reset":
          state.bestSellers = null;
          state.bestSellersRequestStatus = "IDLE";
          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing

            const newCatalogue = [
              ...(state.bestSellers?.resultList ?? []),
              ...catalogue?.resultList,
            ];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => product2.uniqueID === product1.uniqueID) === i
            );

            const maxedProducts = removeDoubles.slice(0, state.bestSellersMaxSlides);

            state.bestSellers = {
              ...catalogue,
              resultList: [...maxedProducts],
            };
          }
          break;

        case "prices":
          if (state.bestSellers?.resultList && prices)
            state.bestSellers.resultList = state.bestSellers.resultList.map((product: Product) => {
              return addPriceToProductorVariant(product, prices) as Product;
            });

          break;

        default:
          break;
      }
    },

    setBestSellerRequestStatus: (state: CatalogueState, action: PayloadAction<RequestStatus>) => {
      state.bestSellersRequestStatus = action.payload;
    },

    ///// ALTERNATIVE PRODUCT
    saveAlternativeProductId: (state: CatalogueState, action: PayloadAction<string | null>) => {
      state.alternativeProductId = action.payload;
    },

    //// SIMILAR PRODUCT
    saveSimilarProductId: (state: CatalogueState, action: PayloadAction<string | null>) => {
      state.similarProductId = action.payload;
    },

    savePDPSimilarProducts: (state, action: PayloadAction<PdpProductCarouselPayload>) => {
      const { type, catalogue, prices } = action.payload;

      if (action.payload.totalProducts)
        state.PDPSimilarProducts.totalProducts = action.payload.totalProducts;
      if (action.payload.requestStatus)
        state.PDPSimilarProducts.requestStatus = action.payload.requestStatus;

      switch (type) {
        case "reset":
          state.PDPSimilarProducts = {
            products: [],
            totalProducts: 0,
            requestStatus: "IDLE",
          };

          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing

            const newCatalogue = [...state.PDPSimilarProducts.products, ...catalogue];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => product2.uniqueID === product1.uniqueID) === i
            );

            state.PDPSimilarProducts.products = removeDoubles.slice(
              0,
              state.PDPSimilarProductsMaxSlides
            );
          }
          break;

        case "prices":
          if (state.PDPSimilarProducts.products && prices)
            state.PDPSimilarProducts.products = state.PDPSimilarProducts.products.map(
              (product: Product) => {
                return addPriceToProductorVariant(product, prices) as Product;
              }
            );

          break;

        default:
          break;
      }
    },

    /// PRODUCT COMPATIBLE WITH ACCESSORIES
    saveProductsCompatibleProductId: (
      state: CatalogueState,
      action: PayloadAction<string | null>
    ) => {
      state.productsCompatibleProductId = action.payload;
    },

    savePDPProductsCompatible: (state, action: PayloadAction<PdpProductCarouselPayload>) => {
      const { type, catalogue, prices } = action.payload;

      if (action.payload.totalProducts)
        state.PDPProductsCompatible.totalProducts = action.payload.totalProducts;
      if (action.payload.requestStatus)
        state.PDPProductsCompatible.requestStatus = action.payload.requestStatus;

      switch (type) {
        case "reset":
          state.PDPProductsCompatible = {
            products: [],
            totalProducts: 0,
            requestStatus: "IDLE",
          };

          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing
            const newCatalogue = [...state.PDPProductsCompatible.products, ...catalogue];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => product2.uniqueID === product1.uniqueID) === i
            );

            state.PDPProductsCompatible.products = removeDoubles.slice(
              0,
              state.PDPSimilarProductsMaxSlides
            );
          }

          break;

        case "prices":
          if (state.PDPProductsCompatible.products && prices)
            state.PDPProductsCompatible.products = state.PDPProductsCompatible.products.map(
              (product: Product) => {
                return addPriceToProductorVariant(product, prices) as Product;
              }
            );

          break;

        default:
          break;
      }
    },

    /// PRODUCT RECOMMENDATION
    saveProductRecommendation: (state, action: PayloadAction<SetProductRecommendationPayload>) => {
      // state.productRecommendation = action.payload;

      const { type, catalogue, prices } = action.payload;

      switch (type) {
        case "reset":
          state.productRecommendation = null;
          state.productRecommendationStatus = "IDLE";
          break;

        case "add":
          if (catalogue) {
            // append products to old catalogue, if existing

            const newCatalogue = [
              ...(state.productRecommendation?.catalogEntryView ?? []),
              ...catalogue?.catalogEntryView,
            ];

            const removeDoubles = newCatalogue.filter(
              (product1, i, a) =>
                a.findIndex((product2) => product2.uniqueID === product1.uniqueID) === i
            );

            const maxedProducts = removeDoubles.slice(0, state.productRecommendationMaxSlides);

            state.productRecommendation = {
              ...catalogue,
              catalogEntryView: [...maxedProducts],
            };
          }
          break;

        case "prices":
          if (state.productRecommendation?.catalogEntryView && prices)
            state.productRecommendation.catalogEntryView = state.productRecommendation.catalogEntryView.map(
              (product: Variant) => {
                return addPriceToProductorVariant(product, prices) as Variant;
              }
            );

          break;

        default:
          break;
      }
    },

    saveProductRecommendationStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.productRecommendationStatus = action.payload;
    },

    saveInstagramBadges: (state, action: PayloadAction<ProductInstagramBadge[] | null>) => {
      state.instagramBadges = action.payload;
    },

    setVMMVStatus: (state, action: PayloadAction<string>) => {
      state.vmmvStatus = action.payload;
    },

    setRTRStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.RTRStatus = action.payload;
    },

    ///// DIGITAL CATALOGUE
    setUrlDigitalCatalogue: (state, action: PayloadAction<string>) => {
      state.urlDigitalCatalogue = action.payload;
    },

    saveLandingPageTechnicalContents: (state, action: PayloadAction<Record<string, string>>) => {
      state.urlsDigitalCatalogueCTA = action.payload;
    },
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  savePDPVariantFacets,
  saveExplosionVideo,
  saveSeethroughImage,
  saveSeethroughWithoutImage,
  saveMoodboard,
  saveAdvContents,
  saveImagesCarouselPdpStatus,
  saveIsZoomPopupOpen,
  saveVideoContents,
  saveOpticianTips,
  saveProductWithVariants,
  setCurrentProductPdp,
  saveVariantDetails,
  saveVariantsForRTR,
  saveVideoAvailability,
  savePlpVariants,
  setPrevProductInfo,
  appendPlpVariants,
  saveProductAvailability,
  unfollowItemsAddedToCart,
  updateProductAvailabilityStatus,
  saveBackorderProductAvailability,
  savePreCart,
  removeItemsFromPrecart,
  clearPreCart,
  resetPlpVariants,
  setLoadingPdp,
  setExpandedTileRequestStatus,
  setCurrentCategoryPlp,
  setSelectedTile,
  resetState,
  savePlpCatalogue,
  savePlpFacetView,
  setLoadingPlp,
  resetLoadingPlp,
  setLoadingPrePlp,
  savePrePLPCarousels,
  savePrePLPBrandGroup,
  setIsPrePLPBrandGroupStars,
  setIsPrePLPBrandGroupStarsStatus,
  saveImagesCarouselPdp,
  saveView360Pdp,
  saveImagesTransitionsPdp,
  setBestSeller,
  setBestSellerRequestStatus,
  saveQRCode,
  saveVTOTransitionLens,
  saveSeeOnInstagramLink,
  saveAlternativeProductId,
  savePopupMultidoorSetting,
  setLoadingAvailability,
  savePdpVariantsList,
  savePDPVariantsListPrices,
  savePDPSimilarProducts,
  savePDPProductsCompatible,
  savePDPVariantsInfo,
  savePDPVariantsInfoLoading,
  saveKeylookContents,
  saveSportContents,
  savePDPKeylookProducts,
  saveSimilarProductId,
  saveProductsCompatibleProductId,
  savePDPVariantFacetsSelected,
  saveIsUpdatingQuantityAFA,
  savePrePLPStarsCarousels,
  savePlpStarsCatalogue,
  savePlpStarsFacetView,
  savePlpStarsCatalogueStatus,
  savePrePLPStarsCarouselsVisibility,
  savePlpStarsStatistics,
  savePlpStarsStatisticsStatus,
  savePopupMultidoorAFASetting,
  saveCustomerReferenceAFA,
  savePDPVariantsInfoAvailability,
  savePreCartMassiveOrder,
  saveProductRecommendation,
  saveProductRecommendationStatus,
  saveAvailabileQuantityAFAInfo,
  savePlpStarsRestOfCatalogueCMSInfo,
  savePlpStarsRestOfCatalogue,
  savePlpStarsRestOfCatalogueStatus,
  savePrePLPStarsCarouselsStats,
  resetPlpStars,
  saveInstagramBadges,
  savePlpVariantsCatalogue,
  savePlpVariantsFacetView,
  setVMMVStatus,
  setRTRStatus,
  setUrlDigitalCatalogue,
  saveLandingPageTechnicalContents,
} = productSlice.actions;

/////////////////// COMMON

export const selectPreCart = (state: RootState): PreCartProduct[] | undefined => {
  if (state.catalogue.preCart) {
    return state.catalogue.preCart;
  }
};

export const selectTotalInPreCart = (state: RootState): number => {
  let total = 0;
  state.catalogue.preCart.map((item) => {
    if (item.quantity) total += item.quantity;
  });

  state.catalogue.preCartMassiveOrder.map((item) => {
    if (item.quantity) total += item.quantity * item.doors.length;
  });

  return total;
};

export const selectPreCartMassiveOrder = (
  state: RootState
): PreCartMassiveOrderProduct[] | undefined => {
  if (state.catalogue.preCartMassiveOrder) {
    return state.catalogue.preCartMassiveOrder;
  }
};

export const selectPageSize = (state: RootState): number => {
  return state.catalogue.productPerPage;
};

export const selectExpandedTilePageSize = (state: RootState): number => {
  return state.catalogue.variantsPerPage;
};

export const selectProductAvailability = (state: RootState): AvailabilityStatusByDoor[] => {
  return state.catalogue.productAvailability;
};

interface AvaiabilityPerSku {
  [skuId: string]: {
    doorId: string;
    status: string;
  }[];
}

export const selectAvailabilityPerSku = createSelector(
  selectProductAvailability,
  (availability: AvailabilityStatusByDoor[]): AvaiabilityPerSku => {
    const availabilityPerSku: AvaiabilityPerSku = {};

    availability.forEach((_) => {
      _.availabilityStatus.forEach((a) => {
        Object.keys(a).forEach((key) => {
          const doorAvailability = {
            doorId: _.doorId,
            status: a[key],
          };

          if (availabilityPerSku[key]) {
            availabilityPerSku[key].push(doorAvailability);
          } else {
            availabilityPerSku[key] = [doorAvailability];
          }
        });
      });
    });

    return availabilityPerSku;
  }
);

export const selectSelectedTile = (state: RootState): Product | Variant | null => {
  return state.catalogue.selectedTile;
};

export const selectBestSellers = (state: RootState): BestsellersCatalogue | null => {
  return state.catalogue.bestSellers;
};

export const selectBestSellerRequestStatus = (state: RootState): RequestStatus => {
  return state.catalogue.bestSellersRequestStatus;
};

export const selectPlpVariantsCatalogue = (state: RootState): PlpVariantsCatalogue | null => {
  return state.catalogue.plpVariantsCatalogue;
};

export const selectPlpVariantsFacetView = (state: RootState): FacetView[] | null => {
  return state.catalogue.plpVariantsFacetView;
};

/////////////////// PDP

export const selectProductWithVariants = (state: RootState): Product | null => {
  return state.catalogue.productWithVariants;
};

export const selectCurrentProductName = (state: RootState): string | undefined => {
  if (state.catalogue.productWithVariants) return state.catalogue.productWithVariants.name;
};

export const selectCurrentProduct = (state: RootState): CurrentProductPdp => {
  return state.catalogue.currentProductPdp;
};

export const selectCurrentVariant = (state: RootState): Variant | null => {
  return state.catalogue.variantDetails;
};

export const selectVideoAvailability = (state: RootState): VideoAvailability[] | null => {
  return state.catalogue.videoAvailability;
};

export const selectCurrentVariantAFA = createSelector(
  selectCurrentVariant,
  selectProductAvailability,
  selectIsMultidoor,
  (variant: Variant | null, availability: AvailabilityStatusByDoor[], isMultiDoor) => {
    return variant ? mapProductsAFA([variant], availability, isMultiDoor)?.[0] : null;
  }
);

export const selectVariantCodeNoUndescore = (state: RootState): string | undefined => {
  return state.catalogue.variantDetails?.variantCodeNoUnderscore;
};

export const selectProductCategory = (state: RootState): string | undefined => {
  return state.catalogue.variantDetails?.productCategory;
};

export const selectImagesCarouselPdpStatus = (state: RootState): RequestStatus | undefined => {
  return state.catalogue.imagesCarouselPdpStatus;
};

export const selectIsZoomPopupOpen = (state: RootState): ZoomPopup => {
  return state.catalogue.zoomPopup;
};

export const selectPopupMultidoorSetting = (state: RootState): PopupMultidoorSetting => {
  return state.catalogue.popupMultidoorSetting;
};

export const selectPopupMultidoorAFASetting = (state: RootState): PopupMultidoorAFASetting => {
  return state.catalogue.popupMultidoorAFASetting;
};

export const selectPdpVariantsList = (state: RootState): Variant[] | null => {
  return state.catalogue.pdpVariantsList;
};

export const selectPdpAFAVariantsList = createSelector(
  selectPdpVariantsList,
  selectProductAvailability,
  selectIsMultidoor,
  (pdpVariantsList: Variant[] | null, availability: AvailabilityStatusByDoor[], isMultiDoor) => {
    return mapProductsAFA(pdpVariantsList, availability, isMultiDoor);
  }
);

export const selectVariantSkus = createSelector(
  selectCurrentVariant,
  (variantDetails: Variant | null): string[] => {
    if (!checkIsAFAOrHelmet(variantDetails?.productCategory)) {
      const tempArray = variantDetails?.skus ?? [];
      return tempArray.map((elem) => elem.upc ?? "");
    }

    return [];
  }
);

export const selectVariantSkusComplete = createSelector(
  selectCurrentVariant,
  (variantDetails: Variant | null): Sku[] => {
    if (!checkIsAFAOrHelmet(variantDetails?.productCategory)) {
      return variantDetails?.skus ?? [];
    }

    return [];
  }
);

export const selectVariantInfo = createSelector(selectCurrentVariant, (variant) => {
  return {
    variantCodeNoUnderscore: variant ? variant.variantCodeNoUnderscore : "",
    variantCode: variant?.variantCode,
    environment:
      variant && variant.attributes
        ? getAttribute(variant.attributes, "LENS_COLOR_DESCRIPTION")
        : null,
    uniqueID: variant?.uniqueID,
    productCategory: variant?.productCategory,
    seo: variant?.seo?.href,
    CLEN: variant && variant.attributes ? getAttribute(variant.attributes, "CLEN") : null,
  };
});

export const selectProductInfo = createSelector(selectProductWithVariants, (product) => {
  return {
    productCode: product ? product.productCode : "",
    brand: product ? product.brand : "",
    uniqueID: product ? product.uniqueID : "",
    seo: product ? product.seo?.href : "",
  };
});

export const selectImagesCarouselPdp = (state: RootState): Attachment[] => {
  return state.catalogue.imagesCarouselPdp;
};

export const selectView360Pdp = (state: RootState): Attachment[] => {
  return state.catalogue.view360Pdp;
};

export const selectImagesTransitionsPdp = (state: RootState): Attachment[] => {
  return state.catalogue.imagesTransitionsPdp;
};

export const selectExplosionVideo = (state: RootState): string | undefined => {
  return state.catalogue.explosionVideo;
};

export const selectSeethroughImage = (state: RootState): string | undefined => {
  return state.catalogue.seethroughImage;
};

export const selectSeethroughWithoutImage = (state: RootState): string | undefined => {
  return state.catalogue.seethroughWithoutImage;
};

export const selectMoodboard = (state: RootState): Moodboard | null | undefined => {
  return state.catalogue.moodboard;
};

export const selectAdvContents = (state: RootState): MultiMediaType[] => {
  return state.catalogue.advContents;
};

export const selectVideoContents = (state: RootState): MultiMediaType[] => {
  return state.catalogue.videoContents;
};

export const selectOpticianTips = (state: RootState): OpticianTipsColumn[] => {
  return state.catalogue.opticianTips;
};

export const selectLoadingPDP = (state: RootState): MultipleRequestStatus<LoadingPdp> => {
  return state.catalogue.loadingPDP;
};

export const selectQRCode = (state: RootState): string => {
  return state.catalogue.QRCode;
};

export const selectVTOTransitionLens = (state: RootState): VTOTransitionLens | null => {
  return state.catalogue.VTOTransitionLens;
};

export const selectInstagramLink = (state: RootState): InstagramTooltip | null => {
  return state.catalogue.instagramLink;
};

export const selectLoadingAvailability = (state: RootState): boolean => {
  return state.catalogue.loadingAvailability;
};

export const selectPDPVariantFacets = (state: RootState): FacetView[] => {
  return state.catalogue.PDPVariantFacets;
};

export const selectPDPVariantFacetsSelected = (state: RootState): QueryParams => {
  return state.catalogue.PDPVariantFacetsSelected;
};

export const selectCustomerReferenceAFA = (state: RootState): string | null => {
  return state.catalogue.customerReferenceAFA;
};

export const selectIsUpdatingQuantityAFA = (state: RootState): boolean => {
  return state.catalogue.isUpdatingQuantityAFA;
};

export const selectAvailabileQuantityAFAInfo = (state: RootState): AvailabileQuantityAFAInfoObj => {
  return state.catalogue.availabileQuantityAFAInfo;
};
export const selectPrevProductInfo = (state: RootState): PrevProductInfo => {
  return state.catalogue.prevProductInfo;
};

/////////////////// PDP

export const selectMaxTilesInPDPList = (state: RootState): number => {
  return state.catalogue.maxTilesInPDPList;
};

export const selectPDPVariantsListPrices = (state: RootState): GetPricesResult[] => {
  return state.catalogue.pdpVariantsListPrices;
};

export const selectPDPVariantsInfo = (state: RootState): PDPVariantsInfo => {
  return state.catalogue.PDPVariantsInfo;
};

export const selectPDPVariantsInfoLoading = (
  state: RootState
): { [key: string]: RequestStatus } => {
  return state.catalogue.PDPVariantsInfoLoading;
};

/////////////////// PLP

export const selectPlpCatalogue = (state: RootState): PlpCatalogue | null =>
  state.catalogue.plpCatalogue;

export const selectFacetViewPlp = (state: RootState): FacetView[] | null => {
  return state.catalogue.plpFacetView;
};

export const selectLoadingPlp = (state: RootState): MultipleRequestStatus<LoadingPlp> => {
  return state.catalogue.plpLoading;
};

export const selectPlpVariants = (state: RootState): PlpVariants => {
  return state.catalogue.plpVariants;
};

export const selectAllProductsPlp = (state: RootState): number => {
  return state.catalogue.plpCatalogue?.totalProducts
    ? state.catalogue.plpCatalogue?.totalProducts
    : 0;
};

export const selectTotalPagesPlp = (state: RootState): number => {
  const resultTotal: number = state.catalogue.plpCatalogue?.resultsTotal
    ? state.catalogue.plpCatalogue?.resultsTotal
    : 0;
  return Math.ceil(resultTotal / state.catalogue.productPerPage);
};

export const selectExpandedTileRequestStatus = (state: RootState): RequestStatus => {
  return state.catalogue.expandedTileRequestStatus;
};

export const selectCurrentCategory = (state: RootState): CurrentCategoryPlp | null => {
  return state.catalogue.currentCategoryPlp;
};

export const selectPlpStarsCatalogue = (state: RootState): PlpStarsCatalogue | null =>
  state.catalogue.plpStarsCatalogue;

export const selectPlpStarsFacetView = (state: RootState): PlpStarsCatalogueFacets | null => {
  const facetView = state.catalogue.plpStarsFacetView;
  if (facetView) {
    const [
      defaultFilters,
      macrofamilyFilters,
      starsNewFilters,
    ] = splitMacroFamilyAndStarsNewFilters(facetView);

    const orderedMacroFamiliesFilter = sortMacroFamilies(macrofamilyFilters);

    //remove brand filter
    const newDefaultFilters = defaultFilters.filter(
      (_) =>
        !isBrandFilter(_.extendedData.propertyvalue) &&
        _.extendedData.propertyvalue !== "PRODUCT_CATEGORY_FILTER"
      // &&
    );

    //remove current assorment only or all the filter is it doesnt have last assorment
    const newNewDefaultFilters = cloneDeep(newDefaultFilters);
    const index = newDefaultFilters.findIndex(
      (_) => _.extendedData.propertyvalue === "STARS_ASSORTMENT"
    );

    const entry: FacetViewEntry[] = [];
    newDefaultFilters[index]?.entry?.findIndex((_) => {
      if (_.identifier === "Latest introduction") {
        entry.push(_);
      }
    });

    if (entry.length > 0) {
      newNewDefaultFilters[index].entry = entry;
    } else {
      newNewDefaultFilters.splice(index, 1);
    }

    return {
      defaultFilters: newNewDefaultFilters,
      macrofamilyFilters: orderedMacroFamiliesFilter,
      starsNewFilters,
    };
  }
  return null;
};

export const selectTotalPagesPlpStars = (state: RootState): number => {
  const resultTotal: number = state.catalogue.plpStarsCatalogue?.recordSetTotal ?? 0;
  return Math.ceil(resultTotal / state.catalogue.productPerPage);
};

export const selectPlpStarsCatalogueStatus = (state: RootState): RequestStatus =>
  state.catalogue.plpStarsCatalogueStatus;

export const selectPlpStarsStatistics = (state: RootState): FacetView[] =>
  state.catalogue.plpStarsStatistics;

export const selectPlpStarsStatisticsStatus = (state: RootState): RequestStatus =>
  state.catalogue.plpStarsStatisticsStatus;

export const selectPlpStarsRestOfCatalogueCMSInfo = (state: RootState): Col | null =>
  state.catalogue.plpStarsRestOfCatalogueCMSInfo;

export const selectPlpStarsRestOfCatalogue = (state: RootState): PlpCatalogue | null =>
  state.catalogue.plpStarsRestOfCatalogue;

export const selectPlpStarsRestOfCatalogueStatus = (state: RootState): RequestStatus =>
  state.catalogue.plpStarsRestOfCatalogueStatus;

export const selectPlpStarsRestOfCatalogueMaxSlides = (state: RootState): number =>
  state.catalogue.plpStarsRestOfCatalogueMaxSlides;

/////////////////// PRE-PLP
export const selectLoadingPrePlp = (state: RootState): MultipleRequestStatus<LoadingPrePlp> => {
  return state.catalogue.prePLPLoading;
};

export const selectPrePLPCarousels = (state: RootState): PrePLPCarousels => {
  return state.catalogue.prePLPCarousels;
};

export const selectPrePLPBrandGroup = (state: RootState): string | null => {
  return state.catalogue.prePLPBrandGroup;
};

export const selectProductsPerCarousel = (state: RootState): string => {
  return state.catalogue.productsPerCarousel;
};

export const selectIsPrePLPBrandGroupStars = (state: RootState): boolean => {
  return (
    state.catalogue.isPrePLPBrandGroupStarsStatus === "SUCCESS" &&
    state.catalogue.isPrePLPBrandGroupStars
  );
};

export const selectIsPrePLPBrandGroupStarsStatus = (state: RootState): RequestStatus => {
  return state.catalogue.isPrePLPBrandGroupStarsStatus;
};

export const selectPrePLPStarsCarousels = (state: RootState): PrePLPStarsCarousels => {
  return state.catalogue.prePLPStarsCarousels;
};

export const selectPrePLPStarsCarouselsVisibility = (
  state: RootState
): PrePlpStarsCatalogueVisibility | null => {
  return state.catalogue.prePLPStarsCarouselsVisibility;
};

export const selectPrePLPStarsCarouselsStats = (state: RootState): PrePLPStarsStatsPerCat => {
  return state.catalogue.prePLPStarsCarouselsStats;
};

///// ALTERNATIVE PRODUCT
export const selectAlternativeProductId = (state: RootState): string | null => {
  return state.catalogue.alternativeProductId;
};

//// SPORT
export const selectSportContents = (state: RootState): KeylookObj => {
  return state.catalogue.sportContents;
};

//// KEYLOOK

export const selectKeylookContents = (state: RootState): KeylookObj => {
  return state.catalogue.keylookContents;
};

export const selectPDPKeylookProducts = (state: RootState): PdpProductCarousel => {
  return state.catalogue.PDPKeylookProducts;
};

export const selectPDPKeylookProductsMaxSlides = (state: RootState): number => {
  return state.catalogue.PDPKeylookProductsMaxSlides;
};

///// SIMILAR PRODUCT
export const selectSimilarProductId = (state: RootState): string | null => {
  return state.catalogue.similarProductId;
};

export const selectPDPSimilarProducts = (state: RootState): PdpProductCarousel => {
  return state.catalogue.PDPSimilarProducts;
};

export const selectPDPSimilarProductsMaxSlides = (state: RootState): number => {
  return state.catalogue.PDPSimilarProductsMaxSlides;
};

///// PRODUCT COMPATIBLE WITH ACCESSORIES
export const selectProductsCompatibleProductId = (state: RootState): string | null => {
  return state.catalogue.productsCompatibleProductId;
};

export const selectPDPProductsCompatible = (state: RootState): PdpProductCarousel => {
  return state.catalogue.PDPProductsCompatible;
};

export const selectPDPProductsCompatibleMaxSlides = (state: RootState): number => {
  return state.catalogue.PDPProductsCompatibleMaxSlides;
};

///// PRIVILEGES
export const selectHasPricePrivilege = createSelector(
  selectActiveDoor,
  selectUsersPrivileges,
  (activeDoor, usersPrivileges) => {
    const users = usersPrivileges.filter(
      (user) => user.orgentityName === activeDoor?.orgentityName
    );

    if (users.length > 0) {
      return users?.[0]?.privileges.some((userPriv: string) => {
        return pricePrivileges.includes(userPriv);
      });
    } else {
      return false;
    }
  }
);

export const selectMultidoorHasPricePrivilege = createSelector(
  selectAtLeastOneTruePrivileges,
  (atLeastOneTrue) => {
    const hasPricePrivilege =
      atLeastOneTrue.filter(
        (priv) => priv === "SUGGESTED_RETAIL_PRICE" || priv === "WHOLESALE_PRICE"
      ).length > 0;
    return hasPricePrivilege;
  }
);

///// PRODUCT RECOMMENDATION
export const selectProductRecommendation = (
  state: RootState
): ProductRecommendationCatalogue | null => {
  return state.catalogue.productRecommendation;
};

export const selectProductRecommendationStatus = (state: RootState): RequestStatus => {
  return state.catalogue.productRecommendationStatus;
};

export const selectInstagramBadges = (state: RootState): ProductInstagramBadge[] | null => {
  return state.catalogue.instagramBadges;
};

export const selectVMMVStatus = (state: RootState): string => {
  return state.catalogue.vmmvStatus;
};

export const selectRTRStatus = (state: RootState): string => {
  return state.catalogue.RTRStatus;
};
export const selectVariantsForRTR = (state: RootState): RTRResponse => {
  return state.catalogue.variantsForRTR;
};

///// DIGITAL CATALOGUE
export const selectUrlDigitalCatalogue = (state: RootState): string => {
  return state.catalogue.urlDigitalCatalogue;
};

export const selectUrlsDigitalCatalogueCTA = (state: RootState): Record<string, string> => {
  return state.catalogue.urlsDigitalCatalogueCTA;
};

export default productSlice.reducer;
