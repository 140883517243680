import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { CategoryColumnInfo } from "../../../../../../hooks/useMenuColumnsInfo";
import { resetScrollPosition } from "../../../../../../hooks/useSaveScrollPosition";
import useTranslation from "../../../../../../hooks/useTranslation";
import { getPagePath } from "../../../../../../routing/routesUtils";
import { externalReferenceNewTag } from "../../../../../../utils/cmsUtils";
import { composeUrlWithParams } from "../../../../../../utils/utils";
import CustomText from "../../../../../styled-UI/CustomText";
import LinkButton from "../../../../../styled-UI/LinkButton";
import NewTag from "../../../../../widgets/tutorial-pills/new-tag/NewTag";
import { MenuColumn as MenuColumnInterface } from "./menuStructure";

interface Content {
  label: string;
  content: JSX.Element;
}
interface Props {
  baseUrl: string;
  columnStructure: MenuColumnInterface;
  identifier: string;
  toggleMenu: (show: boolean) => void;
  setToggleMenu: (identifier: string) => void;
  nameCategory?: string;
  categoryColumns: CategoryColumnInfo[];
  title: string;
  onScrollFadeStateChange?: (hasTopFade: boolean, hasBottomFade: boolean) => void;
}

const MenuColumn = ({
  baseUrl,
  columnStructure,
  identifier,
  toggleMenu,
  setToggleMenu,
  nameCategory,
  categoryColumns,
  title,
  onScrollFadeStateChange,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();
  const [menuContent, setMenuContent] = useState<Content[]>([]);
  const history = useHistory();
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (!scrollableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
    const hasTopFade = scrollTop > 0;
    const hasBottomFade = scrollTop + clientHeight + 1 < scrollHeight;

    // Notify the parent about whether we need top/bottom fades
    onScrollFadeStateChange?.(hasTopFade, hasBottomFade);
  };

  useEffect(() => {
    const el = scrollableRef.current;
    if (!el) return;
    el.addEventListener("scroll", handleScroll, { passive: true });
    // Check immediately
    handleScroll();

    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const categoryItemTemp: Content[] = [];

    //get column content to print by its info
    categoryColumns?.forEach((info) => {
      categoryItemTemp.push({
        label: info.label,
        content: (
          <StyledLink
            key={info.keyColumn}
            onClick={() => {
              toggleMenu(false);
              setToggleMenu("");
            }}
          >
            <Link
              onClick={() => resetScrollPosition()}
              to={getPagePath(info.link)}
              data-element-id={info.dataElementId}
            >
              <CustomText fontSizePx={14} as="span" font="font-regular" lineHeightPx={18}>
                {info.label}
              </CustomText>
            </Link>
          </StyledLink>
        ),
      });
    });

    setMenuContent(categoryItemTemp);
  }, [categoryColumns]);

  /* PRINT VIEW ALL BUTTON */
  const printViewAll = () => {
    const filters: { key: string; value: string }[] = [];

    /*  ADD FILTERS IF PRESENT IN COLUMN  */
    if (columnStructure.facet?.facetName && columnStructure.facet?.value) {
      filters.push({
        key: columnStructure.facet?.facetName as string,
        value: columnStructure.facet?.value as string,
      });
    }

    /*  PRINT VIEW ALL BUTTON FOR EACH COLUMN */
    return (
      <ViewAllContainer>
        <LinkButton
          uppercase
          underline={false}
          onClick={() => {
            toggleMenu(false);
            setToggleMenu("");
            resetScrollPosition();
            history.push(getPagePath(composeUrlWithParams(baseUrl, filters)));
          }}
          data-element-id={`MainNav_Products_${identifier}_${title}_ViewAll`}
        >
          {translateLabel("VIEW_ALL")}
        </LinkButton>
      </ViewAllContainer>
    );
  };

  /* RENDER */
  return (
    <div>
      <UrlList>
        {title !== "MENU_CATEGORY" && (
          <HeaderListContainer noHover={true}>
            <CustomText
              fontSizePx={18}
              as="span"
              data-element-id={`MainNav_Products_${identifier}_${title}`}
            >
              {translateLabel(title)}
            </CustomText>
            <NewTag position={externalReferenceNewTag[`${identifier}.${title}`]}></NewTag>
          </HeaderListContainer>
        )}
        <ListItemsContainer ref={scrollableRef}>
          {menuContent.map((_) => {
            return (
              <React.Fragment key={identifier + _.label + nameCategory + title}>
                {<div key={identifier + _.label + nameCategory + title}>{_.content}</div>}
              </React.Fragment>
            );
          })}
        </ListItemsContainer>
      </UrlList>
      {columnStructure.viewAllButton && printViewAll()}
    </div>
  );
};
export const HeaderListContainer = styled.li<{ noHover?: boolean }>`
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  svg {
    object-fit: contain;
    width: 1rem;
  }
  div {
    margin: unset;
    padding: unset;
  }

  &.is-selected span {
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary};
      font-weight: 700;
    }
  }

  & > span{
    border-bottom: 1px solid transparent;
    &:hover {
      ${(props) =>
        props.noHover
          ? `cursor: default;`
          : `border-bottom: 1px solid ${props.theme.palette.primary};
      font-weight: 700;`}
    } 
  }
`;

const UrlList = styled.ul`
  display: grid;
  flex-direction: column;
  row-gap: 1rem;
  li {
    max-width: 14rem;
  }
`;

const ViewAllContainer = styled.div`
  display: flex;
  justify-content: left;
  padding: 2rem 0 0;
  button {
    border-bottom: 1px solid ${(props) => props.theme.palette.primary};
  }
`;

const ListItemsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;

  div span {
    max-width: 33vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    &:hover {
      font-weight: bold;
    }
  }
`;

const StyledLink = styled.li`
  span {
    display: inline;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: ${(props) => "1px solid " + props.theme.palette.primary};
      font-weight: 700;
    }
  }
`;

export default MenuColumn;
