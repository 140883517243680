import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import Loader from "./Loader";
import CustomText from "./CustomText";
import {
  selectSectionsAlertsStatus,
  selectSectionAlerts,
} from "../../store/accounting/accountingSlice";

interface Props {
  ids: number[];
  textType?: "span" | "p";
  backgroundColor?: string;
}

const BoxCounter = ({ ids, textType = "span", backgroundColor }: Props): JSX.Element => {
  const countStatus = useSelector(selectSectionsAlertsStatus);
  const notificationCounts = useSelector(selectSectionAlerts);
  const [countToShow, setCountToShow] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (notificationCounts && ids?.length) {
      //check if service has count > 0 to show
      //some item may contains more than one id so use accumulator to find count and add it to acc in notificationCounts that contains every single container for every id
      const count: number = ids.reduce(
        (acc, service) => (acc = acc + (notificationCounts?.[service] ?? 0)),
        0
      );
      const countShow = count ? (count > 99 ? "+99" : count.toString()) : undefined;
      setCountToShow(countShow);
    }
  }, [ids, notificationCounts]);

  return (
    <>
      {countStatus === "LOADING" ? (
        <BoxLoader>
          <Loader sizePx={16} />
        </BoxLoader>
      ) : (
        countToShow &&
        countStatus === "SUCCESS" &&
        notificationCounts && (
          <BoxCount backgroundColor={backgroundColor}>
            <CustomText as={textType} fontSizePx={11} font="font-regular" color="white">
              {countToShow}
            </CustomText>
          </BoxCount>
        )
      )}
    </>
  );
};

const BoxLoader = styled.div`
  margin-right: 10px;
`;

export const BoxCount = styled.div<{ backgroundColor?: string }>`
  border-radius: 1.125rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.palette.primary};
  width: 1.875rem;
  height: 1.125rem;
  display: flex;
  justify-content: center;
`;

export default BoxCounter;
