import { AxiosResponse } from "axios";

import { createAndExecuteService } from "../serviceUtils";
import {
  GetCarnetsPayload,
  GetCarnetsMovementsPayload,
  GetInvoicesPayload,
  PrepareMassiveInvoiceDownloadPayload,
  ResetPinPayload,
  UpdatePinPayload,
  ValidatePinPayload,
  NotificationCountByServicesIdsPayload,
} from "./accountingInterfaces";
import {
  creditDetailsUrl,
  creditSummaryUrl,
  downloadInvoiceUrl,
  getInvoicesUrl,
  massiveInvoiceDownloadUrl,
  optionsInvoiceUrl,
  lensCarnetUrl,
  prepareMassiveInvoiceDownloadUrl,
  resetPinUrl,
  updatePinUrl,
  validatePinUrl,
  lensCarnetMovementsUrl,
  filtersCarnetUrl,
  sectionsAlertsUrl,
  notificationsAlertsUrl,
} from "./accountingUrls";

export default {
  /////////////////// pin management
  validatePin: async (payload: ValidatePinPayload): Promise<AxiosResponse> => {
    const pinType = payload.type === "lensCarnet" ? "carnetPin" : "pin";
    const url = validatePinUrl.replace("{pinType}", pinType);
    return createAndExecuteService(url, "POST", null, payload);
  },
  updatePin: async (payload: UpdatePinPayload): Promise<AxiosResponse> => {
    const pinType = payload.type === "lensCarnet" ? "carnetPin/update" : "pin";
    const url = updatePinUrl.replace("{pinType}", pinType);
    return createAndExecuteService(url, "PUT", null, payload);
  },
  resetPin: async (payload: ResetPinPayload): Promise<AxiosResponse> => {
    const pinType = payload.type === "lensCarnet" ? "carnetPin" : "pin";
    const url = resetPinUrl.replace("{pinType}", pinType);
    return createAndExecuteService(url, "POST", null, payload.pin);
  },

  /////////////////// invoices
  getInvoices: async (payload: GetInvoicesPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload.filters).forEach(([key, value]) => {
      if (key === "division" || key === "issuerCompany") {
        //Division and issuerCompany are passed as a concatenation of strings divided by ","
        value.split(",").forEach((_: string) => searchParams.append(key, _));
      } else {
        searchParams.append(key, value);
      }
    });

    searchParams.append("pageSize", String(payload.pageSize));

    return createAndExecuteService(getInvoicesUrl, "GET", searchParams);
  },

  downloadInvoice: async (payload: string): Promise<AxiosResponse> => {
    const url = downloadInvoiceUrl.replace("{invoiceIdentifier}", payload);
    return createAndExecuteService(url, "GET");
  },

  optionsInvoice: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(optionsInvoiceUrl, "GET");
  },

  filtersCarnet: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(filtersCarnetUrl, "GET");
  },

  lensCarnet: async (payload: GetCarnetsPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => searchParams.append(key, value));
    return createAndExecuteService(lensCarnetUrl, "GET", searchParams);
  },

  lensCarnetMovements: async (payload: GetCarnetsMovementsPayload): Promise<AxiosResponse> => {
    const searchParams = new URLSearchParams();
    Object.entries(payload).forEach(([key, value]) => searchParams.append(key, value));
    const url = lensCarnetMovementsUrl.replace("{identifier}", payload.identifier);
    searchParams.delete("identifier");
    return createAndExecuteService(url, "GET", searchParams);
  },

  prepareMassiveInvoiceDownload: async (
    payload: PrepareMassiveInvoiceDownloadPayload
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(prepareMassiveInvoiceDownloadUrl, "POST", undefined, payload);
  },

  massiveInvoiceDownload: async (payload: string): Promise<AxiosResponse> => {
    const url = massiveInvoiceDownloadUrl.replace("{invoiceRequestId}", payload);
    return createAndExecuteService(url, "GET");
  },

  /////////////////// credit
  getCreditSummary: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(creditSummaryUrl, "GET");
  },
  getCreditDetails: async (id: string): Promise<AxiosResponse> => {
    const url = creditDetailsUrl.replace("{branch}", id);
    return createAndExecuteService(url, "GET");
  },

  /////////////////// notifications
  receiveSectionsAlerts: async (
    payload: NotificationCountByServicesIdsPayload | null
  ): Promise<AxiosResponse> => {
    return createAndExecuteService(sectionsAlertsUrl, "POST", null, payload);
  },

  getNotificationsAlerts: async (): Promise<AxiosResponse> => {
    return createAndExecuteService(notificationsAlertsUrl, "GET");
  },
};
