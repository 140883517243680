import { NavbarSection } from "../../../../pages/my-account/account-navbar/navbarSections";

// DO NOT CHANGE IDENTIFIERS, AS THEY ARE USED IN MOST USED TO REVERSE-ENGINEER WHICH MENU ELEMENT WE HAVE SAVED

export const serviceMenu: NavbarSection[] = [
  /*
  {
    sectionTitle: "SALES_SECTION",
    links: [
      {
        url: "/rx-prescription",
        title: "RX_PRESCRIPTION_TITLE",
        identifier: "RX_PRESCRIPTION",
        showInMostUsed: true,
        dataElementId: "RxPrescription",
        privilege: "RX",
        routes: [],
      },
    ],
  },*/
  {
    sectionTitle: "PROGRAMS_AND_REWARDS_SECTION",
    links: [
      {
        url: "",
        title: "360_MEMBERS_TITLE",
        identifier: "360_MEMBERS",
        showInMostUsed: false,
        dataElementId: "360Members",
        privilege: "EL_360",
        routes: [],
        exclusivePrivilege: true,
      },
      {
        url: "",
        title: "REWARDS_TITLE",
        identifier: "REWARDS",
        showInMostUsed: false,
        dataElementId: "Rewards",
        privilege: "REWARDS",
        routes: [],
        exclusivePrivilege: true,
      },
    ],
  },
  {
    sectionTitle: "AFTERSALES_SECTION",
    links: [
      {
        url: "/spare-parts",
        title: "SPAREPARTS_TITLE",
        identifier: "SPAREPARTS_CATALOGUE",
        showInMostUsed: true,
        dataElementId: "Spareparts",
        privilege: "SPARE_PARTS_SECTION",
        routes: [],
        externalReference: "spare-parts",
      },
      {
        url: "/warranty",
        title: "WARRANTY_TITLE",
        identifier: "WARRANTY_CATALOGUE",
        showInMostUsed: true,
        dataElementId: "Warranty",
        privilege: "AFTER_SALES_REQUEST",
        routes: [],
        externalReference: "warranty",
      },
    ],
  },
  {
    sectionTitle: "SERVICES_SECTION",
    links: [
      {
        url: "/pos-source-file",
        title: "POS_SOURCE_FILE_TITLE",
        identifier: "POS_SOURCE_FILE",
        showInMostUsed: true,
        dataElementId: "Pos source file",
        privilege: "DAT_CATALOGUE_DOCUMENT",
        routes: [],
        externalReference: "suggested-retail-price",
      },
      {
        url: "/transport-documents",
        title: "TRANSPORT_DOCUMENTS_TITLE",
        identifier: "TRANSPORT_DOCUMENTS",
        showInMostUsed: true,
        dataElementId: "TransportDocuments",
        privilege: "SHIP_DOCUMENTS",
        routes: [],
        externalReference: "shipping-documents",
      },
      {
        url: "/item-master-data",
        title: "ITEMS_MASTER_DATA_TITLE",
        identifier: "ITEMS_MASTER_DATA",
        showInMostUsed: true,
        dataElementId: "ItemMasterData",
        privilege: "ITEM_MASTER_DATA_DOCUMENT",
        routes: [],
        externalReference: "item-master-data",
      },
      {
        url: "/price-list",
        title: "PRICE_LIST_TITLE",
        identifier: "PRICE_LIST",
        showInMostUsed: true,
        dataElementId: "PriceList",
        privilege: "SUGGESTED_RETAIL_PRICE",
        routes: [],
        externalReference: "price-list",
      },
    ],
  },
  {
    sectionTitle: "DIGITAL_APPLICATION_SECTION",
    links: [
      {
        url: "/smart-shopper",
        title: "SMART_SHOPPER_TITLE",
        identifier: "SMART_SHOPPER",
        showInMostUsed: true,
        dataElementId: "SmartShopper",
        privilege: "M4C",
        routes: [],
        externalReference: "smart-shopper",
      },
      {
        url: "/digital-screen",
        title: "DIGITAL_SCREEN_TITLE",
        identifier: "DIGITAL_SCREEN",
        showInMostUsed: true,
        dataElementId: "DigitalScreen",
        privilege: "DIGITAL_WINDOW",
        routes: [],
        externalReference: "digital-screen",
      },
      {
        url: "",
        title: "LENS_SIMULATOR_TITLE",
        identifier: "LENS_SIMULATOR",
        showInMostUsed: true,
        showAsDefaultInMostUsed: false,
        dataElementId: "LensSimulator",
        privilege: "LENS_SIMULATOR",
        routes: [],
        exclusivePrivilege: true,
        externalReference: "lens-simulator",
      },
      {
        url: "",
        title: "DIGITAL_DISCOUNT_VOUCHER_TITLE",
        identifier: "DIGITAL_DISCOUNT_VOUCHER",
        showInMostUsed: false,
        dataElementId: "DigitalDiscounts",
        privilege: "DIGITAL_DISCOUNT_VOUCHER",
        routes: [],
        exclusivePrivilege: true,
      },
    ],
  },
  {
    sectionTitle: "ASSET_DOWNLOAD_SECTION",
    links: [
      {
        url: "/asset-downloads",
        title: "ADT_LANDING_PAGE",
        identifier: "ADT_LANDING_PAGE",
        showInMostUsed: true,
        dataElementId: "ExploreAssets",
        privilege: "ADT_CATEGORY",
        routes: [],
        exclusivePrivilege: true,
        externalReference: "explore-digital-assets",
      },
      {
        url: "/asset-downloads/product-images",
        title: "ADT_PRODUCT_IMAGES",
        identifier: "ADT_PRODUCT_IMAGES",
        showInMostUsed: true,
        dataElementId: "ProductImages",
        privilege: "ADT_PRODUCT_IMAGE",
        routes: [],
        exclusivePrivilege: true,
        externalReference: "product-images",
      },
      {
        url: "",
        title: "LENSES_TRADE_MARKETING_TITLE",
        identifier: "LENSES_TRADE_MARKETING",
        showInMostUsed: false,
        showAsDefaultInMostUsed: false,
        dataElementId: "LensesTradeMarketing",
        privilege: "LENS_TRADE_LINK",
        routes: [],
        exclusivePrivilege: true,
      },
    ],
  },
];
