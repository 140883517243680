import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { md5 } from "md5js";
import { Product, Sku, Variant } from "../../interfaces/productInterface";
import { RootState } from "../storeConfig";

import {
  doesOrderHaveCarnets,
  getFilters,
  getProductsDataFromOrder,
  getProductsDataFromPdp,
  getProductsDataFromProduct,
  getProductsDataFromSku,
  getProductsDataFromVariant,
  getSizes,
} from "./analyticsUtility";

import { CartCountQuantity, OrderMultidoor } from "../cart/cartInterfaces";
import {
  AvailabilityStatusByDoor,
  PDPVariantsInfo,
  PlpCatalogue,
  PlpStarsCatalogue,
  PlpVariants,
  PrePLPCarousels,
} from "../catalogue/catalogueInterface";
import { MetaData } from "../search/searchInterfaces";
import { CurrencyFormat, Door } from "../user/userInterfaces";
import {
  AnalyticsState,
  GenericProductData,
  OrderData,
  PageData,
  PdpData,
  PlpCatalogueData,
  SearchData,
  UserData,
  SizesData,
} from "./analyticsInterfaces";

import { selectSelectedLens } from "../../store/rx/rxSlice";
import { selectSparePartsPdpVariant } from "../aftersales/aftersalesSlice";
import {
  selectCartTotalPrice,
  selectCountQuantity,
  selectOrderMultidoorList,
} from "../cart/cartSlice";
import {
  selectCurrentVariant,
  selectPDPVariantsInfo,
  selectPdpVariantsList,
  selectPlpCatalogue,
  selectPlpStarsCatalogue,
  selectPlpVariants,
  selectPrePLPCarousels,
  selectProductAvailability,
  selectProductWithVariants,
} from "../catalogue/catalogueSlice";
import { selectCheckoutOrderMultidoor, selectCheckoutTotalPrice } from "../checkout/checkoutSlice";
import {
  selectIsFromSuggestion,
  selectMetaData,
  selectProducts,
  selectSearchedTerm,
  selectTotalProducts,
} from "../search/searchSlice";
import { DigitalWindowProductsResponse } from "../services/servicesInterface";
import { selectDigitalWindowProducts } from "../services/servicesSlice";
import { selectCurrentLang } from "../store/storeSlice";
import {
  selectActiveDoor,
  selectCurrencyFormat,
  selectIsBackOfficeUser,
  selectIsLogged,
  selectSelectedDoor,
  selectSession,
  selectStoreIdentifier,
  selectUserHasStarsUx,
  selectUserName,
} from "../user/userSlice";
import {
  selectWarrantyWizardOptionsStepDamageInfo,
  selectWarrantyWizardProductDetails,
} from "../warranty-wizard/warrantyWizardSlice";
import { Lens } from "../rx/rxInterface";
import {
  WarrantyTypeOfRequest,
  WarrantyWizardOptionsStepDamageInfo,
} from "../warranty-wizard/warrantyWizardInterface";
import { getBoolAttribute } from "../../utils/productUtils";

export const sliceName = "analytics";
const initialState: AnalyticsState = {
  pageData: {
    Page_Brand: "MYL",
    Page_Platform: "HCL",
    Page_DeviceType: "desktop",
    Fs_IsConnected: "0",
    Page_Design: "",
    Page_Type: "",
    Page_Section1: "",
    Page_Section2: "",
  },
  userData: {
    User_LoginType: "Standard",
    User_EmailOptin: "0",
  },
  searchData: {
    Events_SearchRun: "1",
    Search_View: "SERP",
  },
  sizes: {
    Max: 0,
    Min: 0,
  },
  vmSupportedUpc: "",
  loadingData: false,
  requestType: undefined,
};

export const analyticsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetAnalyticsState: () => initialState,
    setVmSupportedUpc: (state, action: PayloadAction<string>) => {
      state.vmSupportedUpc = action.payload;
    },
    setLoadingAnalyticsData: (state, action: PayloadAction<boolean>) => {
      state.loadingData = action.payload;
    },
    setRequestType: (state, action: PayloadAction<WarrantyTypeOfRequest | undefined>) => {
      state.requestType = action.payload;
    },
    setSizesAnalyticsData: (state, action: PayloadAction<SizesData>) => {
      state.sizes = action.payload;
    },
  },
  extraReducers: {
    "user/logout": () => initialState,
  },
});

export const {
  resetAnalyticsState,
  setVmSupportedUpc,
  setLoadingAnalyticsData,
  setRequestType,
  setSizesAnalyticsData,
} = analyticsSlice.actions;

//////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// ANALYTICS SELECTORS //////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const selectLoadingAnalyticsData = (state: RootState): boolean => {
  return state.analytics.loadingData;
};

export const selectVmSupportedUpc = (state: RootState): string => {
  return state.analytics.vmSupportedUpc;
};

export const selectPageData = (state: RootState): PageData => {
  return state.analytics.pageData;
};

export const selectUserData = (state: RootState): UserData => {
  return state.analytics.userData;
};

export const selectBasicSearchData = (state: RootState): SearchData => {
  return state.analytics.searchData;
};

export const selectWarrantyRequestType = (state: RootState): WarrantyTypeOfRequest | undefined => {
  return state.analytics.requestType;
};

export const selectSizesAnalyticsData = (state: RootState): SizesData => {
  return state.analytics.sizes;
};

//////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// DATA SELECTORS /////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

export const selectCommonPageData = createSelector(
  selectPageData,
  selectCurrentLang,
  selectUserName,
  selectStoreIdentifier,
  selectIsBackOfficeUser,
  selectSession,
  selectCurrencyFormat,
  (
    pageData: PageData,
    currentLang: string | null,
    username: string | null,
    storeIdentifier: string,
    isBackOfficeUser: boolean,
    session: string,
    currencyFormat: CurrencyFormat
  ): PageData => {
    const locale = currentLang?.split("-") ?? ["", ""];
    const userValues = username?.split(".") ?? ["", ""];
    const pageCountry = userValues?.[userValues.length - 1];
    const storeIdentifierCleaned = storeIdentifier?.split("-")?.[1] ?? "";

    return {
      ...pageData,
      Page_Language: locale[0].toUpperCase(),
      Page_Country: isBackOfficeUser
        ? storeIdentifierCleaned.toUpperCase()
        : pageCountry.toUpperCase(),
      Session_Id: session,
      Order_Currency: currencyFormat.currency.opt,
    };
  }
);

export const selectCommonUserData = createSelector(
  selectUserData,
  selectUserName,
  selectIsBackOfficeUser,
  selectActiveDoor,
  selectIsLogged,
  (
    userData: UserData,
    username: string | null,
    isBackOfficeUser: boolean,
    activeDoor: Door | null,
    isLogged: boolean
  ): UserData => {
    const userValues = username?.split(".") ?? ["", ""];

    return {
      ...userData,
      User_LoginStatus: isLogged ? "Logged" : "Guest",
      User_Segments: isBackOfficeUser ? "BO" : userValues[0],
      User_Email_MD5: isBackOfficeUser && username ? md5(username, 16) : "" + username,
      User_Id: isBackOfficeUser ? activeDoor?.orgentityName : userValues[1],
      Store_Id: isBackOfficeUser ? activeDoor?.orgentityName : userValues[1],
    };
  }
);

export const selectSearchData = createSelector(
  selectBasicSearchData,
  selectSelectedDoor,
  selectIsFromSuggestion,
  selectMetaData,
  selectSearchedTerm,
  selectTotalProducts,
  selectProducts,
  selectUserHasStarsUx,
  (
    searchData: SearchData,
    selectedDoor: Door | null,
    isFromSuggestion: boolean | null,
    metaData: MetaData | null,
    searchedTerm: string | null,
    totalProducts: number,
    products: Product[],
    userHasStars
  ): SearchData => {
    const searchedWords = metaData?.spellcheck?.[0];
    const keyword = searchedTerm ?? undefined;

    return {
      ...searchData,
      Search_ResultItemsQnt: totalProducts?.toString(),
      Search_Keyword: keyword,
      Search_KeyActual: searchedWords !== keyword ? searchedWords : "",
      Search_Type: isFromSuggestion ? "suggested" : "text",
      Search_FacetValues_String: getFilters(),
      Products: getProductsDataFromProduct(
        products,
        selectedDoor,
        undefined,
        undefined,
        userHasStars
      ),
    };
  }
);

export const selectOrderData = createSelector(
  selectOrderMultidoorList,
  selectCountQuantity,
  selectCartTotalPrice,
  selectUserHasStarsUx,
  (
    orderMultidoorList: OrderMultidoor[],
    countQuantity: CartCountQuantity | null,
    cartTotalPrice: number | null,
    userHasStars
  ): OrderData => {
    return {
      Order_ProductsAmount: cartTotalPrice?.toString() ?? "",
      Order_DiscountAmount: "0", //TODO but not for now (it's NOT the carnet RX for each row)
      Products: getProductsDataFromOrder(orderMultidoorList, userHasStars),
    };
  }
);

export const selectCheckoutData = createSelector(
  selectCheckoutOrderMultidoor,
  selectCheckoutTotalPrice,
  selectUserHasStarsUx,
  (
    checkoutOrderMultidoor: OrderMultidoor[],
    checkoutTotalPrice: number | null,
    userHasStars
  ): OrderData => {
    return {
      Order_ProductsAmount: checkoutTotalPrice?.toString() ?? "",
      Order_DiscountAmount: "0", //TODO but not for now (it's NOT the carnet RX for each row)
      Order_DiscountCode: doesOrderHaveCarnets(checkoutOrderMultidoor) ? "carnetRX" : "",
      Products: getProductsDataFromOrder(checkoutOrderMultidoor, userHasStars),
    };
  }
);

export const selectPlpCatalogueData = createSelector(
  selectPlpCatalogue,
  selectSelectedDoor,
  selectUserHasStarsUx,
  selectSizesAnalyticsData,
  (
    plpCatalogue: PlpCatalogue | null,
    selectedDoor: Door | null,
    userHasStars,
    sizes: SizesData
  ): PlpCatalogueData => {
    return {
      Search_ResultItemsQnt: plpCatalogue?.resultsTotal.toString() ?? "",
      Search_FacetValues_String: getFilters(),
      Events_SearchFiltering: getSizes(sizes),
      Products: getProductsDataFromProduct(
        plpCatalogue?.resultList as Product[] | undefined,
        selectedDoor,
        undefined,
        undefined,
        userHasStars
      ),
    };
  }
);

export const selectPrePlpData = createSelector(
  selectPrePLPCarousels,
  (prePLPCarousels: PrePLPCarousels): any => {
    return {
      Search_ResultItemsQnt: (prePLPCarousels?.[0]?.resultsTotal ?? 0).toString(),
    };
  }
);

export const selectPdpData = createSelector(
  selectCurrentVariant,
  selectProductAvailability,
  selectProductWithVariants,
  selectPdpVariantsList,
  selectVmSupportedUpc,
  selectSelectedDoor,
  selectPDPVariantsInfo,
  selectUserHasStarsUx,
  (
    variantDetails: Variant | null,
    productAvailability: AvailabilityStatusByDoor[],
    productWithVariants: Product | null,
    pdpVariantsList: Variant[] | null,
    vmUpc: string,
    selectedDoor: Door | null,
    PDPVariantsInfo: PDPVariantsInfo,
    userHasStars
  ): PdpData => {
    const variant = variantDetails ?? pdpVariantsList?.[0]; // either the first (grid) or the second (list)

    const data: PdpData = {
      Products: getProductsDataFromPdp(
        variant ? [variant] : [],
        PDPVariantsInfo,
        selectedDoor,
        productAvailability,
        vmUpc,
        userHasStars
      ),
      Event_Source: getFilters(),
      Page_Section2: `${productWithVariants?.productCode}${productWithVariants?.name}:${variant?.colorCode?.values}${variant?.frontColorDescription?.values}`,
      Rtr_IsUpcSupported:
        variant?.attributes && getBoolAttribute(variant?.attributes, "RTR") ? "1" : "0",
    };
    return data;
  }
);

export const selectSparePartsPdpData = createSelector(
  selectSparePartsPdpVariant,
  selectSelectedDoor,
  (sparePartsPdpVariant: Variant | null, selectedDoor: Door | null): PdpData => {
    return {
      Products: getProductsDataFromVariant(
        sparePartsPdpVariant ? [sparePartsPdpVariant] : [],
        selectedDoor
      ),
    };
  }
);

export const selectWarrantyWizardProductData = createSelector(
  selectWarrantyWizardProductDetails,
  selectWarrantyWizardOptionsStepDamageInfo,
  selectSelectedDoor,
  selectSelectedLens,
  (
    warrantyWizardProductDetails: Sku | null,
    warrantyWizardOptionsStepDamageInfo: WarrantyWizardOptionsStepDamageInfo | null,
    selectedDoor: Door | null,
    selectedLens: Lens | null
  ): GenericProductData => {
    const availability = warrantyWizardOptionsStepDamageInfo?.availability?.x_state;

    return {
      Products: getProductsDataFromSku(
        warrantyWizardProductDetails ? [warrantyWizardProductDetails] : [],
        null, // door
        [], // and availability from redux are not given, as we pass availability from warranty's info
        [availability as string],
        "",
        false,
        (selectedDoor?.orgentityName as string) ?? "",
        "",
        "",
        selectedLens?.lensDescription.replace(" ", "_") ?? ""
      ),
    };
  }
);

export const selectQuickViewOverlayProductData = createSelector(
  selectPlpVariants,
  selectProductAvailability,
  selectSelectedDoor,
  selectUserHasStarsUx,
  (
    plpVariants: PlpVariants,
    productAvailability: AvailabilityStatusByDoor[],
    selectedDoor: Door | null,
    userHasStars
  ): GenericProductData => {
    return {
      Products: getProductsDataFromVariant(
        plpVariants.items,
        selectedDoor,
        productAvailability,
        undefined,
        userHasStars
      ),
    };
  }
);

export const selectDigitalWindowProductData = createSelector(
  selectDigitalWindowProducts,
  selectProductAvailability,
  selectSelectedDoor,
  selectUserHasStarsUx,
  (
    digitalWindowProducts: DigitalWindowProductsResponse | null,
    productAvailability: AvailabilityStatusByDoor[],
    selectedDoor: Door | null,
    userHasStars
  ): GenericProductData => {
    return {
      Products: getProductsDataFromVariant(
        digitalWindowProducts?.catalogEntryView,
        selectedDoor,
        productAvailability,
        undefined,
        userHasStars
      ),
    };
  }
);

export const selectPlpStarsCatalogueData = createSelector(
  selectPlpStarsCatalogue,
  selectSelectedDoor,
  selectSizesAnalyticsData,
  (
    plpStarsCatalogue: PlpStarsCatalogue | null,
    selectedDoor: Door | null,
    sizes: SizesData
  ): PlpCatalogueData => {
    return {
      Search_ResultItemsQnt: plpStarsCatalogue?.recordSetTotal.toString() ?? "",
      Search_FacetValues_String: getFilters(),
      Events_SearchFiltering: getSizes(sizes),
      Products: getProductsDataFromSku(
        plpStarsCatalogue?.catalogEntryView,
        selectedDoor,
        undefined,
        undefined,
        undefined,
        true
      ),
    };
  }
);

export default analyticsSlice.reducer;
