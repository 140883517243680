import React from "react";
import styled from "styled-components/macro";
import LoaderElement from "../LoaderElement";

const LoaderMenu = (): JSX.Element => {
  return (
    <>
      <Wrapper>
        <Row>
          <Column>
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
            <LoaderElement height={18} />
          </Column>
        </Row>
      </Wrapper>
    </>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
`;

const Row = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 0 3rem;
`;

export default LoaderMenu;
