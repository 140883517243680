import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { useBreakpoint } from "../../../../assets/theme/breakpoint";
import theme from "../../../../assets/theme/theme";
import useCanRender from "../../../../hooks/useCanRender";
import useTranslation from "../../../../hooks/useTranslation";
import { FirstLevelMenu, SecondLevelMenu } from "../../../../interfaces/menuInterfaces";
import { getCmsContentBanner } from "../../../../store/store/storeSagas";
import {
  selectAdvBanner,
  selectGetMenuStatus,
  selectMenu,
  selectMenuContent,
} from "../../../../store/store/storeSlice";
import { selectCanBuyModelsGlobal, selectIsMultidoor } from "../../../../store/user/userSlice";
import { capitalizeFirstLetter } from "../../../../utils/utils";
import CustomText from "../../../styled-UI/CustomText";
import LoaderNavItem from "../../../styled-UI/loader/menu/LoaderNavItem";
import MemoizedCloseButton from "../../../widgets/CloseButton";
import { MemoizedAdvBanner } from "../../../widgets/adv-banner/AdvBanner";
import WrapperLayoutMenu from "../../WrapperLayoutMenu";
import BrandMenu from "./brands-menu/BrandMenu";
import CategoriesMenu from "./categories-menu/CategoriesMenu";
import ServicesMenu from "./services-menu/ServicesMenu";
import { serviceMenu } from "./services-menu/serviceSections";
import { getColumns } from "./utils";

export type MenuLinkButtonType = "EssilorPro" | "Eyemed";

const NavbarMenu = ({ isStripVisible }: { isStripVisible?: boolean }): JSX.Element => {
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const canRender = useCanRender();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuData = useSelector(selectMenu);
  const getMenuStatus = useSelector(selectGetMenuStatus);
  const isMultidoor = useSelector(selectIsMultidoor);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const advBannerId = useSelector(selectMenuContent)?.advBannerId;
  const canShowBrands = canBuyModelsGlobal || canRender("LENS_DIGITAL_CATALOGUE");
  const [toggleMenu, setMenu] = useState<string>("");
  const [openBanner, setOpenBanner] = useState<boolean>(true);
  const [isStarsAvailable, setIsStarsAvailable] = useState<boolean>(false);
  const [showServiceMenu, setShowServiceMenu] = useState<boolean>(false);
  const [hideServices, setHideServices] = useState<boolean>(false);
  const [isBrandBannerAvailable, setIsBrandBannerAvailable] = useState<boolean>(false);
  const [isBannerAvailable, setIsBannerAvailable] = useState<boolean>(false);
  const [brands, setBrands] = useState<SecondLevelMenu[]>([]);
  const [starsBrands, setStarsBrands] = useState<SecondLevelMenu[]>([]);
  const breakpoints = useBreakpoint();
  const banners = useSelector(selectAdvBanner);
  const [needTopFade, setNeedTopFade] = useState(false);
  const [needBottomFade, setNeedBottomFade] = useState(false);

  const showBrands = menuData.find((menuitem: FirstLevelMenu) => {
    return menuitem.identifier === "BRANDS";
  });
  const showBrandsEssilor = menuData.find((_: FirstLevelMenu) => {
    return _.identifier === "ESSILOR";
  });
  const marginTop = theme.headerHeightRem;

  const handleFadeChange = (top: boolean, bottom: boolean) => {
    setNeedTopFade(top);
    setNeedBottomFade(bottom);
  };

  useEffect(() => {
    if (showBrands?.catalogGroupView) {
      const newBrands: SecondLevelMenu[] = [];
      const newStarsBrands: SecondLevelMenu[] = [];
      showBrands?.catalogGroupView.map((_) => {
        if (_.isStars) {
          newStarsBrands.push(_);
        } else {
          newBrands.push(_);
        }
      });

      setBrands(newBrands);
      setStarsBrands(newStarsBrands);
      newStarsBrands.length && setIsStarsAvailable(true);
    }
  }, [showBrands]);

  useEffect(() => {
    toggleMenu &&
      advBannerId &&
      !banners.length &&
      dispatch(getCmsContentBanner({ id: advBannerId }));
  }, [toggleMenu, advBannerId]);

  useEffect(() => {
    serviceMenu.forEach((column) => {
      column.links?.forEach((value) => {
        if (!showServiceMenu) {
          if (!value.privilege || canRender(value.privilege)) setShowServiceMenu(true);
        }
      });
    });
  }, [serviceMenu]);

  //Hide services options based on catalogue data
  useEffect(() => {
    if (menuData) {
      menuData.forEach((_: FirstLevelMenu) => {
        if (_.name === "PRODUCTS" || _.name === "BRANDS" || _.name === "ESSILOR") {
          if (_.catalogGroupView?.length === 0) {
            setHideServices(true);
          }
        }
      });
    }
  }, [menuData]);

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (menuRef.current) {
        const target = event.target as Node;
        if (!menuRef.current.contains(target)) setToggleMenu("");
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  const setToggleMenu = (value: string) => setMenu(value);
  const isBannerOpen = (value: boolean) => setOpenBanner(value);

  const columns = useMemo(
    () =>
      getColumns(
        !!breakpoints["md"],
        !!showBrands,
        !!showBrandsEssilor,
        !!isStarsAvailable,
        isBrandBannerAvailable
      ),
    [breakpoints["md"], showBrands, showBrandsEssilor, isStarsAvailable]
  );

  return (
    <WrapperMenu ref={menuRef}>
      <MenuLeft>
        {getMenuStatus === "SUCCESS" && (showBrands || showBrandsEssilor) && (
          <>
            <NavElement
              key={showBrands?.uniqueID || showBrandsEssilor?.uniqueID}
              onClick={() => {
                const menuId = showBrands?.identifier
                  ? showBrands.identifier
                  : showBrandsEssilor?.identifier
                  ? showBrandsEssilor.identifier
                  : "";
                if (menuId) {
                  toggleMenu === "" || toggleMenu !== menuId
                    ? setToggleMenu(menuId)
                    : setToggleMenu("");
                }
              }}
              data-element-id="MainNav_Brands"
              className="menuLeft"
            >
              <CustomText
                as="button"
                color="primary"
                fontSizePx={14}
                font="font-medium"
                fontWeightNum={600}
              >
                {showBrands
                  ? capitalizeFirstLetter(showBrands.name)
                  : translateLabel("MENU_TITLE_BRANDS")}
              </CustomText>

              {(toggleMenu === showBrands?.identifier ||
                toggleMenu === showBrandsEssilor?.identifier) &&
                canShowBrands && (
                  <MenuContainer
                    marginTop={marginTop}
                    id="menu-container"
                    isStripVisible={isStripVisible}
                    background={true}
                  >
                    <Menu
                      {...(isStarsAvailable && { backgroundColor: theme.palette.chip.blue })}
                      isStripVisible={isStripVisible}
                    >
                      <WrapperLayoutMenu>
                        <BrandsContainer>
                          {isStarsAvailable && (
                            <BrandMenu
                              setToggleMenu={setToggleMenu}
                              brands={starsBrands}
                              enableDataDescription
                              isStars={true}
                              columns={columns.stars}
                              isStripVisible={isStripVisible}
                              onScrollFadeStateChange={handleFadeChange}
                            />
                          )}
                          {showBrands && (
                            <BrandMenu
                              setToggleMenu={setToggleMenu}
                              brands={brands}
                              enableDataDescription
                              columns={columns.brands}
                              hasStars={isStarsAvailable}
                              isStripVisible={isStripVisible}
                              onScrollFadeStateChange={handleFadeChange}
                            />
                          )}
                          {showBrandsEssilor && (
                            <BrandMenu
                              setToggleMenu={setToggleMenu}
                              brands={showBrandsEssilor.catalogGroupView}
                              enableDataDescription
                              columns={columns.essilor}
                              isEssilor={true}
                              borderLeft={!!showBrands || !!isStarsAvailable}
                              isStripVisible={isStripVisible}
                              onScrollFadeStateChange={handleFadeChange}
                              hasStars={isStarsAvailable}
                            />
                          )}
                          {!isBrandBannerAvailable && (
                            <MemoizedCloseButton
                              dataElementId="MainNav_Menu_Close"
                              lastItem
                              action={() => setToggleMenu("")}
                            />
                          )}
                          {isStarsAvailable && showBrandsEssilor ? (
                            <></>
                          ) : (
                            <WrapperBanner isBrandBannerAvailable={isBrandBannerAvailable}>
                              {isBrandBannerAvailable && (
                                <MemoizedCloseButton
                                  dataElementId="MainNav_Menu_Close"
                                  type="white"
                                  action={() => setToggleMenu("")}
                                  lastItem
                                />
                              )}
                              <MemoizedAdvBanner
                                isBannerAvailable={setIsBrandBannerAvailable}
                                type={
                                  isStarsAvailable ? "menu.stars.brands" : "menu.standard.brands"
                                }
                              />
                            </WrapperBanner>
                          )}
                        </BrandsContainer>
                        {needTopFade && <FadeTop />}
                        {needBottomFade && <FadeBottom />}
                      </WrapperLayoutMenu>
                    </Menu>
                  </MenuContainer>
                )}
            </NavElement>
          </>
        )}
        {getMenuStatus === "SUCCESS" &&
          menuData.map((menuitem: FirstLevelMenu) => {
            return (
              <React.Fragment key={menuitem.uniqueID}>
                {canBuyModelsGlobal &&
                  menuitem.identifier !== "BRANDS" &&
                  menuitem.identifier !== "ESSILOR" && (
                    <NavElement
                      key={menuitem.uniqueID || "" + menuitem?.identifier || ""}
                      data-element-id="MainNav_Products"
                      onClick={() => {
                        if (menuitem.identifier) {
                          toggleMenu === "" || toggleMenu !== menuitem.identifier
                            ? setToggleMenu(menuitem.identifier)
                            : setToggleMenu("");
                        } else setToggleMenu("");
                      }}
                      className="menuLeft"
                    >
                      <CustomText
                        as="button"
                        fontSizePx={14}
                        color="primary"
                        font="font-medium"
                        fontWeightNum={600}
                      >
                        {capitalizeFirstLetter(menuitem.name)}
                      </CustomText>

                      {toggleMenu === menuitem.identifier && (
                        <MenuContainer
                          marginTop={marginTop}
                          id="menu-container"
                          isStripVisible={isStripVisible}
                        >
                          <Menu isStripVisible={isStripVisible}>
                            {menuitem.identifier === "PRODUCTS" && canBuyModelsGlobal && (
                              <WrapperLayoutMenu clearBackground={openBanner}>
                                <CategoriesMenu
                                  catMenuData={menuitem.catalogGroupView}
                                  setToggleMenu={setToggleMenu}
                                  marginTop={marginTop}
                                  isBannerOpen={isBannerOpen}
                                  onScrollFadeStateChange={handleFadeChange}
                                />
                                {openBanner && (
                                  <BannerContainer className={clsx(!isBannerAvailable && "hide")}>
                                    <MemoizedCloseButton
                                      dataElementId="MainNav_Menu_Close"
                                      type="white"
                                      lastItem
                                    />
                                    <MemoizedAdvBanner
                                      isBannerAvailable={setIsBannerAvailable}
                                      type={
                                        isStarsAvailable
                                          ? "menu.stars.categories"
                                          : "menu.standard.categories"
                                      }
                                    ></MemoizedAdvBanner>
                                  </BannerContainer>
                                )}
                                {needTopFade && <FadeTop />}
                                {needBottomFade && <FadeBottom />}
                              </WrapperLayoutMenu>
                            )}
                          </Menu>
                        </MenuContainer>
                      )}
                    </NavElement>
                  )}
              </React.Fragment>
            );
          })}
        {getMenuStatus === "LOADING" && (
          <>
            <NavElement>
              <LoaderNavItem />
            </NavElement>
            <NavElement>
              <LoaderNavItem />
            </NavElement>
          </>
        )}

        {showServiceMenu && serviceMenu.length > 0 && getMenuStatus !== "LOADING" && (
          <NavElement
            onClick={() => {
              toggleMenu === "" || toggleMenu !== "SERVICES"
                ? setToggleMenu("SERVICES")
                : setToggleMenu("");
            }}
            className="menuLeft"
          >
            <CustomText
              as="button"
              color="primary"
              data-element-id="MainNav_Services"
              fontSizePx={14}
              font="font-medium"
              fontWeightNum={600}
            >
              {translateLabel("SERVICES_AND_REWARDS")}
            </CustomText>

            {toggleMenu === "SERVICES" && (
              <MenuContainer
                className={clsx("menu", isMultidoor && "menu-multidoor")}
                marginTop={marginTop}
                id="menu-container"
                isStripVisible={isStripVisible}
              >
                <Menu isStripVisible={isStripVisible}>
                  <WrapperLayoutMenu>
                    <ServicesMenu hideServices={hideServices} setToggle={setToggleMenu} />
                  </WrapperLayoutMenu>
                </Menu>
              </MenuContainer>
            )}
          </NavElement>
        )}

        {showServiceMenu && serviceMenu.length > 0 && getMenuStatus === "LOADING" && (
          <NavElement>
            <LoaderNavItem />
          </NavElement>
        )}
      </MenuLeft>

      {toggleMenu && <Overlay marginTop={marginTop} isStripVisible={isStripVisible} />}
    </WrapperMenu>
  );
};

const WrapperMenu = styled.nav`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
`;

const MenuLeft = styled.div`
  display: flex;
  margin: 0 -1rem;
`;

const NavElement = styled.span<{ onlyOneMenuRight?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  & > button {
    word-break: unset;
    border-bottom: 2px solid transparent;
  }

  &:hover {
    position: relative;

    :after {
      position: absolute;
      bottom: 0;
      border-bottom: solid 2px ${(props) => props.theme.palette.primary};
      content: "";
      width: 100%;
    }
  }
`;

export const MenuContainer = styled.div<{
  marginTop?: number;
  isStripVisible?: boolean;
  background?: boolean;
}>`
  ${(props) => props.background && `background-color: ${props.theme.palette.white}`};
  position: fixed;
  left: 0;
  top: ${(props) =>
    (props.isStripVisible
      ? props.marginTop
      : (props.marginTop || props.theme.headerHeightRem) - props.theme.stripHeightRem) + "rem"};
  width: 100%;
  border-top: solid 1px ${(props) => props.theme.palette.gray.medium};
  z-index: 200;

  @media screen and (max-width: 1366px) {
    max-width: 1366px;
  }
`;

const Overlay = styled.div<{ marginTop?: number; isStripVisible?: boolean }>`
  position: fixed;
  left: 0;
  top: ${(props) =>
    (props.isStripVisible
      ? props.marginTop
      : (props.marginTop || props.theme.headerHeightRem) - props.theme.stripHeightRem) + "rem"};
  width: 100%;
  height: 100vh;
  background-color: rgba(1, 9, 23, 0.8);
`;

export const Menu = styled.div<{
  width?: number;
  backgroundColor?: string;
  isStripVisible?: boolean;
}>`
  z-index: 101;
  height: calc(
    100vh -
      ${(props) =>
        props.isStripVisible
          ? props.theme.headerHeightRem + "rem"
          : props.theme.headerHeightRem - props.theme.stripHeightRem + "rem"}
  );
  max-height: 45.85rem;
  ${(props) => props.width && `width: ${props.width}`}

  > div {
    position: relative;
    display: flex;
  }
`;

const WrapperBanner = styled.div<{ isBrandBannerAvailable?: boolean }>`
  width: 100%;
  max-width: 30rem;
  margin-left: auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  border-left: 1px solid
    ${(props) => (props.isBrandBannerAvailable ? props.theme.palette.blue.whale : "transparent")};
`;

interface BrandsInterface {
  starsBrandsColumnCount?: number;
  brandsColumnCount?: number;
  essilorBrandsColumnCount?: number;
}
const BrandsContainer = styled.div<BrandsInterface>`
  background-color: ${(props) => props.theme.palette.white};
  width: 100%;
  height: 100%;
  max-height: 45.85rem;
  display: flex;
  position: relative;
  padding-left: 2.5rem;

  @media ${(props) => props.theme.queries.md} {
    padding-left: 1rem;
  }
`;

const BannerContainer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid ${(props) => props.theme.palette.blue.whale};
  width: 29.6875rem;
  background-color: ${(props) => props.theme.palette.white};

  @media ${(props) => props.theme.queries.minLaptop} {
    width: 33vw;
  }

  &.hide {
    display: none;
  }
`;

const FadeTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2rem;
  pointer-events: none;
  z-index: 10; /* above the menu items */
  background: radial-gradient(
    80% 80% at 50% 0,
    rgba(217, 217, 217, 0.56) 0%,
    rgba(217, 217, 217, 0) 70%
  );
`;

const FadeBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2rem;
  pointer-events: none;
  z-index: 10;
  background: radial-gradient(
    80% 80% at 50% 100%,
    rgba(217, 217, 217, 0.56) 0%,
    rgba(217, 217, 217, 0) 70%
  );
`;

export const MemoizedNavbarMenu = React.memo(NavbarMenu);
