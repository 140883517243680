import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";
import useTranslation from "../../hooks/useTranslation";
import { capitalizeFirstLetter } from "../../utils/utils";
import { IconName, iconMap } from "./utils.tsx/TagUtils";

interface Props {
  tag: string;
  size?: "md" | "sm";
  type?: "one-tile";
  color?: string;
  backgroundColor?: string;
  img?: IconName;
  isEllipsis?: boolean;
}

const Tag = ({
  tag,
  size = "md",
  type,
  color,
  backgroundColor,
  isEllipsis,
  img,
}: Props): JSX.Element => {
  const { translateLabel } = useTranslation();

  const tagContent = capitalizeFirstLetter(translateLabel(tag) ? translateLabel(tag) : tag);
  const IconToRender = img && iconMap[img];

  const contentToRender = IconToRender ? (
    <IconToRender style={{ maxHeight: "18px" }} />
  ) : (
    tagContent
  );

  return (
    <TagItem
      key="tag"
      className={clsx(
        size === "sm" && "tag-sm",
        type === "one-tile" && "tag-one-tile",
        isEllipsis === true && "tag-is-ellipsis",
        IconToRender && "tag-img"
      )}
      color={color}
      isEllipsis
      title={tagContent}
      backgroundColor={backgroundColor}
    >
      {contentToRender}
    </TagItem>
  );
};

interface TagInterface {
  color?: string;
  isEllipsis: boolean;
  backgroundColor?: string;
}

const TagItem = styled.div<TagInterface>`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.palette.gray.medium};
  border-radius: 0.875rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  font-family: ${(props) => props.theme.fonts.fontMedium};
  color: ${(props) => props.color ?? props.theme.palette.primary};
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`};

  &.tag-sm {
    padding: 0 0.5rem;
    line-height: 1.25rem;
  }

  &.tag-one-tile {
    color: ${(props) => props.color ?? props.theme.palette.white};
    text-transform: uppercase;
  }

  &.tag-is-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 7.125rem;
  }

  &.tag-img {
    padding: 0.4rem 0.75rem 0.25rem;
  }
`;

export default Tag;
