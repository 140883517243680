import { isEqual, omit } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { getDynamicUrl } from "../../../../store/serviceUtils";
import { selectBrandGroupByBrandMap } from "../../../../store/store/storeSlice";
import { CurrencyFormat, DisplayPricesOptions } from "../../../../store/user/userInterfaces";
import {
  selectCurrencyFormat,
  selectDisplayPrices,
  selectLocale,
} from "../../../../store/user/userSlice";
import Popup from "../../../styled-UI/Popup";

import { ReactComponent as LoaderIcon } from "../../../../assets/icons/loader.svg";

export interface M4CConfiguration {
  xitemM4CbagId?: string;
  xitemM4CCustomVendorId?: string;
  xitemM4CCustomModelCode?: string;
  xitemM4CCustomRegion?: string;
  xitemM4CCustomHierarchy?: string;
  xitemM4CCustomProductId?: string;
  xitemM4CConfigurationId?: string;
}
interface Props {
  isOpenM4CPopUp: boolean;
  setIsOpenM4CPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  m4cConfiguration: M4CConfiguration;
  handleM4CUpdate: (xitemM4CConfigurationId: string) => void;
  brand: string;
  isLoading?: boolean;
}

declare global {
  interface Window {
    configureParams: any;
  }
}

interface FluidConfigurationParams {
  ocId: string;
  endpoint: string;
  showWholesalePrice: boolean; // static
  styleSelector: boolean; // static
  inStore: boolean; // static
  skin: string; // static
  showCloseCTA: boolean; // static
  recipeId: string | undefined; // configurationID
  vendorId: string | undefined; // xitem_M4CCustomVendorId
  productId: string | undefined; //xitem_M4CCustomProductId
  modelCode: string | undefined; // xitem_M4CCustomModelCode
  region: string | undefined; // xitem_M4CCustomRegion
  ocHierarchy: string | undefined; // xitem_M4CCustomHierarchy
  priceSelectorOnLoad: string | undefined; // based on display prices selector
  currency: string | undefined; // currency from redux
  numberFormat: string | undefined; // currency from redux
  locale: string | undefined; // locale from redux
  addToCartClick: (recipe: any, callback: any) => void;
  exclusiveLabelModelSelector: string;
  exclusiveLabelGetInspired: string;
  exclusiveLabelComponent: string;
  getInspiredTitle: string;
  getInspiredSubtitle: string;
}

const loadFluid = (url: string, callback?: () => void) => {
  const existingScript = document.getElementById("fluid-library");
  if (!existingScript) {
    // console.log("loading fluid...");
    const script = document.createElement("script");
    script.src = url;
    script.id = "fluid-library";
    script.async = true;
    script.nonce = "DAzDfjXu096LaFS6n_asyQ";

    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback?.();
    };
  }
};

const unloadFluid = () => {
  // console.log("unloading fluid...");

  const existingScript = document.getElementById("fluid-library");
  if (existingScript)
    try {
      document.body.removeChild(existingScript);
    } catch (error) {
      // console.log(error);
    }

  // remove imported links
  const links = document.getElementsByTagName("link");

  for (let i = links.length; i >= 0; i--) {
    if (links[i]?.href?.toLowerCase()?.includes("fluidconfigure"))
      try {
        document.head.removeChild(links[i]);
      } catch (error) {
        // console.log(error);
      }
  }

  // remove imported scripts
  const scripts = document.getElementsByTagName("script");

  for (let i = scripts.length; i >= 0; i--) {
    if (scripts[i]?.src?.toLowerCase()?.includes("fluidconfigure"))
      try {
        document.head.removeChild(scripts[i]);
      } catch (error) {
        // console.log(error);
      }
  }
};

const getPriceSelectorForM4C = (displayPrices: DisplayPricesOptions | null) => {
  if (displayPrices === "ALL_PRICES") return "WHOLESALE";
  if (displayPrices === "SUGGESTED_PRICES") return "SRP";
  if (displayPrices === "NO_PRICES") return "NO_PRICES";
  return "NO_PRICES";
};

const mapConfigParams = (
  m4cConfiguration: M4CConfiguration,
  displayPrices: DisplayPricesOptions | null,
  currency: CurrencyFormat,
  locale: string,
  handleM4CUpdate: (xitemM4CConfigurationId: string) => void,
  brand: string,
  translateLabel: (label: string) => string
): FluidConfigurationParams => {
  return {
    ocId: getOcId(brand),
    endpoint: getDynamicUrl("/fo-bff/api/priv/v1/{storeIdentifier}/{locale}/m4c/custom"),
    showWholesalePrice: true,
    styleSelector: false,
    inStore: false,
    skin: "myl",
    showCloseCTA: false,
    recipeId: m4cConfiguration?.xitemM4CConfigurationId,
    vendorId: m4cConfiguration?.xitemM4CCustomVendorId,
    productId: m4cConfiguration?.xitemM4CCustomProductId,
    modelCode: m4cConfiguration?.xitemM4CCustomModelCode,
    region: m4cConfiguration?.xitemM4CCustomRegion,
    ocHierarchy: m4cConfiguration?.xitemM4CCustomHierarchy,
    priceSelectorOnLoad: getPriceSelectorForM4C(displayPrices),
    currency: currency?.currency.opt,
    numberFormat: currency?.currency.opt,
    locale: getLocale(locale, brand),
    exclusiveLabelModelSelector: translateLabel("EXCLUSIVE_LABEL_MODEL_SELECTOR"),
    exclusiveLabelGetInspired: translateLabel("EXCLUSIVE_LABEL_GET_INSPIRED"),
    exclusiveLabelComponent: translateLabel("EXCLUSIVE_LABEL_COMPONENT"),
    getInspiredTitle: translateLabel("GET_INSPIRED_TITLE"),
    getInspiredSubtitle: translateLabel("GET_INSPIRED_SUBTITLE"),

    addToCartClick: function (recipe: any, callback: any) {
      // console.log("RECIPE ID", JSON.stringify(recipe?.id)); // configuration id
      // console.log("RECIPE", JSON.stringify(recipe));
      // console.log("RECIPE CUSTOM BOM", JSON.stringify(recipe?.custom?.bom));

      handleM4CUpdate(recipe?.id); // update order item w/ new configuration id
    },
    //NON DA FARE <c:if test='${param.fromOrderDetail}'>nobag: true,</c:if>
  };
};

const validateConfigParams = (config: FluidConfigurationParams | null | undefined): boolean => {
  const validationResult =
    !!config && !!config?.ocId && !!config?.recipeId && !!config?.productId && !!config?.modelCode;
  // console.log(`validation ${validationResult ? "successful" : "failed"}`, config);
  return validationResult ?? false;
};

const getOcId = (brand: string): string => {
  return globalEnvVariables[`ocId${brand as "RB" | "OO"}` as "ocIdRB" | "ocIdOO"];
};

const getLocale = (locale: string, brand: string): string => {
  if (brand === "RB") return locale?.replace("-", "_");
  if (brand === "OO") return locale.split("-")?.[0];
  return locale?.replace("-", "_");
};

const getConfigurationScriptURL = (brand: string): string => {
  return globalEnvVariables[
    `configurationScriptURL${brand as "RB" | "OO"}` as
      | "configurationScriptURLOO"
      | "configurationScriptURLRB"
  ];
};

const M4CEditPopup = ({
  isOpenM4CPopUp,
  setIsOpenM4CPopUp,
  m4cConfiguration,
  handleM4CUpdate,
  brand,
  isLoading = false,
}: Props): JSX.Element => {
  const fluidRef = useRef<HTMLDivElement | null>(null);
  const { translateLabel } = useTranslation();

  const displayPrices = useSelector(selectDisplayPrices);
  const currency = useSelector(selectCurrencyFormat);
  const locale = useSelector(selectLocale);
  const brandGroupByBrandMap = useSelector(selectBrandGroupByBrandMap);

  const [configParams, setConfigParams] = useState<FluidConfigurationParams | null>();

  ////////// map content into viable config object and save it in local state configParams
  useEffect(() => {
    if (isOpenM4CPopUp) {
      const newParams = mapConfigParams(
        m4cConfiguration,
        displayPrices,
        currency,
        locale,
        handleM4CUpdate,
        brandGroupByBrandMap[brand],
        translateLabel
      );
      if (!isEqual(omit(newParams, ["addToCartClick"]), omit(configParams, ["addToCartClick"])))
        setConfigParams(newParams);
    }
  }, [m4cConfiguration, displayPrices, locale, currency, brand]);

  ////////// set configuration when configParams changes
  useEffect(() => {
    if (validateConfigParams(configParams)) {
      window.configureParams = configParams;
    }
  }, [configParams]);

  ////////// attach script
  useEffect(() => {
    if (fluidRef.current !== null)
      loadFluid(getConfigurationScriptURL(brandGroupByBrandMap[brand]));
  }, [fluidRef]);

  useEffect(() => {
    return () => {
      unloadFluid();
    };
  }, []);

  return (
    <Popup
      isOpen={isOpenM4CPopUp}
      close={() => setIsOpenM4CPopUp(false)}
      title={translateLabel("M4C_EDIT_POPUP_EDIT_YOUR_CUSTOM_PRODUCT")}
      zindex={601}
      disableCloseButton={isLoading}
    >
      <>
        {isLoading && (
          <LoadingOverlay>
            <LoaderIcon />
          </LoadingOverlay>
        )}
        <ModalContainer isLoading={isLoading}>
          <div id="fluidConfigure" ref={fluidRef} />
        </ModalContainer>
      </>
    </Popup>
  );
};

const ModalContainer = styled.div<{ isLoading: boolean }>`
  padding: 2rem;
  max-height: 80vh;
  width: 70rem;
  overflow: auto;
`;

const LoadingOverlay = styled.div`
  background-color: ${(props) => props.theme.palette.gray.dark};
  height: 100%;
  position: fixed;
  width: 95%; //  70rem;;
  max-height: 80vh;
  z-index: 100;
  opacity: 0.5;
  display: flex;
`;

export default M4CEditPopup;
