import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../hooks/useTranslation";
import { useForm } from "react-hook-form";

import {
  selectPopupAccessAccounting,
  togglePopupAccessAccounting,
} from "../../../store/store/storeSlice";
import { updatePin } from "../../../store/accounting/accountingSagas";
import {
  clearAcccountingError,
  selectAccountingError,
  selectRequestStatus,
  selectUpdateStatus,
} from "../../../store/accounting/accountingSlice";
import { checkSameDigitsInsideNumber } from "../../../utils/utils";
import Popup from "../../styled-UI/Popup";
import Button from "../../styled-UI/Button";
import InputComponent from "../../styled-UI/InputComponent";
import CustomText from "../../styled-UI/CustomText";

interface FormValues {
  pinCodeFirst: string;
  pinCodeSecond: string;
}

interface Props {
  resetPopup: () => void;
}

function AccountingUpdatePinPopup({ resetPopup }: Props): JSX.Element {
  const { translateLabel } = useTranslation();
  const [feedbackMessageFirst, setFeedbackMessageFirst] = useState<string>("");
  const [feedbackMessageSecond, setFeedbackMessageSecond] = useState<string>("");
  const dispatch = useDispatch();

  const { register, watch, reset } = useForm<FormValues>();
  const watchPinFirst = watch("pinCodeFirst");
  const watchPinSecond = watch("pinCodeSecond");

  const popupAccessAccounting = useSelector(selectPopupAccessAccounting);
  const pinErrorMessage = useSelector(selectAccountingError);
  const validateRequestStatus = useSelector(selectRequestStatus);
  const updateRequestStatus = useSelector(selectUpdateStatus);

  useEffect(() => {
    () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    if (pinErrorMessage.pinError?.type !== "POPUP_PIN_TEMPORARY_PIN") {
      setFeedbackMessageFirst(pinErrorMessage.pinError?.type ? pinErrorMessage.pinError?.type : "");
      setFeedbackMessageSecond(
        pinErrorMessage.pinError?.type ? pinErrorMessage.pinError?.type : ""
      );
    }
  }, [pinErrorMessage.pinError?.type]);

  const checkFormatPin = (pin: string) => {
    if (pin.length === 0) {
      return "POPUP_UPDATE_INSERT_PIN";
    } else if (!/[a-z]/i.test(pin)) {
      if (pin && pin.length > 6) {
        return "PIN_TOO_LONG";
      } else if (pin && pin.length < 6) {
        return "PIN_TOO_SHORT";
      } else if (!checkSameDigitsInsideNumber(pin)) {
        return "PIN_NOT_SAME_NUMBER";
      }
    } else {
      return "PIN_NOT_NUMERIC";
    }
    return null;
  };

  const handleSubmit = () => {
    const errorFirst = checkFormatPin(watchPinFirst);
    const errorSecond = checkFormatPin(watchPinSecond);

    if (errorFirst) setFeedbackMessageFirst(translateLabel(errorFirst));
    if (errorSecond) setFeedbackMessageSecond(translateLabel(errorSecond));

    //submit if both pin passed the controls
    if (!errorFirst && !errorSecond) {
      if (watchPinFirst === watchPinSecond) {
        dispatch(
          updatePin({
            pin: watchPinFirst,
            type: popupAccessAccounting.type,
          })
        );
      } else {
        watchPinFirst.length > 0 && setFeedbackMessageFirst(translateLabel("PIN_NOT_SAME"));
        watchPinSecond.length > 0 && setFeedbackMessageSecond(translateLabel("PIN_NOT_SAME"));
      }
    }
  };

  const handleClose = () => {
    resetPopup();
    dispatch(clearAcccountingError("pinError"));
    dispatch(togglePopupAccessAccounting({ open: false, type: null }));
  };

  return (
    <Popup
      isOpen={popupAccessAccounting.open}
      title={translateLabel(
        popupAccessAccounting.type === "lensCarnet" ? "CHANGE_LENS_CARNET_PIN" : "CHANGE_CREDIT_PIN"
      )}
      close={handleClose}
    >
      <ContentPopup>
        <Row>
          <CustomText as="p" fontSizePx={13} font="font-medium">
            {translateLabel("POPUP_PIN_CHANGE_PIN")}
          </CustomText>
        </Row>
        <form>
          <Row>
            <WrapInput>
              <InputSpacer>
                <InputComponent
                  ref={register()}
                  id="pinCodeFirst"
                  type="password"
                  required
                  placeholder={translateLabel("PIN_NEW_PASSWORD")}
                  name="pinCodeFirst"
                  onChange={() => {
                    dispatch(clearAcccountingError("pinError"));
                    setFeedbackMessageFirst("");
                  }}
                  helperText={feedbackMessageFirst.length !== 0 ? feedbackMessageFirst : ""}
                  colorStyle={feedbackMessageFirst.length !== 0 ? "error" : "enabled"}
                  maxLength={6}
                />
              </InputSpacer>
              <InputComponent
                ref={register()}
                id="pinCodeSecond"
                type="password"
                required
                placeholder={translateLabel("RETYPE_NEW_PASSWORD")}
                name="pinCodeSecond"
                onChange={() => {
                  dispatch(clearAcccountingError("pinError"));
                  setFeedbackMessageSecond("");
                }}
                helperText={feedbackMessageSecond.length !== 0 ? feedbackMessageSecond : ""}
                colorStyle={feedbackMessageSecond.length !== 0 ? "error" : "enabled"}
                maxLength={6}
              />
            </WrapInput>
          </Row>
        </form>
        <ButtonContainer>
          <ButtonSpacer>
            <Button
              type="secondary"
              onClick={() => {
                setFeedbackMessageFirst("");
                setFeedbackMessageSecond("");
                reset({ pinCodeFirst: "", pinCodeSecond: "" });
              }}
            >
              {translateLabel("RESET")}
            </Button>
          </ButtonSpacer>

          <Button
            type="primary"
            onClick={handleSubmit}
            isLoading={updateRequestStatus === "LOADING" || validateRequestStatus === "LOADING"}
          >
            {translateLabel("UPDATE")}
          </Button>
        </ButtonContainer>
      </ContentPopup>
    </Popup>
  );
}

const ContentPopup = styled.div`
  padding: 3rem;
  width: 42.375rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const WrapInput = styled.div`
  width: 100%;
`;

const InputSpacer = styled.div`
  margin-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonSpacer = styled.div`
  margin-right: 1.5rem;
`;

export default AccountingUpdatePinPopup;
