import styled from "styled-components/macro";

export const LensIcon = styled.div`
  width: 2.125rem;
  height: 1.75rem;
  border-top-left-radius: 1.125rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 0.625rem;
  border: 0.1875rem solid ${(props) => props.theme.palette.primary};
  position: relative;

  &::before {
    content: "";
    width: 0.6875rem;
    background: none;
    transform: rotate(230deg);
    border: 2px solid ${(props) => props.theme.palette.primary};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-size: 2.375rem;
    margin-top: 0;
    position: absolute;
    top: 0.45rem;
    left: 0.2rem;
    background-repeat: no-repeat;
    display: block;
  }
  &::after {
    content: "";
    width: 0.4rem;
    background: none;
    transform: rotate(230deg);
    border: 2px solid ${(props) => props.theme.palette.primary};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-size: 2.375rem;
    margin-top: 0;
    position: absolute;
    top: 0.4rem;
    left: 0.65rem;
    background-repeat: no-repeat;
    display: block;
  }

  /* Inizio Colori lenti RX */
  &.SOLID_MIRROR_FIRE {
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #ff0000 0%,
      #ffff00 75%,
      #ffff00 100%
    ); /* ff3.6+ */
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, #ff0000),
      color-stop(75%, #ffff00),
      color-stop(100%, #ffff00)
    ); /* safari4+,chrome */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #ff0000 0%,
      #ffff00 75%,
      #ffff00 100%
    ); /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(
      center,
      ellipse cover,
      #ff0000 0%,
      #ffff00 75%,
      #ffff00 100%
    ); /* opera 11.10+ */
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      #ff0000 0%,
      #ffff00 75%,
      #ffff00 100%
    ); /* ie10+ */
    background: radial-gradient(ellipse at center, #ff0000 0%, #ffff00 75%, #ffff00 100%); /* w3c */
  }

 &.SOLID_MIRROR_GREEN_MIRROR_BLUE
  {
  background: -moz-linear-gradient(135deg, #ff00ff 0%, #00B0B0 65%, #00B0B0 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #00B0B0),
      color-stop(35%, #00B0B0), color-stop(100%, #ff00ff) );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(135deg, #ff00ff 0%, #00B0B0 65%, #00B0B0 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(135deg, #ff00ff 0%, #00B0B0 65%, #00B0B0 100%)
      ; /* opera 11.10+ */
  background: -ms-linear-gradient(135deg, #ff00ff 0%, #00B0B0 65%, #00B0B0 100%)
      ; /* ie10+ */
  background: linear-gradient(315deg, #ff00ff 0%, #00B0B0 65%, #00B0B0 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#00B0B0', endColorstr = '#ff00ff', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ICE {
  background: -moz-radial-gradient(center, ellipse cover, #4348CC 0%, #80537E 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #4348CC), color-stop(100%, #80537E) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #4348CC 0%, #80537E 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #4348CC 0%, #80537E 100%)
      ; /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #4348CC 0%, #80537E 100%)
      ; /* ie10+ */
  background: radial-gradient(ellipse at center, #4348CC 0%, #80537E 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#4348CC', endColorstr = '#80537E', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_JADE {
  background: -moz-radial-gradient(center, ellipse cover, #00ff00 0%, #00ff00 20%,
      #5E36FF 85%, #5E36FF 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #00ff00), color-stop(20%, #00ff00),
      color-stop(85%, #5E36FF), color-stop(100%, #5E36FF) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #00ff00 0%, #00ff00 20%,
      #5E36FF 85%, #5E36FF 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #00ff00 0%, #00ff00 20%,
      #5E36FF 85%, #5E36FF 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #00ff00 0%, #00ff00 20%,
      #5E36FF 85%, #5E36FF 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #00ff00 0%, #00ff00 20%, #5E36FF 85%,
      #5E36FF 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#00ff00', endColorstr = '#5E36FF', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ORANGE {
  background: -moz-linear-gradient(90deg, #FF82A3 0%, #FF5100 50%, #FFB005 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFB005),
      color-stop(50%, #FF5100), color-stop(100%, #FF82A3) );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #FF82A3 0%, #FF5100 50%, #FFB005 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #FF82A3 0%, #FF5100 50%, #FFB005 100%)
      ; /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #FF82A3 0%, #FF5100 50%, #FFB005 100%)
      ; /* ie10+ */
  background: linear-gradient(0deg, #FF82A3 0%, #FF5100 50%, #FFB005 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#FFB005', endColorstr = '#FF82A3', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_GREY {
  background: -moz-radial-gradient(center, ellipse cover, #6c6c79 3%, #6c6c79 16%,
      #737379 23%, #7c7c7b 31%, #cacbd4 71%, #cacbd4 98%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(3%, #6c6c79), color-stop(16%, #6c6c79),
      color-stop(23%, #737379), color-stop(31%, #7c7c7b),
      color-stop(71%, #cacbd4), color-stop(98%, #cacbd4) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #6c6c79 3%, #6c6c79 16%,
      #737379 23%, #7c7c7b 31%, #cacbd4 71%, #cacbd4 98%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #6c6c79 3%, #6c6c79 16%,
      #737379 23%, #7c7c7b 31%, #cacbd4 71%, #cacbd4 98%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #6c6c79 3%, #6c6c79 16%,
      #737379 23%, #7c7c7b 31%, #cacbd4 71%, #cacbd4 98%); /* ie10+ */
  background: radial-gradient(ellipse at center, #6c6c79 3%, #6c6c79 16%, #737379 23%,
      #7c7c7b 31%, #cacbd4 71%, #cacbd4 98%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#6c6c79', endColorstr = '#cacbd4', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_POSITIVE_RED {
  background: -moz-radial-gradient(center, ellipse cover, #0300AD 0%, #CC00FF 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #0300AD), color-stop(100%, #CC00FF) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #0300AD 0%, #CC00FF 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #0300AD 0%, #CC00FF 100%)
      ; /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #0300AD 0%, #CC00FF 100%)
      ; /* ie10+ */
  background: radial-gradient(ellipse at center, #0300AD 0%, #CC00FF 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#0300AD', endColorstr = '#CC00FF', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ROAD {
  background: -moz-radial-gradient(center, ellipse cover, #652a58 0%, #652a58 8%,
      #c14564 50%, #d8d455 88%, #d8d455 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #652a58), color-stop(8%, #652a58),
      color-stop(50%, #c14564), color-stop(88%, #d8d455),
      color-stop(100%, #d8d455) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #652a58 0%, #652a58 8%,
      #c14564 50%, #d8d455 88%, #d8d455 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #652a58 0%, #652a58 8%,
      #c14564 50%, #d8d455 88%, #d8d455 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #652a58 0%, #652a58 8%,
      #c14564 50%, #d8d455 88%, #d8d455 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #652a58 0%, #652a58 8%, #c14564 50%,
      #d8d455 88%, #d8d455 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#652a58', endColorstr = '#d8d455', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_RUBY {
  background: -moz-radial-gradient(center, ellipse cover, #d61657 0%, #d61657 8%,
      #c72015 39%, #c25416 49%, #dbdc7b 80%, #dbdc7b 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #d61657), color-stop(8%, #d61657),
      color-stop(39%, #c72015), color-stop(49%, #c25416),
      color-stop(80%, #dbdc7b), color-stop(100%, #dbdc7b) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #d61657 0%, #d61657 8%,
      #c72015 39%, #c25416 49%, #dbdc7b 80%, #dbdc7b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #d61657 0%, #d61657 8%,
      #c72015 39%, #c25416 49%, #dbdc7b 80%, #dbdc7b 100%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #d61657 0%, #d61657 8%,
      #c72015 39%, #c25416 49%, #dbdc7b 80%, #dbdc7b 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #d61657 0%, #d61657 8%, #c72015 39%,
      #c25416 49%, #dbdc7b 80%, #dbdc7b 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#d61657', endColorstr = '#dbdc7b', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_SAPPHIRE {
  background: -moz-radial-gradient(center, ellipse cover, #6bc5e4 0%, #6bc5e4 8%,
      #278de1 39%, #2e7ed0 49%, #923cdc 80%, #923cdc 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #6bc5e4), color-stop(8%, #6bc5e4),
      color-stop(39%, #278de1), color-stop(49%, #2e7ed0),
      color-stop(80%, #923cdc), color-stop(100%, #923cdc) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #6bc5e4 0%, #6bc5e4 8%,
      #278de1 39%, #2e7ed0 49%, #923cdc 80%, #923cdc 100%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #6bc5e4 0%, #6bc5e4 8%,
      #278de1 39%, #2e7ed0 49%, #923cdc 80%, #923cdc 100%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #6bc5e4 0%, #6bc5e4 8%,
      #278de1 39%, #2e7ed0 49%, #923cdc 80%, #923cdc 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #6bc5e4 0%, #6bc5e4 8%, #278de1 39%,
      #2e7ed0 49%, #923cdc 80%, #923cdc 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#6bc5e4', endColorstr = '#923cdc', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_SHALLOW_BLUE {
  background: -moz-radial-gradient(center, ellipse cover, #0ebb5a 0%, #0ebb5a 8%,
      #0e9f62 40%, #29465a 65%, #4a1848 75%, #4a1848 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #0ebb5a), color-stop(8%, #0ebb5a),
      color-stop(40%, #0e9f62), color-stop(65%, #29465a),
      color-stop(75%, #4a1848), color-stop(100%, #4a1848) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #0ebb5a 0%, #0ebb5a 8%,
      #0e9f62 40%, #29465a 65%, #4a1848 75%, #4a1848 100%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #0ebb5a 0%, #0ebb5a 8%,
      #0e9f62 40%, #29465a 65%, #4a1848 75%, #4a1848 100%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #0ebb5a 0%, #0ebb5a 8%,
      #0e9f62 40%, #29465a 65%, #4a1848 75%, #4a1848 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #0ebb5a 0%, #0ebb5a 8%, #0e9f62 40%,
      #29465a 65%, #4a1848 75%, #4a1848 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#0ebb5a', endColorstr = '#4a1848', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_SILVER {
  background: -moz-radial-gradient(center, ellipse cover, #e8e9ed 0%, #e8e9ed 7%,
      #84929d 60%, #5a6873 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #e8e9ed), color-stop(7%, #e8e9ed),
      color-stop(60%, #84929d), color-stop(100%, #5a6873) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #e8e9ed 0%, #e8e9ed 7%,
      #84929d 60%, #5a6873 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #e8e9ed 0%, #e8e9ed 7%,
      #84929d 60%, #5a6873 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #e8e9ed 0%, #e8e9ed 7%,
      #84929d 60%, #5a6873 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #e8e9ed 0%, #e8e9ed 7%, #84929d 60%,
      #5a6873 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#e8e9ed', endColorstr = '#5a6873', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_GOLD {
  background: -moz-radial-gradient(center, ellipse cover, #f2ddbf 0%, #f2ddbf 7%,
      #88643c 60%, #4a261c 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #f2ddbf), color-stop(7%, #f2ddbf),
      color-stop(60%, #88643c), color-stop(100%, #4a261c) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #f2ddbf 0%, #f2ddbf 7%,
      #88643c 60%, #4a261c 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #f2ddbf 0%, #f2ddbf 7%,
      #88643c 60%, #4a261c 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #f2ddbf 0%, #f2ddbf 7%,
      #88643c 60%, #4a261c 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #f2ddbf 0%, #f2ddbf 7%, #88643c 60%,
      #4a261c 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#f2ddbf', endColorstr = '#4a261c', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_TORCH {
  background: -moz-radial-gradient(center, ellipse cover, #7f1a3a 0%, #7f1a3a 15%,
      #a82120 43%, #e6c72f 85%, #e6c72f 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #7f1a3a), color-stop(15%, #7f1a3a),
      color-stop(43%, #a82120), color-stop(85%, #e6c72f),
      color-stop(100%, #e6c72f) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #7f1a3a 0%, #7f1a3a 15%,
      #a82120 43%, #e6c72f 85%, #e6c72f 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #7f1a3a 0%, #7f1a3a 15%,
      #a82120 43%, #e6c72f 85%, #e6c72f 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #7f1a3a 0%, #7f1a3a 15%,
      #a82120 43%, #e6c72f 85%, #e6c72f 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #7f1a3a 0%, #7f1a3a 15%, #a82120 43%,
      #e6c72f 85%, #e6c72f 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#7f1a3a', endColorstr = '#e6c72f', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_TRAIL {
  background: -moz-radial-gradient(center, ellipse cover, #a0574d 0%, #a0574d 15%,
      #b761af 50%, #d697c6 85%, #d697c6 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #a0574d), color-stop(15%, #a0574d),
      color-stop(50%, #b761af), color-stop(85%, #d697c6),
      color-stop(100%, #d697c6) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #a0574d 0%, #a0574d 15%,
      #b761af 50%, #d697c6 85%, #d697c6 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #a0574d 0%, #a0574d 15%,
      #b761af 50%, #d697c6 85%, #d697c6 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #a0574d 0%, #a0574d 15%,
      #b761af 50%, #d697c6 85%, #d697c6 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #a0574d 0%, #a0574d 15%, #b761af 50%,
      #d697c6 85%, #d697c6 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#a0574d', endColorstr = '#d697c6', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ROAD_BLACK {
  background: -moz-radial-gradient(center, ellipse cover, #594c52 0%, #3b2e34 75%,
      #4d3c44 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #3b2e34), color-stop(75%, #3b2e34),
      color-stop(100%, #3b2e34) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #594c52 0%, #3b2e34 75%,
      #4d3c44 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #594c52 0%, #3b2e34 75%,
      #4d3c44 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #594c52 0%, #3b2e34 75%,
      #4d3c44 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #594c52 0%, #3b2e34 75%,
      #4d3c44 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#3b2e34', endColorstr = '#3b2e34', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_TRAIL_TORCH {
  background: -moz-radial-gradient(center, ellipse cover, #ce7f5f 10%, #ac535b 80%,
      #ce7f5f 20%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(10%, #ce7f5f), color-stop(80%, #ac535b),
      color-stop(20%, #ce7f5f) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #ce7f5f 10%, #ac535b 80%,
      #ce7f5f 20%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #ce7f5f 10%, #ac535b 80%,
      #ce7f5f 20%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #ce7f5f 10%, #ac535b 80%,
      #ce7f5f 20%); /* ie10+ */
  background: radial-gradient(ellipse at center, #ce7f5f 10%, #ac535b 80%,
      #ce7f5f 20%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#ac535b', endColorstr = '#ac535b', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ROSE_GOLD {
  background: -moz-radial-gradient(center, ellipse cover, #a87f5b 0%, #ad7467 75%,
      #c09573 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #c09573), color-stop(75%, #c09573),
      color-stop(100%, #c09573) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #ad7467 75%,
      #c09573 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #ad7467 75%,
      #c09573 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #ad7467 75%,
      #c09573 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #ad7467 75%,
      #c09573 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#c09573', endColorstr = '#c09573', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_TUNGSTEN {
  background: -moz-radial-gradient(center, ellipse cover, #775832 0%, #775832 11%,
      #966F3F 40%, #ae9677 69%, #dbcfbe 89%, #dbcfbe 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #775832), color-stop(11%, #775832),
      color-stop(40%, #966F3F), color-stop(69%, #ae9677),
      color-stop(89%, #dbcfbe), color-stop(100%, #dbcfbe) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #775832 0%, #775832 11%,
      #966F3F 40%, #ae9677 69%, #dbcfbe 89%, #dbcfbe 100%);
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #775832 0%, #775832 11%,
      #966F3F 40%, #ae9677 69%, #dbcfbe 89%, #dbcfbe 100%);
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #775832 0%, #775832 11%,
      #966F3F 40%, #ae9677 69%, #dbcfbe 89%, #dbcfbe 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #775832 0%, #775832 11%, #966F3F 40%,
      #ae9677 69%, #dbcfbe 89%, #dbcfbe 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#775832', endColorstr = '#dbcfbe', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_VIOLET {
  background: -moz-radial-gradient(center, ellipse cover, #4a4a7b 0%, #4a4a7b 15%,
      #8f5bd8 70%, #7684db 95%, #7684db 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #4a4a7b), color-stop(15%, #4a4a7b),
      color-stop(70%, #8f5bd8), color-stop(95%, #7684db),
      color-stop(100%, #7684db) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #4a4a7b 0%, #4a4a7b 15%,
      #8f5bd8 70%, #7684db 95%, #7684db 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #4a4a7b 0%, #4a4a7b 15%,
      #8f5bd8 70%, #7684db 95%, #7684db 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #4a4a7b 0%, #4a4a7b 15%,
      #8f5bd8 70%, #7684db 95%, #7684db 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #4a4a7b 0%, #4a4a7b 15%, #8f5bd8 70%,
      #7684db 95%, #7684db 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#4a4a7b', endColorstr = '#7684db', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_24K {
  background: -moz-radial-gradient(center, ellipse cover, rgba(225, 224, 116, 1)
      0%, rgba(225, 224, 116, 1) 13%, rgba(224, 179, 24, 1) 61%,
      rgba(186, 176, 62, 1) 86%, rgba(184, 176, 66, 1) 88% ); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, rgba(225, 224, 116, 1) ),
      color-stop(13%, rgba(225, 224, 116, 1) ),
      color-stop(61%, rgba(224, 179, 24, 1) ),
      color-stop(86%, rgba(186, 176, 62, 1) ),
      color-stop(88%, rgba(184, 176, 66, 1) ) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(225, 224, 116, 1)
      0%, rgba(225, 224, 116, 1) 13%, rgba(224, 179, 24, 1) 61%,
      rgba(186, 176, 62, 1) 86%, rgba(184, 176, 66, 1) 88% );
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(225, 224, 116, 1)
      0%, rgba(225, 224, 116, 1) 13%, rgba(224, 179, 24, 1) 61%,
      rgba(186, 176, 62, 1) 86%, rgba(184, 176, 66, 1) 88% );
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(225, 224, 116, 1)
      0%, rgba(225, 224, 116, 1) 13%, rgba(224, 179, 24, 1) 61%,
      rgba(186, 176, 62, 1) 86%, rgba(184, 176, 66, 1) 88% ); /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(225, 224, 116, 1) 0%,
      rgba(225, 224, 116, 1) 13%, rgba(224, 179, 24, 1) 61%,
      rgba(186, 176, 62, 1) 86%, rgba(184, 176, 66, 1) 88% ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#E1E074', endColorstr = '#B8B042', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_BLACK {
  background: -moz-radial-gradient(center, ellipse cover, rgba(54, 40, 40, 1)
      0%, rgba(54, 40, 40, 1) 13%, rgba(60, 46, 46, 1) 48%,
      rgba(105, 91, 91, 1) 81%, rgba(105, 91, 91, 1) 86% ); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, rgba(54, 40, 40, 1) ),
      color-stop(13%, rgba(54, 40, 40, 1) ),
      color-stop(48%, rgba(60, 46, 46, 1) ),
      color-stop(81%, rgba(105, 91, 91, 1) ),
      color-stop(86%, rgba(105, 91, 91, 1) ) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(54, 40, 40, 1)
      0%, rgba(54, 40, 40, 1) 13%, rgba(60, 46, 46, 1) 48%,
      rgba(105, 91, 91, 1) 81%, rgba(105, 91, 91, 1) 86% );
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(54, 40, 40, 1)
      0%, rgba(54, 40, 40, 1) 13%, rgba(60, 46, 46, 1) 48%,
      rgba(105, 91, 91, 1) 81%, rgba(105, 91, 91, 1) 86% );
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(54, 40, 40, 1)
      0%, rgba(54, 40, 40, 1) 13%, rgba(60, 46, 46, 1) 48%,
      rgba(105, 91, 91, 1) 81%, rgba(105, 91, 91, 1) 86% ); /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(54, 40, 40, 1) 0%,
      rgba(54, 40, 40, 1) 13%, rgba(60, 46, 46, 1) 48%, rgba(105, 91, 91, 1)
      81%, rgba(105, 91, 91, 1) 86% ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#362828', endColorstr = '#695B5B', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_BLUE {
  background: -moz-linear-gradient(314deg, rgba(226, 240, 249, 0.95) 0%,
      rgba(226, 240, 249, 0.95) 1%, rgba(105, 192, 235, 0.97) 8%,
      rgba(105, 192, 235, 0.98) 14%, rgba(87, 80, 163, 0.98) 19%,
      rgba(105, 192, 235, 0.98) 33%, rgba(105, 192, 235, 0.98) 65%,
      rgba(226, 240, 249, 1) 100% ); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(226
      , 240, 249, 0.95) ), color-stop(1%, rgba(226, 240, 249, 0.95) ),
      color-stop(8%, rgba(105, 192, 235, 0.97) ),
      color-stop(14%, rgba(105, 192, 235, 0.98) ),
      color-stop(19%, rgba(87, 80, 163, 0.98) ),
      color-stop(33%, rgba(105, 192, 235, 0.98) ),
      color-stop(65%, rgba(105, 192, 235, 0.98) ),
      color-stop(100%, rgba(226, 240, 249, 1) ) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(314deg, rgba(226, 240, 249, 0.95) 0%,
      rgba(226, 240, 249, 0.95) 1%, rgba(105, 192, 235, 0.97) 8%,
      rgba(105, 192, 235, 0.98) 14%, rgba(87, 80, 163, 0.98) 19%,
      rgba(105, 192, 235, 0.98) 33%, rgba(105, 192, 235, 0.98) 65%,
      rgba(226, 240, 249, 1) 100% ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(314deg, rgba(226, 240, 249, 0.95) 0%,
      rgba(226, 240, 249, 0.95) 1%, rgba(105, 192, 235, 0.97) 8%,
      rgba(105, 192, 235, 0.98) 14%, rgba(87, 80, 163, 0.98) 19%,
      rgba(105, 192, 235, 0.98) 33%, rgba(105, 192, 235, 0.98) 65%,
      rgba(226, 240, 249, 1) 100% ); /* opera 11.10+ */
  background: -ms-linear-gradient(314deg, rgba(226, 240, 249, 0.95) 0%,
      rgba(226, 240, 249, 0.95) 1%, rgba(105, 192, 235, 0.97) 8%,
      rgba(105, 192, 235, 0.98) 14%, rgba(87, 80, 163, 0.98) 19%,
      rgba(105, 192, 235, 0.98) 33%, rgba(105, 192, 235, 0.98) 65%,
      rgba(226, 240, 249, 1) 100% ); /* ie10+ */
  background: linear-gradient(136deg, rgba(226, 240, 249, 0.95) 0%,
      rgba(226, 240, 249, 0.95) 1%, rgba(105, 192, 235, 0.97) 8%,
      rgba(105, 192, 235, 0.98) 14%, rgba(87, 80, 163, 0.98) 19%,
      rgba(105, 192, 235, 0.98) 33%, rgba(105, 192, 235, 0.98) 65%,
      rgba(226, 240, 249, 1) 100% ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#e2f0f9', endColorstr = '#e2f0f9', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_COPPER {
  background: -moz-linear-gradient(314deg, rgba(233, 164, 148, 0.95) 0%,
      rgba(233, 164, 148, 0.98) 3%, rgba(196, 151, 120, 0.98) 5%,
      rgba(233, 164, 148, 0.99) 41%, rgba(233, 164, 148, 0.99) 65%,
      rgba(226, 240, 249, 1) 100% ); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(233
      , 164, 148, 0.95) ), color-stop(3%, rgba(233, 164, 148, 0.98) ),
      color-stop(5%, rgba(196, 151, 120, 0.98) ),
      color-stop(41%, rgba(233, 164, 148, 0.99) ),
      color-stop(65%, rgba(233, 164, 148, 0.99) ),
      color-stop(100%, rgba(226, 240, 249, 1) ) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(314deg, rgba(233, 164, 148, 0.95) 0%,
      rgba(233, 164, 148, 0.98) 3%, rgba(196, 151, 120, 0.98) 5%,
      rgba(233, 164, 148, 0.99) 41%, rgba(233, 164, 148, 0.99) 65%,
      rgba(226, 240, 249, 1) 100% ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(314deg, rgba(233, 164, 148, 0.95) 0%,
      rgba(233, 164, 148, 0.98) 3%, rgba(196, 151, 120, 0.98) 5%,
      rgba(233, 164, 148, 0.99) 41%, rgba(233, 164, 148, 0.99) 65%,
      rgba(226, 240, 249, 1) 100% ); /* opera 11.10+ */
  background: -ms-linear-gradient(314deg, rgba(233, 164, 148, 0.95) 0%,
      rgba(233, 164, 148, 0.98) 3%, rgba(196, 151, 120, 0.98) 5%,
      rgba(233, 164, 148, 0.99) 41%, rgba(233, 164, 148, 0.99) 65%,
      rgba(226, 240, 249, 1) 100% ); /* ie10+ */
  background: linear-gradient(136deg, rgba(233, 164, 148, 0.95) 0%,
      rgba(233, 164, 148, 0.98) 3%, rgba(196, 151, 120, 0.98) 5%,
      rgba(233, 164, 148, 0.99) 41%, rgba(233, 164, 148, 0.99) 65%,
      rgba(226, 240, 249, 1) 100% ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#E9A494', endColorstr = '#e2f0f9', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_DEEP_WATER {
  background: -moz-radial-gradient(center, ellipse cover, rgba(184, 213, 229, 0.95)
      0%, rgba(201, 229, 229, 0.97) 22%, rgba(146, 202, 229, 0.98) 48%,
      rgba(37, 86, 218, 0.99) 67%, rgba(104, 17, 181, 0.99) 86%,
      rgba(104, 17, 181, 1) 100% ); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, rgba(184, 213, 229, 0.95) ),
      color-stop(22%, rgba(201, 229, 229, 0.97) ),
      color-stop(48%, rgba(146, 202, 229, 0.98) ),
      color-stop(67%, rgba(37, 86, 218, 0.99) ),
      color-stop(86%, rgba(104, 17, 181, 0.99) ),
      color-stop(100%, rgba(104, 17, 181, 1) ) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(184, 213, 229, 0.95)
      0%, rgba(201, 229, 229, 0.97) 22%, rgba(146, 202, 229, 0.98) 48%,
      rgba(37, 86, 218, 0.99) 67%, rgba(104, 17, 181, 0.99) 86%,
      rgba(104, 17, 181, 1) 100% ); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(184, 213, 229, 0.95)
      0%, rgba(201, 229, 229, 0.97) 22%, rgba(146, 202, 229, 0.98) 48%,
      rgba(37, 86, 218, 0.99) 67%, rgba(104, 17, 181, 0.99) 86%,
      rgba(104, 17, 181, 1) 100% ); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(184, 213, 229, 0.95)
      0%, rgba(201, 229, 229, 0.97) 22%, rgba(146, 202, 229, 0.98) 48%,
      rgba(37, 86, 218, 0.99) 67%, rgba(104, 17, 181, 0.99) 86%,
      rgba(104, 17, 181, 1) 100% ); /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(184, 213, 229, 0.95)
      0%, rgba(201, 229, 229, 0.97) 22%, rgba(146, 202, 229, 0.98) 48%,
      rgba(37, 86, 218, 0.99) 67%, rgba(104, 17, 181, 0.99) 86%,
      rgba(104, 17, 181, 1) 100% ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#B8D5E5', endColorstr = '#6811B5', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_EMERALD {
  background: -moz-radial-gradient(center, ellipse cover, rgba(125, 137, 120, 0.95)
      0%, rgba(125, 137, 120, 0.96) 14%, rgba(126, 160, 166, 0.97) 46%,
      rgba(114, 203, 208, 0.99) 98%, rgba(114, 203, 208, 1) 100% );
  /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, rgba(125, 137, 120, 0.95) ),
      color-stop(14%, rgba(125, 137, 120, 0.96) ),
      color-stop(46%, rgba(126, 160, 166, 0.97) ),
      color-stop(98%, rgba(114, 203, 208, 0.99) ),
      color-stop(100%, rgba(114, 203, 208, 1) ) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(125, 137, 120, 0.95)
      0%, rgba(125, 137, 120, 0.96) 14%, rgba(126, 160, 166, 0.97) 46%,
      rgba(114, 203, 208, 0.99) 98%, rgba(114, 203, 208, 1) 100% );
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(125, 137, 120, 0.95)
      0%, rgba(125, 137, 120, 0.96) 14%, rgba(126, 160, 166, 0.97) 46%,
      rgba(114, 203, 208, 0.99) 98%, rgba(114, 203, 208, 1) 100% );
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(125, 137, 120, 0.95)
      0%, rgba(125, 137, 120, 0.96) 14%, rgba(126, 160, 166, 0.97) 46%,
      rgba(114, 203, 208, 0.99) 98%, rgba(114, 203, 208, 1) 100% );
  /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(125, 137, 120, 0.95)
      0%, rgba(125, 137, 120, 0.96) 14%, rgba(126, 160, 166, 0.97) 46%,
      rgba(114, 203, 208, 0.99) 98%, rgba(114, 203, 208, 1) 100% );
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#7D8978', endColorstr = '#72CBD0', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_FIELD {
  background: -moz-radial-gradient(center, ellipse cover, rgba(155, 69, 98, 0.95)
      0%, rgba(155, 69, 98, 0.96) 21%, rgba(217, 120, 96, 0.98) 62%,
      rgba(223, 196, 108, 0.99) 98%, rgba(223, 196, 108, 1) 100% );
  /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, rgba(155, 69, 98, 0.95) ),
      color-stop(21%, rgba(155, 69, 98, 0.96) ),
      color-stop(62%, rgba(217, 120, 96, 0.98) ),
      color-stop(98%, rgba(223, 196, 108, 0.99) ),
      color-stop(100%, rgba(223, 196, 108, 1) ) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(155, 69, 98, 0.95)
      0%, rgba(155, 69, 98, 0.96) 21%, rgba(217, 120, 96, 0.98) 62%,
      rgba(223, 196, 108, 0.99) 98%, rgba(223, 196, 108, 1) 100% );
  /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(155, 69, 98, 0.95)
      0%, rgba(155, 69, 98, 0.96) 21%, rgba(217, 120, 96, 0.98) 62%,
      rgba(223, 196, 108, 0.99) 98%, rgba(223, 196, 108, 1) 100% );
  /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(155, 69, 98, 0.95)
      0%, rgba(155, 69, 98, 0.96) 21%, rgba(217, 120, 96, 0.98) 62%,
      rgba(223, 196, 108, 0.99) 98%, rgba(223, 196, 108, 1) 100% );
  /* ie10+ */
  background: radial-gradient(ellipse at center, rgba(155, 69, 98, 0.95)
      0%, rgba(155, 69, 98, 0.96) 21%, rgba(217, 120, 96, 0.98) 62%,
      rgba(223, 196, 108, 0.99) 98%, rgba(223, 196, 108, 1) 100% );
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#9B4562', endColorstr = '#DFC46C', GradientType = 0 ); /* ie6-9 */
 }
 &.GRADIENT_BLUE {
  background: -moz-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 23%, #768B8C 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #768B8C),
      color-stop(77%, #CFCCCC), color-stop(100%, #CFCCCC) );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 23%, #768B8C 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 23%, #768B8C 100%)
      ; /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 23%, #768B8C 100%)
      ; /* ie10+ */
  background: linear-gradient(0deg, #CFCCCC 0%, #CFCCCC 23%, #768B8C 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#768B8C', endColorstr = '#CFCCCC', GradientType = 0 ); /* ie6-9 */
 }
 &.GRADIENT_BROWN {
  background: -moz-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 29%, #59381D 95%,
      #59381D 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #59381D),
      color-stop(5%, #59381D), color-stop(71%, #CFCCCC),
      color-stop(100%, #CFCCCC) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 29%, #59381D 95%,
      #59381D 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 29%, #59381D 95%, #59381D
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #CFCCCC 0%, #CFCCCC 29%, #59381D 95%, #59381D
      100%); /* ie10+ */
  background: linear-gradient(0deg, #CFCCCC 0%, #CFCCCC 29%, #59381D 95%, #59381D 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#59381D', endColorstr = '#CFCCCC', GradientType = 0 ); /* ie6-9 */
 }
 &.GRADIENT_GREY {
  background: -moz-linear-gradient(90deg, #A3A3A3 0%, #A3A3A3 26%, #32363B 98%,
      #32363B 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #32363B),
      color-stop(2%, #32363B), color-stop(74%, #A3A3A3),
      color-stop(100%, #A3A3A3) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #A3A3A3 0%, #A3A3A3 26%, #32363B 98%,
      #32363B 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #A3A3A3 0%, #A3A3A3 26%, #32363B 98%, #32363B
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #A3A3A3 0%, #A3A3A3 26%, #32363B 98%, #32363B
      100%); /* ie10+ */
  background: linear-gradient(0deg, #A3A3A3 0%, #A3A3A3 26%, #32363B 98%, #32363B 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#32363B', endColorstr = '#A3A3A3', GradientType = 0 ); /* ie6-9 */
 }
 &.GRADIENT_MIRROR_BLUE {
  background: -moz-linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 11%, #0093F5 55%,
      #4D0BA3 86%, #4D0BA3 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #4D0BA3),
      color-stop(14%, #4D0BA3), color-stop(45%, #0093F5),
      color-stop(89%, #FFFFFF), color-stop(100%, #FFFFFF) );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 11%, #0093F5 55%,
      #4D0BA3 86%, #4D0BA3 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 11%, #0093F5 55%, #4D0BA3
      86%, #4D0BA3 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 11%, #0093F5 55%,
      #4D0BA3 86%, #4D0BA3 100%); /* ie10+ */
  background: linear-gradient(315deg, #FFFFFF 0%, #FFFFFF 11%, #0093F5 55%, #4D0BA3
      86%, #4D0BA3 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#4D0BA3', endColorstr = '#FFFFFF', GradientType = 1 ); /* ie6-9 */
 }
 &.GRADIENT_MIRROR_PURPLE_GOLD{
    background: -moz-linear-gradient(45deg, rgba(244,210,164,1) 0%, rgba(244,210,164,1) 16%, rgba(154,104,106,1) 82%, rgba(154,104,106,1) 99%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(244,210,164,1)), color-stop(16%, rgba(244,210,164,1)), color-stop(82%, rgba(154,104,106,1)), color-stop(99%, rgba(154,104,106,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(244,210,164,1) 0%, rgba(244,210,164,1) 16%, rgba(154,104,106,1) 82%, rgba(154,104,106,1) 99%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(244,210,164,1) 0%, rgba(244,210,164,1) 16%, rgba(154,104,106,1) 82%, rgba(154,104,106,1) 99%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(244,210,164,1) 0%, rgba(244,210,164,1) 16%, rgba(154,104,106,1) 82%, rgba(154,104,106,1) 99%); /* ie10+ */
    background: linear-gradient(45deg, rgba(244,210,164,1) 0%, rgba(244,210,164,1) 16%, rgba(154,104,106,1) 82%, rgba(154,104,106,1) 99%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9A686A', endColorstr='#F4D2A4',GradientType=1 ); /* ie6-9 */
}
&.GRADIENT_MIRROR_BROWN_SILVER{
    background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(128,108,70,1) 65%, rgba(128,108,70,1) 99%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(65%, rgba(128,108,70,1)), color-stop(99%, rgba(128,108,70,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(128,108,70,1) 65%, rgba(128,108,70,1) 99%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(128,108,70,1) 65%, rgba(128,108,70,1) 99%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(128,108,70,1) 65%, rgba(128,108,70,1) 99%); /* ie10+ */
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(128,108,70,1) 65%, rgba(128,108,70,1) 99%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#806c46', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
}
 &.GRADIENT_MIRROR_GOLD {
  background: -moz-linear-gradient(135deg, #757D43 0%, #757D43 30%, #D1C96B 67%,
      #D1C96B 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #D1C96B),
      color-stop(33%, #D1C96B), color-stop(70%, #757D43),
      color-stop(100%, #757D43) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(135deg, #757D43 0%, #757D43 30%, #D1C96B 67%,
      #D1C96B 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(135deg, #757D43 0%, #757D43 30%, #D1C96B 67%, #D1C96B
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(135deg, #757D43 0%, #757D43 30%, #D1C96B 67%,
      #D1C96B 100%); /* ie10+ */
  background: linear-gradient(315deg, #757D43 0%, #757D43 30%, #D1C96B 67%, #D1C96B
      100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#D1C96B', endColorstr = '#757D43', GradientType = 1 ); /* ie6-9 */
 }

 &.GRADIENT_MIRROR_BROWN_GOLD{
    background: -moz-linear-gradient(45deg, rgba(239,223,110,1) 0%, rgba(117,101,68,1) 65%, rgba(117,101,68,1) 99%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(239,223,110,1)), color-stop(65%, rgba(117,101,68,1)), color-stop(99%, rgba(117,101,68,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(239,223,110,1) 0%, rgba(117,101,68,1) 65%, rgba(117,101,68,1) 99%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(239,223,110,1) 0%, rgba(117,101,68,1) 65%, rgba(117,101,68,1) 99%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(239,223,110,1) 0%, rgba(117,101,68,1) 65%, rgba(117,101,68,1) 99%); /* ie10+ */
    background: linear-gradient(45deg, rgba(239,223,110,1) 0%, rgba(117,101,68,1) 65%, rgba(117,101,68,1) 99%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#756544', endColorstr='#efdf6e',GradientType=1 ); /* ie6-9 */
}
 &.GRADIENT_MIRROR_GREY_MIRROR_GREY_GRADIENT
  {
  background: -moz-linear-gradient(45deg, #FFFFFF 0%, #8A8A8A 31%, #8a8a8a 68%,
      #FAFAFA 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #FFFFFF),
      color-stop(31%, #8A8A8A), color-stop(68%, #8a8a8a),
      color-stop(100%, #FAFAFA) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #FFFFFF 0%, #8A8A8A 31%, #8a8a8a 68%,
      #FAFAFA 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #FFFFFF 0%, #8A8A8A 31%, #8a8a8a 68%, #FAFAFA
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #FFFFFF 0%, #8A8A8A 31%, #8a8a8a 68%, #FAFAFA
      100%); /* ie10+ */
  background: linear-gradient(45deg, #FFFFFF 0%, #8A8A8A 31%, #8a8a8a 68%, #FAFAFA
      100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#FAFAFA', endColorstr = '#FFFFFF', GradientType = 1 ); /* ie6-9 */
 }
 &.GRADIENT_MIRROR_SILVER {
  background: -moz-linear-gradient(135deg, #ffffff 0%, #ffffff 12%, #454545 93%,
      #454545 100%, #454545 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #454545),
      color-stop(0%, #454545), color-stop(7%, #454545),
      color-stop(88%, #ffffff), color-stop(100%, #ffffff) );
  /* safari4+,chrome */
  background: -webkit-linear-gradient(135deg, #ffffff 0%, #ffffff 12%, #454545 93%,
      #454545 100%, #454545 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(135deg, #ffffff 0%, #ffffff 12%, #454545 93%, #454545
      100%, #454545 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(135deg, #ffffff 0%, #ffffff 12%, #454545 93%,
      #454545 100%, #454545 100%); /* ie10+ */
  background: linear-gradient(315deg, #ffffff 0%, #ffffff 12%, #454545 93%, #454545
      100%, #454545 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#454545', endColorstr = '#ffffff', GradientType = 1 ); /* ie6-9 */
 }
 &.GRADIENT_MIRROR_GREEN_MIRROR_GOLD_GRADIENT
  {
  background: -moz-linear-gradient(45deg, #7F915C 0%, #7F915C 8%, #A8AD79 43%, #EBD86C
      100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #7F915C),
      color-stop(8%, #7F915C), color-stop(43%, #A8AD79),
      color-stop(100%, #EBD86C) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, #7F915C 0%, #7F915C 8%, #A8AD79 43%,
      #EBD86C 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, #7F915C 0%, #7F915C 8%, #A8AD79 43%, #EBD86C
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, #7F915C 0%, #7F915C 8%, #A8AD79 43%, #EBD86C
      100%); /* ie10+ */
  background: linear-gradient(45deg, #7F915C 0%, #7F915C 8%, #A8AD79 43%, #EBD86C 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#EBD86C', endColorstr = '#7F915C', GradientType = 1 ); /* ie6-9 */
 }
 &.GRADIENT_ROSE {
  background: -moz-linear-gradient(90deg, #5c2c3a 0%, #5c2c3a 26%, #21060d 98%,
      #21060d 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #21060d),
      color-stop(2%, #21060d), color-stop(74%, #5c2c3a),
      color-stop(100%, #5c2c3a) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #5c2c3a 0%, #5c2c3a 26%, #21060d 98%,
      #21060d 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #5c2c3a 0%, #5c2c3a 26%, #21060d 98%, #21060d
      100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #5c2c3a 0%, #5c2c3a 26%, #21060d 98%, #21060d
      100%); /* ie10+ */
  background: linear-gradient(0deg, #5c2c3a 0%, #5c2c3a 26%, #21060d 98%, #21060d 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#21060d', endColorstr = '#5c2c3a', GradientType = 0 ); /* ie6-9 */
 }
 &.PHOTO_BLACK {
  background: -moz-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #8C8C8C), color-stop(100%, #FFFFFF) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* ie10+ */
  background: radial-gradient(ellipse at center, #8C8C8C 0%, #FFFFFF 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#8C8C8C', endColorstr = '#FFFFFF', GradientType = 1 ); /* ie6-9 */
 }
 &.PHOTO_BLACK_IRIDIUM {
  background: -moz-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #8C8C8C), color-stop(100%, #FFFFFF) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #8C8C8C 0%, #FFFFFF 100%)
      ; /* ie10+ */
  background: radial-gradient(ellipse at center, #8C8C8C 0%, #FFFFFF 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#8C8C8C', endColorstr = '#FFFFFF', GradientType = 1 ); /* ie6-9 */
 }
 &.PHOTO_GREY {
  background: -moz-radial-gradient(center, ellipse cover, #545454 0%, #616161 17%,
      #D6D6D6 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #545454), color-stop(17%, #616161),
      color-stop(100%, #D6D6D6) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #545454 0%, #616161 17%,
      #D6D6D6 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #545454 0%, #616161 17%,
      #D6D6D6 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #545454 0%, #616161 17%,
      #D6D6D6 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #545454 0%, #616161 17%, #D6D6D6 100%
      ); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#545454', endColorstr = '#D6D6D6', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_BLUE {
  background: #535c63;
 }
 &.SOLID_BLACK {
  background:#434343;
 }
 &.SOLID_BRONZE {
  background: -moz-radial-gradient(center, ellipse cover, #917E50 0%, #99783A 46%,
      #80745b 80%, #998B6D 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #917E50), color-stop(46%, #99783A),
      color-stop(80%, #80745b), color-stop(100%, #998B6D) );
  /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #917E50 0%, #99783A 46%,
      #80745b 80%, #998B6D 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #917E50 0%, #99783A 46%,
      #80745b 80%, #998B6D 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #917E50 0%, #99783A 46%,
      #80745b 80%, #998B6D 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #917E50 0%, #99783A 46%, #80745b 80%,
      #998B6D 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#917E50', endColorstr = '#998B6D', GradientType = 0 ); /* ie6-9 */
 }
  &.SOLID_BROWN {
    background: #9f6e45;
  }
  &.SOLID_LIGHT_BROWN{
   background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(217,205,193,1) 0%, rgba(217,205,193,1) 100%); /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(217,205,193,1)), color-stop(100%, rgba(217,205,193,1))); /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(217,205,193,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(217,205,193,1) 0%, rgba(217,205,193,1) 100%); /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(217,205,193,1) 0%, rgba(217,205,193,1) 100%); /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(217,205,193,1) 0%, rgba(217,205,193,1) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#059a78',GradientType=1 ); /* ie6-9 */
  }
 &.SOLID_CLEAR {
  background: #b2b2b1;
 }
 &.SOLID_GREEN {
  background: #6d7b6e;
 }
 &.SOLID_B15{
  background: #9f6e45;
 }
 &.SOLID_G15 {
  background: #6d7b6e;
 }
 &.SOLID_B-15{
  background: #9f6e45;
 }
 &.SOLID_G-15 {
  background: #6d7b6e;
 }
 &.SOLID_DARK_GREY {
  background: #605e5f;
 }
 &.SOLID_DARK_BLUE {
  background: #5b6d85;
 }
 &.SOLID_MIRROR_DARK_GOLF {
  background: -moz-linear-gradient(316deg, #F15CFF 0%, #942959 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #F15CFF),
      color-stop(100%, #942959) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(316deg, #F15CFF 0%, #942959 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(316deg, #F15CFF 0%, #942959 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(316deg, #F15CFF 0%, #942959 100%);
  /* ie10+ */
  background: linear-gradient(134deg, #F15CFF 0%, #942959 100%);
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#F15CFF', endColorstr = '#942959', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_DEEP_BLUE {
  background: -moz-linear-gradient(310deg, #8ECAED 0%, #326996 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #8ECAED),
      color-stop(100%, #326996) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(310deg, #8ECAED 0%, #326996 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(310deg, #8ECAED 0%, #326996 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(310deg, #8ECAED 0%, #326996 100%);
  /* ie10+ */
  background: linear-gradient(140deg, #8ECAED 0%, #326996 100%);
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#8ECAED', endColorstr = '#326996', GradientType = 0 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_GOLF {
  background: -moz-linear-gradient(310deg, #ED8AEB 0%, #B03F6E 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #ED8AEB),
      color-stop(100%, #B03F6E) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(310deg, #ED8AEB 0%, #B03F6E 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(310deg, #ED8AEB 0%, #B03F6E 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(310deg, #ED8AEB 0%, #B03F6E 100%);
  /* ie10+ */
  background: linear-gradient(140deg, #ED8AEB 0%, #B03F6E 100%);
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#ED8AEB', endColorstr = '#B03F6E', GradientType = 0 ); /* ie6-9 */
 }
 &.PHOTO_BROWN {
  background: -moz-linear-gradient(310deg, #EDDFAF 0%, #B08956 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #EDDFAF),
      color-stop(100%, #B08956) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(310deg, #EDDFAF 0%, #B08956 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(310deg, #EDDFAF 0%, #B08956 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(310deg, #EDDFAF 0%, #B08956 100%);
  /* ie10+ */
  background: linear-gradient(140deg, #EDDFAF 0%, #B08956 100%);
  /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#EDDFAF', endColorstr = '#B08956', GradientType = 0 ); /* ie6-9 */
 }
 &.PHOTO_GREEN {
  background: #6d7b6e;
 }
 &.PHOTO_G-15 {
  background: #6d7b6e;
 }
 &.PHOTO_GRAPHITE_GREEN {
  background-color:#6d7b6e;
 }
 &.SOLID_GREY {
  background: -moz-radial-gradient(center, ellipse cover, #71737A 0%, #71737A 9%,
      #DEDEDE 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #71737A), color-stop(9%, #71737A),
      color-stop(100%, #DEDEDE) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #71737A 0%, #71737A 9%,
      #DEDEDE 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #71737A 0%, #71737A 9%,
      #DEDEDE 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #71737A 0%, #71737A 9%,
      #DEDEDE 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #71737A 0%, #71737A 9%, #DEDEDE 100%)
      ; /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#71737A', endColorstr = '#DEDEDE', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_ROAD_JADE {
  background: -moz-radial-gradient(center, ellipse cover, #2b984c 10%, #25a54d 10%, #6c5b71 50%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #6c5b71), color-stop(9%, #6c5b71),
      color-stop(100%, #DEDEDE) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #2b984c 10%, #25a54d 10%, #6c5b71 50%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #2b984c 10%, #25a54d 10%, #6c5b71 50%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #2b984c 10%, #25a54d 10%, #6c5b71 50%); /* ie10+ */
  background: radial-gradient(ellipse at center, #2b984c 10%, #25a54d 10%, #6c5b71 50%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#6c5b71', endColorstr = '#6c5b71', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_SLATE {
  background: -moz-radial-gradient(center, ellipse cover, #e1e1e1 0%, #e1e1e1 20%,
      #bcbdbf 50%, #9fa2a6 80%, #9fa2a6 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #e1e1e1), color-stop(20%, #e1e1e1),
      color-stop(50%, #bcbdbf), color-stop(80%, #9fa2a6),
      color-stop(100%, #9fa2a6) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #e1e1e1 0%, #e1e1e1 20%,
      #bcbdbf 50%, #9fa2a6 80%, #9fa2a6 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #e1e1e1 0%, #e1e1e1 20%,
      #bcbdbf 50%, #9fa2a6 80%, #9fa2a6 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #e1e1e1 0%, #e1e1e1 20%,
      #bcbdbf 50%, #9fa2a6 80%, #9fa2a6 100%); /* ie10+ */
  background: radial-gradient(ellipse at center, #e1e1e1 0%, #e1e1e1 20%, #bcbdbf 50%,
      #9fa2a6 80%, #9fa2a6 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient (   startColorstr =
      '#e1e1e1', endColorstr = '#9fa2a6', GradientType = 1 ); /* ie6-9 */
 }
 &.SOLID_COPPER{
  background: -moz-radial-gradient(center, ellipse cover, rgba(125,66,31,1) 0%, rgba(189,99,47,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(125,66,31,1)), color-stop(100%, rgba(189,99,47,1))); /* safari4+,chrome */
  background:-webkit-radial-gradient(center, ellipse cover, rgba(125,66,31,1) 0%, rgba(189,99,47,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(125,66,31,1) 0%, rgba(189,99,47,1) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(125,66,31,1) 0%, rgba(189,99,47,1) 100%); /* ie10+ */
  background:radial-gradient(ellipse at center, rgba(125,66,31,1) 0%, rgba(189,99,47,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7D421F', endColorstr='#BD632F',GradientType=1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_COPPER_SILVER{
  background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(225,207,195,1) 0%, rgba(168,142,133,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(225,207,195,1)), color-stop(100%, rgba(168,142,133,1))); /* safari4+,chrome */
  background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(225,207,195,1) 0%, rgba(168,142,133,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(225,207,195,1) 0%, rgba(168,142,133,1) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(225,207,195,1) 0%, rgba(168,142,133,1) 100%); /* ie10+ */
  background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(225,207,195,1) 0%, rgba(168,142,133,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#A88E85',GradientType=1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_GREY_SILVER{
  background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,197,198,1) 0%, rgba(128,126,127,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(199,197,198,1)), color-stop(100%, rgba(128,126,127,1))); /* safari4+,chrome */
  background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,197,198,1) 0%, rgba(128,126,127,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,197,198,1) 0%, rgba(128,126,127,1) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,197,198,1) 0%, rgba(128,126,127,1) 100%); /* ie10+ */
  background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(199,197,198,1) 0%, rgba(128,126,127,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#807e7f',GradientType=1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_SUNRISE_SILVER{
  background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(255,253,196,1) 0%, rgba(207,204,140,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(255,253,196,1)), color-stop(100%, rgba(207,204,140,1))); /* safari4+,chrome */
  background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(255,253,196,1) 0%, rgba(207,204,140,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(255,253,196,1) 0%, rgba(207,204,140,1) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(255,253,196,1) 0%, rgba(207,204,140,1) 100%); /* ie10+ */
  background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(255,253,196,1) 0%, rgba(207,204,140,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#CFCC8C',GradientType=1 ); /* ie6-9 */
 }
 &.SOLID_MIRROR_GREEN{
  background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(5,154,120,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(6,186,144,1)), color-stop(100%, rgba(5,154,120,1))); /* safari4+,chrome */
  background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(5,154,120,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(5,154,120,1) 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(5,154,120,1) 100%); /* ie10+ */
  background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(6,186,144,1) 0%, rgba(5,154,120,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#059a78',GradientType=1 ); /* ie6-9 */
 }
 /*nuovi colori lente RX*/
 &.PHOTO_SAPPHIRE {
  background-color:#545C99
 }
 &.PHOTO_AMETHYST {
  background-color:#8C6EAD
 }
 &.PHOTO_AMBER {
  background-color:#B4844F
 }
 &.PHOTO_EMERALD {
  background-color:#218750
 }
 &.PHOTO_COPPER {
  background-color:#b87333
 }
 &.PHOTO_B15{
  background: #9f6e45;
 }
 &.PHOTO_G15 {
  background: #6d7b6e;
 }
 &.PHOTO_B-15{
  background: #9f6e45;
 }
 &.PHOTO_G-15 {
  background: #6d7b6e;
 }
 // LENTI NEW TRANSITIONS GEN
 &.PHOTO_RUBY {
    background-color: #81344e;
 }
 &.SOLID_INDIGO{
  background-color:#6C768F;
 }
 &.SOLID_VIOLET{
  background-color:#83464B;
 }
 &.SOLID_LOW_LIGHT{
  background-color:#efd1dc;
 }
 &.SOLID_TRANSPARENT_BROWN{
  background-color:#d9cbbe;
 }
 &.SOLID_LIGHT_GREY {
    background: #9aa3a0;
 }
 &.SOLID_LIGHT_PINK {
    background:#f8cbae;
 }
 // LENTI SUPERNOVA
 &.SOLID_TEAL_BLUE {
    background-color: #7a9294;
 }
 &.SOLID_DUSTY_BLUE {
    background-color: #5a5f73;
 }
 &.SOLID_DUSTY_RED {
    background-color: #a0636b;
 }
 &.SOLID_LAPIS_BLUE {
    background-color: #7f84be;
 }
 &.SOLID_LILIAC {
    background-color: #cdb7e8;
 }
 &.SOLID_BLUSH_ROSE {
    background-color: #f7c9d3;
 }
 &.PHOTO_CERULEAN_BLUE {
    background-color: #7691a2;
 }
 &.PHOTO_DARK_GREEN {
    background-color: #7d7340;
 }
 &.PHOTO_SAFFRON_YELLOW {
    background-color: #b4742c;
 }
 &.PHOTO_WATERMELON_RED {
    background-color: #bf7375;
 }
&.SOLID_LIGHT_BLUE {
    background-color: #91bbd3;
}
&.SOLID_GREYW {
    background-color: #c4c4be;
}
&.SOLID_LIGHT_GREEN {
    background-color: #bcccb3;
}
&.SOLID_YELLOW {
    background-color: #fceca4;
}
&.GRADIENT_CLEAR_BLUE {
    background: -moz-linear-gradient(90deg, #eaeae5 0%, #eaeae5 29% , #717d92 95%,
      #717d92 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #717d92),
      color-stop(29%, #eaeae5), color-stop(95%, #717d92), 
      color-stop(100%, #717d92) ); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, #eaeae5 0%, #eaeae5 29%,  #717d92 95%,
    #717d92 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, #eaeae5 0%, #eaeae5 29%, #717d92 95%,
    #717d92 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, #eaeae5 0%, #eaeae5 29%, #717d92 95%,
    #717d92 100%); /* ie10+ */
  background: linear-gradient(90deg, #eaeae5 0%, #eaeae5 29%,  #717d92 95%,
    #ecebe6 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#717d92', endColorstr = '#eaeae5', GradientType = 0 ); /* ie6-9 */
}

//// MIRROR SNOW
&.SOLID_MIRROR_SNOW_SAPPHIRE {
  background: -moz-radial-gradient(center, ellipse cover, #e0c1c8 0%, #e0c1c8 15%,
      #dbc2cd 50%, #b5a1ac 85%, #b5a1ac 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #e0c1c8), color-stop(15%, #e0c1c8),
      color-stop(50%, #dbc2cd), color-stop(85%, #b5a1ac),
      color-stop(100%, #b5a1ac) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #e0c1c8 0%, #e0c1c8 15%,
    #dbc2cd 50%, #b5a1ac 85%, #b5a1ac 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #e0c1c8 0%, #e0c1c8 15%,
    #dbc2cd 50%, #b5a1ac 85%, #b5a1ac 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #e0c1c8 0%, #e0c1c8 15%,
    #dbc2cd 50%, #b5a1ac 85%, #b5a1ac 100%); /* ie10+ */
  background: radial-gradient(center, ellipse cover, #e0c1c8 0%, #e0c1c8 15%,
    #dbc2cd 50%, #b5a1ac 85%, #b5a1ac 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#e0c1c8', endColorstr = '#b5a1ac', GradientType = 1 ); /* ie6-9 */
 }

 &.SOLID_MIRROR_SNOW_BLACK {
  background: -moz-radial-gradient(center, ellipse cover, #8c646c 0%, #8c646c 15%,
      #845b64 50%, #7c545c 85%, #7c545c 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #8c646c), color-stop(15%, #8c646c),
      color-stop(50%, #845b64), color-stop(85%, #7c545c),
      color-stop(100%, #7c545c) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #8c646c 0%, #8c646c 15%,
    #845b64 50%, #7c545c 85%, #7c545c 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #8c646c 0%, #8c646c 15%,
    #845b64 50%, #7c545c 85%, #7c545c 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #8c646c 0%, #8c646c 15%,
    #845b64 50%, #7c545c 85%, #7c545c 100%); /* ie10+ */
  background: radial-gradient(center, ellipse cover, #8c646c 0%, #8c646c 15%,
    #845b64 50%, #7c545c 85%, #7c545c 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#8c646c', endColorstr = '#7c545c', GradientType = 1 ); /* ie6-9 */
 }

 &.SOLID_MIRROR_SNOW_TORCH {
  background: -moz-radial-gradient(center, ellipse cover, #f6a865 0%, #f6a865 15%,
      #fb957b 50%, #f37685 85%, #f37685 100%); /* ff3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%,
      color-stop(0%, #f6a865), color-stop(15%, #f6a865),
      color-stop(50%, #fb957b), color-stop(85%, #f37685),
      color-stop(100%, #f37685) ); /* safari4+,chrome */
  background: -webkit-radial-gradient(center, ellipse cover, #f6a865 0%, #f6a865 15%,
    #fb957b 50%, #f37685 85%, #f37685 100%); /* safari5.1+,chrome10+ */
  background: -o-radial-gradient(center, ellipse cover, #f6a865 0%, #f6a865 15%,
    #fb957b 50%, #f37685 85%, #f37685 100%); /* opera 11.10+ */
  background: -ms-radial-gradient(center, ellipse cover, #f6a865 0%, #f6a865 15%,
    #fb957b 50%, #f37685 85%, #f37685 100%); /* ie10+ */
  background: radial-gradient(center, ellipse cover, #f6a865 0%, #f6a865 15%,
    #fb957b 50%, #f37685 85%, #f37685 100%); /* w3c */
  filter: progid : DXImageTransform.Microsoft.gradient ( startColorstr =
      '#f6a865', endColorstr = '#f37685', GradientType = 1 ); /* ie6-9 */
 }
 
 // // // // // //
 &.SOLID_MIRROR_GREEN_SILVER{
  background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(74,75,61,1) 87%, rgba(74,75,61,1) 87%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(87%, rgba(74,75,61,1)), color-stop(87%, rgba(74,75,61,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(74,75,61,1) 87%, rgba(74,75,61,1) 87%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(74,75,61,1) 87%, rgba(74,75,61,1) 87%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(74,75,61,1) 87%, rgba(74,75,61,1) 87%); /* ie10+ */
  background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(74,75,61,1) 87%, rgba(74,75,61,1) 87%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4A4B3D', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
 }
 &.GRADIENT_LIGHT_BROWN{
  background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(227,218,209,1) 14%, rgba(129,89,48,1) 64%, rgba(129,89,48,1) 87%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(14%, rgba(227,218,209,1)), color-stop(64%, rgba(129,89,48,1)), color-stop(87%, rgba(129,89,48,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(227,218,209,1) 14%, rgba(129,89,48,1) 64%, rgba(129,89,48,1) 87%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(227,218,209,1) 14%, rgba(129,89,48,1) 64%, rgba(129,89,48,1) 87%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(227,218,209,1) 14%, rgba(129,89,48,1) 64%, rgba(129,89,48,1) 87%); /* ie10+ */
  background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(227,218,209,1) 14%, rgba(129,89,48,1) 64%, rgba(129,89,48,1) 87%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#815930', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
 }
  &.GRADIENT_PINK{
    background: rgba(203,182,175,1);/* Old Browsers */
    background: -moz-linear-gradient(top, rgba(203,182,175,1) 0%, rgba(229,228,226,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(203,182,175,1)), color-stop(100%, rgba(229,228,226,1)));/* Chrome, Safari4+ */
    background: -webkit-linear-gradient(top, rgba(203,182,175,1) 0%, rgba(229,228,226,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(203,182,175,1) 0%, rgba(229,228,226,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(203,182,175,1) 0%, rgba(229,228,226,1) 100%); /* IE 10+ */
    background: linear-gradient(to bottom, rgba(203,182,175,1) 0%, rgba(229,228,226,1) 100%);/* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbb6af', endColorstr='#e5e4e2', GradientType=0 );/* IE6-9 */
  }
  &.GRADIENT_LIGHT_BLUE{
    background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(229,233,237,1) 14%, rgba(138,157,177,1) 64%, rgba(138,157,177,1) 87%); /* ff3.6+ */
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(14%, rgba(229,233,237,1)), color-stop(64%, rgba(138,157,177,1)), color-stop(87%, rgba(138,157,177,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(229,233,237,1) 14%, rgba(138,157,177,1) 64%, rgba(138,157,177,1) 87%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(229,233,237,1) 14%, rgba(138,157,177,1) 64%, rgba(138,157,177,1) 87%); /* opera 11.10+ */
    background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(229,233,237,1) 14%, rgba(138,157,177,1) 64%, rgba(138,157,177,1) 87%); /* ie10+ */
    background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(229,233,237,1) 14%, rgba(138,157,177,1) 64%, rgba(138,157,177,1) 87%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8A9DB1', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
  }
 &.SOLID_LIGHT_BLUE_GREY{
    background: -moz-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,221,221,1) 0%, rgba(199,221,221,1) 100%); /* ff3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(-46%, rgba(192,162,152,1)), color-stop(0%, rgba(199,221,221,1)), color-stop(100%, rgba(199,221,221,1))); /* safari4+,chrome */
    background:-webkit-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,221,221,1) 0%, rgba(199,221,221,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,221,221,1) 0%, rgba(199,221,221,1) 100%); /* opera 11.10+ */
    background: -ms-radial-gradient(center, ellipse cover, rgba(192,162,152,1) -46%, rgba(199,221,221,1) 0%, rgba(199,221,221,1) 100%); /* ie10+ */
    background:radial-gradient(ellipse at center, rgba(192,162,152,1) -46%, rgba(199,221,221,1) 0%, rgba(199,221,221,1) 100%); /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c0a298', endColorstr='#059a78',GradientType=1 ); /* ie6-9 */
 }
 &.GRADIENT_LIGHT_GREY{
  background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(228,229,229,1) 14%, rgba(117,123,121,1) 73%, rgba(117,123,121,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(14%, rgba(228,229,229,1)), color-stop(73%, rgba(117,123,121,1)), color-stop(100%, rgba(117,123,121,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(228,229,229,1) 14%, rgba(117,123,121,1) 73%, rgba(117,123,121,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(228,229,229,1) 14%, rgba(117,123,121,1) 73%, rgba(117,123,121,1) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(228,229,229,1) 14%, rgba(117,123,121,1) 73%, rgba(117,123,121,1) 100%); /* ie10+ */
  background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(228,229,229,1) 14%, rgba(117,123,121,1) 73%, rgba(117,123,121,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#757B79', endColorstr='#ffffff',GradientType=1 ); /* ie6-9 */
 }
 &.GRADIENT_DARK_GREY{
  background: -moz-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(214,213,214,1) 14%, rgba(150,147,149,1) 36%, rgba(105,99,103,1) 52%, rgba(115,109,113,1) 61%, rgba(115,109,113,1) 87%); /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(255,255,255,1)), color-stop(14%, rgba(214,213,214,1)), color-stop(36%, rgba(150,147,149,1)), color-stop(52%, rgba(105,99,103,1)), color-stop(61%, rgba(115,109,113,1)), color-stop(87%, rgba(115,109,113,1))); /* safari4+,chrome */
`;
