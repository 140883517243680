export const routesAccount = {
  notifications: "notifications",
  notificationsPreferences: "notifications-preferences",
  availability: "availability",
  orderUpload: "order-upload",
  orderHistory: "order-history",
  orderDetails: "order-details",
  ordersToBeApproved: "orders-to-be-approved",
  ordersToBeApprovedDetails: "orders-to-be-approved-details",
  ordersToBeApprovedSimulation: "approval-simulation",
  preferences: "preferences",
  addresses: "addresses",
  editPassword: "edit-password",
  manageSubuser: "manage-subuser",
  editSubuser: "edit-subuser",
  creditDetail: "credit-detail",
  credit: "credit",
  invoices: "invoices",
  invoicesDowload: "invoices-download",
  warranty: "warranty",
  warrantyDetail: "warranty-detail",
  serviceRequest: "service-request",
  returns: "returns",
  returnNotification: "return-notification",
  // commercial: "commercial",
  multiDoor: "multi-door",
  contracts: "contracts",
  legalDocuments: "legal-documents",
  approveSubuser: "approve-subuser",
  paymentManagement: "payment-management",
  paymentMethod: "payment-method",
  paymentHistory: "payment-history",
  paymentHistoryDetail: "payment-history-detail",
  paymentDocumentHistory: "payment-document-history",
  paymentStatement: "payment-statement",
  paymentTYP: "payment-typ",
  trafficCounter: "traffic-counter",
  ADTSavedAssets: "ADT-saved-assets",
  ADTDownloadedAssets: "ADT-downloaded-assets",
  ADTAssetPlatforms: "ADT-asset-platforms",
  lensCarnet: "lens-carnet",
  lensCarnetDetail: "lens-carnet-detail",
};

export const routesAccountWithHiddenMenu = [
  "payment-management",
  "payment-method",
  "payment-history",
  "payment-history-detail",
  "payment-document-history",
  "payment-statement",
  "payment-typ",
];

// DO NOT CHANGE IDENTIFIERS, AS THEY ARE USED IN MOST USED TO REVERSE-ENGINEER WHICH MENU ELEMENT WE HAVE SAVED

export const navbarSections: NavbarSection[] = [
  {
    sectionTitle: "ACCOUNT_SECTION",
    links: [
      {
        url: "/account/addresses",
        title: "ADDRESSES_TITLE",
        identifier: "ADDRESSES",
        showInMostUsed: true,
        privilege: "CUSTOMER_INFO",
        dataElementId: "Addresses",
        routes: [routesAccount.addresses],
        onlySubuser: true,
        externalReference: "addresses",
      },
      {
        url: "/account/preferences",
        title: "PREFERENCES_TITLE",
        identifier: "PREFERENCES",
        showInMostUsed: true,
        privilege: "CUSTOMER_INFO",
        dataElementId: "Preferences",
        routes: [routesAccount.preferences],
        onlySubuser: true,
        externalReference: "preferences",
      },
      {
        url: "/account/edit-password",
        title: "EDIT_PASSWORD_TITLE",
        identifier: "EDIT_PASSWORD",
        showInMostUsed: false,
        dataElementId: "EditPassword",
        routes: [routesAccount.editPassword],
      },
      {
        url: "/account/multidoor",
        title: "MULTIDOOR_TITLE",
        identifier: "MULTIDOOR",
        showInMostUsed: true,
        dataElementId: "Multidoor",
        routes: [routesAccount.multiDoor],
        privilege: "MULTIDOOR",
        externalReference: "multidoor",
      },
      {
        url: "/account/lens-carnet",
        title: "LENS_CARNET_TITLE",
        identifier: "LENS_CARNET",
        showInMostUsed: false,
        dataElementId: "LensCarnet",
        routes: [routesAccount.lensCarnet, routesAccount.lensCarnetDetail],
        privilege: "CARNET_BALANCE",
        exclusivePrivilege: true,
        needAccessWithPin: false,
      },
    ],
  },
  {
    sectionTitle: "MESSAGES_SECTION",
    links: [
      {
        url: "/account/notifications",
        title: "NOTIFICATIONS_TITLE",
        identifier: "NOTIFICATIONS",
        showInMostUsed: true,
        dataElementId: "Notifications",
        routes: [routesAccount.notifications, routesAccount.notificationsPreferences],
        serviceId: [-1],
        externalReference: "notifications",
      },
      {
        url: "/account/availability",
        title: "GREEN_IS_BACK_TITLE",
        identifier: "GREEN_IS_BACK",
        showInMostUsed: true,
        dataElementId: "Availability",
        routes: [routesAccount.availability],
        privilege: "GREEN_IS_BACK",
        serviceId: [27],
        externalReference: "availability",
      },
    ],
  },
  {
    sectionTitle: "ORDERS_SECTION",
    links: [
      {
        url: "/account/order-upload",
        title: "ORDER_UPLOAD_TITLE",
        identifier: "ORDER_UPLOAD",
        showInMostUsed: true,
        dataElementId: "OrderUpload",
        routes: [routesAccount.orderUpload],
        privilege: "ORDER_CREATION",
        externalReference: "order-upload",
      },
      {
        url: "/account/order-history",
        title: "ORDER_HISTORY_TITLE",
        identifier: "ORDER_HISTORY",
        showInMostUsed: false,
        dataElementId: "OrderHistory",
        routes: [routesAccount.orderHistory, routesAccount.orderDetails],
        privilege: "ORDER_HISTORY",
      },
      {
        url: "/account/orders-to-be-approved",
        title: "ORDERS_TO_BE_APPROVED_TITLE",
        identifier: "ORDERS_TO_BE_APPROVED",
        showInMostUsed: true,
        dataElementId: "OrdersToBeApproved",
        routes: [
          routesAccount.ordersToBeApproved,
          routesAccount.ordersToBeApprovedDetails,
          routesAccount.ordersToBeApprovedSimulation,
        ],
        privilege: "CAN_APPROVE_ORDERS",
        serviceId: [31],
        externalReference: "orders-to-be-approved",
      },
    ],
  },
  {
    sectionTitle: "AFTERSALES_SECTION",
    links: [
      {
        url: "/account/service-request",
        title: "SERVICE_REQUEST_TITLE",
        identifier: "SERVICE_REQUEST",
        showInMostUsed: false,
        dataElementId: "ServiceRequest",
        privilege: "SERVICE_REQUEST_PAGE",
        routes: [routesAccount.serviceRequest],
        serviceId: [30],
      },
      {
        url: "/account/warranty",
        title: "WARRANTY_TITLE",
        identifier: "WARRANTY_INBOX",
        showInMostUsed: true,
        dataElementId: "Warranty",
        privilege: "AFTER_SALES_REQUEST",
        routes: [
          routesAccount.warranty,
          routesAccount.warrantyDetail,
          routesAccount.returnNotification,
          routesAccount.returns,
        ],
        serviceId: [28],
        externalReference: "frame-warranty",
      },
    ],
  },
  {
    sectionTitle: "ACCOUNTING_SECTION",
    links: [
      {
        url: "/account/credit",
        title: "CREDIT_TITLE",
        identifier: "CREDIT",
        showInMostUsed: true,
        dataElementId: "Credit",
        routes: [routesAccount.creditDetail, routesAccount.credit],
        needAccessWithPin: false,
        privilege: "CREDIT_SECTION",
        exclusivePrivilege: true,
        externalReference: "credit",
      },
      {
        url: "/account/invoices",
        title: "INVOICES_TITLE",
        identifier: "INVOICES",
        showInMostUsed: true,
        dataElementId: "Invoices",
        routes: [routesAccount.invoices, routesAccount.invoicesDowload],
        needAccessWithPin: false,
        privilege: "INVOICE_SECTION",
        exclusivePrivilege: true,
        serviceId: [4, 5],
        externalReference: "invoices",
      },
      {
        url: "/account/payment-management",
        routes: [],
        title: "PAYMENT_SECTION_TITLE",
        identifier: "PAYMENT_SECTION",
        showInMostUsed: false,
        isAccordion: true,
        privilege: "PAYMENT",
        hideInNavbar: true,
        accordionList: [
          {
            url: "/account/payment-management",
            title: "PAYMENT_MANAGEMENT_TITLE",
            identifier: "PAYMENT_MANAGEMENT",
            dataElementId: "PaymentManagement",
            routes: [routesAccount.paymentManagement, routesAccount.paymentTYP],
            needAccessWithPin: true,
            privilege: "PAYMENT",
            hideInNavbar: true,
          },
          {
            url: "/account/payment-method",
            title: "PAYMENT_METHOD_TITLE",
            identifier: "PAYMENT_METHOD",
            dataElementId: "PaymentMethod",
            routes: [routesAccount.paymentMethod],
            needAccessWithPin: true,
            privilege: "PAYMENT_FULL_MODE",
            isNotBOUser: true,
            hideInNavbar: true,
          },
          {
            url: "/account/payment-history",
            title: "PAYMENT_HISTORY_LIST_TITLE",
            identifier: "PAYMENT_HISTORY_LIST",
            dataElementId: "PaymentHistory",
            routes: [routesAccount.paymentHistory, routesAccount.paymentHistoryDetail],
            needAccessWithPin: true,
            privilege: "PAYMENT",
            hideInNavbar: true,
          },
          {
            url: "/account/payment-document-history",
            title: "PAYMENT_DOCUMENT_HISTORY_TITLE",
            identifier: "PAYMENT_DOCUMENT_HISTORY",
            dataElementId: "PaymentDocumentHistory",
            routes: [routesAccount.paymentDocumentHistory],
            needAccessWithPin: true,
            privilege: "PAYMENT",
            hideInNavbar: true,
          },
          {
            url: "/account/payment-statement",
            title: "PAYMENT_STATEMENT_TITLE",
            identifier: "PAYMENT_STATEMENT",
            dataElementId: "PaymentStatement",
            routes: [routesAccount.paymentStatement],
            needAccessWithPin: true,
            privilege: "PAYMENT",
            hideInNavbar: true,
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "ASSETS_SECTION",
    links: [
      {
        url: "/account/asset-platforms",
        title: "ADT_ASSET_PLATFORMS_TITLE",
        identifier: "ADT_ASSET_PLATFORMS",
        showInMostUsed: false,
        dataElementId: "AssetPlatforms",
        routes: [routesAccount.ADTAssetPlatforms],
        privilege: "ADT_CATEGORY",
        exclusivePrivilege: true,
      },
      {
        url: "/account/saved-assets",
        title: "ADT_SAVED_ASSETS_TITLE",
        identifier: "ADT_SAVED_ASSETS",
        showInMostUsed: true,
        dataElementId: "SavedAssets",
        routes: [routesAccount.ADTSavedAssets],
        privilege: "ADT_CATEGORY",
        exclusivePrivilege: true,
        externalReference: "saved-assets",
      },
      {
        url: "/account/downloaded-assets",
        title: "ADT_DOWNLOADED_ASSETS_TITLE",
        identifier: "ADT_DOWNLOADED_ASSETS",
        showInMostUsed: true,
        dataElementId: "DownloadedAssets",
        routes: [routesAccount.ADTDownloadedAssets],
        privilege: "ADT",
        exclusivePrivilege: true,
        serviceId: [48, 49],
        externalReference: "downloaded-assets",
      },
    ],
  },
  {
    sectionTitle: "DOCUMENTS_SECTION",
    links: [
      // {
      //   url: "/account/commercial",
      //   title: "COMMERCIAL_TITLE",
      //   routes: [routesAccount.commercial],
      // },
      {
        url: "/account/legal-documents",
        title: "LEGAL_DOCUMENTS_TITLE",
        identifier: "LEGAL_DOCUMENTS",
        showInMostUsed: true,
        dataElementId: "LegalDocuments",
        routes: [routesAccount.legalDocuments],
        privilege: "WE_AGREE",
        notShowForSubuser: true,
        serviceId: [34],
        externalReference: "legal-documents",
      },
      {
        url: "/account/contracts",
        title: "POLICIES_PAGE_TITLE",
        identifier: "POLICIES_PAGE",
        showInMostUsed: true,
        dataElementId: "PolicyMyEssilorLuxottica",
        routes: [routesAccount.contracts],
        externalReference: "policies",
      },
    ],
  },
  {
    sectionTitle: "CONTACT_SECTION",
    links: [
      {
        url: "/account/manage-subuser",
        title: "MANAGE_USER_TITLE",
        identifier: "MANAGE_USER",
        showInMostUsed: true,
        dataElementId: "Manage subuser",
        routes: [routesAccount.manageSubuser, routesAccount.editSubuser],
        privilege: "USER_MANAGEMENT_SECTION",
        externalReference: "manage-subuser",
      },
      {
        url: "/account/approve-subuser",
        title: "APPROVE_SUBUSER_TITLE",
        identifier: "APPROVE_SUBUSER",
        showInMostUsed: false,
        dataElementId: "ApproveSubuser",
        routes: [routesAccount.approveSubuser],
        privilege: "LEONARDO_SUBUSERS_APPROVAL",
        countApproveSubuser: true,
        serviceId: [17],
      },
    ],
  },
  {
    sectionTitle: "PROGRAMS_AND_REWARDS_SECTION",
    links: [
      {
        url: "",
        title: "360_MEMBERS_TITLE",
        identifier: "360_MEMBERS",
        showInMostUsed: false,
        dataElementId: "360Members",
        privilege: "EL_360",
        routes: [],
        exclusivePrivilege: true,
      },
      {
        url: "",
        title: "REWARDS_TITLE",
        identifier: "REWARDS",
        showInMostUsed: false,
        dataElementId: "Rewards",
        privilege: "REWARDS",
        routes: [],
        exclusivePrivilege: true,
      },
    ],
  },
  {
    sectionTitle: "SMART_DASHBOARD_SECTION",
    links: [
      {
        url: "/account/traffic-counter",
        title: "TRAFFIC_COUNTER_TITLE",
        identifier: "TRAFFIC_COUNTER",
        showInMostUsed: true,
        dataElementId: "TrafficCounter",
        routes: [routesAccount.trafficCounter],
        privilege: "TRAFFIC_COUNTER",
        externalReference: "traffic-counter",
      },
    ],
  },
];

export type NavbarSection = {
  sectionTitle: string;
  links: NavbarSectionLink[];
};

export type NavbarSectionLink = {
  url: string;
  title: string;
  identifier: string;
  showInMostUsed?: boolean;
  showAsDefaultInMostUsed?: boolean;
  dataElementId?: string;
  routes: string[];
  privilege?: string;
  onlySubuser?: boolean;
  isNotBOUser?: boolean; // if true the link is not displayed to BO users
  hideInNavbar?: boolean;
  needAccessWithPin?: boolean;
  countApproveSubuser?: boolean;
  isAccordion?: boolean;
  accordionList?: NavbarSectionLink[];
  exclusivePrivilege?: boolean;
  notShowForSubuser?: boolean;
  serviceId?: number[];
  externalReference?: string;
};
