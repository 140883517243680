import clsx from "clsx";
import React from "react";
import styled from "styled-components/macro";

import { ReactComponent as Checkmark } from "../../assets/icons/checkmark-icon.svg";
import { ReactComponent as FilePreview } from "../../assets/icons/note-icon.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin-icon.svg";
import { ReactComponent as Plus } from "../../assets/icons/plus-icon.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x-icon.svg";

export type ChipStyle =
  | "border"
  | "bg-gray"
  | "dark-on-selected"
  | "no-rounded"
  | "blue-on-selected";
export type TypeIcon = "plus" | "file-preview" | "checkmark";

interface ChipProps {
  text: string;
  active?: boolean;
  disabled?: boolean;
  fixed?: boolean;
  onClick?: () => void;
  type?: "light" | "dark";
  letterCase?: "uppercase" | "capitalize";
  size?: "sm" | "md";
  hasMargin?: boolean;
  hideIcon?: boolean;
  "data-element-id"?: string;
  defaultCursor?: boolean;
  style?: ChipStyle;
  customColor?: string;
  noMaxWidth?: boolean;
  showTitle?: boolean;
  maxWidth?: string;
  deleteIcon?: JSX.Element;
  showFileIcon?: boolean;
  iconWithoutMargin?: boolean;
  spacedContent?: boolean;
  customSpacedContent?: number;
  borderRadiusPx?: number;
  widthPx?: number;
  heightPx?: number;
  paddingTopPx?: number;
  paddingBottomPx?: number;
  paddingLeftPx?: number;
  paddingRightPx?: number;
  gapPx?: number;
  justifyContent?: string;
  fontSize?: number;
  lineHeightPx?: number;
  letterSpacingPx?: number;
  fontWeight?: number;
  typeIcon?: TypeIcon;
  onSelectedColor?: string;
  widthText?: number;
  gapText?: number;
  border?: string;
  noHover?: boolean;
  inheritWidth?: boolean;
  customBackgroundColor?: string;
  noBorders?: boolean;
}

const Chip = ({
  text,
  active = false,
  disabled = false,
  fixed = false,
  onClick,
  hasMargin = false,
  type = "light",
  letterCase,
  size = "md",
  hideIcon,
  defaultCursor = false,
  style = "border",
  customColor,
  noMaxWidth = false,
  showTitle = false,
  maxWidth = "16.5rem",
  deleteIcon = <XIcon />,
  typeIcon = "file-preview",
  showFileIcon = false,
  spacedContent = false,
  customSpacedContent,
  borderRadiusPx,
  widthPx,
  heightPx,
  paddingTopPx,
  paddingBottomPx,
  paddingLeftPx,
  paddingRightPx,
  gapPx,
  justifyContent,
  fontSize,
  lineHeightPx,
  letterSpacingPx,
  fontWeight,
  onSelectedColor,
  iconWithoutMargin,
  widthText,
  border,
  noHover,
  inheritWidth,
  customBackgroundColor,
  noBorders,
}: ChipProps): JSX.Element => {
  const handleIcon = () => {
    if (fixed && active) {
      return <PinIcon />;
    }
    if (active) {
      return deleteIcon;
    }
    if (!active && !fixed) {
      return <Plus />;
    }
  };

  const handleFileIcon = () => {
    switch (typeIcon) {
      case "plus":
        return <Plus />;
      case "checkmark":
        return <Checkmark />;
      case "file-preview":
      default:
        return <FilePreview />;
    }
  };

  return (
    <StyledContainer
      className={clsx(
        active && "active",
        fixed && "fixed",
        type === "dark" && "chip-dark",
        style === "bg-gray" && "bg-gray",
        style === "dark-on-selected" && "dark-on-selected",
        style === "no-rounded" && "no-rounded",
        style === "blue-on-selected" && "blue-on-selected",
        letterCase === "uppercase" && "chip--uppercase",
        letterCase === "capitalize" && "chip--capitalize",
        size === "sm" && "chip-sm",
        hasMargin && "has-margin",
        disabled && "disabled",
        noHover && "no-hover",
        customBackgroundColor && "custom-background-color",
        noBorders && "no-borders"
      )}
      onClick={() => {
        !disabled && !fixed && onClick?.();
      }}
      defaultCursor={defaultCursor}
      maxWidth={maxWidth}
      noMaxWidth={noMaxWidth}
      borderRadiusPx={borderRadiusPx}
      widthPx={widthPx}
      heightPx={heightPx}
      paddingTopPx={paddingTopPx}
      paddingBottomPx={paddingBottomPx}
      paddingLeftPx={paddingLeftPx}
      paddingRightPx={paddingRightPx}
      gapPx={gapPx}
      justifyContent={justifyContent}
      fontSize={fontSize}
      border={border}
      customBackgroundColor={customBackgroundColor}
    >
      <TextContainer
        width={widthText}
        gap={customSpacedContent}
        className={clsx(spacedContent && "has-space-between", inheritWidth && "inherit-width")}
      >
        {showFileIcon && (
          <StyledIcon
            className={clsx(
              type === "dark" && "chip-icon-dark",
              iconWithoutMargin && "icon-without-margin"
            )}
          >
            {handleFileIcon()}
          </StyledIcon>
        )}
        <StyledText
          className={clsx(
            type === "dark" && "chip-text-dark",
            active && "active",
            style === "dark-on-selected" && "dark-on-selected",
            style === "blue-on-selected" && "blue-on-selected"
          )}
          customColor={customColor && customColor != "No Colour" ? customColor : ""}
          {...(showTitle && { title: text })}
          lineHeightPx={lineHeightPx}
          letterSpacingPx={letterSpacingPx}
          fontWeight={fontWeight}
        >
          {text}
        </StyledText>
        {!hideIcon && (
          <StyledIcon className={clsx(type === "dark" && "chip-icon-dark")}>
            {handleIcon()}
          </StyledIcon>
        )}
      </TextContainer>
    </StyledContainer>
  );
};

interface StyledContainer {
  defaultCursor: boolean;
  maxWidth: string;
  noMaxWidth: boolean;
  borderRadiusPx?: number;
  widthPx?: number;
  heightPx?: number;
  paddingTopPx?: number;
  paddingBottomPx?: number;
  paddingLeftPx?: number;
  paddingRightPx?: number;
  gapPx?: number;
  justifyContent?: string;
  fontSize?: number;
  border?: string;
  customBackgroundColor?: string;
}

const StyledContainer = styled.div<StyledContainer>`
  display: inline-flex;
  border-radius: 5rem;
  border: ${(props) =>
    props.border ??
    `solid 2px
    ${props.theme.palette.gray.medium}`};
  padding: ${(props) =>
    props.paddingTopPx || props.paddingBottomPx || props.paddingLeftPx || props.paddingRightPx
      ? `unset;`
      : `0.875rem 1.125rem;`};
  cursor: ${(props) => (props.defaultCursor ? "default" : "pointer")};
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize / props.theme.baseFontSize}rem;` : `0.875rem;`};
  max-width: ${(props) => (props.noMaxWidth ? "unset" : props.maxWidth)};
  text-align: center;
  ${(props) =>
    props.borderRadiusPx && `border-radius: ${props.borderRadiusPx / props.theme.baseFontSize}rem;`}
  ${(props) => props.widthPx && `width: ${props.widthPx / props.theme.baseFontSize}rem;`}
  ${(props) => props.heightPx && `height: ${props.heightPx / props.theme.baseFontSize}rem;`}
  ${(props) =>
    props.paddingTopPx && `padding-top: ${props.paddingTopPx / props.theme.baseFontSize}rem;`}
  ${(props) =>
    props.paddingBottomPx &&
    `padding-bottom: ${props.paddingBottomPx / props.theme.baseFontSize}rem;`}
  ${(props) =>
    props.paddingLeftPx && `padding-left: ${props.paddingLeftPx / props.theme.baseFontSize}rem;`}
  ${(props) =>
    props.paddingRightPx && `padding-right: ${props.paddingRightPx / props.theme.baseFontSize}rem;`}
    ${(props) => props.gapPx && `gap: ${props.gapPx / props.theme.baseFontSize}rem;`}
    ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
    
    &.custom-background-color {
    background-color: ${(props) => props.customBackgroundColor}!important;
  }
  &.active {
    border-color: ${(props) => props.theme.palette.primary};
  }

  &.chip-dark {
    border-color: ${(props) => props.theme.palette.bordersBlue};
    background-color: transparent;
  }

  /* no hover con la chip-dark */
  &:not(.chip-dark):hover {
    border-color: ${(props) => props.theme.palette.gray.dark};
  }

  &.no-hover {
    &:hover {
      border: ${(props) =>
        props.border ??
        `solid 2px
        ${props.theme.palette.gray.medium}`};
    }
  }

  &.chip--uppercase {
    text-transform: uppercase;
  }

  &.chip--capitalize {
    text-transform: capitalize;
  }

  &.chip-sm {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
  }

  &.has-margin {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    &:not(.chip-dark):hover {
      border-color: ${(props) => props.theme.palette.gray.medium};
    }
  }

  &.bg-gray {
    background-color: ${(props) => props.theme.palette.gray.medium};
    border-color: ${(props) => props.theme.palette.gray.medium};

    &:hover {
      border-color: ${(props) => props.theme.palette.gray.medium};
    }
  }

  &.dark-on-selected {
    &.active {
      background-color: ${(props) => props.theme.palette.primary};
      border: none;
    }
  }

  &.blue-on-selected {
    &.active {
      background-color: ${(props) => props.theme.palette.chip.gray};
      border: solid 1px ${(props) => props.theme.palette.chip.blue};
    }
  }

  &.no-rounded {
    border-radius: 0.25rem;
  }

  &.no-borders {
    border-color: unset;
    border: unset;
  }
`;

const TextContainer = styled.div<{ gap?: number; width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.width && `width: ${props.width / props.theme.baseFontSize}rem;`}

  &.has-space-between {
    display: inline-flex;
    justify-content: space-between;
    gap: ${(props) => (props.gap ? `${props.gap / props.theme.baseFontSize}rem;` : `0.25rem;`)};
  }

  &.inherit-width {
    width: inherit;
  }
`;

interface StyledText {
  customColor?: string;
  lineHeightPx?: number;
  letterSpacingPx?: number;
  fontWeight?: number;
}
const StyledText = styled.p<StyledText>`
  color: ${(props) => props.theme.palette.primary};
  ${(props) => props.customColor && `color: ${props.customColor}!important`};
  overflow: hidden;
  text-overflow: ellipsis;
  // height: 1rem;
  line-height: ${(props) =>
    props.lineHeightPx ? `${props.lineHeightPx / props.theme.baseFontSize}rem;` : `1rem;`};
  ${(props) =>
    props.letterSpacingPx &&
    `letter-spacing:${props.letterSpacingPx / props.theme.baseFontSize}rem;`}
  ${(props) => props.fontWeight && `font-weight:${props.fontWeight};`}
  &.dark-on-selected.active {
    color: ${(props) => props.theme.palette.white};
  }

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: -webkit-fill-available;

  &.uppercase {
    text-transform: uppercase;
  }

  &.chip-text-dark {
    color: ${(props) => props.theme.palette.white};
  }
`;

const StyledIcon = styled.span`
  margin-left: 0.625rem;
  width: 1rem;
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
    fill: ${(props) => props.theme.palette.primary};
  }

  &.chip-icon-dark svg {
    fill: ${(props) => props.theme.palette.white};
  }

  &.icon-without-margin {
    margin-left: 0rem;
  }
`;

export default Chip;
