import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import useTranslation from "../../../../hooks/useTranslation";

import { useParams } from "react-router";
import { ReactComponent as XIcon } from "../../../../assets/icons/x-icon.svg";
import theme from "../../../../assets/theme/theme";
import { SearchCategories } from "../../../../store/search/searchInterfaces";
import { getSearchCategories } from "../../../../store/search/searchSagas";
import { selectSearchCategories } from "../../../../store/search/searchSlice";
import {
  selectCanBuyModelsGlobal,
  selectIsMultidoor,
  selectLocale,
} from "../../../../store/user/userSlice";
import CustomSelect from "../../../styled-UI/CustomSelect";
import CustomText from "../../../styled-UI/CustomText";
import Search from "../../../styled-UI/Search";
import WrapperLayout from "../../WrapperLayout";

interface Props {
  closeExpandMenu: () => void;
  "data-element-id"?: string;
  height: number;
  isStripVisible: boolean;
}

const SearchContainer = ({
  closeExpandMenu,
  "data-element-id": dataElementId,
  height,
  isStripVisible,
}: Props): JSX.Element => {
  const { translateLabel, labels } = useTranslation();
  const dispatch = useDispatch();
  const [currentValueSearch, setCurrentValueSearch] = useState<string>("");
  const [defaultCategory, setDefaultCategory] = useState<SearchCategories>({
    label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
    value: "DEFAULT",
  });
  const [selectedCategory, setSelectedCategory] = useState<SearchCategories>(defaultCategory);
  const receivedCategories = useSelector(selectSearchCategories);
  const [categoryOptions, setCategoryOptions] = useState<SearchCategories[]>([defaultCategory]);

  const currentLocale = useSelector(selectLocale);
  const searchCategories = useSelector(selectSearchCategories);
  const canBuyModelsGlobal = useSelector(selectCanBuyModelsGlobal);
  const marginTop = theme.headerHeightRem;
  const isMultidoor = useSelector(selectIsMultidoor);
  const searchMenuRef = useRef<HTMLDivElement | null>(null);
  const ignoreClickRef = useRef(false);
  const [isSelectOpened, setIsSelectOpened] = useState<boolean>(false);

  const { term } = useParams() as {
    term: string;
  };

  //set category according to filter in url
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams && receivedCategories) {
      let chipValue: string | undefined;
      queryParams.forEach((value, key) => {
        if (key === "PRODUCT_CATEGORY_CHIPS") {
          chipValue = value;
        }
      });

      if (chipValue) {
        const option = receivedCategories.find((_) => _.value === chipValue);
        if (option) setSelectedCategory(option);
      }
    }
  }, [location, receivedCategories]);

  useEffect(() => {
    if (searchCategories.length === 0) {
      dispatch(getSearchCategories());
    }
  }, [currentLocale]);

  //when label changes update search select
  useEffect(() => {
    if (labels) {
      setDefaultCategory({
        label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
        value: "DEFAULT",
      });
      setSelectedCategory({
        label: translateLabel("CATEGORIES_ALL_CATEGORIES"),
        value: "DEFAULT",
      });
    }
  }, [labels]);

  //set value in search from url
  useEffect(() => {
    if (term) {
      setCurrentValueSearch(decodeURIComponent(term));
    } else {
      currentValueSearch && setCurrentValueSearch("");
    }
  }, [term]);

  //save options once they arrive from service
  useEffect(() => {
    if (canBuyModelsGlobal) {
      setCategoryOptions([defaultCategory, ...receivedCategories]);
    }
  }, [defaultCategory, receivedCategories, canBuyModelsGlobal]);

  //MANAGE THE CLICK OUTSIDE THE MENU IN ORDER TO CLOSE IT
  useEffect(() => {
    function handler(event: MouseEvent) {
      if (ignoreClickRef.current) {
        ignoreClickRef.current = false;
        return;
      }

      const target = (event.target as Node) || null;
      if (searchMenuRef.current && !searchMenuRef.current.contains(target)) {
        closeExpandMenu();
        event.stopPropagation();
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);

  return (
    <>
      <SearchWrapper
        ref={searchMenuRef}
        id="search-container"
        marginTop={marginTop}
        menuUpdatedHeight={height}
        isSelectOpen={isSelectOpened}
        isStripVisible={isStripVisible}
      >
        <WrapperLayout>
          <SearchHeader>
            <CustomText
              as="p"
              fontSizePx={28}
              font="font-regular"
              fontWeightNum={600}
              lineHeightPx={28}
            >
              {translateLabel("HEADER_SEARCH_PLACEHOLDER")}
            </CustomText>
            <IconContainerEnd>
              <StyledButton onClick={closeExpandMenu}>
                <XIcon />
              </StyledButton>
            </IconContainerEnd>
          </SearchHeader>
          <SelectWrapper>
            <CustomSelect
              type="header"
              onChange={(option) => {
                setSelectedCategory(option as SearchCategories);
              }}
              onMenuClosed={() => {
                ignoreClickRef.current = true;
                setIsSelectOpened(false);
              }}
              options={categoryOptions}
              value={selectedCategory}
              isSearchable={false}
              onMenuOpen={() => setIsSelectOpened(true)}
            ></CustomSelect>
            <Search
              placeholder={translateLabel("HEADER_SEARCH_PLACEHOLDER_NEW")}
              category={selectedCategory}
              closeExpandMenu={closeExpandMenu}
              value={currentValueSearch}
              setIsSelectOpen={setIsSelectOpened}
              {...(dataElementId ? { "data-element-id": dataElementId } : {})}
            />
          </SelectWrapper>
        </WrapperLayout>
      </SearchWrapper>
      <Overlay isStripVisible={isStripVisible}></Overlay>
    </>
  );
};

const SearchWrapper = styled.div<{
  marginTop?: number;
  menuUpdatedHeight?: number;
  isSelectOpen?: boolean;
  isStripVisible?: boolean;
}>`
  background-color: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 0;
  top: ${(props) =>
    (props.isStripVisible
      ? props.marginTop
      : (props.marginTop || props.theme.headerHeightRem) - props.theme.stripHeightRem) + "rem"};
  width: 100%;
  border-top: solid 1px ${(props) => props.theme.palette.gray.medium};
  z-index: 200;
  gap: 3.5rem;
  padding: 2rem 0rem 3rem;
  box-shadow: 0px 4px 10px 0px #0000001a inset;
  height: ${(props) => {
    if (props.isSelectOpen)
      if (props.menuUpdatedHeight) {
        return (
          (props.theme.searchContainerHeightPx <= props.menuUpdatedHeight
            ? props.theme.searchContainerHeightPx
            : props.menuUpdatedHeight) + "px"
        );
      } else {
        return props.theme.searchContainerHeightPx + "px";
      }

    if (props.menuUpdatedHeight) {
      return props.menuUpdatedHeight + "px";
    } else return "auto";
  }};

  &.menu-multidoor {
    top: ${(props) => props.marginTop + props.theme.multidoorBannerHeightRem + "rem"};
  }

  @media screen and (max-width: 1366px) {
    max-width: 1366px;
  }
`;

const SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 4fr;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  &:focus {
    border: unset;
  }
  .custom-select__menu {
    border-bottom: 1px solid ${(props) => props.theme.palette.gray.borders};
  }
`;

const IconContainerEnd = styled.div`
  display: flex;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
`;

const StyledButton = styled.button`
  cursor: pointer;
  svg {
    fill: ${(props) => props.theme.palette.primary};
    height: 1.2rem;
    width: 1.2rem;
  }
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.25rem;
`;

interface OverlayProps {
  isStripVisible: boolean;
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: ${(props) =>
    props.isStripVisible
      ? props.theme.headerHeightRem + "rem"
      : props.theme.headerHeightRem - props.theme.stripHeightRem + "rem"};
  left: 0;
  width: 100%;
  background-color: rgba(1, 9, 23, 0.8);
  height: calc(
    100vh -
      ${(props) =>
        props.isStripVisible
          ? props.theme.headerHeightRem + "rem"
          : props.theme.headerHeightRem - props.theme.stripHeightRem + "rem"}
  );
`;
export default SearchContainer;
