import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import styled from "styled-components";
import useTranslation from "../../../../hooks/useTranslation";
import { ItemsTutorialPillMap } from "../../../../interfaces/gridInterfaces";
import { authRoutes } from "../../../../routing/authRoutes";
import { ContextTutorialPill, TutorialPill } from "../../../../store/catalogue/catalogueInterface";
import { getCmsContentPopup } from "../../../../store/store/storeSagas";
import {
  selectCanRenderPopupTutorialPills,
  selectLxConfigurations,
  selectPopupMap,
  selectTutorialPills,
} from "../../../../store/store/storeSlice";
import { updateTutorialPills } from "../../../../store/user/userSagas";
import {
  selectTutorialPillsPopup,
  setContextTutorialPillsPopup,
} from "../../../../store/user/userSlice";
import Carousel from "../../../styled-UI/Carousel";
import Popup from "../../../styled-UI/Popup";
import FeatureTile from "./FeatureTile";
import TagNewFeatures from "./TagNewFeatures";

function ExploreFeatures(): JSX.Element {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [externalRef, setExternalRef] = useState<string>();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const tutorialPills = useSelector(selectTutorialPills);
  const canRender = useSelector(selectCanRenderPopupTutorialPills);
  const userContextTutorialPills = useSelector(selectTutorialPillsPopup);
  const { translateLabel } = useTranslation();
  const dispatch = useDispatch();
  const popupMap = useSelector(selectPopupMap);
  const [popupContent, setPopupContent] = useState<TutorialPill[] | undefined>(undefined);
  const url = useLocation();
  const LXConfiguration = useSelector(selectLxConfigurations);
  const automaticPopupConfig =
    LXConfiguration?.filter((element) => element.key === "tutorial.pills.popup")?.[0]?.value ===
    "true";
  const [localContextTutorialPills, setLocalContextTutorialPills] = useState<
    ContextTutorialPill | null | undefined
  >(undefined);

  const getExternalReference = (): string | undefined => {
    let namePath: string | undefined;
    for (let i = 0; i < authRoutes.length; i++) {
      const pathRoot = authRoutes[i].path;

      const matchpath = matchPath(url.pathname, {
        path: pathRoot,
        exact: true,
        strict: false,
      });

      if (matchpath !== null) {
        return (namePath = authRoutes[i].externalReference);
      }
    }
    return namePath;
  };

  const isEmpty = (obj: ItemsTutorialPillMap) => {
    return Object.keys(obj).length === 0;
  };

  const getIdsAsString = (obj: ContextTutorialPill): string => {
    const idsString: string = Object.keys(obj).join(",");
    return idsString;
  };

  const setContextTutorialPills = (
    context: ContextTutorialPill | undefined | null,
    obj: TutorialPill[]
  ): ContextTutorialPill => {
    if (!context) context = {};
    for (const item of obj) context[item.id] = item.id;
    return context;
  };

  function mustOpenPopup(obj1: TutorialPill[], obj2: ContextTutorialPill): boolean {
    for (const item of obj1) if (!obj2.hasOwnProperty(item.id)) return true;
    return false;
  }

  useEffect(() => {
    if (tutorialPills) {
      const externalReference = getExternalReference();
      if (externalReference) {
        setExternalRef(externalReference);
        setPopupContent(
          tutorialPills.filter((pill: TutorialPill) => {
            return (
              pill.externalReference === externalReference &&
              pill.layoutVariant === "Tp-tab-overlay"
            );
          })
        );
      } else {
        setPopupContent([]);
      }
    }
  }, [tutorialPills, location.pathname]);

  useEffect(() => {
    if (popupContent?.length)
      popupContent.map((content) => {
        if (!popupMap[content.id]) dispatch(getCmsContentPopup({ id: content.id }));
      });
  }, [popupContent]);

  useEffect(() => {
    if (!isEmpty(popupMap) && popupContent?.length) {
      popupContent.map((content) => {
        if (popupMap?.[content.id]) setIsAvailable(true);
        else setIsAvailable(false);
      });
    } else setIsAvailable(false);
    canRender === false && setIsAvailable(canRender);
  }, [popupMap, popupContent, canRender]);

  useEffect(() => {
    if (popupContent) {
      const context = setContextTutorialPills({ ...localContextTutorialPills }, [...popupContent]);
      if (
        !isEqual(context, userContextTutorialPills) &&
        !isEqual(context, localContextTutorialPills)
      ) {
        if (
          externalRef &&
          automaticPopupConfig &&
          localContextTutorialPills &&
          mustOpenPopup(popupContent, localContextTutorialPills)
        ) {
          setOpenPopup(true);
        }
        // EDGE CASE - User never logged in -> it doesn't have a localContextTutorialPills
        if (popupContent?.length && !localContextTutorialPills) {
          setOpenPopup(true);
        }
      }
    }
  }, [localContextTutorialPills, popupContent, automaticPopupConfig]);

  useEffect(() => {
    if (!isEqual(userContextTutorialPills, localContextTutorialPills)) {
      setLocalContextTutorialPills({ ...userContextTutorialPills });
    }
  }, [userContextTutorialPills]);

  useEffect(() => {
    if (openPopup && popupContent) {
      const context = setContextTutorialPills({ ...localContextTutorialPills }, [...popupContent]);
      if (
        !isEqual(context, userContextTutorialPills) &&
        !isEqual(context, localContextTutorialPills)
      ) {
        dispatch(setContextTutorialPillsPopup({ ...context }));
        dispatch(updateTutorialPills(getIdsAsString(context)));
      }
    }
  }, [popupContent, openPopup, userContextTutorialPills, localContextTutorialPills]);

  const renderTiles = (): JSX.Element[] => {
    const tiles: JSX.Element[] = [];

    if (popupContent?.length && !isEmpty(popupMap))
      popupContent.map((content, index) => {
        tiles.push(
          <FeatureTile
            key={popupMap?.[content.id]?.id || "" + index}
            title={popupMap?.[content.id]?.title}
            description={popupMap?.[content.id]?.description}
            srcImg={popupMap?.[content.id]?.srcImg}
            linkString={popupMap?.[content.id]?.linkString}
            linkLabel={popupMap?.[content.id]?.linkLabel}
            openInNewTab={popupMap?.[content.id]?.openInNewTab}
            externalLink={popupMap?.[content.id]?.externalLink}
            slideNumber={index + 1}
            totalSlide={popupContent?.length}
            currentPage={currentPage}
            id={content.id}
            data-element-id="Overlay_TutorialPills"
            closePopup={callbackOnClosePage}
          />
        );
      });
    return tiles.length ? tiles : [<></>];
  };

  const callbackOnClosePage = () => {
    setOpenPopup(false);
  };

  return (
    <>
      {isAvailable && !openPopup && (
        <TagNewFeatures clickFeatures={setOpenPopup} data-element-id="TutorialPillsOpen" />
      )}
      {isAvailable && (
        <Popup
          isOpen={openPopup}
          title={translateLabel("TUTORIAL_PILLS_POPUP_TITLE")}
          close={() => setOpenPopup(false)}
          data-element-id-close="Overlay_TutorialPills_Close"
        >
          <CarouselContainer>
            <Carousel
              key="images-carousel"
              lazyLoad="progressive"
              slidesToScroll={1}
              slidesToShow={1}
              navigationType="tutorialpills-popup"
              data-element-id={"Overlay_TutorialPills"}
              callbackOnChangeSlide={(page: number) => {
                setCurrentPage(page);
              }}
              callbackOnClosePage={callbackOnClosePage}
            >
              {renderTiles()}
            </Carousel>
          </CarouselContainer>
        </Popup>
      )}
    </>
  );
}

const CarouselContainer = styled.div`
  width: 61rem;
  height: 29.125rem;
`;

export default ExploreFeatures;
