import React from "react";
import ContentLoader from "react-content-loader";

interface Props {
  width?: number | string;
  height?: number | string;
}
const LoaderHomeTile = ({ width = "100%", height = 550 }: Props): JSX.Element => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    // viewBox="0 0 444 550"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="653" y="90" rx="0" ry="0" width={width} height="126" />
    <rect x="793" y="41" rx="1" ry="1" width={width} height="18" />
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </ContentLoader>
);

export default LoaderHomeTile;

export const MemoizedLoaderHomeTile = React.memo(LoaderHomeTile);
